import { Component, OnInit, Inject } from '@angular/core';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-owner-colaborator-schedule',
  templateUrl: './owner-colaborator-schedule.component.html',
  styleUrls: ['./owner-colaborator-schedule.component.css']
})
export class OwnerColaboratorScheduleComponent implements OnInit {

  collaboratorScheduleModel={
      main:{
        uuid:'',
         // Horarios rotativo
        rotating:false,

      },
      system:{
          // Colaborador al que se le asigna el horario
        collaborator:{
          main:{
            uuid:''
          }
        },
        // Horarios asigando
        workingHours:{
          main:{
            uuid:''
          }
        },
        // Activo o inactivo
        status: false,

      }
  }

  workingHoursMin={
    system:{
      company:{
        main:{
          uuid:''
        }
      },
      status:true,
    }
  }

  workingHoursList=[];

  
  object = JSON.parse(JSON.stringify(this.collaboratorScheduleModel));

  constructor(public loadingService: LoadingService, private session: SessionService, public dialogRef: MatDialogRef<OwnerColaboratorScheduleComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getWorkingHours();
    if(this.data != 'new') {
      this.object.system.collaborator.main.uuid = this.data;
      this.session.getRequest('collaboratorSchedule:findByCollaborator', {system:{collaborator:{main:{uuid: this.data}}}}).subscribe(
        (data: any) => {
          if(data.object.main.uuid !=''){
            this.object = data.object;
          }
          this.loadingService.hide();
        }, error => {
          console.log('Error: collaboratorSchedule:findByCollaborator', error);
          this.loadingService.hide();
        });
    }
  }

  getWorkingHours(){
    this.loadingService.wait();
    this.workingHoursMin.system.company.main.uuid= this.session.getSessionObject().main.uuid;
    this.session.getRequest('workingHours:findAllByCompanyAndStatus', this.workingHoursMin).subscribe(
      (data: any) => {
        this.workingHoursList = data.object.instanceList;
        this.loadingService.hide();
      }, error => {
        console.log('Error: workingHours:findAllByCompanyAndStatus', error);
        this.loadingService.hide();
    });
  }

  
  send(object) {
    this.loadingService.wait();
    this.session.getRequest('collaboratorSchedule:update', this.object).subscribe(
      (data: any) => {
        this.object = data.object;
        this.loadingService.hide();
        this.close(true);
      }, error => {
        console.log('Error: collaboratorSchedule:update', error);
        this.loadingService.hide();
      });
  }



   /*
   * Cierra el modal
   */
   close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'collaboratorSchedule:001';
    }
    this.dialogRef.close(object);
  }

}
