import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import * as moment from 'moment';
import { startOfDay, endOfDay, addMinutes, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { OwnerMedicalAppointmentDetailComponent } from './owner-medical-appointment-detail/owner-medical-appointment-detail.component';
import { OwnerMedicalAppointmentUpdateComponent } from './owner-medical-appointment-update/owner-medical-appointment-update.component';
import { OwnerMedicalNotePatientComponent} from './owner-medical-note-patient/owner-medical-note-patient.component';

@Component({
  selector: 'app-owner-medical-consultation',
  templateUrl: './owner-medical-consultation.component.html',
  styleUrls: ['./owner-medical-consultation.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class OwnerMedicalConsultationComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;

  view: CalendarView = CalendarView.Week;

  CalendarView = CalendarView;

  viewDate: Date = new Date();
  todayViewDate:Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  colors: any = {
    red: {
      primary: '#838383',
      secondary: '#838383',
    },
    blue: {
      primary: '#138086',
      secondary: '#138086',
    },
    yellow: {
      primary: '#534666',
      secondary: '#534666',
    },
    purple: {
      primary: '#cd7672',
      secondary: '#cd7672',
    },
    gray:{
      primary: '#eeb462',
      secondary: '#eeb462',
    },
    primeravez:{
      primary: '#9e91b1',
      secondary: '#9e91b1',
    },
    not:{
      primary: '#607d8b',
      secondary: '#607d8b',
    },
    endoscopio:{
      primary: '#1F618D',
      secondary: '#1F618D',
    }
  };

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];
  todayEvents: CalendarEvent[] = [];

  activeDayIsOpen: boolean = false;

  globalConsultingRoomMin = {
    main:{
      dateTimeAppointmentEnd:'',
      dateTimeAppointmentStart:''
    },
    system:{
      doctor:{
        main:{
          uuid:''
        }
      }
    }
  }
  todayConsultingRoomMin= {
    main:{
      startDate:'',
      endDate:''
    }
  }

  collaboratorMin = {
    system:{
      role:'',
      company:{
        main:{
          uuid:''
        }
      },
      status:true,
      max: 100,
      offset: 0,
    },
    filter:{
      name:'searchElement',
      value: ''
    }
  }

  doctorList = [];

  metadata = {
    firtsTime:true,
    loadded:true,
    totalIncome: 0.0,
    date: moment().clone().startOf('day').toISOString(),
  }
  constructor(private session: SessionService,public dialog: MatDialog, public loadingService: LoadingService, public snackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {
    this.findAllByCompanyAndRoleAndStatus();
  }

  beforeViewRender(ev){
    // console.log("beforeViewRender",ev);
    // let actualMonth = moment().get('month');
    // let endOfTheMonth = moment().set('month',actualMonth+1).set('date', 1).subtract(1, 'day');
    if( this.globalConsultingRoomMin.main.dateTimeAppointmentStart != moment(ev.period.start).subtract(1, "days").format('DD-MM-YYYY')){
      if(this.metadata.firtsTime){
        this.metadata.firtsTime = false;
        setTimeout(()=>{
          this.globalConsultingRoomMin.main.dateTimeAppointmentStart =  moment(ev.period.start).subtract(1, "days").format('DD-MM-YYYY');
          this.globalConsultingRoomMin.main.dateTimeAppointmentEnd =  moment(ev.period.end).add(1, "days").format('DD-MM-YYYY');
          this.getConsultingRoomByDates();
         }, 2000);
      }else{
        this.globalConsultingRoomMin.main.dateTimeAppointmentStart =  moment(ev.period.start).subtract(1, "days").format('DD-MM-YYYY');
        this.globalConsultingRoomMin.main.dateTimeAppointmentEnd =  moment(ev.period.end).add(1, "days").format('DD-MM-YYYY');
        this.getConsultingRoomByDates();
      }
    }
  }

  getConsultingRoomByDates(){
    this.loadingService.show(true,"Espere un momento...");
    this.session.getRequest("validate:findByUsername", {
      system: {
        user: this.session.getUser()
      }
    }).subscribe((data: any) => {
      this.globalConsultingRoomMin.system.doctor.main.uuid = data.object.main.uuid;
      this.getconsultingRoomfindAllByDoctorAndDateTimeAppointmentBetween().then((data:any)=>{
        this.events = [];
        for(let item of data){
          let date = moment(item.main.dateTimeAppointment).toDate();
          let color:any = {
            primary:'',
            secondary:''
          };
          switch(item.main.status){
            case 'Nueva':
              color = this.colors.blue;
            break;
            case 'Atendida':
              color = this.colors.yellow;
            break
            case 'Cancelada':
              color = this.colors.red;
            break
          };
          switch(item.main.typeAppointment){
            case 'Cirugía':
              color = this.colors.purple;
            break;
            case 'No disponible':
              color = this.colors.gray;
            break
            case 'Primera vez':
              color = this.colors.primeravez;
            break
            case 'Endoscopia':
              color = this.colors.endoscopio;
            break
          };
          if(item.main.status == 'Cancelada'){
            color = this.colors.red;
          }
          if(item.main.status == 'Atendida'){
            color = this.colors.yellow;
          }
          if(item.main.status == 'No asistió'){
            color = this.colors.not;
          }
          let text = '';
          if(item.system.patient){
            text = item.system.patient.system.physicalPerson.main.name + ' '+ item.system.patient.system.physicalPerson.main.lastname + ' '+ item.system.patient.system.physicalPerson.main.surname;
          }else{
            text = item.text;
          }
          let auxEvent =
          {
            start: date,
            end: addMinutes(date, item.main.minute ? item.main.minute : 30 ),
            title: text,
            color: color,
            allDay: false,
            resizable: {
              beforeStart: false,
              afterEnd: false,
            },
            draggable: false,
            meta:{main:item.main}
          };
          this.events.push(auxEvent);
        }
        this.loadingService.hide();
      }).catch(e=>{
        this.loadingService.hide();
        console.log(e);
      })
    }, error=>{
      this.loadingService.hide();
      console.log(error);
    });
  }

  dayClicked(event): void {
    this.createAppointMent(event.day.date,'new');
  }

  hourSegmentClicked(event):void{
    // console.log(event);
    this.createAppointMent(event.date,'new');

  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  addEvent(): void {
    this.events = [
      {
        title: 'Nueva cita',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: this.colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  openHistory(){
    this.dialog.open(OwnerMedicalAppointmentDetailComponent, {
      width: '60%',
      // data: { object: object}
    }).afterClosed().subscribe(result => {
      if(result){
        // this.findAllUsersByCompany();
      }
    });
  }

  createAppointMent(date, uuid) {
    console.log('date', date);
    console.log('uuid', uuid)
    // Validamos si es agendar una cita
    if (uuid == 'new') {
      let doctor = {};
      for(let item of this.doctorList){
        if(this.globalConsultingRoomMin.system.doctor.main.uuid ==  item.main.uuid){
          doctor = item;
        }
      }

      this.dialog.open(OwnerMedicalAppointmentUpdateComponent , {
        width: '80%',
        height: '90%',
        data: { object: date, uuidConsultingRoom:uuid, doctor:doctor }
      }).afterClosed().subscribe(result => {
        if(result){
          if(result.transaction == 'ok'){
            this.getConsultingRoomByDates();
          }
        }
      });
    } else {
      // vamos a mostrar las notas medicas del paciente, obtenemos los datos de la consulta medica, para obtener el paciente, y despues su historico de notas medicas
      this.dialog.open(OwnerMedicalNotePatientComponent, {
        width: '80%',
        height: '90%',
        data: { uuidConsultingRoom:uuid }
      }).afterClosed().subscribe(result => {
        // Simplemente cerramos, porque solo consultamos su notas medicas
      });
    }
  }

  handleEvent(action: string, event: CalendarEvent): void {
    switch(action){
      case 'Clicked':
        this.createAppointMent(event.start,event.meta.main.uuid)
      break;
      default:
      console.log("No hacemos nada");
      break;
    }
    // console.log("action",action);
    // console.log("event",event);
  }

  /** funcionalidad para obtener role por company y status
  */
  findAllByCompanyAndRoleAndStatus(){
    this.loadingService.wait();
    this.collaboratorMin.system.role = 'Specialist doctor';
    this.collaboratorMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("collaborator:findAllByCompanyAndRoleAndStatus",this.collaboratorMin).subscribe((data:any)=>{
      this.doctorList = data.object.instanceList;
      this.globalConsultingRoomMin.system.doctor.main.uuid = data.object.instanceList[0].main.uuid;
      this.loadingService.hide();
    },error=>{
      console.log("error: findAllByCompanyAndRoleAndStatus",error);
      this.loadingService.hide();
    })
  }

  getScheduleDoctor(uuid):Promise<string>{
    return new Promise((resolve,reject)=>{
      let scheduleDoctorObject = {
        system:{
          consultingRoom:{
            main:{
              uuid:uuid
            }
          }
        }
      }
      this.session.getRequest("scheduleDoctor:findByConsultingRoom", scheduleDoctorObject).subscribe(
        async (data:any) => {
          resolve(data.object.main.description);
        }, error => {
          console.log("Error: scheduleDoctor:findByConsultingRoom",error);
          resolve("No disponible");
        });
    });
  }


  getconsultingRoomfindAllByDoctorAndDateTimeAppointmentBetween(){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("consultingRoom:findAllByDoctorAndDateTimeAppointmentBetween",this.globalConsultingRoomMin).subscribe(async(data:any)=>{
        // console.log(data.object.instanceList);
        this.events = [];
        for(let i = 0; i < data.object.instanceList.length; i++){
          if(data.object.instanceList[i].system.patient){
          }else{
            try{
              data.object.instanceList[i].text = await this.getScheduleDoctor(data.object.instanceList[i].main.uuid);
            }catch(e){
              data.object.instanceList[i].text  ="No disponible";
            }
          }
        }
        resolve(data.object.instanceList);
        //realizamos el bindeo para ver los eventos
      },error=>{
        reject(error);
        // console.log(error);
      });
    });
  }

  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
