import { Component, OnInit, ViewChild  } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material';
import * as Rx from 'rxjs';
import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-transfer-update',
  templateUrl: './admin-transfer-update.component.html',
  styleUrls: ['./admin-transfer-update.component.css']
})
export class AdminTransferUpdateComponent implements OnInit {
  complexForm: FormGroup;
  transferListFilter= [];
  warehouseList = [];
  productsFilter = [];
  productSelectedList = [];
  transferModel = {
    main: {
      uuid: '',
      dateTransfer:'',
      status:'En revisión' //'Por validar', 'Validado', 'Cancelado'
    },
    complement:{
      comment:''
    },
    system: {
      company:{
        main:{
          uuid:''
        }
      },
      warehouseDestination:{
        main:{
          uuid:''
        }
      },
      warehouseOrigin:{
        main:{
          uuid:''
        }
      },
      collaborator:{
        main:{
          uuid:''
        }
      },
      products:[]
    },
  }
  transferProductModel = {
    main:{
      uuid:'',
      quantity:1,
    },
    system:{
      product:{
        main:{
          uuid:''
        }
      },
      transfer:{
        main:{
          uuid:''
        }
      }
    }

  }
  metadata={
    searchBoxInput: new Rx.Subject<string>(),
    dateAdjustment:'',
    searching:false
  }
  warehouseMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  productModel= {
    main: {
      uuid: '',
    },
    system: {
      company:{
        main:{
          uuid:''
        }
      }
    },
    filter: {
      name: 'likeNameOrCode',
      value: ''
    }
  }
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  object = JSON.parse(JSON.stringify(this.transferModel));
  constructor(public fb: FormBuilder, public session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'comment': [null, Validators.required],
    })
    this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.productsFilter = [];
          this.productFindAllByNameLike();
        } else {
          this.productsFilter = [];
          this.productModel.filter.value = '';
          val = 'false'
        }

        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged:');
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        console.log(error);
      });
   }

   ngOnInit() {
     this.loadingService.show(true,'Cargando Rescursos...')
     this.getWarehouseList().then(data=>{
       this.object.system.status = true;
       this.activatedRoute.params.subscribe((params: Params) => {
         if(params['uuid'] == 'new'){
           this.loadingService.hide();
         }else{
           this.loadingService.wait();
           this.object.main.uuid = params['uuid'];
           // cargamos los datos de la BD
           this.session.getRequest('transfer:get',this.object).subscribe((data:any)=>{
           this.object = JSON.parse(JSON.stringify(data.object));
           this.loadingService.hide();
         },
         (error)=>{
           this.loadingService.hide();
           this.snackBar.open('Hubo un error, salga y vuelva a entrar de nuevo.', 'Error', {
             duration: 8000
           });
           console.log('Error:transfer:get',error)
         })
       }
     });
   }).catch(e=>{
     this.loadingService.hide();
     this.snackBar.open('Hubo un error, salga y vuelva a entrar de nuevo.', 'Error', {
       duration: 8000
     });
   })
   }

   send(object) {
     this.loadingService.show(true,'Guardando registro...');
     object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
     // object.system.user.main.uuid = this.session.getUser().main.uuid;
     object.main.status = 'Por validar';
     object.main.dateTransfer = moment().format('DD-MM-YYYY HH:mm:ss');
     this.session.getRequest("transfer:update", object).subscribe(
       (data:any) => {
         this.snackBar.open('El registro se guardo correctamente', '', {
           duration: 8000
         });
         this.loadingService.hide();
         this.router.navigate(['/admin/transfer/list']);
       },
       error => {
         console.log("transfer:update",error);
         this.loadingService.hide();
       }
     );
   }
   /*
   Obtenemos el listado de almacenes
   */
   getWarehouseList(){
     return new Promise((resolve,reject)=>{
       // this.loadingService.wait();
       this.warehouseList = [];
       // verificamos en que pagina verificamos
       this.warehouseMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
       this.session.getRequest("warehouse:findAllByCompany", this.warehouseMin).subscribe(
         (data: any) => {
           this.warehouseList = data.object.instanceList;
           // this.loadingService.hide();
           resolve(true);
         },
         error => {
           console.log("warehouse:findAllByCompany", error);
           // this.loadingService.hide();
           reject(error);
         }
       );
     })
   }
   productFindAllByNameLike() {
     this.productsFilter = [];
     this.metadata.searching = true;
     this.productModel.system.company.main.uuid = this.session.getSessionObject().main.uuid;
     // this.productModel.filter.value = name;
     this.session.getRequest('product:findAllByCompany',this.productModel).subscribe(
       (data:any)=>{
         this.productsFilter = data.object.instanceList;
         this.metadata.searching = false;
       },
       error => {
         console.log("product:findAllByCompany",error);
         this.snackBar.open(error.message, 'Error', {duration: 5000});
       })
   }
   /*
   Funcion para agregar un producto al traspaso
   */
   addProductToSelectedList(object){
     this.getProductsAvailableByWharehouse(object,this.object.system.warehouseOrigin).then((data:any)=>{
       if(data.main.uuid != null && data.main.uuid != 'null' && data.main.quantity > 0){
         let transferProductAux = JSON.parse(JSON.stringify(this.transferProductModel));
         transferProductAux.main.quantity = 1;
         transferProductAux.system.product.main = data.system.product.main;
         this.object.system.products.push(transferProductAux);
         this.productModel.filter.value = '';
       }else{
         this.snackBar.open('No hay existencias de este producto en el inventario.', 'Aceptar', {
           duration: 8000
         });
       }
     })
     this.productsFilter = [];
   }
   /*
   Funcion para quitar un almacen en al seleccionarlo.
   */
   selectedWarehouse(object,type){
     console.log(object);
     for(let i=0; i < this.warehouseList.length; i++){
       if(object ==  this.warehouseList[i].main.uuid){
         if(type == 'destiny'){
           this.object.system.warehouseDestination = JSON.parse(JSON.stringify(this.warehouseList[i]));
           this.object.system.warehouseDestination.system.room = {};
           this.object.system.warehouseDestination.system.company = {};
         }
         if(type == 'origin'){
           this.object.system.warehouseOrigin = JSON.parse(JSON.stringify(this.warehouseList[i]));
           this.object.system.collaborator.main = this.object.system.warehouseOrigin.system.collaborator.main;
           this.object.system.warehouseOrigin.system.room = {};
           this.object.system.warehouseOrigin.system.company = {};
         }
         break;
       }
     }
   }

   addQuantity(object){
     this.getProductsAvailableByWharehouse(object.system.product,this.object.system.warehouseOrigin).then((data:any)=>{
       if(object.main.quantity > data.main.quantity){
         this.snackBar.open('La cantidad ingresada supera el maximo de existencias en el inventario...', 'Aceptar', {
           duration: 8000
         });
         object.main.quantity = data.main.quantity;
       }
     })
   }

   /*
   Funcion para encontrar productos en inventario segun consulta de productos
   */
   getProductsAvailableByWharehouse(product,wharehouse){
     return new Promise((resolve=>{
       this.session.getRequest("warehouseProduct:findByProductByWarehouse", {system:{product:{main:{uuid:product.main.uuid}},warehouse:{main:{uuid:wharehouse.main.uuid}}}}).subscribe(
         (data:any) => {
           resolve(data.object);
        },
        error => {
          console.log("warehouseProduct:findByProductByWarehouse",error);
          this.loadingService.hide();
        }
       );
     }))
   }

   /**
    * Funcion para buscar
    */
   search() {
     this.metadata.searchBoxInput.next(this.productModel.filter.value);
   }
   /*
   funcion para controlar si se abre o cierra el menu
   */
   toogleMenu(ev){
     this.drawerCtrl.opened = ev;
   }

}
