import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { LoadingService } from '../../../directive/loading/loading.module';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { SessionService } from '../../../service/session/session.service';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-calendar-incidence-day',
  templateUrl: './admin-calendar-incidence-day.component.html',
  styleUrls: ['./admin-calendar-incidence-day.component.css']
})
export class AdminCalendarIncidenceDayComponent implements OnInit {

  day:any ={};
  employeeListFiltered = [];
  typePunishmentList = [];
  incidentManagementList = [];
  metadata = {
    filter:'',
    searchBoxInput: new Rx.Subject<string>(),
    range:false,
    startDate:moment().toISOString(),
    endDate:moment().toISOString(),
    searching:false
  }
  incidentManagementModel = {
    main: {
      uuid: '',
      incidentDate: ''
    },
    system: {
      collaborator: {
        main: {
          uuid: ''
        }
      },
      typePunishment: {
        main: {
          uuid: ''
        }
      },
      company: {
        main: {
          uuid: ''
        }
      },
      status: true
    }
  };
  listEmploymentInformation = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      }
    },
    filter:{
      name:"",
      value:""
    },
    max: 10,
    offset: 0
  };
  object = JSON.parse(JSON.stringify(this.incidentManagementModel));

  constructor(public dialogRef: MatDialogRef<AdminCalendarIncidenceDayComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) {
    console.log(this.data);
    this.listEmploymentInformation.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.day = this.data.daySelected;
    this.typePunishmentList = this.data.typePunishmentList;
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      console.log('called once',val)
      if(val != ''){
        this.listEmploymentInformation.filter.value  = val;
        this.listEmploymentInformation.filter.name = 'searchElement';
        this.getEmployeeListFiltered(val);
      }else{
      }
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
    console.log('error logged:');
    this.snackBar.open(error.message, 'Error', {duration: 5000});
    console.log(error);
  });
   }

  ngOnInit() {
    this.loadEventsFromCalendar(moment(this.day.date).format('DD-MM-YYYY HH:mm') + ':00')
  }

  /**
   * Funcion para obtener las incidencias del dia.
   */
  loadEventsFromCalendar(date){
    this.session.getRequest('incidentManagement:findAllByCompanyAndDate',{
        main: {
          incidentDate: date.split(' ')[0]
        },
        system: {
          company: {
            main: {
              uuid: this.session.getSessionObject().main.uuid
            }
          }
        }
      }).subscribe((data:any)=>{
      this.incidentManagementList = [];
      this.incidentManagementList = data.object.instanceList;
    },error=>{
      console.log("error:incidentManagement:findAllByCompanyAndDate",error);
    })
  }

  /**
   * Funcion para filtrar los trabajdores segun busqueda.
   */
  getEmployeeListFiltered(val:string){
    this.employeeListFiltered = [];
    this.metadata.searching = true;
    this.session.getRequest('collaborator:findAllByCompany',this.listEmploymentInformation).subscribe(
      (data:any)=>{
        this.employeeListFiltered = data.object.instanceList;
        this.metadata.searching = false;
      },
      error => {
        console.log("employmentInformation:list",error);
        this.snackBar.open(error.message, 'Error', {duration: 5000});
      })
  }

  // Agrega el trabajador a asignarle una incidencia
  addEmployeeSelected(object) {
    this.employeeListFiltered = [];
    let itExist = false;
    this.object = JSON.parse(JSON.stringify(this.incidentManagementModel));
    if (object.main.uuid !== undefined && object.main.uuid !== '') {
      //guardamos los datos del trabajador
      this.object.system.collaborator = object;
      //guardamos la fecha selecionada.
      this.object.main.incidentDate = this.day.date;
      this.object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
      for (let i = 0; i < this.incidentManagementList.length; i++) {
        if (this.incidentManagementList[i].system.collaborator.main.uuid === object.main.uuid) {
          itExist = true;
        }
      }
      if (itExist) {
        this.snackBar.open('El trabajador ya tiene una incidencia en este día ', 'Success', {duration: 5000});
      } else {
        this.incidentManagementList.unshift(this.object);
      }
    }
  };

  //funcion para agregar el evento en el calendario, esta funcion se ejecuta cuando al trabajador se le asigna una incidencia.
  addIncidentManagementToCalendarEvent(object){
  object.main.incidentDate = moment(object.main.incidentDate).utc().format('DD-MM-YYYY HH:mm') + ':00';
    this.session.getRequest('incidentManagement:update',object).subscribe((data:any)=>{
      this.snackBar.open('Incidencia Agregada', 'Success', {duration: 5000});
    },error=>{
      console.log("error:incidentManagement:update",error);
    })
  }
  //funcion para eliminar una incidencia de acuerdo a la fecha y el prospectWorker
  deleteIncidentManagement(object) {
    // Validamos si ya existe en la base de datos
    if (object.main.uuid === '') {
      //eliminamos el objeto del incidentManagementList
      for (let i = 0; i < this.incidentManagementList.length; i++) {
        if (this.incidentManagementList[i].main.uuid == object.main.uuid) {
          this.incidentManagementList.splice(i, 1);
        }
      }
    } else {
      //eliminamos el object de la bd
      this.session.getRequest('payrollIncidence:delete',object).subscribe((data:any)=>{
        //eliminamos el objeto del incidentManagementList
        for (let i = 0; i < this.incidentManagementList.length; i++) {
          if (this.incidentManagementList[i].main.uuid == object.main.uuid) {
            this.incidentManagementList.splice(i, 1);
            this.snackBar.open('Registro eliminado', 'Success', {duration: 5000});
          }
        }
      },error=>{
        console.log("error:payrollIncidence:delete",error)
      })
    }
  }

  /**
   * Selecciona un rango de fecha
   */
  rangeSelected(object) {
    let objectTemp = JSON.parse(JSON.stringify(object));
    let initDay = new Date(this.metadata.startDate);
    let finalDay = new Date(this.metadata.endDate);
    //caculamos cuantos dias son el rango de fechas.
    let daysTime = Math.floor(((finalDay.getTime() - initDay.getTime())/86400000));

    //guardarmos las incidencias selecionadas para el trabajador.
    for (let i=0; i <= daysTime; i++ ) {
      objectTemp.main.incidentDate =initDay;
      //guadamos la vacación
      this.addIncidentManagementToCalendarEvent(objectTemp);
      //sumamos un dia mas para la siguiente iteración
      initDay = new Date(initDay.getTime() + 86400000);
    }
    this.metadata.range = false;
    //limpiamos la lista de incidencias.
    // this.incidentManagementList = [];
    this.snackBar.open( 'Se asignaron incidencias a: ' + this.object.system.prospectWorker.main.fullName + ' del ' + this.metadata.startDate + ' al ' + this.metadata.endDate , 'Success', {duration: 5000});
  }

  /**
   * Funcion para buscar
   */
  search(){
    if(this.metadata.filter.length > 3)
      this.metadata.searchBoxInput.next(this.metadata.filter);
    else
    this.employeeListFiltered = [];
  }

  /**
   * Funcion para cerrar el modal de incremento salarial.
   */
  closeDialog(){
    this.dialogRef.close(true);
  }

}
