import { Component, OnInit, Inject } from '@angular/core';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import * as moment from 'moment';


@Component({
  selector: 'app-owner-cashing-by-emergencies-validate',
  templateUrl: './owner-cashing-by-emergencies-validate.component.html',
  styleUrls: ['./owner-cashing-by-emergencies-validate.component.css']
})
export class OwnerCashingByEmergenciesValidateComponent implements OnInit {
 
  saleClinicModel={
    main:{
      uuid:'',
      total:0.0,
      cashFund:0.0,
      status:'Abierto',
      starDate:'',
      endDate:'',
      totalAttended: 0,
      totalReceived:0.0,
    },
    system:{
      collaborator:{
        main:{
          uuid:''
        }
      },
      otherIncome:{
        main:{
          uuid:''
        }
      }
    }
  }

  otherIncomeList = [];

  otherIncomeMin={
    system:{
      company:{
        main:{
          uuid:''
        }
      }
    }
  }

  otherIncomeModel = {
    main: {
      uuid: '',
      voucherType: '',
      name: '',
      concept:'',
      dateExpenditure:'',
      amount:''
    },
    system: {
      branch: {
        main:{
          uuid: ''
        }
      },
      company:{
        main:{
          uuid:''
        }
      },
      otherIncomeClassification:{
        main:{
          uuid:''
        }
      },
    }
  };

  otherIncomeClassificationList=[];

  object = JSON.parse(JSON.stringify(this.saleClinicModel));
  otherIncome = JSON.parse(JSON.stringify(this.otherIncomeModel));

  constructor(public loadingService: LoadingService, private session: SessionService, public dialogRef: MatDialogRef<OwnerCashingByEmergenciesValidateComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar) { }

  ngOnInit() {
    // Se obtienen las clasificaciones de gastos
    this.session.getRequest("otherIncomeClassification:findAllByCompany", {system: {company: {main: {uuid: this.session.getSessionObject().main.uuid} } } }).subscribe(
      (data: any) => {
        this.otherIncomeClassificationList = data.object.instanceList;
      },
      error => {
        console.log("otherIncomeClassification:findAllByCompany", error);
    });

    if(this.data != 'new') {
      // this.loadingService.wait();
      console.log('aqui', this.data)
      this.object.main.uuid = this.data;
      this.session.getRequest('saleClinic:get', {main:{uuid: this.object.main.uuid }}).subscribe(
        (data: any) => {
          this.object = data.object;
          this.calculateTotal();
          this.loadingService.hide();
        }, error => {
          console.log('Error: saleClinic:get', error);
          this.loadingService.hide();
        });
    }
  }

  calculateTotal(){
    this.object.main.totalReceived = this.object.main.total + this.object.main.cashFund;
    console.log(this.object.main.totalReceived)
  }

  send(object, otherIncome) {
    this.loadingService.wait();
     otherIncome.main.voucherType = 'Efectivo';
     otherIncome.main.dateExpenditure =  moment(this.object.main.endDate).format("DD/MM/YYYY hh:mm:ss"); 
     otherIncome.main.amount = this.object.main.totalReceived;
     otherIncome.main.name = 'Urgencias';
     otherIncome.main.concept = 'Cobro por urgencias'
     otherIncome.system.company.main.uuid = this.session.getSessionObject().main.uuid;
     this.session.getRequest('otherIncome:update', otherIncome).subscribe(
       (data: any) => {
       this.saleClinicModel.main.uuid = object.main.uuid;
       this.saleClinicModel.main.total = object.main.total;
       this.saleClinicModel.main.cashFund = object.main.cashFund;
       this.saleClinicModel.main.status = 'Entrego';
       this.saleClinicModel.main.starDate = moment(this.object.main.starDate).format("DD-MM-YYYY hh:mm:ss"); 
       this.saleClinicModel.main.endDate = moment(this.object.main.endDate).format("DD-MM-YYYY hh:mm:ss"); 
       this.saleClinicModel.main.totalAttended = object.main.totalAttended;
       this.saleClinicModel.main.totalReceived = object.main.totalReceived;
       this.saleClinicModel.system.collaborator.main.uuid = object.system.collaborator.main.uuid;
       this.saleClinicModel.system.otherIncome.main.uuid = data.object.main.uuid;
         this.session.getRequest('saleClinic:update', this.saleClinicModel).subscribe(
           (data: any) => {
             this.object = data.object;
             this.loadingService.hide();
             this.close(true);
           }, error => {
             console.log('Error: saleClinic:update', error);
             this.loadingService.hide();
           });
 
         this.loadingService.hide();
         this.close(true);
       }, error => {
         console.log('Error: otherIncome:update', error);
         this.loadingService.hide();
       }); 
   }

    /*
   * Cierra el modal
   */
    close(status){
      let object = {
        transaction:'',
        code:'',
        object:{}
      };
      if(status){
        object.transaction = 'ok';
        object.object = this.object;
      }else{
        object.transaction = 'bad';
        object.code = 'saleClinic:001';
      }
      this.dialogRef.close(object);
    }

}
