import { Component, OnInit, Inject } from '@angular/core';
import { LoadingService } from '../../../directive/loading/loading.module';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-room-service-catalog',
  templateUrl: './admin-room-service-catalog.component.html',
  styleUrls: ['./admin-room-service-catalog.component.css']
})
export class AdminRoomServiceCatalogComponent implements OnInit {
  serviceCatalogList = [];
  room = {main: {uuid: ''}};
  serviceCatalogModel = {
    checked:false,
    main: {
      uuid: '',
      code: '',
      name: '',
      description: '',
      price: 0,
      tax: true
    },
    system: {
      status: true,
      company: {
        main: {
          uuid: ''
        }
      }
    }
  };
  constructor(public dialogRef: MatDialogRef<AdminRoomServiceCatalogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) {
    this.room = this.data;
  }

  ngOnInit() {
    // Obtenemos los servicios disponibles
    this.getServiceCatalog();
  }

  send(checked, object) {
    if (checked) {
      this.session.getRequest("roomServiceCatalog:update", {system: {room: {main: {uuid: this.room.main.uuid}}, serviceCatalog: {main:object.main}}}).subscribe(
        (data: any) => {
        },
        error => {
          console.log("Error: roomServiceCatalog:update", error);
          this.loadingService.hide();
        });
    } else {
      this.session.getRequest("roomServiceCatalog:delete", {system: {room: {main: {uuid: this.room.main.uuid}}, serviceCatalog: {main:object.main}}}).subscribe(
        (data: any) => {
        },
        error => {
          console.log("Error: roomServiceCatalog:delete", error);
          this.loadingService.hide();
        });
    }
  }

  /**
   * obtiene la lista de catalogo de servicios
   */
  getServiceCatalog() {
    this.loadingService.wait();
    this.session.getRequest("serviceCatalog:findAllByCompany", {system: {company: {main: {uuid: this.session.getSessionObject().main.uuid}}}}).subscribe((data:any)=>{
      this.serviceCatalogList = [];
      for (var x=0; x<data.object.instanceList.length; x++) {
        let serviceCatalog = JSON.parse(JSON.stringify(this.serviceCatalogModel));
        serviceCatalog.main = JSON.parse(JSON.stringify(data.object.instanceList[x].main));
        serviceCatalog.system = JSON.parse(JSON.stringify(data.object.instanceList[x].system));
        this.serviceCatalogList.push(serviceCatalog);
      }
      // Obtenemos los servicios asignados al cuarto
      this.getRoomServiceCatalog(this.room.main.uuid);
      this.loadingService.hide();
    },error=>{
      console.log("error: serviceCatalog:findAllByCompany",error);
      this.loadingService.hide();
    })
  }

  /**
   * Obtenemos los servicios asignados al cuarto indicado
   */
  getRoomServiceCatalog(roomUuid) {
    this.loadingService.wait();
    // verificamos en que pagina verificamos
    this.session.getRequest("roomServiceCatalog:findAllByRoom", {system: {room: {main: {uuid: roomUuid}}}}).subscribe(
      (data: any) => {
        for (var x=0; x<data.object.instanceList.length; x++) {
          for (var y=0; y<this.serviceCatalogList.length; y++) {
            if (data.object.instanceList[x].system.serviceCatalog.main.uuid == this.serviceCatalogList[y].main.uuid) {
              this.serviceCatalogList[y].checked = true;
            }
          }
        }
        this.loadingService.hide();
      },
      error => {
        console.log("room:findAllByCompany", error);
        this.loadingService.hide();
      }
    );
  }

  /*
   * Cierra el modal
   */
  closeDialog() {
    this.dialogRef.close(true);
  }
}
