import { Component, OnInit, Inject } from '@angular/core';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import * as moment from 'moment';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-owner-fee-payment-update',
  templateUrl: './owner-fee-payment-update.component.html',
  styleUrls: ['./owner-fee-payment-update.component.css']
})
export class OwnerFeePaymentUpdateComponent implements OnInit {

  feedPaymentModel = {
    main: {
      uuid: '',
      dateRegister: moment().toISOString(),
      pay: 0.0,
      typeCharge: true,
      wayPay: 'Efectivo',
      paymentType: 'Anticipo'
    },
    system: {
      user: {
        main: {
          uuid: '',
          username: ''
        }
      },
      personReceivedGavePayment: '',
      sales: {
        main: {
          uuid: ''
        }
      }
    }
  }

  wayPays = ['Efectivo', 'Transferencia', 'Tarjeda de debito/credito', 'Otro']

  object: any = JSON.parse(JSON.stringify(this.feedPaymentModel));
  constructor(public loadingService: LoadingService, private session: SessionService, public dialogRef: MatDialogRef<OwnerFeePaymentUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    if (this.data.type == 'feedPayment') {
      this.object = JSON.parse(JSON.stringify(this.data.object));
    } else {
      this.object = JSON.parse(JSON.stringify(this.feedPaymentModel));
      this.object.system.sales.main.uuid = this.data.object.main.uuid;
      this.object.main.pay = 0;
      // obtenemos el detalle
      for (let x=0; x<this.data.object.system.salesDetails.length; x++) {
        if (this.data.object.system.salesDetails[x].system.object == 'OtherCharge') {
          // Obtenemos el dato y validamos que sea hospitalario
          this.session.getRequest("otherCharge:get", {main: { uuid: this.data.object.system.salesDetails[x].system.objectUuid}}).subscribe(
            (dat: any) => {
              if (dat.object.main.status == 'Hospitalario' || dat.object.main.status == 'Otro hospitalario') {
                this.object.main.pay = this.object.main.pay + dat.object.main.total;
              }
              this.loadingService.hide();
            }, error => {
              console.log("Error: otherCharge:get", error);
              this.loadingService.hide();
            });
        } else {
          this.object.main.pay = this.object.main.pay + this.data.object.system.salesDetails[x].main.total;
        }
      }
      // Obtenemos los pagos ya realizados
      this.session.getRequest("feedPayment:findAllBySales", {system: {sales: {main: {uuid: this.object.system.sales.main.uuid}}}}).subscribe((da:any) => {
        for (let y=0; y<da.object.instanceList.length; y++) {
          this.object.main.pay = this.object.main.pay - da.object.instanceList[y].main.pay;
        }
        this.loadingService.hide();
      }, error => {
        console.log("Error: feedPayment:findAllBySales", error);
        this.loadingService.hide();
      });
    }
  }

  send(object) {
    this.loadingService.show(true, "Espere un momento... se esta guardando el registro.");
    object.main.dateRegister = moment(object.main.dateRegister).format('DD/MM/YYYY HH:mm:ss');
    object.system.user.main.uuid = this.session.getUser().main.uuid;
    object.system.user.main.username = this.session.getUser().main.username;
    this.session.getRequest("feedPayment:update", object).subscribe(
      (data: any) => {
        this.object = data.object;
        this.loadingService.hide();
        this.close(true);
      }, error => {
        console.log("Error: roomOccupation:update", error);
        this.loadingService.hide();
      });
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'category:001';
    }
    this.dialogRef.close(object);
  }
}
