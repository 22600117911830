import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import * as Rx from 'rxjs/Rx';
// import { LoadingService } from '../../../directive/loading/loading.module';
// import * as moment from 'moment';

@Component({
  selector: 'app-admin-inventory',
  templateUrl: './admin-inventory.component.html',
  styleUrls: ['./admin-inventory.component.css']
})
export class AdminInventoryComponent implements OnInit {

  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  warehouseList = [];
  warehouseMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  // MatPaginator Output
  pageEvent: PageEvent;
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }

  constructor(private session: SessionService, public snackBar: MatSnackBar) {
  //   this.metadata.searchBoxInput.debounceTime(700)
  //   .switchMap(val => {
  //     // console.log('called once',val)
  //     if(val != ''){
  //       this.warehouseList = [];
  //       this.warehouseMin.filter.name = 'searchElement';
  //     }else{
  //       this.warehouseList = [];
  //       this.warehouseMin.filter.name = '';
  //       this.warehouseMin.filter.value = '';
  //       val = 'false'
  //     }
  //     this.getWarehouseList();
  //     return val;
  //   }).subscribe(results => {
  //     // Modificaciones sobre cada letra si se requiere
  //   }, error => {
  //   console.log('error logged:');
  //   this.snackBar.open(error.message, 'Error', {duration: 5000});
  //   console.log(error);
  // });
}

ngOnInit() {
  this.getWarehouseList();
  // this.drawerCtrl.opened = true;
}
getWarehouseList(){
  // this.loadingService.wait();
  this.warehouseList = [];
  // verificamos en que pagina verificamos
  this.warehouseMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
  this.session.getRequest("warehouse:findAllByCompany", this.warehouseMin).subscribe(
    (data: any) => {
      this.warehouseList = data.object.instanceList;
      this.paginatorModel.total = data.object.total;
      // this.loadingService.hide();
    },
    error => {
      console.log("warehouse:findAllByCompany", error);
      // this.loadingService.hide();
    }
  );
}
//funcion para controlar si se abre o cierra el menu

toogleMenu(ev){
  this.drawerCtrl.opened = ev;
}

/*
Funcion para inactivar un almacen
*/
disableAWarehouse(object) {
  // this.loadingService.wait();
  object.system.status = false;
  this.session.getRequest("warehouse:update", object).subscribe(
    data => {
      this.snackBar.open('Almacen desactivado correctamente', '', {
        duration: 8000
      });
      // this.loadingService.hide();
      for (var _i = 0; _i < this.warehouseList.length; _i++) {
        if (object.main.uuid == this.warehouseList[_i].main.uuid) {
          this.warehouseList.splice(_i, 1);
          break;
        }
      }
    },
    error => {
      console.log("Erro: maker:delete", error);
      // this.loadingService.hide();
    }
  );
}

/*
Funcionalidad para colocar el objeto en el menu de opciones
*/
objectSelected(object){
  for(let i=0; i < this.warehouseList.length; i++){
    this.warehouseList[i].main.selected = false;
  }
  if(object.main.uuid == this.metadata.object.main.uuid){
    object.main.selected = false;
    this.metadata.object.main.uuid = '';
  }else{
    object.main.selected = true;
    this.metadata.object = JSON.parse(JSON.stringify(object));;
  }
}

/*
  *Funcion para buscar
*/
search(){
  this.metadata.searchBoxInput.next(this.warehouseMin.filter.value);
}
// funcion para obtener los datos del paginado.
onPaginateChange(event){
  this.warehouseMin.offset = (event.pageIndex * event.pageSize);
  this.warehouseMin.max = event.pageSize;
  this.getWarehouseList();
}

handleUpdated(event) {
  this.warehouseMin.offset = (this.pageEvent ? this.pageEvent.pageIndex * this.pageEvent.pageSize  : this.warehouseMin.offset);
  this.getWarehouseList();
}

}
