import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import * as Rx from 'rxjs/Rx';
import { LoadingService } from '../../directive/loading/loading.module';
import { AdminCashingByEmergenciesValidateComponent } from './admin-cashing-by-emergencies-validate/admin-cashing-by-emergencies-validate.component';



@Component({
  selector: 'app-admin-cashing-by-emergencies',
  templateUrl: './admin-cashing-by-emergencies.component.html',
  styleUrls: ['./admin-cashing-by-emergencies.component.css']
})
export class AdminCashingByEmergenciesComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;

  saleClinicMin = {
    main: {
      status: ''
    },
    max:10,
    offset:0,
  }

  saleClinicList=[];

  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100, 200, 500, 1000],
  };
  pageEvent: PageEvent;

  constructor(private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, public dialog: MatDialog) { }

  ngOnInit() {
    this.getSaleClinic('Cerrado');
  }

   /**
   * Obtenemos las ventas por colaborador y estatus
   */
   getSaleClinic(status) {
    this.saleClinicMin.main.status = status;
    this.session.getRequest('saleClinic:findAllByStatus', this.saleClinicMin).subscribe(
      (data: any) => {
        this.saleClinicList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
      }, error => {
        console.log('Error: saleClinic:findAllByStatus', error);
        this.loadingService.hide();
      });
  }

    /**
  * Modal para validar la caja
  */
    validateCash(uuid){
      console.log('object', uuid)
      const dialogRef = this.dialog.open(AdminCashingByEmergenciesValidateComponent, {
        width: '50%',
        height:'30rem',
        data: uuid,
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined ){
          if(result.transaction == 'ok'){
            this.getSaleClinic('Cerrado');
            // El modal se cerro con objeto
           
          }else{
            // El modal se cerro sin objeto
           
          }
        }else{
          // El modal se cerro sin seleccionar algo, dandole click fuera
        }
      });
    }

    // funcion para obtener los datos del paginado.
    onPaginateChange(event, status) {
      this.saleClinicMin.offset = (event.pageIndex * event.pageSize);
      this.saleClinicMin.max = event.pageSize;
      this.getSaleClinic(status);
    }

  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
