import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-owner-clock-map',
  templateUrl: './owner-clock-map.component.html',
  styleUrls: ['./owner-clock-map.component.css']
})
export class OwnerClockMapComponent implements OnInit {

  object:any = {};
  metadata = {
    fullname:''
  }

  constructor(
    public dialogRef: MatDialogRef<OwnerClockMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    if(this.data){
      this.object = JSON.parse(JSON.stringify(this.data.object));
      console.log(this.object);
      this.metadata.fullname = this.data.name;
  }
  }

  ngOnInit(): void {
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{},
    };
    if(status){
      object.transaction = 'ok';
      object.object = {};
    }else{
      object.transaction = 'bad';
      // object.code = 'user:001';
    }
    this.dialogRef.close(object);
  }

}
