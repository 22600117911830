import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-access-update',
  templateUrl: './admin-access-update.component.html',
  styleUrls: ['./admin-access-update.component.css']
})
export class AdminAccessUpdateComponent implements OnInit {
  complexForm: FormGroup;

  collaboratorMin = {
    system:{
      role:'',
      company:{
        main:{
          uuid:''
        }
      },
      status:true,
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }

  physicalPerson = {
    main:{
      uuid:"",
      name:"",
      lastname:"",
      surname:""
    },
    complement:{
      dateBirth:"",
      curp:"",
      rfc:"",
      gender:""
    },
    system :{
      status:true,
      address:''
    }
  };

  user = {
    main:{
      uuid:"",
      username:"",
      password:"",
      passwordConfirm:"",
    },
    complement:{
      enabled:"true",
      accountExpired:"false",
      accountLocked:"false",
      passwordExpired:"false"
    },
    system:{
      status:true,
      type: "Company",
      objectUuid: "",
      physicalPerson: this.physicalPerson
    }
  };
  metadata = {
    disable:false,
    searchBoxInput: new Rx.Subject<string>(),
    searching:false,
    dateBirth:''
  }

  collaboratorModel = {
    main:{
      uuid:'',
      specialty: ''
    },
    system:{
      physicalPerson:this.physicalPerson,
      user:{
        main:{
          uuid:''
        }
      },
      role:'',
      company:{
        main:{
          uuid:''
        }
      },
      status:true
    }
  };

  colaborator =  JSON.parse(JSON.stringify(this.collaboratorModel));

  collaboratorList = [];
  object = JSON.parse(JSON.stringify(this.user));
  constructor(public fb: FormBuilder, protected session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar,public dialogRef: MatDialogRef<AdminAccessUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.complexForm = fb.group({
      'username': [null, Validators.compose([Validators.required])],
      'password': [null, Validators.compose([Validators.required])],
      'passwordConfirm': [null, Validators.compose([Validators.required])],
    });
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.collaboratorList = [];
        this.collaboratorMin.filter.name = 'searchElement';
        this.getCollaborators();
      }else{
        this.collaboratorList = [];
        this.collaboratorMin.filter.name = '';
        this.collaboratorMin.filter.value = '';
        val = 'false'
      }
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
    console.log('error logged:');
    this.snackBar.open(error.message, 'Error', {duration: 5000});
    console.log(error);
  });
   }

  ngOnInit() {
    this.object.system.objectUuid = this.session.getSessionObject().main.uuid;
    console.log("data",this.data);
    if(this.data.object.main.uuid != ''){
      this.object.main.uuid = this.data.object.main.uuid;
      // cargamos los datos de la BD
      this.session.getRequest('user:get',this.object).subscribe((data:any)=>{
        this.object = JSON.parse(JSON.stringify(data.object));
        this.metadata.disable = true;
        this.collaboratorMin.filter.value = this.object.system.physicalPerson.main.name + ' ' + this.object.system.physicalPerson.main.lastname + ' ' + this.object.system.physicalPerson.main.surname;
      },
      (error)=>{
        console.log('Error:user:get',error)
      })
    }
  }

  send(object) {
    // cargamos los datos de la BD
    this.loadingService.show(true,'Guardando usuario');
    this.session.getRequest('user:update', object).subscribe(async(data:any)=>{
      this.object = JSON.parse(JSON.stringify(data.object));
      //verificamos que el usuario  ya este asignado a un colaborador
      try {
        await this.checkColaborator();
        this.snackBar.open('Registro guardado exitosamente', 'Información', {duration: 5000});
        // cerrar el modal
        // this.router.navigate(['/sti/users']);
        this.closeModal(true);
        this.loadingService.hide();
      }catch(e){
        console.log(e);
        console.log('Error:user:update',e)
        this.snackBar.open(e.message, 'Error', {duration: 5000});
        this.loadingService.hide();
      }

    },
    (error)=>{
      console.log('Error:user:update',error)
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      this.loadingService.hide();
    })
  }

  /** funcion para obtener los colaboradores del sistema
  */
  getCollaborators(){
    this.metadata.searching = true;
    this.collaboratorMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("collaborator:findAllByCompany",this.collaboratorMin).subscribe((data:any)=>{
      this.collaboratorList = data.object.instanceList;
      this.metadata.searching = false;
    },error=>{
      console.log("error",error);
      this.metadata.searching = false;
    })
  }

  checkColaborator(){
      return new Promise((resolve,reject)=>{
        this.session.getRequest('collaborator:get',this.colaborator).subscribe((data:any)=>{
          this.colaborator = JSON.parse(JSON.stringify(data.object));
          this.metadata.dateBirth = moment(this.colaborator.system.physicalPerson.complement.dateBirth).utc().toISOString();
          //actualizamos el usuario con el nuevo colaborador.
          this.colaborator.system.physicalPerson.complement.dateBirth = moment(this.metadata.dateBirth).format("DD-MM-YYYY");
          this.colaborator.system.user.main.uuid = this.object.main.uuid; //asignamos el usuario.
          this.session.getRequest("collaborator:update", this.colaborator).subscribe(
            (data:any) => {
              resolve(true);
            },
            error => {
              console.log("Error: collaborator:update",error);
              reject(error);
            }
          );
        },
        (error)=>{
          console.log('Error:collaborator:get',error);
          reject(error);
        })
      });
  }
  /**función para seleccionar un colaborador
  */
  collaboratorSeleted(object){
    console.log("conlaborador",object);
    this.colaborator.main = JSON.parse(JSON.stringify(object.main));
    this.collaboratorList = [];
    this.collaboratorMin.filter.value = object.system.physicalPerson.main.name + ' ' + object.system.physicalPerson.main.lastname + ' ' + object.system.physicalPerson.main.surname;
    this.object.system.physicalPerson = object.system.physicalPerson;
  }
  /*
    *Funcion para buscar
  */
  search(){
    this.metadata.searchBoxInput.next(this.collaboratorMin.filter.value);
  }

  /** Funcion para cerrar un modal
  */
    closeModal(status): void {
      this.dialogRef.close(status);
    }

}
