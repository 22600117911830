import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { Router } from '@angular/router'
import { NursePatientUpdateComponent } from '../../../../nurse/nurse-dashboard/nurse-register-update/nurse-patient-update/nurse-patient-update.component';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';

@Component({
  selector: 'app-specialist-medical-appointment-update',
  templateUrl: './specialist-medical-appointment-update.component.html',
  styleUrls: ['./specialist-medical-appointment-update.component.css']
})
export class SpecialistMedicalAppointmentUpdateComponent implements OnInit {
  patientMin = {
    system:{
      company:{
        main:{
          uuid:''
        }
      },
      max: 10,
      offset: 0,
    },
    filter:{
      name:'',
      value: ''
    }
  }
  patientList = [];
  consultingRoomModel = {
    main: {
      uuid:'',
      dateTimeAppointment:'',
      pay: '0',
      status:'',
      minute:'30',
      typeAppointment:''// medica, cirugía ( morado ), seguimiento, primera vez, no disponible (GRIS).
    },
    system: {
      patient: {
        main: {
          uuid: ''
        }
      },
      doctor:{
        main:{
          uuid:''
        }
      }
    }
  };

  // ========= Modelo de signos vitales  =========
  vitalSignsModel = {
    main:{
      uuid:'',
      dataType:'',
      value:''
    },
    system:{
      patient:{
        main:{
          uuid:''
        }
      },
      consultingRoom:{
        main:{
          uuid:''
        }
      },
      medicalNotes:{
        main:{
          uuid:''
        }
      }
    }
  }
  vitalSignsList = [];
  vitalSignsTypes =[
    'Presión arterial',
    'Pulso',
    'Temperatura',
    'Oxigenación',
    'Dextrosa',
    'Peso',
    'Estatura',
    'IMC'
  ]
  // ========= fin Modelo de signos vitales  =========

  metadata = {
    searchPatient: new Rx.Subject<string>(),
    searchDoctor:new Rx.Subject<string>(),
    date:'',
    hour:'',
    min:''
  }
  statusList = [
    'Nueva',
    'Cancelada',
    'No asistió'
  ]

  typeAppointmentList = [
    'Cirugía',
    'Seguimiento',
    'Primera vez',
    'No disponible',
    'Endoscopia'
  ]

  scheduleDoctorModel = {
    main:{
      uuid:'',
      dateTimeAppointment:'',
      description:''
    },
    system:{
      consultingRoom:{
        main:{
          uuid:''
        }
      },
      doctor:{
        main:{
          uuid:''
        }
      }
    }
  }

  object = JSON.parse(JSON.stringify(this.consultingRoomModel));
  scheduleDoctorObject = JSON.parse(JSON.stringify(this.scheduleDoctorModel));

  constructor(private session: SessionService,
  public loadingService: LoadingService,
  public snackBar: MatSnackBar,
  public dialogRef: MatDialogRef<SpecialistMedicalAppointmentUpdateComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,
  public dialog:MatDialog,
  public router:Router
  ) {
    this.patientMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
      this.metadata.searchPatient.debounceTime(700)
        .switchMap(val => {
          // console.log('called once',val)
          if (val != '') {
            this.patientList = [];
            this.patientMin.filter.name = 'searchElement';
          } else {
            this.patientList = [];
            this.patientMin.filter.name = '';
            this.patientMin.filter.value = '';
            val = 'false'
          }
          this.getPatients();
          return val;
        }).subscribe(results => {
          // Modificaciones sobre cada letra si se requiere
        }, error => {
          console.log('error logged:');
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
          console.log(error);
        });

  }

  ngOnInit() {
    if(this.data.uuidConsultingRoom == 'new'){
      this.metadata.date = moment(this.data.object).toISOString();
      this.metadata.hour =moment(this.data.object).format('HH');
      this.metadata.min =moment(this.data.object).format('mm');
      //cargamos los datos del doctor
      this.object.system.doctor.main.uuid = this.data.doctor.main.uuid;
      //cargamos los signos vitales
      for(let item of this.vitalSignsTypes){
        let aux = JSON.parse(JSON.stringify(this.vitalSignsModel));
        aux.main.dataType = item;
        aux.system.patient.main.uuid = this.object.system.patient.main.uuid;
        aux.system.consultingRoom.main.uuid = this.object.main.uuid;
        this.vitalSignsList.push(aux);
      }
    }else{
      this.loadingService.show(true,"Espere un momento...");
      this.object.main.uuid = this.data.uuidConsultingRoom;
      this.session.getRequest("consultingRoom:get",this.object).subscribe(async(data:any)=>{
        this.object = JSON.parse(JSON.stringify(data.object));
        this.metadata.date = moment(this.object.main.dateTimeAppointment).toISOString();
        this.metadata.hour = moment(this.object.main.dateTimeAppointment).format('HH');
        this.metadata.min = moment(this.object.main.dateTimeAppointment).format('mm');
        if(this.object.system.patient){
          let patient = this.object.system.patient;
          this.patientMin.filter.value = patient.system.physicalPerson.main.name + ' ' + patient.system.physicalPerson.main.lastname + ' ' + patient.system.physicalPerson.main.surname;
          this.getVitals();
        }
        this.loadingService.hide();
        if(this.object.main.typeAppointment == 'No disponible'){
          this.scheduleDoctorObject.system.consultingRoom.main.uuid = this.object.main.uuid;
          this.scheduleDoctorObject.system.doctor.main.uuid = this.object.system.doctor.main.uuid;
          try{
            await this.getScheduleDoctor();
          }catch(e){
            console.log(e);
          }
        }
      },error=>{
        console.log(error);
        this.loadingService.hide();
      })
    }
  }

  /**
   * Obtiene los pacientes de acuerdo a la palabra escrita por el usuario
   */
  getPatients() {
    this.loadingService.wait();
    this.patientList = [];
    this.session.getRequest("patient:findAllByCompany", this.patientMin).subscribe(
      (data: any) => {
        this.patientList = data.object.instanceList;
        this.loadingService.hide();
      },
      error => {
        console.log("Error: patient:findAllByCompany", error);
        this.loadingService.hide();
      });
  }

  /**
   * Funcion para buscar paciente
   */
  searchPatient(value) {
    this.metadata.searchPatient.next(value);
  }

  /**
   * Apertura la pantalla para registrar un nuevo paciente
   */
  addPatientDialog() {
    this.dialog.open(NursePatientUpdateComponent, {
      width: '70%',
    }).afterClosed().subscribe(result => {
      if (result.main.uuid !== '') {
        this.object.system.patient = result;
        this.patientMin.filter.value = result.system.physicalPerson.main.name + " " + result.system.physicalPerson.main.lastname + " " + result.system.physicalPerson.main.surname;
        this.searchPatient(this.patientMin.filter.value);
      } else {
        this.patientMin.filter.value = '';
      }
    });
  }

  /**
   * Apertura la pantalla para registrar un nuevo paciente
   */
  editPatientDialog() {
    this.dialog.open(NursePatientUpdateComponent, {
      width: '70%',
      data: this.object.system.patient.main.uuid,
    }).afterClosed().subscribe(result => {
      if (result.main.uuid !== '') {
        this.object.system.patient = result;
        this.patientMin.filter.value = result.system.physicalPerson.main.name + " " + result.system.physicalPerson.main.lastname + " " + result.system.physicalPerson.main.surname;
        this.searchPatient(this.patientMin.filter.value);
      } else {
        this.patientMin.filter.value = '';
      }
    });
  }

  patientSelected(ev){
    console.log(ev.option.value);
    for(let item of this.patientList){
      if(ev.option.value == item.system.physicalPerson.main.name + ' ' + item.system.physicalPerson.main.lastname + ' ' + item.system.physicalPerson.main.surname){
        this.object.system.patient.main = item.main;
      }
    }
  }

  /**funcionalidad para guardar una cita
  */
  send(object){
    this.object.main.dateTimeAppointment = moment(this.metadata.date).format('DD/MM/YYYY') +' '+ this.metadata.hour+':'+this.metadata.min+':00';
    if (this.object.main.uuid == '') {
      this.object.main.status = 'Nueva';
    }
    this.loadingService.show(true,"Espere un momento...");
    this.session.getRequest("validate:findByUsername", {
      system: {
        user: this.session.getUser()
      }
    }).subscribe((data: any) => {
      object.system.doctor.main.uuid = data.object.main.uuid;
      this.session.getRequest("consultingRoom:update",object).subscribe(async(data:any)=>{
        this.loadingService.hide();
        this.object.main.uuid = data.object.main.uuid;
        //guardamos los signos vitales
        if(this.object.system.patient.main.uuid != ''){
          //guardamos los signos vitales.
          for(let i = 0; i < this.vitalSignsList.length; i++){
            //actualizamos los datos de signos vitales
            this.vitalSignsList[i].system.patient.main.uuid = this.object.system.patient.main.uuid;
            this.vitalSignsList[i].system.consultingRoom.main.uuid = this.object.main.uuid;
            if(this.vitalSignsList[i].main.value != ''){
              this.saveSigVitals(this.vitalSignsList[i]);
            }
          }
        }
        if(object.main.typeAppointment == 'No disponible'){
          this.scheduleDoctorObject.main.dateTimeAppointment = this.object.main.dateTimeAppointment;
          this.scheduleDoctorObject.system.consultingRoom.main.uuid = this.object.main.uuid;
          this.scheduleDoctorObject.system.doctor.main.uuid = this.object.system.doctor.main.uuid;
          try{
            await this.saveScheduleDoctor();
          }catch(e){
            console.log(e);
          }
        }
        this.snackBar.open('Cita creada exitosamente', 'Aceptar', { duration: 5000 });
        this.close(true);
      },error=>{
        this.loadingService.hide();
      });
    },error=>{
      this.loadingService.hide();
    });
  }

  /**funcionalidad para obtener los signos vitales
  */
  getVitals(){
    //cargamos signos vitales predeterminados.
    for(let item of this.vitalSignsTypes){
      let aux = JSON.parse(JSON.stringify(this.vitalSignsModel));
      aux.main.dataType = item;
      aux.system.patient.main.uuid = this.object.system.patient.main.uuid;
      aux.system.consultingRoom.main.uuid = this.object.main.uuid;
      this.vitalSignsList.push(aux);
    }
    this.session.getRequest("vitalSigns:findAllByPatientAndConsultingRoom",{system:{consultingRoom:{main:{uuid:this.object.main.uuid}},patient:{main:{uuid:this.object.system.patient.main.uuid}}}}).subscribe((data:any)=>{
      if(data.object){
        for(let item of data.object.instanceList){
          for(let i = 0; i < this.vitalSignsList.length; i++){
            if(this.vitalSignsList[i].main.dataType == item.main.dataType){
              this.vitalSignsList[i] = JSON.parse(JSON.stringify(item));
            }
          }
        }
      }

    },error=>{
      console.log(error);
    });
  }

  saveSigVitals(object){
    this.session.getRequest("vitalSigns:update",object).subscribe((data:any)=>{
      object.main.uuid = data.object.main.uuid;
    },error=>{
      console.log(error);
    });
  }

  //calculamos indice de masa corporal
  calulateIMC(object){

    if(object.main.dataType == 'Estatura' || object.main.dataType == 'Peso'){
      let peso = '';
      let est = '';
      let imc:any = '';
      for(let item of this.vitalSignsList){
        if(item.main.dataType == 'Estatura'){
          est = item.main.value;
        }
        if(item.main.dataType == 'Peso'){
          peso= item.main.value;
        }
      }
      if(peso != '' && est != ''){
        imc = parseFloat(peso)/(parseFloat(est)*parseFloat(est));
      }
      if(imc != ''){
        for(let i = 0 ; i < this.vitalSignsList.length; i++){
          if(this.vitalSignsList[i].main.dataType == 'IMC'){
            this.vitalSignsList[i].main.value = Math.round(imc*100)/100;
          }
        }
      }
    }

  }

  loadPatientToConsulting(){
      this.close(false);
      this.router.navigate(['/specialist/medicalConsultation'],{
        queryParams: {
          patientUuid: this.object.system.patient.main.uuid
        },
        queryParamsHandling: 'merge',
      })
  }
  /**funcionalidad para guardar un schedule de doctor
  */
  saveScheduleDoctor(){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("scheduleDoctor:update", this.scheduleDoctorObject).subscribe(
        async (data:any) => {
          this.scheduleDoctorObject.main.uuid = data.object.main.uuid;
          resolve(true);
        }, error => {
          console.log("Error: scheduleDoctor:update",error);
          reject(error);
        });
    });
  }

  getScheduleDoctor(){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("scheduleDoctor:findByConsultingRoom", this.scheduleDoctorObject).subscribe(
        async (data:any) => {
          this.scheduleDoctorObject = data.object;
          resolve(true);
        }, error => {
          console.log("Error: scheduleDoctor:findByConsultingRoom",error);
          reject(error);
        });
    });
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'consultingRoom:001';
    }
    this.dialogRef.close(object);
  }
}
