import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { SingleDataSet, Label, Color, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import * as moment from 'moment';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';
import * as pluginLabels from 'chartjs-plugin-labels';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-admin-report-dashboard',
  templateUrl: './admin-report-dashboard.component.html',
  styleUrls: ['./admin-report-dashboard.component.css']
})
export class AdminReportDashboardComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;

  metadata = {
    dates: {
      startDate: moment().clone().startOf('month').toISOString(),
      endDate: moment().clone().endOf('month').toISOString(),
    },
    system: {
      roomOccupationDetail: {
        main: {
          startDate: moment().clone().startOf('month').format('DD/MM/YYYY hh:mm:ss'),
          endDate: moment().clone().endOf('month').format('DD/MM/YYYY hh:mm:ss'),
        }
      }
    },
    startDate: '',
    endDate: '',
    openAmount: 0.0,
    totalSpending: 0.0,
    totalSpendingGeneral: 0.0,
    honoraryGuards: 0.0,
    totalPatient: 0
  }
  years = [];
  // Objeto para almacenar la linea del tiempo de ingresos de pacientes en el sistema
  patientChart = {
    chartType:'line',
    chartData: [
      { data: [], label: 'Observación' },
      { data: [], label: 'Cirugia ambulatoria' },
      { data: [], label: 'Hospitalizacion y cirugia' },
      { data: [], label: 'Hospitalizacion' },
      { data: [], label: 'Total pacientes' },
    ],
    chartLabels:[],
    chartLegend:true,
    year:moment().format('YYYY'),
    chartPlugins:[DataLabelsPlugin],
    chartColors:[
      { backgroundColor: '#C0D8C0' },
      { backgroundColor: '#F5EEDC' },
      { backgroundColor: '#DD4A48' },
      { backgroundColor: '#ECB390' },
      { backgroundColor: '#61764B' },
    ],
    chartOptions:{
      responsive: true,
      plugins: {
        labels: {
          render: 'value',
          fontSize: 1,
          fontColor: []
        },
        datalabels: {
          backgroundColor:function(context) {
            let color:any= context.dataset.backgroundColor;
            return color;
          },
          borderRadius: 20,
          color: '#fff',
          font: {
            weight: 'bold',
          },
          formatter: function(value, context) {
              return ''+Math.round(value);
            },
          padding: 6
        }
      }
    },

  }

    // Grafica de pastel
    public pieChartOptions: ChartOptions = {
      responsive: true,
      maintainAspectRatio: true,
      legend: {
        position: 'left',
      },
      plugins: {
        labels: {
          render: 'percentage',
          fontColor: ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
          precision: 2
        }
      },
    };
    public pieChartLabels: Label[] = [];
    public pieChartData: SingleDataSet = [];
    public pieChartLegend = true;
    public pieChartPlugins = [];
    public pieChartColors = [{
      backgroundColor: [
        'rgba(255,0,0,1)', 'rgba(0,255,200,1)', 'rgba(0,0,255,0.3)', 'rgba(245,135,51,1)',
        'rgba(171,95,36,1)', 'rgba(255,226,205,1)', 'rgba(51,215,245,1)', 'rgba(36,150,171,1)',
        'rgba(101,0,36,1)', 'rgba(133,100,2,1)', 'rgba(91,15,205,1)', 'rgba(244,100,171,1)',
        'rgba(211,0,96,1)', 'rgba(3,206,205,1)', 'rgba(151,0,205,1)', 'rgba(96,190,101,1)',
        'rgba(0,4,255,1)', 'rgba(3,0,205,1)', 'rgba(51,200,10,1)', 'rgba(96,2,0,1)',
        'rgba(1,3,105,1)', 'rgba(22,0,5,1)', 'rgba(101,90,200,1)', 'rgba(125,2,10,1)',
        'rgba(2,0,205,1)', 'rgba(22,150,5,1)', 'rgba(10,110,200,1)', 'rgba(175,2,110,1)',
        'rgba(201,190,10,1)', 'rgba(0,191,253)', 'rgba(186,85,211)', 'rgba(255,228,196)', 'rgba(240,248,255)',
        'rgba(0,128,128)', 'rgba(138,43,226)', 'rgba(128,0,128)', 'rgba(255,255,224)', 'rgba(240,255,240)',
        'rgba(224,255,255)', 'rgba(106,90,205)', 'rgba(238,130,238)', 'rgba	(139,69,19)', 'rgba(240,255,255)',
  
      ]
    }];


  constructor(private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit() {
    this.createYears();
    this.creatingChartPatient();
        // Cargamos los datos de la gráfica de pastel
        this.findAllByCompanyAndRoleAndStatus();
  }
  /**funcionalidad para la grafica de linea de paciente de ingresos al hospital
  */
  async creatingChartPatient(){
    try{
      this.patientChart.chartLabels = [];
      for(let i = 0; i < this.patientChart.chartData.length; i++){
        this.patientChart.chartData[i].data = [];
      }

      let index = 0;
      let end = moment().locale('es-mx').format('M');
      // let endNumber = 11;
      //creamos los labels del mes
      while (index < 12) {
        let month = moment().locale('es-mx').set('month',index).format('MMMM');
        console.log(month);
        this.patientChart.chartLabels.push(month);
        index++;
      }

      // Generamos las consultas para lo contadores
      index = 0;
      while(index < 12){
        let year:any = this.patientChart.year;
        // let startMonth = moment().set('day',1).set('months',index).set('year',year).toISOString();
        // let endMonth = moment().set('day',1).set('months',index+1).subtract(1,'day').set('year',year).toISOString();
        let startMonth  = new Date(year, index, 1).toISOString();
        let endMonth =  new Date((new Date(year, index+1, 1)).setDate((new Date(year, index+1, 1)).getDate() - 1)).toISOString();

        let observacionTotal = await this.findAllByTypeOccupationAndDateBetweenDate('Observacion',startMonth,endMonth);
        let cirugiaAmbulatoriaTotal = await this.findAllByTypeOccupationAndDateBetweenDate('Cirugia ambulatoria',startMonth,endMonth);
        let hospitalYCirugiaTotal = await this.findAllByTypeOccupationAndDateBetweenDate('Hospitalizacion y cirugia',startMonth,endMonth);
        let hospitalTotal = await this.findAllByTypeOccupationAndDateBetweenDate('Hospitalizacion',startMonth,endMonth);
        let totalPacientes = observacionTotal + cirugiaAmbulatoriaTotal + hospitalYCirugiaTotal + hospitalTotal;

        this.patientChart.chartData[0].data.push(observacionTotal);
        this.patientChart.chartData[1].data.push(cirugiaAmbulatoriaTotal);
        this.patientChart.chartData[2].data.push(hospitalYCirugiaTotal);
        this.patientChart.chartData[3].data.push(hospitalTotal);
        this.patientChart.chartData[4].data.push(totalPacientes);
        index++;
      }
      // console.log(this.consultinChart)
    }catch(e){

    }
  }
  /***
  UTILIDADES
  **/

  /**funciñonalidad para crear el arreglo de años
  */
  createYears(){
    let year = moment().format('YYYY');
    let initial = 2019;
    this.years = [];

    while(initial <= parseInt(year)){
      this.years.push(initial);
      initial++;
    }
  }
  /**funcionalidad para obtener los totales de ingresos al CHUM
  */
  findAllByTypeOccupationAndDateBetweenDate(typeOccupation,startDate,endDate):Promise<number> {
    return new Promise((resolve,reject)=>{
      this.session.getRequest("dashboard:findAllByTypeOccupationAndDateBetween", { complement: { typeOccupation: typeOccupation }, system: {roomOccupationDetail:{main:{startDate: moment(startDate).format('DD/MM/YYYY hh:mm:ss'),endDate: moment(endDate).format('DD/MM/YYYY hh:mm:ss')}}} }).subscribe((data: any) => {
        resolve(data.object.value)
      }, error => {
        reject(error);
        console.log("Error: dashboard:findAllByTypeOccupationAndDateBetween", error);
      })
    });
  }

  // Obtenemos los colaboradores doctores especialistas
  findAllByCompanyAndRoleAndStatus() {
    this.loadingService.wait();
    this.session.getRequest("collaborator:findAllByCompanyAndRoleAndStatus", {
      system: {
        role: 'Specialist doctor',
        company: {
          main: {
            uuid: this.session.getSessionObject().main.uuid
          }
        },
        status: true
      }
    }).subscribe((data: any) => {
      this.pieChartLabels = [];
      this.pieChartData = [];
      for (let x = 0; x < data.object.instanceList.length; x++) {
        // Obtenemos el número de pacientes por doctor especialista
        this.getPatientByCollaborator(data.object.instanceList[x]).then((object: any) => {
          if (object.object.value > 0) {
            this.pieChartLabels.push(object.object.value + ' ' + data.object.instanceList[x].system.physicalPerson.main.name + ' ' + data.object.instanceList[x].system.physicalPerson.main.lastname + ' ' + data.object.instanceList[x].system.physicalPerson.main.surname);
            this.pieChartData.push(object.object.value);
          }
        });
      }
      this.loadingService.hide();
    }, error => {
      console.log("error", error);
      this.loadingService.hide();
    })
  }

    // Obtenemos el número de pacientes atendidos por cada dr. especialista indicado
    getPatientByCollaborator(collaborator) {
      return new Promise((resolve, reject) => {
        this.loadingService.wait();
        this.session.getRequest("dashboard:findAllByCollaboratorSpecialistAndDateBetween", { system: { collaborator: { main: { uuid: collaborator.main.uuid } }, roomOccupationDetail: this.metadata.system.roomOccupationDetail } }).subscribe(
          (data: any) => {
            resolve({ transaction: 'ok', object: data.object });
            this.loadingService.hide();
          }, error => {
            console.log("Error: dashboard:findAllByCollaboratorSpecialistAndDateBetween", error);
            this.loadingService.hide();
          });
      });
    }


    selectPeriond(event, period) {
      if (period === 'Inicial') {
        this.metadata.system.roomOccupationDetail.main.startDate = moment(event).format('DD/MM/YYYY hh:mm:ss');
      } else {
        this.metadata.system.roomOccupationDetail.main.endDate = moment(event).format('DD/MM/YYYY hh:mm:ss');
      }
      if (this.metadata.system.roomOccupationDetail.main.startDate <= this.metadata.system.roomOccupationDetail.main.endDate) {

        // Cargamos los datos de las gráficas por doctor especialista de acuerdo al rol
        this.findAllByCompanyAndRoleAndStatus();
  
      }
    }
  


  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
