import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuService } from './menu.service';
// import { SessionService } from '../service/session/session.module';
import { Router } from '@angular/router';
export interface oneMenuOption {
  route: string,
  title: string,
  icon: string,
  options: Array<{
    route: string,
    title: string,
    icon: string,
  }>,
  selected: boolean
}
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit {
  metadata = {
    user: {
      main: {
        fullName: '',
        lastname: '',
        name: '',
        surname: '',
        uuid: '',
        username: ''
      }
    },
  }
  menuOptions = [];

  constructor( private menustore: MenuService, private router: Router) {
    // this.metadata.user = this.session.getUser();
    // console.log(this.metadata)
    this.menuOptions = this.menustore.findMyMenu();
   }

  ngOnInit() {
    // console.log(this.menuOptions);
    let menuSelected =  sessionStorage.getItem('router');
    for(let i=0; i < this.menuOptions.length; i++){
      this.menuOptions[i].selected = false;
      if(menuSelected ==  this.menuOptions[i].route){
        this.menuOptions[i].selected = true;
      }
    }
  }

  logout() {
    // this.session.logout();
    this.menustore.clean();
    this.router.navigate(['login']);
  }

  menuSelection(object) {
    for (let i = 0; i < this.menuOptions.length; i++) {
      this.menuOptions[i].selected = false;
    }
    if (object.options.length > 0) {
    } else {
      this.menustore.clean();
    }
    object.selected = true;
    this.menustore.selected(object.route);
    this.router.navigate([object.route]).catch(error => {
      console.log("URL no definida:", object.route);
    });
  }
}
