import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import {MatSnackBar } from '@angular/material';
// import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../../service/session/session.module';
// import { LoadingService } from '../../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-maker-update',
  templateUrl: './maker-update.component.html',
  styleUrls: ['./maker-update.component.css']
})
export class MakerUpdateComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  complexForm: FormGroup;
  makertModel = {
    main: {
      uuid: '',
      code: '',
      name: ''
    },
    system: {
      status: true
    }
  }
  object = JSON.parse(JSON.stringify(this.makertModel));
  constructor(public fb: FormBuilder, private session: SessionService, public snackBar: MatSnackBar, private router: Router, private activatedRoute: ActivatedRoute) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'code' : [null, Validators.required],
      'name': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
    })
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params['uuid'] == 'new'){

      }else{
        // this.loadingService.wait();
         this.object.main.uuid = params['uuid'];
         // cargamos los datos de la BD
         this.session.getRequest('maker:get',this.object).subscribe((data:any)=>{
           this.object = JSON.parse(JSON.stringify(data.object));
           // this.loadingService.hide();
         },
         (error)=>{
           // this.loadingService.hide();
           console.log('Error:maker:get',error)
         })

      }
    });
  }

 /*
 funcion para guardar una unidad de medida
 */
 send(object){
   // this.loadingService.show(true,'Guardando registro');
   this.session.getRequest("maker:update",object).subscribe(
     (data:any) => {
       this.snackBar.open('El fabricante se guardo correctamente', '', {
         duration: 8000
       });
       // this.loadingService.hide();
       this.router.navigate(['/admin/maker/list']);
    },
    error => {
      console.log("maker:update",error);
      // this.loadingService.hide();
    }
   );
 }


 /*
 funcion para controlar si se abre o cierra el menu
 */
 toogleMenu(ev){
   this.drawerCtrl.opened = ev;
 }

}
