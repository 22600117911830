import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';
import { OwnerSaleReportComponent } from './owner-point-sale-update/owner-sale-report/owner-sale-report.component';
import { PatientMedicalRecordsComponent } from '../../directive/patient-medical-records/patient-medical-records.component';
import { OwnerFeePaymentComponent } from './owner-fee-payment/owner-fee-payment.component';
import { OwnerShowAnnotationComponent } from './owner-show-annotation/owner-show-annotation.component';
import * as Rx from 'rxjs/Rx'

@Component({
  selector: 'app-owner-point-sale',
  templateUrl: './owner-point-sale.component.html',
  styleUrls: ['./owner-point-sale.component.css']
})
export class OwnerPointSaleComponent implements OnInit {
  @ViewChild('drawer', { static: true }) drawerCtrl: MatDrawer;
  salesList = [];
  saleMin = {
    system: {
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      }
    },
    main: {
      status: ''
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }
  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100, 200, 500, 1000],
  };
  pageEvent: PageEvent;

  patientMin = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      },
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    surveyUuid:''
  }
  patientList = [];
  questionMin = {
    system:{
      object:'',
      objectUuid:''
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  constructor(private session: SessionService, public dialog: MatDialog, public snackBar: MatSnackBar, public loadingService: LoadingService) {
    this.patientMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.patientMin.filter.name = 'searchElement';
          this.patientAllByCompany();
        } else {
          this.patientMin.filter.name = '';
          this.patientMin.filter.value = '';
          val = 'false'
          this.getSales(this.saleMin.main.status);
        }
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged: ', error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
      });
  }

  ngOnInit() {
    // obtenemos todas las ventas abiertas
    this.getSales('Abierta');
    this.getQuestionList();
  }

  patientAllByCompany() {
    this.loadingService.show(true, "Espere un momento...");
    this.patientMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("patient:findAllByCompany", this.patientMin).subscribe(async (data: any) => {
      this.salesList = [];
      this.patientList = data.object.instanceList;
      for (let item of data.object.instanceList) {
        let aux: any = await this.findByPatientAndStatus(item, this.saleMin.main.status);
        if (aux.object) {
          this.salesList.push(aux.object);
          // this.salesList = this.salesList.concat(aux);
        }
      }
      for (let x = 0; x < this.salesList.length; x++) {
        this.getSalesDetails(this.salesList[x]).then((data: any) => {
          this.salesList[x] = data.object;
        });
      }
      this.loadingService.hide();
    }, error => {
      this.loadingService.hide();

      console.log("error", error);
    })
  }

  /**
   * Obtiene el total consumo por Paciente
   */
  findByPatientAndStatus(patient, status) {
    return new Promise((resolve, reject) => {
      this.session.getRequest("sales:findAllByPatientAndStatus", { main: { status: status }, system: { patient: patient } }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
        },
        error => {
          console.log("Erro: sales:findAllByPatientAndStatus", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Funcion para buscar
   */
  search() {
    this.metadata.searchBoxInput.next(this.patientMin.filter.value);
  }

  /**
   * Obtenemos las ventas por empresa y estatus
   */
  getSales(status) {
    this.loadingService.show(true, "Obteniendo detalle de la estancia, espere un momento...");
    this.salesList = [];
    this.saleMin.main.status = status;
    this.session.getRequest("sales:listByCompanyAndStatus", this.saleMin).subscribe(
      async (data: any) => {
        this.salesList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        // iteramos las ventas para obtener su detalle por venta
        for (let x = 0; x < this.salesList.length; x++) {
          let aux:any = await this.getSalesDetails(this.salesList[x])
           this.salesList[x] = aux.object;
          // this.getSalesDetails(this.salesList[x]).then((data: any) => {
          //   this.salesList[x] = data.object;
          // });
        }
        this.loadingService.hide();
      }, error => {
        console.log("Error: sales:listByCompanyAndStatus", error);
        this.loadingService.hide();
      });
  }

  /**
   * Obtiene el detalle de los productos consumidos en la habitación
   */
  getRoomOccupation(uuid) {
    return new Promise((resolve, reject) => {
      this.session.getRequest("roomOccupation:get", { main: { uuid: uuid } }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
        },
        error => {
          console.log("Error: roomOccupation:get", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Obtiene el detalle de los medicos que supervisaron al paciente
   */
  getRoomOccupationDoctor(uuid) {
    return new Promise((resolve, reject) => {
      this.session.getRequest("roomOccupationDoctor:get", { main: { uuid: uuid } }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
        },
        error => {
          console.log("Error: roomOccupationDoctor:get", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Obtiene el detalle de los productos consumidos en la habitación
   */
  getRoomOccupationRegister(uuid) {
    return new Promise((resolve, reject) => {
      this.session.getRequest("roomOccupationRegister:get", { main: { uuid: uuid } }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
        },
        error => {
          console.log("Error: roomOccupationRegister:get", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Obtiene la lista de ventas por empresa y estatus
   */
  getSalesDetails(sales) {
    return new Promise((resolve, reject) => {
      // Obtenemos el detalle de la venta
      for (let x = 0; x < sales.system.salesDetails.length; x++) {
        // Obtenemos el detalle del objecto de venta
        if (sales.system.salesDetails[x].system.object == 'RoomOccupation') {
          // case 'RoomOccupationRegister':
          this.getRoomOccupationRegister(sales.system.salesDetails[x].system.objectUuid).then((data: any) => {
            sales.system.salesDetails[x].system.objectDetail = data.object;
            // Obtenemos el detalle del usuario que realizo la acción
            if ( sales.system.salesDetails[x].system.objectDetail.system.user.main.uuid != null ) {
              this.getUser(sales.system.salesDetails[x].system.objectDetail.system.user).then((data: any) => {
                sales.system.salesDetails[x].system.objectDetail.system.user = data.object;
              });
            }
            // Obtenemos el doctor que origino la venta del servicio
            this.getDoctor(sales.system.salesDetails[x].system.collaboratorUuid).then((data: any) => {
              sales.system.salesDetails[0].system.doctor = data.object;
            });
          });
        }
      }
      resolve({ transaction: 'ok', object: sales });
    });
  }

  /**
   * Obtiene el detalle del usuario que origino el movimiento
   */
  getUser(user) {
    return new Promise((resolve, reject) => {
      // this.loadingService.show(true, "Obteniendo el detalle del usuario que origino el movimiento, espere un momento...");
      this.session.getRequest("user:get", user).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
          // this.loadingService.hide();
        },
        error => {
          console.log("Error: user:get", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Obtiene el detalle del usuario que origino el movimiento
   */
  getDoctor(collaboratorUuid) {
    return new Promise((resolve, reject) => {
      this.session.getRequest("collaborator:get", { main: { uuid: collaboratorUuid } }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
        },
        error => {
          console.log("Error: collaborator:get", error);
        });
    });
  }

  report(object) {
    const dialogRef = this.dialog.open(OwnerSaleReportComponent, {
      width: '50%',
      data: { object: object }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('--- ', result);
    });
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event, status) {
    this.saleMin.offset = (event.pageIndex * event.pageSize);
    this.saleMin.max = event.pageSize;
    this.getSales(status);
  }

  async tabChange(ev) {
    console.log(ev);
    this.saleMin.offset = 0;
    if (ev.index == 0) {
      this.saleMin.main.status = 'Abierta';
    } else {
      this.saleMin.main.status = 'Pagada';
    }

    if(ev.index == 2){
      //obteemos la ventas con anotaciones
      await this.getSalesWithComments();
    }else{
      if (this.patientMin.filter.value == '') {
        this.getSales(this.saleMin.main.status);
      } else {
        this.salesList = [];
        for (let item of this.patientList) {
          let aux: any = await this.findByPatientAndStatus(item, this.saleMin.main.status);
          console.log('aux ', aux);
          if (aux.object) {
            this.salesList.push(aux.object);
            // this.salesList = this.salesList.concat(aux);
          }
        }
      }
    }
  }

  getSalesWithComments(){
    return new Promise((resolve,reject)=>{
      this.loadingService.show(true, "Obteniendo detalle de la estancia, espere un momento...");
      this.salesList = [];
      this.saleMin.main.status = status;
      this.session.getRequest("sales:listByCompanyAndFeedPaymentAnnotation", this.saleMin).subscribe(
        async (data: any) => {
          this.salesList = data.object.instanceList;
          this.paginatorModel.total = data.object.total;
          // iteramos las ventas para obtener su detalle por venta
          for (let x = 0; x < this.salesList.length; x++) {
            let aux:any = await this.getSalesDetails(this.salesList[x])
             this.salesList[x] = aux.object;
            // this.getSalesDetails(this.salesList[x]).then((data: any) => {
            //   this.salesList[x] = data.object;
            // });
          }
          this.loadingService.hide();
        }, error => {
          console.log("Error: sales:listByCompanyAndStatus", error);
          this.loadingService.hide();
        });
    });
  }

  openPatientMedicalNotes(patient) {
    this.dialog.open(PatientMedicalRecordsComponent, {
      width: '90%',
      height: '90%',
      data: patient,
      // disableClose:true
    }).afterClosed().subscribe(result => {
      if (result) {
        if (result.transaction == 'ok') {
          console.log(result);
        }
      }
    });
  }

  showAnotation(sale){
    this.dialog.open(OwnerShowAnnotationComponent, {
      width: '36rem',
      data: sale,
      // disableClose:true
    }).afterClosed().subscribe(result => {
      if (result) {
        if (result.transaction == 'ok') {
          console.log(result);
        }
      }
    });
  }

  /**funcionalidad para pago de cuota
  */
  openFeePayment(sale) {
    this.dialog.open(OwnerFeePaymentComponent, {
      width: '90rem',
      height: '90%',
      data: sale.main.uuid,
      // disableClose:true
    }).afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          this.getSales(this.saleMin.main.status);
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }
  async getQuestionList() {
    this.questionMin.system.object = 'Company'
    this.questionMin.system.objectUuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("questionnaire:findByObjectAndObjectUuid",this.questionMin).subscribe((data:any)=>{
      for(let item of data.object.instanceList){
        if(item.main.name == 'CALIDAD EN LA ATENCIÓN MEDICA BRINDADA'){
          this.metadata.surveyUuid = item.main.uuid;
        }
      }
    },error=>{
      console.log("error:questionnaire",error);
      this.loadingService.hide();
    })
  }

  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
