import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { MatSnackBar, MatDialog } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';

@Component({
  selector: 'app-owner-slope-tracking',
  templateUrl: './owner-slope-tracking.component.html',
  styleUrls: ['./owner-slope-tracking.component.css']
})
export class OwnerSlopeTrackingComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;
  slopeTrackingModel = {
    main:{
      uuid:'',
      note:''
    },
    system:{
      doctor:{
        main:{
          uuid:''
        }
      },
      collaborator:{
        main:{
          uuid:''
        }
      },
      status:'Iniciada',
      createDate:''//dd/MM/yyyy hh:mm:ss
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }

  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    doctor:{
      main:{
        uuid:''
      }
    },
    saving:true
  }
  public model = {
    editorData: '<p>Hello, world!</p>'
  };

  editorConfig = {
    height: '25em'
  }

  object:any = JSON.parse(JSON.stringify(this.slopeTrackingModel))
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, private router: Router, private dialog:MatDialog) {
    this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        this.send();
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged: ', error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
      });
   }

  async ngOnInit() {
    await this.getDoctorUUid();
    // funcionalidad para obtener la nota almacenada.
    this.session.getRequest('slopeTracking:get',{main:{uuid:'2c9fa81f82f90ce20182f98fb035003c'}}).subscribe((data:any)=>{
      this.object = JSON.parse(JSON.stringify(data.object));
      this.loadingService.hide();
    },(error)=>{
      this.loadingService.hide();
      console.log('Error: slopeTracking:get',error)
    })
  }

  send(){
    console.log(this.object);
    this.metadata.saving = false;
    this.object.system.createDate = moment().format('DD/MM/yyyy hh:mm:ss');
    this.object.system.doctor.main = this.metadata.doctor.main;
    this.session.getRequest('slopeTracking:update', this.object).subscribe(
      (data:any)=>{
        this.metadata.saving = true;
      },
      error => {
        console.log("slopeTracking:update",error);
        this.metadata.saving = true;
      })
  }

  getDoctorUUid(){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("validate:findByUsername", {
        system: {
          user: this.session.getUser()
        }
      }).subscribe(async(data: any) => {
        this.metadata.doctor.main.uuid = data.object.main.uuid;
        resolve(true)
      }, error=>{
        this.loadingService.hide();
        reject(error);
        console.log(error);
      });
    });
  }


  /**
   * Funcion para buscar
   */
    saving() {
      this.metadata.searchBoxInput.next('guardando');
    }


  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
