import { Component, OnInit, ViewChild, Inject  } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar, MAT_DIALOG_DATA, MatDrawer, MatDialogRef } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-nurse-product-service',
  templateUrl: './admin-nurse-product-service.component.html',
  styleUrls: ['./admin-nurse-product-service.component.css']
})
export class AdminNurseProductServiceComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  productFamilyList = [];
  doctorList = [];
  warehouseList = [];
  warehouseProductList = [];
  roomOccupationRegisterModel = {
    main: {
      uuid: '',
      dateRegister: '',
    },
    system: {
      user: {
        main: {
          uuid: ''
        }
      },
      roomOccupation: {
        main: {
          uuid: ''
        }
      },
      doctor: {
        main: {
          uuid: ''
        },
        system: {
          physicalPerson: {
            main: {
              uuid: '',
              name: '',
              lastname: '',
              surname: ''
            }
          }
        }
      },
      roomOccupationProducts: []
    }
  };
  roomOccupationProductModel = {
    main: {
      uuid: '',
      quantity: 0,
      price: 0,
    },
    system: {
      warehouse: {
        main: {
          uuid: '',
          code: '',
          name: '',
          toSell: ''
        }
      },
      roomOccupationRegister: {
        main: {
          uuid: ''
        }
      },
      product: {
        main: {
          uuid: ''
        }
      },
    }
  };
  roomOccupationDoctorModel = {
    main: {
      uuid: '',
      price: 0,
      subTotal: 0,
      tax: 0,
      total: 0
    },
    system: {
      object: '',
      objectUuid: '',
      doctor: {
        main: {
          uuid: ''
        }
      },
      company: {
        main: {
          uuid: ''
        }
      },
      roomOccupation: {
        main: {
          uuid: ''
        }
      },
      patient: {
        main: {
          uuid: ''
        }
      }
    }
  }
  metadata = {
    productFamily: {
      main: {
        uuid: '',
        code: '',
        name: ''
      },
      complement: {
        description: ''
      },
      system: {
        company: {
          main: {
            uuid: this.session.getSessionObject().main.uuid
          }
        }
      }
    },
    filtering: {
      system: {
        product: {
          main: {
            nameCharacteristicValue: ''
          }
        }
      }
    },
    warehouse: {
      main: {
        uuid: '',
        code: '',
        name: '',
        toSell: ''
      }
    },
    doctor: {
      main: {
        uuid: ''
      },
      system: {
        physicalPerson: {
          main: {
            uuid: '',
            name: '',
            lastname: '',
            surname: ''
          }
        }
      }
    },
    showFiller: false
  }

  object = JSON.parse(JSON.stringify(this.roomOccupationRegisterModel));
  constructor(protected session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: any, private router: Router, private activatedRoute: ActivatedRoute, public dialogRef: MatDialogRef<AdminNurseProductServiceComponent>) {
    if (this.data) {
      this.object.main.dateRegister =  moment().format('DD/MM/YYYY HH:mm:ss');
      this.object.system.roomOccupation.main = this.data.object.main;
      this.object.system.user.main.uuid = this.session.getUser().main.uuid;
      // Validamos si hay un registro previo de ocupación por el usuario
      this.loadingService.show(true, "Obtenemos los registros previso del usuario, espere un momento por favor...");
      this.warehouseProductList = [];
      this.session.getRequest("roomOccupationRegister:findByRoomOccupationAndUserAndDate", this.object).subscribe(
        (data: any) => {
          if (data.object) {
            this.object.main.uuid = data.object.main.uuid;
            this.object.main.dateRegister = moment(data.object.main.dateRegister).format('DD/MM/YYYY HH:mm:ss');
            this.object.system.user = data.object.system.user;
            this.object.system.doctor = data.object.system.doctor;
            this.metadata.doctor = data.object.system.doctor;
            this.object.system.roomOccupationProducts = data.object.system.roomOccupationProducts;
            this.loadingService.hide();
            // Obtejemos el detalle de productos
            this.loadingService.show(true, "Obtenemos el detalle de los productos asignados al paciente, espere un momento por favor...");
            this.session.getRequest("roomOccupationRegister:findByRoomOccupationAndUserAndDate", this.object).subscribe(
              (data: any) => {
                this.loadingService.hide();
              },
              error => {
                console.log("Error: roomOccupationRegister:findByRoomOccupationAndUserAndDate", error);
                this.loadingService.hide();
              });
          }
        },
        error => {
          console.log("Error: roomOccupationRegister:findByRoomOccupationAndUserAndDate", error);
          this.loadingService.hide();
        });
    }
  }

  ngOnInit() {
    this.getWarehouse();
    this.getDoctor();
  }

  /**
   * Agregamos el producto indicado a la lista de productos de la cuenta
   */
  addProduct(warehouseProduct) {
    // validamos si el producto ya existe en el listado de venta
    let exist = true;
    for (let x=0; x<this.object.system.roomOccupationProducts.length; x++) {
      if (this.object.system.roomOccupationProducts[x].system.product.main.uuid == warehouseProduct.system.product.main.uuid) {
        exist = false;
        this.object.system.roomOccupationProducts[x].main.quantity = this.object.system.roomOccupationProducts[x].main.quantity + 1;
      }
    }
    if (exist) {
      let roomOccupationProduct = JSON.parse(JSON.stringify(this.roomOccupationProductModel));
      roomOccupationProduct.main.quantity = 1;
      roomOccupationProduct.system.product = warehouseProduct.system.product;
      roomOccupationProduct.system.warehouse = warehouseProduct.system.warehouse;
      this.object.system.roomOccupationProducts.push(roomOccupationProduct)
    }
    // Disminuimos la existencia del producto
    for (let x=0; x<this.warehouseProductList.length; x++) {
      if (this.warehouseProductList[x].system.product.main.uuid == warehouseProduct.system.product.main.uuid) {
        this.warehouseProductList[x].main.quantity = this.warehouseProductList[x].main.quantity - 1;
      }
    }
  }

  /**
   * Indica el cambio de familia de producto
   */
  changeTab(tabSelect) {
    this.metadata.productFamily = this.productFamilyList[tabSelect.index];
    this.getWarehouseProduct();
  }

  /**
   * Eliminamos el producto indicado
   */
  deleteProduct(roomOccupationProduct) {
    for (let x=0; x<this.object.system.roomOccupationProducts.length; x++) {
      if (this.object.system.roomOccupationProducts[x].system.product.main.uuid == roomOccupationProduct.system.product.main.uuid && this.object.system.roomOccupationProducts[x].system.warehouse.main.uuid == roomOccupationProduct.system.warehouse.main.uuid) {
        // Agregamos la cantidad a la lista de productos disponibles
        for (var y=0; y<this.warehouseProductList.length; y++) {
          if (this.warehouseProductList[y].system.product.main.uuid == roomOccupationProduct.system.product.main.uuid && this.warehouseProductList[y].system.warehouse.main.uuid == roomOccupationProduct.system.warehouse.main.uuid) {
            this.warehouseProductList[y].main.quantity = this.warehouseProductList[y].main.quantity + roomOccupationProduct.main.quantity;
          }
        }
        // eliminamos el producto
        this.object.system.roomOccupationProducts.splice(x, 1);
      }
    }
  }

  /**
   * Obtiene la lista de doctores de guardia
   */
  getDoctor() {
    this.loadingService.show(true, "Obteniendo doctores de guardia, espere un momento por favor...");
    this.session.getRequest("collaborator:findAllByCompanyAndRoleAndStatus", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } }, role: 'Doctor', status: true } }).subscribe(
      (data: any) => {
        this.doctorList = data.object.instanceList;
        this.loadingService.hide();
      }, error => {
        console.log("Error: collaborator:findAllByCompanyAndRoleAndStatus", error);
        this.loadingService.hide();
      });
  }

  /**
   * Obtiene categorias de producto de acuerdo a las existencias
   */
  getProductFamily() {
    this.loadingService.show(true, "Obteniendo productos, espere un momento por favor...");
    this.session.getRequest("warehouseProduct:getProductFamilyByWarehouse", { system: { warehouse: this.metadata.warehouse, status: true } }).subscribe(
      (data: any) => {
        if (data.object.instanceList.length > 0) {
          this.productFamilyList = data.object.instanceList;
          if (this.productFamilyList.length > 0) {
            this.metadata.productFamily = this.productFamilyList[0];
            this.getWarehouseProduct();
          }
        } else {
          this.loadingService.hide();
          this.snackBar.open('Información', 'No existen productos en el inventario', {
            duration: 8000
          });
          this.dialogRef.close(this.object);
        }
      }, error => {
        console.log("Error: warehouseProduct:getProductFamilyByWarehouse", error);
        this.loadingService.hide();
      });
  }

  /**
   * Obtiene la lista de almacenes para venta
   */
  getWarehouse() {
    this.loadingService.show(true, "Obteniendo alamacenes, espere un momento por favor...");
    this.session.getRequest("warehouse:findAllByCompanyAndToSellAndStatus", { main: {toSell: true}, system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } }, status: true } }).subscribe(
      (data: any) => {
        this.warehouseList = data.object.instanceList;
      }, error => {
        console.log("Error: warehouse:findAllByCompanyAndToSellAndStatus", error);
        this.loadingService.hide();
      });
  }

  /**
   * Obtiene los productos en existencia por el almacen y categoria
   */
  getWarehouseProduct() {
    this.loadingService.show(true, "Obteniendo productos en existencia, espere un momento por favor...");
    this.warehouseProductList = [];
    this.session.getRequest("warehouseProduct:findAllByWarehouseAndProductFamily", { system: { warehouse: this.metadata.warehouse, productFamily: this.metadata.productFamily } }).subscribe(
      (data: any) => {
        this.warehouseProductList = data.object.instanceList;
        this.loadingService.hide();
      },
      error => {
        console.log("Error: warehouseProduct:findAllByWarehouseAndProductFamily", error);
        this.loadingService.hide();
      }
    );
  }

  /**
   * Obtiene las categorias por el almacen indicado
   */
  selectWarehouse(uuid) {
    this.loadingService.show(true, "Obtenemos los productos del almacen seleccionado, espere un momento por favor...");
    this.session.getRequest("warehouse:get", { main: { uuid: uuid }  }).subscribe(
      (data: any) => {
        this.metadata.warehouse.main = data.object.main;
        this.getProductFamily();
        this.loadingService.hide();
      },
      error => {
        console.log("Error: warehouseProduct:findAllByWarehouseAndProductFamily", error);
        this.loadingService.hide();
      }
    );
  }

  /**
   * Inicializa el doctor de guardia
   */
  selectDoctor() {
    // No existe doctor de guardía
    if (this.metadata.doctor.main.uuid == '-') {
      this.object.system.doctor = {
        main: {
          uuid: ''
        },
        system: {
          physicalPerson: {
            main: {
              uuid: '',
              name: '',
              surname: '',
              lastname: ''
            }
          }
        }
      }
    } else {
      for (let x=0; x<this.doctorList.length; x++) {
        if (this.doctorList[x].main.uuid == this.metadata.doctor.main.uuid) {
          this.object.system.doctor = this.doctorList[x];
          this.metadata.doctor.system = this.doctorList[x].system;
        }
      }
    }
    this.drawerCtrl.opened = false;
  }

  /**
   * Guarda los productos seleccionados
   */
  send(object) {
    this.loadingService.show(true, "Guardando los suministros del paciente, espere un momento por favor...");
    this.warehouseProductList = [];
    this.session.getRequest("roomOccupationRegister:update", object).subscribe(
      (data: any) => {
        this.object = data.object;
        let roomOccupationDoctor = JSON.parse(JSON.stringify(this.roomOccupationDoctorModel));
        roomOccupationDoctor.system.object = 'RoomOccupationRegister';
        roomOccupationDoctor.system.objectUuid = this.object.main.uuid;
        roomOccupationDoctor.system.doctor.main.uuid = this.object.system.doctor.main.uuid;
        roomOccupationDoctor.system.roomOccupation.main.uuid = this.object.system.roomOccupation.main.uuid;
        roomOccupationDoctor.system.company.main.uuid = this.session.getSessionObject().main.uuid;
        if (roomOccupationDoctor.system.doctor.main.uuid !='' ) {
          this.session.getRequest("roomOccupationDoctor:update", roomOccupationDoctor).subscribe(
            (data: any) => {
              this.snackBar.open('Ok', 'El registro se guardo correctamente', {
                duration: 8000
              });
            }, error => {
              console.log("Error: roomOccupationDoctor:update", error);
              this.loadingService.hide();
            });
        }
        this.dialogRef.close(this.object);
        this.loadingService.hide();
      }, error => {
        console.log("Error: roomOccupationRegister:update", error);
        this.loadingService.hide();
      });
  }
}
