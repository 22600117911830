import { Component, OnInit, Inject } from '@angular/core';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-assistant-point-sale-open-cash',
  templateUrl: './assistant-point-sale-open-cash.component.html',
  styleUrls: ['./assistant-point-sale-open-cash.component.css']
})
export class AssistantPointSaleOpenCashComponent implements OnInit {
  saleClinicModel={
    main:{
      uuid:'',
      total:0.0,
      cashFund:0.0,
      status:'Abierto',
      starDate:'',
      endDate:'',
      totalAttended:0,
      totalReceived:0.0,
    },
    system:{
      collaborator:{
        main:{
          uuid:''
        }
      },
      otherIncome:{
        main:{
          uuid:''
        }
      }
    }
  }

  otherIncomeMin = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }
  otherIncomeList = [];

  object = JSON.parse(JSON.stringify(this.saleClinicModel));

  constructor(public loadingService: LoadingService, private session: SessionService, public dialogRef: MatDialogRef<AssistantPointSaleOpenCashComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar) {
   }

  ngOnInit() {
    // if(this.data != 'new') {
    //   // this.loadingService.wait();
    //   this.object.main.uuid = this.data;
    //   this.session.getRequest('saleClinic:get', this.object.main.uuid).subscribe(
    //     (data: any) => {
    //       this.object = data.object;
    //       this.loadingService.hide();
    //     }, error => {
    //       console.log('Error: saleClinic:get', error);
    //       this.loadingService.hide();
    //     });
    // }
  }

     /**Obtenemos la lista de gastos***/
     getOtherIncomeList() {
      this.loadingService.wait();
      this.otherIncomeList = [];
      // verificamos en que pagina verificamos
      this.otherIncomeMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
      this.session.getRequest("otherIncome:list", this.otherIncomeMin).subscribe(
        (data: any) => {
          console.log('----', data);
          this.otherIncomeList = data.object.instanceList;
          //this.paginatorModel.total = data.object.total;
          this.loadingService.hide();
        },
        error => {
          console.log("otherIncome:list", error);
          this.loadingService.hide();
        });
    }




  send(object) {
    this.session.getRequest('validate:findByUsername',  {system: {
      user: this.session.getUser()
    }}).subscribe(
      (data: any) => {
        object.system.collaborator.main.uuid = data.object.main.uuid;
        this.loadingService.wait();
        this.object.main.starDate = moment().format('DD-MM-YYYY hh:mm:ss');
        this.session.getRequest('saleClinic:update', this.object).subscribe(
          (data: any) => {
            this.object = data.object;
            this.loadingService.hide();
            this.closeDialog(true);
          }, error => {
            console.log('Error: saleClinic:update', error);
            this.loadingService.hide();
          });
        this.loadingService.hide();
      }, error => {
        console.log('Error: validate:findByUsername', error);
        this.loadingService.hide();
      });
  }

  /*
   * Cierra el modal
   */
  closeDialog(object) {
    this.dialogRef.close(object);
  }

}
