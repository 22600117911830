import { Input, HostListener, OnInit, Component, ChangeDetectionStrategy } from '@angular/core';
import { SessionService } from '../../service/session/session.module';
export interface images {
  file: string,
  type: string,
  name: string,
}
@Component({
  selector: 'sti-file-directive',
  templateUrl: './sti-file.component.html',
  styleUrls: ['./sti-file.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class StiFileComponent {
  @Input('stiFile') stiFile: images;

  constructor(public session: SessionService) {
    console.log("FILE",this.stiFile);
  }

  /*
  * funcion para convertir un archivo en base 64
  */

  onFileChange(event) {
  let reader = new FileReader();
  if(event.target.files && event.target.files.length > 0) {
    console.log(event);
    this.stiFile.name = event.target.files[0].name;
    this.stiFile.type = event.target.files[0].type;
    let file = event.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = (event:any) => {
      this.stiFile.file = event.target.result
      // console.log("event.target.result", this.stiFile);
    };
  }
}
/*
funcion para eliminar el objeto.
*/
deleteAFile(){
  this.stiFile.file = '';
  this.stiFile.name = '';
  this.stiFile.type = '';
}
/*
funcion para descargar un archivo.
*/
downloadAFile(){
  let elem = document.createElement('a');
  elem.href = this.stiFile.file;
  elem.setAttribute('download', this.stiFile.name);
  document.body.appendChild(elem);
  elem.click();
}


}
