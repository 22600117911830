import { Component, OnInit, Inject } from '@angular/core';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-other-charge-update',
  templateUrl: './other-charge-update.component.html',
  styleUrls: ['./other-charge-update.component.css']
})
export class OtherChargeUpdateComponent implements OnInit {

  // salesDetail
  object:any = {
    main: {
      uuid: '',
      movementDate: '',
      subTotal: 0,
      tax: 0,
      total: 0
    },
    system: {
      object: 'OtherCharge',
      objectUuid: '',
      collaboratorUuid: this.session.getUser().main.uuid,
      sales: {
        main: {
          uuid: '',
        },
      },
      objectDetail: {
        main: {
          uuid: '',
          concept: '',
          quantity: 1,
          value: 0.0,
          subtotal: 0.0,
          tax: 0.0,
          total: 0.0,
          status: 'Hospitalario',
          dateRegister: ''
        },
        system: {
          userUuid: this.session.getUser().main.uuid,
          user: {
            main: {
              uuid: ''
            },
            system: {
              physicalPerson: {
                main: {
                  name: '',
                  surname: '',
                  lastname: ''
                }
              }
            }
          }
        }
      }
    }
  }
  chargers = ['Hospitalario', 'Honorarios', 'Otro', 'Otro hospitalario'];
  constructor(public loadingService: LoadingService, private session: SessionService, public dialogRef: MatDialogRef<OtherChargeUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar) {
    this.object = JSON.parse(JSON.stringify(data.object));
  }

  ngOnInit() {
  }

  /**
   * Agrega el concepto al detalle de la venta
   */
  addOtherCharge(salesDetail) {
    salesDetail.main.uuid = this.object.main.uuid;
    salesDetail.main.movementDate = moment(salesDetail.system.objectDetail.main.dateRegister).format('DD/MM/YYYY hh:mm:ss');
    salesDetail.system.objectDetail.main.dateRegister = moment(salesDetail.system.objectDetail.main.dateRegister).format('DD/MM/YYYY hh:mm:ss');
    salesDetail.system.objectDetail.system.collaboratorUuid = this.session.getUser().main.uuid;
    salesDetail.system.sales = this.object.system.sales;
    this.loadingService.show(true, "Agregando otro cargo, espere un momento...");
    this.session.getRequest("otherCharge:update", salesDetail).subscribe(
      (data: any) => {
        salesDetail.main = JSON.parse(JSON.stringify(data.object.main));
        salesDetail.system.objectUuid = JSON.parse(JSON.stringify(data.object.system.objectUuid));
        salesDetail.system.objectDetail.main = JSON.parse(JSON.stringify(data.object.system.objectDetail.main));
        salesDetail.system.objectDetail.system = JSON.parse(JSON.stringify(data.object.system.objectDetail.system));
        salesDetail.system.objectDetail.system.user.main.uuid = this.session.getUser().main.uuid;
        // Obtenemos el detalle del usuario que realizo la acción
        this.getUser(salesDetail.system.objectDetail.system.user).then((data: any) => {
          salesDetail.system.objectDetail.system.user = data.object;
          // Retornamos el resultado
          this.loadingService.hide();
          this.closeDialog(salesDetail);
        });
      },
      error => {
        console.log("Error: otherCharge:update", error);
        this.loadingService.hide();
      });
  }

  /**
   * Se actualiza el cargo del concepto de acuerdo
   */
  otherChargeUpdate(salesDetail) {
    this.loadingService.show(true, "Actualizando el detalle del cargo, espere un momento...");
    salesDetail.system.objectDetail.main.subtotal = salesDetail.system.objectDetail.main.quantity * salesDetail.system.objectDetail.main.value;
    if (this.object.system.sales.main.enableTax) {
      salesDetail.system.objectDetail.main.tax = salesDetail.system.objectDetail.main.subtotal * 0.16;
      salesDetail.system.objectDetail.main.total = salesDetail.system.objectDetail.main.subtotal * 1.16;
    } else {
      salesDetail.system.objectDetail.main.tax = 0;
      salesDetail.system.objectDetail.main.total = salesDetail.system.objectDetail.main.subtotal;
    }
    this.loadingService.hide();
  }

  /*
   * Cierra el modal
   */
  closeDialog(object) {
    this.dialogRef.close(object);
  }

  /**
   * Obtiene el detalle del usuario que origino el movimiento
   */
  getUser(user) {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo el detalle del usuario que origino el movimiento, espere un momento...");
      this.session.getRequest("user:get", user).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
          this.loadingService.hide();
        },
        error => {
          console.log("Error: user:get", error);
          this.loadingService.hide();
        });
    });
  }

}
