import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { Router } from '@angular/router';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';

@Component({
  selector: 'app-owner-medical-note-patient',
  templateUrl: './owner-medical-note-patient.component.html',
  styleUrls: ['./owner-medical-note-patient.component.css']
})
export class OwnerMedicalNotePatientComponent implements OnInit {
  consultingRoomModel = {
    main: {
      uuid:'',
      dateTimeAppointment:'',
      pay: '0',
      status:'',
      minute:'30',
      typeAppointment:''// medica, cirugía ( morado ), seguimiento, primera vez, no disponible (GRIS).
    },
    system: {
      patient: {
        main: {
          uuid: ''
        }
      },
      doctor:{
        main:{
          uuid:''
        }
      }
    }
  };
  physicalPerson = {
    main: {
      uuid: '',
      name: '',
      lastname: '',
      surname: ''
    },
    complement: {
      dateBirth: '',
      curp: '',
      rfc: '',
      gender: ''
    },
    system: {
      status:true,
    }
  };
  medicalNotesModel = {
    main: {
      uuid:'',
      // Fecha y hora de la cita
      dateMedical: '',
      note: '',
      typeNote: '',
      share:true
    },
    system: {
      doctor: {
        main: {
          uuid: ''
        }
      },
      patient: {
        main: {
          uuid: ''
        },
        system: {
          physicalPerson:this.physicalPerson,
        }
      }
    }
  };
  instanceList = [];
  vitalSignsList = [];
  vitalSignsTypes =[
    'Presión arterial',
    'Pulso',
    'Temperatura',
    'Oxigenación',
    'Dextrosa',
    'Peso',
    'Estatura',
    'IMC'
  ]
  minCie10={main: {description: ''} };
  cie10List = [];
  medicalNotesCIE10List = [];
  object = JSON.parse(JSON.stringify(this.medicalNotesModel));
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<OwnerMedicalNotePatientComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public dialog:MatDialog, public router:Router) {

  }

  ngOnInit() {
    // Obtenemos los datos de la consulta
    this.loadingService.show(true,"Espere un momento...");
    this.session.getRequest("consultingRoom:get", {main: {uuid: this.data.uuidConsultingRoom}}).subscribe((data:any)=>{
      // obtenemos todos las notas medicas por el paciente y el doctor indicado
      this.session.getRequest("medicalNotes:findAllByPatient", {system: {patient: {main: {uuid:data.object.system.patient.main.uuid}}}}).subscribe((data:any)=>{
        this.instanceList = data.object.instanceList;
        this.loadingService.hide();
      },error=>{
        console.log('Error: medicalNotes:findAllByPatient', error);
        this.loadingService.hide();
      });
    },error=>{
      console.log('Error: consultingRoom:get', error);
      this.loadingService.hide();
    });
  }

  /*Funcion para obtener los cie10 asociados a una nota medica.
  */
  findAllCie10FromMedicalNote(medicalNotes) {
    this.session.getRequest("medicalNotesCIE10:findAllByMedicalNotes", { system: { medicalNotes: { main: { uuid: medicalNotes.main.uuid } } } }).subscribe(
      (data: any) => {
        this.medicalNotesCIE10List = data.object.instanceList;
      },
      error => {
        console.log("Error: medicalNotesCIE10:findAllByMedicalNotes", error);
      });
  }

  /**
   * Obtiene el listado de ci-10
   */
  getCie10() {
    this.loadingService.wait();
    this.session.getRequest("cie10:findAllByDescriptionLike", this.minCie10).subscribe(
      (data: any) => {
        this.cie10List = data.object.instanceList;
        this.loadingService.hide();
      },
      error => {
        console.log("Error: cie10:findAllByDescriptionLike", error);
        this.loadingService.hide();
      });
  }

  openHistory(medicalNote) {
    this.object = medicalNote;
    this.findAllCie10FromMedicalNote(this.object);
  }

  printMedicalNote(medicalNote){
    this.loadingService.show(true, 'Espere un momento...');
    this.session.getRequest('reportMedicalNotes:download', {main: medicalNote.main, system: {doctor: {main: this.object.system.doctor.main}}}).subscribe((data: any) => {
      let elem = document.createElement('a');
      elem.href = data.object.base64;
      elem.setAttribute('download', data.object.nameFile);
      document.body.appendChild(elem);
      elem.click();
      this.loadingService.hide();
    }, error => {
      console.log("Error: reportMedicalNotes:download", error);
      this.loadingService.hide();
    });
  }

}
