import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../service/session/session.module';
import { LoadingService } from '../directive/loading/loading.service';
import { ChangeMyPasswordComponent } from '../directive/change-my-password/change-my-password.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivedsessionService } from '../login/activedsession.service';
import { MenuService } from '../menu/menu.service';

@Component({
  selector: 'app-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.css']
})
export class ToolBarComponent implements OnInit {
  login = {
    main: {
      username:'',
      password:''
    }
  }
  metadata = {
    enabledSettingProduct: false,
    enabledIventory: false,
    enabledZone: false,
    enabledDoctor: false,
    enabledTreasury: false,
    enabledOwnerTreasury: false,
    toggleMenu: false,
    objects: [],
    object: {
      main: {
        uuid: ''
      }
    },
    sessionList:[],
    username:''
  }
  @Output() menuToogle: EventEmitter<boolean> = new EventEmitter<boolean>();
  productSettings = [
    { route: '/admin/product/list', title: 'Articulos', icon: 'scatter_plot', options: [], selected: true },
    { route: '/admin/category/list', title: 'Categorias', icon: 'drag_indicator', options: [], selected: false },
    { route: '/admin/characteristic/list', title: 'Caracteriticas', icon: 'turned_in_not', options: [], selected: false },
    { route: '/admin/maker/list', title: 'Marca', icon: 'museum', options: [], selected: false },
    { route: '/admin/unitMeasure/list', title: 'Medidas', icon: 'square_foot', options: [], selected: false },
  ];
  inventoryRouters = [
    { route: '/admin/inventory/list', title: 'Almacenes', icon: 'dynamic_feed', options: [], selected: false },
    { route: '/admin/warehouse/stock', title: 'Existencias en inventario', icon: 'line_style', options: [], selected: false },
    { route: '/admin/adjustment/list', title: 'Ajustes de inventario', icon: 'sync', options: [], selected: false },
    { route: '/admin/transfer/list', title: 'Traspaso', icon: 'sync', options: [], selected: false },
    { route: '/admin/adjustmentType/list', title: 'Tipo de ajustes', icon: 'surround_sound', options: [], selected: false },
  ];
  doctorRouters = [
    { route: '/admin/doctors-guards', title: 'Guardias de Doctores', icon: 'line_style', options: [], selected: false },
    { route: '/admin/doctor/list', title: 'Colaboradores', icon: 'dynamic_feed', options: [], selected: false },
  ];
  treasuryRouters = [
    { route: '/admin/treasury/list', title: 'Caja chica', icon: 'line_style', options: [], selected: false },
    { route: '/admin/treasury/expense/list', title: 'Gasto', icon: 'dynamic_feed', options: [], selected: false },
    { route: '/admin/treasury/expenseClassification/list', title: 'Clasificación de gastos', icon: 'dynamic_feed', options: [], selected: false },
    { route: '/admin/treasury/otherIncome/list', title: 'Otros ingresos', icon: 'dynamic_feed', options: [], selected: false },
    { route: '/admin/treasury/otherIncomeClassification/list', title: 'Clasificación de otros ingresos', icon: 'dynamic_feed', options: [], selected: false },
  ];
  zoneRouters = [
  /*   { route: '/admin/room/list', title: 'Habitaciones', icon: 'meeting_room', options: [], selected: false }, */
    { route: '/admin/zone/list', title: 'Zonas ó Pisos', icon: 'branding_watermark', options: [], selected: true },
  ];
  ownerTreasuryRouters = [
    { route: '/owner/expense/list', title: 'Gastos', icon: 'line_style', options: [], selected: false },
    { route: '/owner/expenseClassification/list', title: 'Clasificación de gastos', icon: 'dynamic_feed', options: [], selected: false },
    { route: '/owner/otherIncome/list', title: 'Otros ingresos', icon: 'line_style', options: [], selected: false },
    { route: '/owner/otherIncomeClassification/list', title: 'Clasificación de otros ingresos', icon: 'dynamic_feed', options: [], selected: false },
  ];
  constructor(public snackBar: MatSnackBar,
    private router: Router,
    private session: SessionService,
    public dialog: MatDialog,
    private activedsession:ActivedsessionService,
    public loadingService:LoadingService,
    public menuService: MenuService
   ) {
  }

  ngOnInit() {
    this.metadata.sessionList = this.activedsession.getSessions();
    this.metadata.username =  this.session.getUser().main.username;
    if(this.metadata.sessionList.length > 0){
    }
    this.activedsession.sessionLoaded.subscribe((value)=>{
      this.metadata.sessionList = this.activedsession.getSessions();
      if(this.metadata.sessionList.length > 0){
      }
      console.log("Mostrar sessiones",this.metadata);
    })
    // verificamos que tipo routa estamos paar el logueo
    if (sessionStorage.getItem('router') == '/admin/product/list') {
      this.metadata.enabledSettingProduct = true;
    } else {
      this.metadata.enabledSettingProduct = false;
    }
    if (sessionStorage.getItem('router') == '/admin/inventory/list') {
      this.metadata.enabledIventory = true;
    } else {
      this.metadata.enabledIventory = false;
    }
    if (sessionStorage.getItem('router') == '/admin/zone/list') {
      this.metadata.enabledZone = true;
    } else {
      this.metadata.enabledZone = false;
    }
    if (sessionStorage.getItem('router') == '/admin/doctors-guards') {
      this.metadata.enabledDoctor = true;
    } else {
      this.metadata.enabledDoctor = false;
    }
    if (sessionStorage.getItem('router') == '/admin/treasury/list') {
      this.metadata.enabledTreasury = true;
    } else {
      this.metadata.enabledTreasury = false;
    }
    if (sessionStorage.getItem('router') == '/owner/expense/list') {
      this.metadata.enabledOwnerTreasury = true;
    } else {
      this.metadata.enabledOwnerTreasury = false;
    }
    this.metadata.objects = this.session.getObjects();
    this.metadata.object = this.session.getSessionObject();
  }

  /** función para salir de sesión.
  */
  async logout() {
    let exit = await this.session.logout();
    if (exit) {
      this.router.navigate(['/']);
    } else {
      // mandamos alerta no se pudo iniciar sesión
    }
  }

  /**funcionalidad para cargar el cambio de contraseña
  */
  openChangePass(){
    let user = this.session.getUser();
    this.dialog.open(ChangeMyPasswordComponent, {
      width: '36rem',
      data: { object: user}
    }).afterClosed().subscribe(result => {
      if(result){
      }
    });
  }
  selected(object, menu) {
    switch (menu) {
      case 'productSettings':
        for (var x = 0; x < this.productSettings.length; x++) {
          this.productSettings[x].selected = false;
          if (object.route == this.productSettings[x].route) {
            this.productSettings[x].selected = true;
          }
        }
        break;
      case 'inventoryRouters':
        for (var x = 0; x < this.inventoryRouters.length; x++) {
          this.inventoryRouters[x].selected = false;
          if (object.route == this.inventoryRouters[x].route) {
            this.inventoryRouters[x].selected = true;
          }
        }
        break;
      case 'zoneRouters':
        for (var x = 0; x < this.zoneRouters.length; x++) {
          this.zoneRouters[x].selected = false;
          if (object.route == this.zoneRouters[x].route) {
            this.zoneRouters[x].selected = true;
          }
        }
        break;
      case 'doctorRouters':
        for (var x = 0; x < this.doctorRouters.length; x++) {
          this.doctorRouters[x].selected = false;
          if (object.route == this.doctorRouters[x].route) {
            this.doctorRouters[x].selected = true;
          }
        }
        break;
      case 'treasuryRouters':
        for (var x = 0; x < this.treasuryRouters.length; x++) {
          this.treasuryRouters[x].selected = false;
          if (object.route == this.treasuryRouters[x].route) {
            this.treasuryRouters[x].selected = true;
          }
        }
        break;
      case 'ownerTreasuryRouters':
        for (var x = 0; x < this.ownerTreasuryRouters.length; x++) {
          this.ownerTreasuryRouters[x].selected = false;
          if (object.route == this.ownerTreasuryRouters[x].route) {
            this.ownerTreasuryRouters[x].selected = true;
          }
        }
        break;
    }
  }
  /*
  * funcion para abrir o cerrar le menu
  */
  toogleMenu() {
    this.metadata.toggleMenu = !this.metadata.toggleMenu;
    this.menuToogle.emit(this.metadata.toggleMenu);
  }

  /**Funcion para inicializar session de una usuario por sessiones activas
  */
  goToLogin(object){
    this.login.main.password = atob(object.oauth);
    this.login.main.username = object.user;
    this.send(this.login);
  }

  send(object){
    this.loadingService.show(true,"Iniciado sesión, espere un momento...")
    this.session.login(this.login).then((data) => {
      this.loadingService.hide();
      this.snackBar.open("¡ Bienvenido !", "Aceptar", { duration: 2000 });
      let companies = this.session.getObjects();
      if(companies.length == 1){
        let objectSelected =  companies[0].system.object;
        this.session.setObjectSession(objectSelected);
      }
      // obtenemos el rol
      let role = this.session.getRoles()[0];
      switch (role) {
        case 'ROLE_TREASURY':
          this.router.navigate(['/admin/nurse/dashboard']);
          this.menuService.setMenuType('admin');
        break;
        case 'ROLE_ADMINISTRATION':
          this.router.navigate(['/owner/medicalConsultation/dashboard']);
          this.menuService.setMenuType('owner');
        break;
        case 'ROLE_ASSISTANT':
          this.router.navigate(['/assistant/register/list']);
          this.menuService.setMenuType('assistant');
        break;
        case 'ROLE_DOCTOR':
          this.router.navigate(['/doctor/register/list']);
          this.menuService.setMenuType('doctor');
        break;
        case 'ROLE_SPECIALIST':
          console.log('')
          this.router.navigate(['/specialist/dashboard']);
          this.menuService.setMenuType('specialist');
        break;
        case 'ROLE_NURSE':
          this.router.navigate(['/nurse/dashboard']);
          this.menuService.setMenuType('nurse');
        break;

        default:
          break;
      }
    }).catch((error) => {
      this.loadingService.hide();
      this.snackBar.open("¡ Usuario o Contraseña incorrectos !", "Error", { duration: 2000 });
      console.log('Error: ', error);
    });
  }

}
