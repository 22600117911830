import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';

@Component({
  selector: 'app-owner-other-income-classification',
  templateUrl: './owner-other-income-classification.component.html',
  styleUrls: ['./owner-other-income-classification.component.css']
})
export class OwnerOtherIncomeClassificationComponent implements OnInit {

  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;
  otherIncomeClassificationList = [];
  otherIncomeClassificationMin = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }
  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100, 200, 500, 1000],
  };
  // MatPaginator Output
  pageEvent: PageEvent;
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object: {
      main: {
        uuid: ''
      }
    }
  }
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getotherIncomeClassificationList();
  }

  /**Obtenemos la lista de clasificaciones de gasto***/
  getotherIncomeClassificationList() {
    this.loadingService.wait();
    this.otherIncomeClassificationList = [];
    // verificamos en que pagina verificamos
    this.otherIncomeClassificationMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("otherIncomeClassification:list", this.otherIncomeClassificationMin).subscribe(
      (data: any) => {
        this.otherIncomeClassificationList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
      },
      error => {
        console.log("otherIncomeClassification:list", error);
        this.loadingService.hide();
      }
    );
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event) {
    this.otherIncomeClassificationMin.offset = (event.pageIndex * event.pageSize);
    this.otherIncomeClassificationMin.max = event.pageSize;
    this.getotherIncomeClassificationList();
  }

  handleUpdated(event) {
    this.otherIncomeClassificationMin.offset = (this.pageEvent ? this.pageEvent.pageIndex * this.pageEvent.pageSize : this.otherIncomeClassificationMin.offset);
    this.getotherIncomeClassificationList();
  }
  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
