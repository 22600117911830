import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-transfer',
  templateUrl: './admin-transfer.component.html',
  styleUrls: ['./admin-transfer.component.css']
})
export class AdminTransferComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  transferList = [];
  transferMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 100000,
    order: 'desc',
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  // MatPaginator Output
  pageEvent: PageEvent;
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) {
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.transferList = [];
        this.transferMin.filter.name = 'searchElement';
      }else{
        this.transferList = [];
        this.transferMin.filter.name = '';
        this.transferMin.filter.value = '';
        val = 'false'
      }
      this.gettransferList();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
    console.log('error logged:');
    this.snackBar.open(error.message, 'Error', {duration: 5000});
    console.log(error);
  });
}
ngOnInit() {
  this.gettransferList();
}

gettransferList(){
  this.loadingService.wait();
  this.transferList = [];
  // verificamos en que pagina verificamos
  this.transferMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
  this.session.getRequest("transfer:list", this.transferMin).subscribe(
    (data: any) => {
      this.transferList = data.object.instanceList;
      console.log("list", this.transferList)
      this.paginatorModel.total = data.object.total;
      this.loadingService.hide();
    },
    error => {
      console.log("transfer:list", error);
      this.loadingService.hide();
    }
  );
}
//funcion para controlar si se abre o cierra el menu

toogleMenu(ev){
  this.drawerCtrl.opened = ev;
}


/*
Funcionalidad para colocar el objeto en el menu de opciones
*/
objectSelected(object){
  for(let i=0; i < this.transferList.length; i++){
    this.transferList[i].main.selected = false;
  }
  if(object.main.uuid == this.metadata.object.main.uuid){
    object.main.selected = false;
    this.metadata.object.main.uuid = '';
  }else{
    object.main.selected = true;
    this.metadata.object = JSON.parse(JSON.stringify(object));;
  }
}
/*
Funcion para abrir el modal de validación de transferencias.
*/


/*
  *Funcion para buscar
*/
search(){
  this.metadata.searchBoxInput.next(this.transferMin.filter.value);
}
// funcion para obtener los datos del paginado.
onPaginateChange(event){
  this.transferMin.offset = (event.pageIndex * event.pageSize);
  this.transferMin.max = event.pageSize;
  this.gettransferList();
}

handleUpdated(event) {
  this.transferMin.offset = (this.pageEvent ? this.pageEvent.pageIndex * this.pageEvent.pageSize  : this.transferMin.offset);
  this.gettransferList();
}
getDateFormat(date){
  return moment(date).format('DD-MM-YYYY HH:mm:ss')
}
}
