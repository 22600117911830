import { Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import * as Rx from 'rxjs/Rx';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-admin-survey',
  templateUrl: './admin-survey.component.html',
  styleUrls: ['./admin-survey.component.css']
})
export class AdminSurveyComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;

  questionList = [];
  questionMin = {
    system:{
      object:'',
      objectUuid:''
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 5, 10, 25, 100],
  };
  pageEvent: PageEvent;
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  constructor(public snackBar: MatSnackBar, public dialog: MatDialog, private session: SessionService, public loadingService: LoadingService) {
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.questionList = [];
        this.questionMin.filter.name = 'findAllByLikeName';
      }else{
        this.questionList = [];
        this.questionMin.filter.name = '';
        this.questionMin.filter.value = '';
        val = 'false'
      }
      this.getQuestionList();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
        console.log('error logged:');
        this.snackBar.open(error.message, 'Error', {duration: 5000});
        console.log(error);
      });

  }

  /** función para obtener los cuestionarios con object y objectUuid vacios
  * Es decir los cuestionarios no asiganos
  */

  async getQuestionList() {
    this.loadingService.wait();
    this.questionMin.system.object = 'Company'
    this.questionMin.system.objectUuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("questionnaire:findByObjectAndObjectUuid",this.questionMin).subscribe((data:any)=>{
      this.questionList = data.object.instanceList;
      this.paginatorModel.total = data.object.total;
      this.loadingService.hide();
    },error=>{
      console.log("error:questionnaire",error);
      this.loadingService.hide();
    })
  }

  ngOnInit() {
    this.getQuestionList();
  }

  /*
    *Funcion para buscar
  */
  search(){
    this.metadata.searchBoxInput.next(this.questionMin.filter.value);
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.questionMin.offset = (event.pageIndex * event.pageSize);
    this.questionMin.max = event.pageSize;
    this.getQuestionList();
  }

  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
