import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import * as Rx from 'rxjs';
// import { LoadingService } from '../../../directive/loading/loading.module';
// import * as moment from 'moment';

@Component({
  selector: 'app-adjustment-type',
  templateUrl: './adjustment-type.component.html',
  styleUrls: ['./adjustment-type.component.css']
})
export class AdjustmentTypeComponent implements OnInit {
  @ViewChild('drawer', { static: true }) drawerCtrl: MatDrawer;

  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100, 200, 500, 1000],
  };
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object: {
      main: {
        uuid: ''
      }
    }
  }
  // MatPaginator Output
  pageEvent: PageEvent;
  adjustmentTypeList = [];
  adjustmentTypeMin = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }
  constructor(private session: SessionService, public snackBar: MatSnackBar) {
    this.adjustmentTypeMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    //   this.metadata.searchBoxInput.debounceTime(700)
    //   .switchMap(val => {
    //     // console.log('called once',val)
    //     if(val != ''){
    //       this.adjustmentTypeList = [];
    //       this.adjustmentTypeMin.filter.name = 'searchElement';
    //     }else{
    //       this.adjustmentTypeList = [];
    //       this.adjustmentTypeMin.filter.name = '';
    //       this.adjustmentTypeMin.filter.value = '';
    //       val = 'false'
    //     }
    //     this.getAdjustmentTypeList();
    //     return val;
    //   }).subscribe(results => {
    //     // Modificaciones sobre cada letra si se requiere
    //   }, error => {
    //   console.log('error logged:');
    //   this.snackBar.open(error.message, 'Error', {duration: 5000});
    //   console.log(error);
    // });
  }

  ngOnInit() {
    this.getAdjustmentTypeList();
  }

  /**
 * Obtiene la lista de tipos de ajuste de inventarios por empresa logueada
 */
  getAdjustmentTypeList() {
    this.adjustmentTypeList = [];
    // verificamos en que pagina verificamos
    this.session.getRequest("adjustmentType:findAllByCompany", this.adjustmentTypeMin).subscribe(
      (data: any) => {
        this.adjustmentTypeList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
      },
      error => {
        console.log("Erro: adjustmentType:findAllByCompany", error);
      }
    );
  }

  /*
  Funcionalidad para colocar el objeto en el menu de opciones
  */
  objectSelected(object) {
    for (let i = 0; i < this.adjustmentTypeList.length; i++) {
      this.adjustmentTypeList[i].system.selected = false;
    }
    if (object.main.uuid == this.metadata.object.main.uuid) {
      object.system.selected = false;
      this.metadata.object.main.uuid = '';
    } else {
      object.system.selected = true;
      this.metadata.object = JSON.parse(JSON.stringify(object));;
    }
  }

  /*
    *Funcion para buscar
  */
  search() {
    this.metadata.searchBoxInput.next(this.adjustmentTypeMin.filter.value);
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event) {
    this.adjustmentTypeMin.offset = (event.pageIndex * event.pageSize);
    this.adjustmentTypeMin.max = event.pageSize;
    this.getAdjustmentTypeList();
  }

  handleUpdated(event) {
    this.adjustmentTypeMin.offset = (this.pageEvent ? this.pageEvent.pageIndex * this.pageEvent.pageSize : this.adjustmentTypeMin.offset);
    this.getAdjustmentTypeList();
  }
  /*
  funcion para controlar si se abre o cierra el menu
  */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
