import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {PageEvent, MatSnackBar, MatDialog} from '@angular/material';
import {SessionService} from '../../service/session/session.module';
import {LoadingService} from '../../directive/loading/loading.module';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as printJS from "print-js";
import {NursePointSaleOpenCashComponent} from "./nurse-point-sale-open-cash/nurse-point-sale-open-cash.component";
import {NursePointSaleCloseCashComponent} from "./nurse-point-sale-close-cash/nurse-point-sale-close-cash.component";
import {
  NursePointSaleOtherChargeUpdateComponent
} from "./nurse-point-sale-other-charge-update/nurse-point-sale-other-charge-update.component";

@Component({
  selector: 'app-nurse-point-sale',
  templateUrl: './nurse-point-sale.component.html',
  styleUrls: ['./nurse-point-sale.component.css']
})
export class NursePointSaleComponent implements OnInit {
  @ViewChild('drawer', {static: true}) drawerCtrl: MatDrawer;

  complexForm: FormGroup;

  saleClinicModel = {
    main: {
      uuid: '',
      total: 0.0,
      cashFund: 0.0,
      status: 'Abierto',
      starDate: '',
      endDate: '',
      totalAttended: 0,
      totalReceived: 0.0,
    },
    system: {
      collaborator: {
        main: {
          uuid: ''
        }
      },
      otherIncome: {
        main: {
          uuid: ''
        }
      },
    }
  }

  saleClinicMin = {
    max: 10,
    offset: 0,
    system: {
      collaborator: {
        main: {
          uuid: ''
        }
      }
    },
    main: {
      status: ''
    },
  }

  saleClinicDetailMin = {
    system: {
      saleClinic: {
        main: {
          uuid: ''
        }
      },
    },
    max: 10,
    offset: 0,
    order: 'desc'
  }

  saleClinicDetailList = [];

  paginatorModel = {
    total: 0,
    pageSizeOptions: [5, 10, 25, 100, 200, 500, 1000],
  };
  pageEvent: PageEvent;

  object = JSON.parse(JSON.stringify(this.saleClinicModel));

  constructor(public fb: FormBuilder, private session: SessionService, public dialog: MatDialog, public snackBar: MatSnackBar, public loadingService: LoadingService) {
  }

  ngOnInit() {
    // obtenemos todas las ventas abiertas
    this.getSaleClinic('Abierto');
  }


  /**
   * Obtenemos las ventas por colaborador y estatus
   */
  getSaleClinic(status) {
    this.session.getRequest('validate:findByUsername', {
      system: {
        user: this.session.getUser()
      }
    }).subscribe(
      (data: any) => {
        this.saleClinicMin.system.collaborator.main.uuid = data.object.main.uuid;
        this.loadingService.show(true, "Obteniendo detalle de la estancia, espere un momento...");
        this.saleClinicMin.main.status = status;
        this.session.getRequest("saleClinic:findByCollaboratorAndStatus", this.saleClinicMin).subscribe(
          (data: any) => {
            if (data.object != '') {
              this.object = data.object;
              this.saleClinicDetailMin.system.saleClinic.main.uuid = data.object.main.uuid;
              this.session.getRequest("saleClinicDetail:findAllBySaleClinic", this.saleClinicDetailMin).subscribe(
                (data: any) => {
                  this.saleClinicDetailList = data.object.instanceList;
                  this.paginatorModel.total = data.object.total;
                  this.loadingService.hide();
                }, error => {
                  console.log("Error: saleClinicDetail:findAllBySaleClinic", error);
                  this.loadingService.hide();
                });
            }
            this.loadingService.hide();
          }, error => {
            console.log("Error: saleClinic:findByCollaboratorAndStatus", error);
            this.loadingService.hide();
          });

        this.loadingService.hide();
      }, error => {
        console.log('Error: validate:findByUsername', error);
        this.loadingService.hide();
      });
  }

  /**
   * Funcion para obtener los datos del paginado.
   */
  onPaginateChange(event: any) {
    console.log(' que evento ', event);
    this.saleClinicDetailMin.offset = (event.pageIndex * event.pageSize);
    this.saleClinicDetailMin.max = event.pageSize;
    this.getSaleClinic('Abierto');
  }

  /**
   * Modal para aperturar caja
   */
  openCash() {
    this.dialog.open(NursePointSaleOpenCashComponent, {
      width: '50%',
      height: '20rem',
      disableClose: true,
      data: {
        // object: object,
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.getSaleClinic('Abierto');
      } else {
        //No hacemos Nada
      }


    });
  }

  /**
   * Modal para cerrar caja
   */
  closeCash(uuid) {
    console.log('object', uuid)
    const dialogRef = this.dialog.open(NursePointSaleCloseCashComponent, {
      width: '50%',
      height: '20rem',
      data: uuid,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        if (result.transaction == 'ok') {

          // El modal se cerro con objeto
          this.getSaleClinic('Abierto');
          this.saleClinicDetailList = [];
          this.object.main.total = 0;
          this.object.main.uuid = '';
        } else {
          // El modal se cerro sin objeto

        }
      } else {
        // El modal se cerro sin seleccionar algo, dandole click fuera
      }
    });
  }

  addOtherCharge(object) {
    const dialogRef = this.dialog.open(NursePointSaleOtherChargeUpdateComponent, {
      width: '50%',
      data: {object: object.main.uuid}
    });
    dialogRef.afterClosed().subscribe(salesDetailUpdate => {
      if (salesDetailUpdate != undefined) {
        this.getSaleClinic('Abierto');
      } else {
        // El modal se cerro sin seleccionar algo, dandole click fuera
      }
    });
  }

  report(object: any) {
    this.loadingService.show(true, "Obteniendo ticket, espere un momento...");
    this.session.getRequest("saleClinicDetail:ticket", {main: {uuid: object.main.uuid}}).subscribe((data: any) => {
      let elem = document.createElement('a');
      console.log(data.object.base64);
      elem.href = data.object.base64;
      elem.setAttribute('download', data.object.nameFile);
      document.body.appendChild(elem);
      elem.click();
      this.loadingService.hide();
    }, error => {
      console.log("Error: saleClinicDetail:ticket", error);
      this.loadingService.hide();
    });
  }

  printTicket(object: any) {
    this.loadingService.show(true, "Obteniendo ticket, espere un momento...");
    this.session.getRequest("saleClinicDetail:ticket", {main: {uuid: object.main.uuid}}).subscribe((data: any) => {
      this.loadingService.hide();
      printJS({printable: data.object, type: 'pdf', base64: true})
    }, error => {
      console.log('Error: saleClinicDetail:ticket', error);
      this.loadingService.hide();
    })
  }

  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }
}
