import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import * as Rx from 'rxjs/Rx';
import { LoadingService } from '../../directive/loading/loading.module';
import { OwnerColaboratorScheduleComponent } from './owner-colaborator-schedule/owner-colaborator-schedule.component';

@Component({
  selector: 'app-owner-colaborator',
  templateUrl: './owner-colaborator.component.html',
  styleUrls: ['./owner-colaborator.component.css']
})
export class OwnerColaboratorComponent implements OnInit {


    @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
    collaboratorMin = {
      system:{
        role:'',
        company:{
          main:{
            uuid:''
          }
        },
        status:true,
        max: 10,
        offset: 0,
        filter:{
          name:'',
          value: ''
        }
      }
    }
    paginatorModel = {
      total:0,
      pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
    };
    // MatPaginator Output
    pageEvent: PageEvent;
    metadata = {
      searchBoxInput: new Rx.Subject<string>(),
      object:{
        main:{
          uuid:''
        }
      },
      specialty: false
    }
    list = [];

    constructor(public dialog: MatDialog, private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) {
  }

    ngOnInit() {
      // this.drawerCtrl.opened = true;
      this.findAllByCompanyAndRoleAndStatus('Doctor');
    }

    /** funcionalidad para obtener role por company y status
    */
    findAllByCompanyAndRoleAndStatus(role){
      this.loadingService.wait();
      this.collaboratorMin.system.role = role;
      this.collaboratorMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
      this.session.getRequest("collaborator:findAllByCompanyAndRoleAndStatus",this.collaboratorMin).subscribe((data:any)=>{
        this.list = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
      },error=>{
        console.log("error",error);
        this.loadingService.hide();
      })
    }

    /** funcionalidad para cambiar de status el tab
    */
    changeTab(ev){
      // console.log("ev",ev);
      switch(ev.tab.textLabel){
        case 'Enfermeras':{
          this.findAllByCompanyAndRoleAndStatus('Nurse');
          this.metadata.specialty = false;
          break;
        }
        case 'Doctores de Guardia':{
          this.findAllByCompanyAndRoleAndStatus('Doctor');
          this.metadata.specialty = false;
          break;
        }
        case 'Doctores Especialistas':{
          this.findAllByCompanyAndRoleAndStatus('Specialist doctor');
          this.metadata.specialty = true;
          break;
        }
        case 'Asistentes':{
          this.findAllByCompanyAndRoleAndStatus('Assistant');
          this.metadata.specialty = false;
          break;
        }
        case 'Colaboradores':{
          this.findAllByCompanyAndRoleAndStatus('Collaborator');
          this.metadata.specialty = false;
          break;
        }
      }
    }

     /**
  * Modal para asignarle un horario al colaborador
  */
     schedule(uuid){
      console.log('object', uuid)
      const dialogRef = this.dialog.open(OwnerColaboratorScheduleComponent, {
        width: '40%',
        height:'20rem',
        data: uuid,
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined ){
          if(result.transaction == 'ok'){
            this.findAllByCompanyAndRoleAndStatus('Doctor');
            // El modal se cerro con objeto
           
          }else{
            // El modal se cerro sin objeto
           
          }
        }else{
          // El modal se cerro sin seleccionar algo, dandole click fuera
        }
      });
    }

    toogleMenu(ev){
      this.drawerCtrl.opened = ev;
    }

}
