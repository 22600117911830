import { Component, OnInit, Inject } from '@angular/core';
// import { LoadingService } from '../../../../directive/loading/loading.module';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
// import { SessionService } from '../../../../service/session/session.module';
import * as moment from 'moment';

@Component({
  selector: 'app-doctor-room-occupation-detail',
  templateUrl: './doctor-room-occupation-detail.component.html',
  styleUrls: ['./doctor-room-occupation-detail.component.css']
})
export class DoctorRoomOccupationDetailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
