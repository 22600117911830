import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { PettyCashOpenComponent } from './petty-cash-open/petty-cash-open.component';
import * as moment from 'moment';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-owner-expense-record-update',
  templateUrl: './owner-expense-record-update.component.html',
  styleUrls: ['./owner-expense-record-update.component.css']
})
export class OwnerExpenseRecordUpdateComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  pettyCashModel = {
    main: {
      uuid: '',
      openAmount: 0.0,
      closeAmount: 0.0,
      openDate: moment().format('DD/MM/YYYY HH:mm:ss'),
      closeDate: moment().format('DD/MM/YYYY HH:mm:ss'),
      open: false,
    },
    system: {
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      },
      openDoctor: {
        main: {
          uuid: ''
        },
        system: {
          user: {
            main: {
              uuid: this.session.getUser().main.uuid
            }
          },
          physicalPerson: {
            main: {
              name: '',
              lastname: '',
              surname: ''
            }
          }
        },
      },
      closeDoctor: {
        main: {
          uuid: ''
        },
        system: {
          user: {
            main: {
              uuid: this.session.getUser().main.uuid
            }
          },
          physicalPerson: {
            main: {
              name: '',
              lastname: '',
              surname: ''
            }
          }
        },
      },
      spendings: [],
    }
  }
  metadata = {
    diference: 0.0
  }

  object=JSON.parse(JSON.stringify(this.pettyCashModel));
  constructor(protected session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      // Obtenemos el turno abierto
      if (params['uuid'] == 'new') {
        this.loadingService.wait();
        this.session.getRequest('pettyCash:findByCompanyAndOpen', {system: {company: {main: {uuid: this.session.getSessionObject().main.uuid} } }, main: {open: true} }).subscribe((data:any)=>{
          if (data.object == '') {
            // Abrimos el modal para crear la apertura de caja
            this.dialog.open(PettyCashOpenComponent, {
              width: '30%',
            }).afterClosed().subscribe(result => {
              if (result.main.uuid == '') {
                this.router.navigate(['/owner/expenseRecord/list']);
              } else {
                this.object=JSON.parse(JSON.stringify(result));
                this.object.main.openDate = moment(this.object.main.openDate).format('DD/MM/YYYY HH:mm:SS');
              }
            });
          } else {
            // Asignamos el detalle de la apertura y cierre del turno
            this.object = JSON.parse(JSON.stringify(data.object));
            this.object.main.openDate = moment(this.object.main.openDate).format('DD/MM/YYYY HH:mm:SS');
            // Obtenemos los detalles de los gastos
            this.loadingService.wait();
            this.session.getRequest('spending:findAllByPettyCash', {system: {pettyCash: {main: {uuid: this.object.main.uuid} } } }).subscribe((data:any)=>{
              this.object.system.spendings = data.object.instanceList;
              this.loadingService.hide();
            }, (error)=>{
              console.log('spending:findAllByPettyCash', error);
              this.loadingService.hide();
            });
          }
          // this.metadata.dateBirth = moment(this.object.system.physicalPerson.complement.dateBirth).utc().toISOString();
          this.loadingService.hide();
        }, (error)=>{
          console.log('pettyCash:findByCompanyAndOpen', error);
          this.loadingService.hide();
        });
      } else {
        this.loadingService.wait();
        this.session.getRequest('pettyCash:get', {main: {uuid: params['uuid']} }).subscribe((data:any)=>{
          this.object = data.object;
          // Obtenemos los detalles de los gastos
          this.loadingService.wait();
          this.session.getRequest('spending:findAllByPettyCash', {system: {pettyCash: {main: {uuid: this.object.main.uuid} } } }).subscribe((data:any)=>{
            this.object.system.spendings = data.object.instanceList;
            this.loadingService.hide();
          }, (error)=>{
            console.log('spending:findAllByPettyCash', error);
            this.loadingService.hide();
          });
          this.loadingService.hide();
        }, (error)=>{
          console.log('pettyCash:get', error);
          this.loadingService.hide();
        });
      }
      });
  }

  send(object) {
    object.main.open = false;
    object.main.closeDate = moment().format('DD/MM/YYYY HH:mm:ss');
    this.session.getRequest("collaborator:findByUser", {system: {user: {main: {uuid: this.session.getUser().main.uuid}}}}).subscribe(
      (data:any) => {
        object.system.closeDoctor = data.object;
        object.main.openDate = moment(object.main.openDate).format('DD/MM/YYYY HH:mm:ss'),
        object.main.closeDate = moment(object.main.closeDate).format('DD/MM/YYYY HH:mm:ss'),
        this.session.getRequest("pettyCash:update", object).subscribe(
          (data:any) => {
            this.snackBar.open('El registro se guardo correctamente', '', {
              duration: 8000
            });
            this.loadingService.hide();
            this.router.navigate(['/owner/expenseRecord/list']);
          }, error => {
            console.log("Error: pettyCash:update",error);
            this.loadingService.hide();
          });
      }, error => {
        console.log("Error: collaborator:findByUser",error);
        this.loadingService.hide();
      });
  }

  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
