import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import {MatSnackBar } from '@angular/material';
import * as Rx from 'rxjs/Rx';
// import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../../service/session/session.module';
// import { LoadingService } from '../../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { TreeNode, TreeModel, TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions } from 'angular-tree-component';

@Component({
  selector: 'app-categories-update',
  templateUrl: './categories-update.component.html',
  styleUrls: ['./categories-update.component.css']
})
export class CategoriesUpdateComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  complexForm: FormGroup;
  // form control para campos requeridos.
  requiredField = new FormControl('', [Validators.required]);
  productFamilyList = [];

  customTemplateStringOptions: ITreeOptions = {
    isExpandedField: 'expanded',
    idField: 'uuid',
    animateExpand: true,
    animateSpeed: 30,
    animateAcceleration: 1.2
  }
  productFamilyModel = {
    main: {
      uuid: '',
      code: '',
      name: ''
    },
    complement: {
      description: ''
    },
    system: {
      company: {
        main: {
          uuid: ''
        }
      },
      productFamily: {
        main: {
          uuid: '',
          name: ''
        }
      },
      status: true
    }
  };
  object = JSON.parse(JSON.stringify(this.productFamilyModel));

  constructor(public fb: FormBuilder, public session: SessionService, public snackBar: MatSnackBar, private router: Router, private activatedRoute: ActivatedRoute) {
    this.session = session;
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'code': [null, Validators.required],
      'name': [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(100)])],
    })
  }

  ngOnInit() {
    this.object.system.status = true;
    this.treeViewCategories();
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params['uuid'] == 'new'){

      }else{
        // this.loadingService.wait();
         this.object.main.uuid = params['uuid'];
         // cargamos los datos de la BD
         this.session.getRequest('productFamily:get',this.object).subscribe((data:any)=>{
           this.object = JSON.parse(JSON.stringify(data.object));
           // this.loadingService.hide();
         },
         (error)=>{
           // this.loadingService.hide();
           console.log('Error:productFamily:get',error)
         })

      }
    });
  }

  // ngOnChanges() {
  //   this.object.system.status = true;
  //   this.treeViewCategories();
  // }

  onEvent(event) {
    this.object.system.productFamily.main.uuid =event.node.data.uuid;
    this.object.system.productFamily.main.name =event.node.data.name;
  }

  treeViewCategories() {
    // this.loadingService.wait();
    this.session.getRequest("productFamily:treeviewByCompanyAndStatus", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } }, status: true } }).subscribe(
      (data: any) => {
        // this.loadingService.hide();
        this.productFamilyList = data.object;
      },
      error => {
        console.log("productFamily:treeviewByCompanyAndStatus", error);
        // this.loadingService.hide();
      });
  }

  deleteProductFamilyDepend(object) {
    this.object.system.productFamily.main.uuid = '';
    this.object.system.productFamily.main.code = '';
    this.object.system.productFamily.main.name = '';
  }

  /*
  funcion para guardar un categoria
  */
  send(object) {
    object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    // this.loadingService.show(true,'Guardando registro');
    this.session.getRequest("productFamily:update", object).subscribe(
      (data:any) => {
        // evento a emitir cuando se crea o actaliza una categoria
        this.snackBar.open('La categoria se guardo correctamente', '', {
          duration: 8000
        });
        // this.loadingService.hide();
        this.router.navigate(['/admin/category/list']);
      },
      error => {
        // this.loadingService.hide();
        console.log("productFamily:update", error);
      }
    );
  }

  /*
  funcion para controlar si se abre o cierra el menu
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
