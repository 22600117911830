import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar, MatDialog } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { OtherChargeUpdateComponent } from './other-charge-update/other-charge-update.component';
import { SaleReportComponent } from './sale-report/sale-report.component';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import * as moment from 'moment';
import * as printJS from 'print-js';

@Component({
  selector: 'app-point-sale-update',
  templateUrl: './point-sale-update.component.html',
  styleUrls: ['./point-sale-update.component.css']
})
export class PointSaleUpdateComponent implements OnInit {

  @ViewChild('drawer', { static: true }) drawerCtrl: MatDrawer;

  object: any = {
    main: {
      uuid: '',
      saleNumber: '',
      enableTax: false,
    },
    complement: {
      description: ''
    },
    system: {
      patient: {
        system: {
          physicalPerson: {
            main: {
              name: '',
              lastname: '',
              surname: ''
            }
          },
        }
      },
      salesPayment: {
        main: {
          amount: 0.0,
          paymentDate: '',
        }
      },
      salesDetails: [
        {
          main: {
            uuid: ''
          },
          system: {
            objectDetail: {
              system: {
                user: {
                  system: {
                    physicalPerson: {
                      main: {
                        name: '',
                        surname: '',
                        lastname: ''
                      }
                    }
                  }
                },
                object: '',
                room: {
                  main: {
                    nameOrNumber: '',
                  }
                }
              }
            }
          }
        },
      ]
    }
  };

  salesDetail = {
    main: {
      uuid: '',
      movementDate: '',
      subTotal: 0,
      tax: 0,
      total: 0
    },
    system: {
      object: 'OtherCharge',
      objectUuid: '',
      collaboratorUuid: this.session.getUser().main.uuid,
      sales: {
        main: {
          uuid: '',
          saleNumber: '',
          enableTax: false,
        },
      },
      objectDetail: {
        main: {
          uuid: '',
          concept: '',
          quantity: 1,
          value: 0.0,
          subtotal: 0.0,
          tax: 0.0,
          total: 0.0,
          status: 'Hospitalario',
          dateRegister: ''
        },
        system: {
          userUuid: this.session.getUser().main.uuid,
          user: {
            main: {
              uuid: ''
            },
            system: {
              physicalPerson: {
                main: {
                  name: '',
                  surname: '',
                  lastname: ''
                }
              }
            }
          }
        }
      }
    }
  }

  constructor(protected session: SessionService, public dialog: MatDialog, public loadingService: LoadingService, public snackBar: MatSnackBar, private router: Router, private activatedRoute: ActivatedRoute, private _locate:Location) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.object.main.uuid = params['uuid'];

      // cargamos los datos de la BD
      this.loadingService.show(true, "Espere un momento... Obteniendo el detalle.")
      this.session.getRequest('sales:get', this.object).subscribe((data: any) => {
        this.object = JSON.parse(JSON.stringify(data.object));
        this.getSalesDetails(this.object).then((data: any) => {
          this.object.main = data.object.main;
          this.object.complement = data.object.complement;
          this.object.system.company = data.object.system.company;
          this.object.system.patient = data.object.system.patient;
          this.object.system.salesDetails = data.object.system.salesDetails;
          this.object.system.status = data.object.system.status;
          this.getSalesPayment(this.object).then((data: any) => {
            this.object.system.salesPayment.main.amount = data.object.amount;
            this.object.system.salesPayment.main.paymentDate = data.object.paymentDate;
            this.loadingService.hide();
          });
        });
      }, (error) => {
        console.log('Error: sales:get', error)
        this.loadingService.hide();
      });
    });
  }

  /**
   * Obtenemos el detalle de abonos que ha realizado el paciente
   */
  getSalesPayment(object) {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo el saldo de los abonos...");
      this.session.getRequest("salesPayment:getPaymentTotalBySales", object).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
          this.loadingService.hide();
        }, error => {
          console.log("Error: salesPayment:getPaymentTotalBySales", error);
          this.loadingService.hide();
        });
      });
  }

  /**
   * Metodo con el cual se ingresa el objecto cargo
   */
  addOtherCharge(salesDetail) {
    salesDetail.system.sales.main = this.object.main;
    const dialogRef = this.dialog.open(OtherChargeUpdateComponent, {
      width: '50%',
      data: {object: salesDetail}
    });
    dialogRef.afterClosed().subscribe(salesDetailUpdate => {
      if (salesDetailUpdate != undefined) {
        if (salesDetail.main.uuid == '') {
          this.object.system.salesDetails.push(salesDetailUpdate);
        } else {
          for (let x = 0; x < this.object.system.salesDetails.length; x++) {
            if (salesDetail.main.uuid == this.object.system.salesDetails[x].main.uuid) {
              this.object.system.salesDetails[x] = salesDetailUpdate;
            }
          }
        }
      } else {
        // El modal se cerro sin seleccionar algo, dandole click fuera
      }
    });
  }

  /**
   * Modifica el cargo de la venta a donde se debe de cargar
   */
  chargeTax(event) {
    this.object.main.enableTax = event.checked;
    this.changeCharge();
  }

  /**
   * Modifica el cargo de la venta a donde se debe de cargar
   */
  chargeType(event, salesDetail) {
    for (let x = 0; x < this.object.system.salesDetails.length; x++) {
      if (salesDetail.main.uuid == this.object.system.salesDetails[x].main.uuid && event.checked) {
        this.object.system.salesDetails[x].system.objectDetail.main.status = 'Other charge';
        this.otherChargeUpdate(this.object.system.salesDetails[x]);
      }
      if (salesDetail.main.uuid == this.object.system.salesDetails[x].main.uuid && !event.checked) {
        this.object.system.salesDetails[x].system.objectDetail.main.status = 'Hospitalario';
        this.otherChargeUpdate(this.object.system.salesDetails[x]);
      }
    }
  }

  /**
   * Actualiza el cargo de la pantalla
   */
  changeCharge() {
    this.loadingService.show(true, "Actualizando los totales del cobro, espere un momento...");
    this.session.getRequest("changeCharge:tax", this.object).subscribe(
      (data: any) => {
        this.object = JSON.parse(JSON.stringify(data.object));
        this.getSalesDetails(this.object).then((data: any) => {
          this.object.main = data.object.main;
          this.object.complement = data.object.complement;
          this.object.system = data.object.system;
          this.loadingService.hide();
        });
      },
      error => {
        console.log("Error: changeCharge:tax", error);
        this.loadingService.hide();
      });
  }

  report(object) {
    const dialogRef = this.dialog.open(SaleReportComponent, {
      width: '50%',
      data: {object: object}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('--- ', result);
    });
  }

  /**
   * Actualiza el porecio o cantidad del producto utilizado en la ocupación
   */
  roomOccupationProductUpdate(object) {
    this.loadingService.show(true, "Actualizando el cargo de consumo de productos, espere un momento...");
    this.session.getRequest("roomOccupation:updateProduct", object).subscribe(
      (data: any) => {
        for (let x=0; x<this.object.system.salesDetails.length; x++) {
          if (this.object.system.salesDetails[x].system.objectUuid == object.system.roomOccupationRegister.main.uuid) {
            // Restamos al total el saldo
            this.object.main.subtotal = this.object.main.subtotal - this.object.system.salesDetails[x].main.subTotal;
            this.object.main.total = this.object.main.total - this.object.system.salesDetails[x].main.subTotal;
            this.object.system.salesDetails[x].main.subTotal = 0;
            for (let y=0; y<this.object.system.salesDetails[x].system.objectDetail.system.roomOccupationProducts.length; y++) {
              if (this.object.system.salesDetails[x].system.objectDetail.system.roomOccupationProducts[y].main.uuid == object.main.uuid) {
                this.object.system.salesDetails[x].system.objectDetail.system.roomOccupationProducts[y] = data.object;
              }
              this.object.system.salesDetails[x].main.subTotal = this.object.system.salesDetails[x].main.subTotal + this.object.system.salesDetails[x].system.objectDetail.system.roomOccupationProducts[y].main.subTotal;
            }
            this.object.system.salesDetails[x].main.total = this.object.system.salesDetails[x].main.subTotal;
            this.object.main.subtotal = this.object.main.subtotal + this.object.system.salesDetails[x].main.subTotal;
            this.object.main.total = this.object.main.total + this.object.main.subtotal;
            // Guardamos el detalle de la venta
            this.session.getRequest("sales:updateCharge", this.object.system.salesDetails[x]).subscribe((data: any) => {
              this.loadingService.hide();
            }, error => {
              console.log("Error: sales:updateCharge", error);
              this.loadingService.hide();
            });
          }
        }
        this.changeCharge();
        this.loadingService.hide();
      }, error => {
        console.log("Error: roomOccupation:updateProduct", error);
        this.loadingService.hide();
      });
  }

  /**
   * Actualiza el nuevo cargo de ocupación del cuarto
   */
  roomOccupationUpdate(object) {
    let subTotal = object.system.objectDetail.main.subTotal * 1.0;
    object.main.subTotal = subTotal;
    if (this.object.main.enableTax) {
      object.system.objectDetail.main.tax = subTotal * 0.16;
      object.system.objectDetail.main.total = subTotal * 1.16;
      object.main.tax = subTotal * 0.16;
      object.main.total = subTotal * 1.16;
    } else {
      object.system.objectDetail.main.tax = 0;
      object.system.objectDetail.main.total = subTotal;
      object.main.tax = 0;
      object.main.total = subTotal;
    }
    // obtenemos el detalle del objecto
    this.loadingService.show(true, "Actualizando el cargo de la habitación, espere un momento...");
    this.session.getRequest("roomOccupation:updateCharge", object).subscribe((data: any) => {
      for (let x = 0; x < this.object.system.salesDetails.length; x++) {
        if (this.object.system.salesDetails[x].main.uuid == data.object.main.uuid) {
          this.object.system.salesDetails[x].main = JSON.parse(JSON.stringify(data.object.main));
          this.object.system.salesDetails[x].system = JSON.parse(JSON.stringify(data.object.system));
          // Obtenemos el detalle del usuario que realizo la acción
          this.getUser(data.object.system.objectDetail.system.detail.system.user).then((data: any) => {
            this.object.system.salesDetails[x].system.objectDetail.system.detail.system.user = data.object;
          });
          this.loadingService.hide();
        }
      }
      this.changeCharge();
      this.loadingService.hide();
    }, error => {
      console.log("Error: roomOccupation:updateCharge", error);
      this.loadingService.hide();
    });
  }

  roomOccupationDayUpdate(uuid, day) {
    this.session.getRequest("roomOccupation:updateDay", {main:{uuid: uuid, day: day}}).subscribe((data: any) => {

      this.loadingService.hide();
    }, error => {
      console.log("Error: roomOccupation:updateDay", error);
      this.loadingService.hide();
    });
  }

  /**
   * Actualiza el nuevo cargo de ocupación del cuarto
   */
  roomOccupationDoctorUpdate(salesDetail) {
    let subTotal = salesDetail.system.objectDetail.main.subTotal * 1.0;
    salesDetail.main.subTotal = subTotal;
    if (this.object.main.enableTax) {
      salesDetail.system.objectDetail.main.price = subTotal;
      salesDetail.system.objectDetail.main.subTotal = subTotal;
      salesDetail.system.objectDetail.main.tax = subTotal * 0.16;
      salesDetail.system.objectDetail.main.total = subTotal * 1.16;

      salesDetail.main.tax = subTotal * 0.16;
      salesDetail.main.total = subTotal * 1.16;
    } else {
      salesDetail.system.objectDetail.main.price = subTotal;
      salesDetail.system.objectDetail.main.subTotal = subTotal;
      salesDetail.system.objectDetail.main.tax = 0;
      salesDetail.system.objectDetail.main.total = subTotal;

      salesDetail.system.objectDetail.main.tax = 0;
      salesDetail.system.objectDetail.main.total = subTotal;
    }
    // obtenemos el detalle del objecto
    this.loadingService.show(true, "Actualizando el cargo de la habitación, espere un momento...");
    this.session.getRequest("roomOccupationDoctor:update", salesDetail.system.objectDetail).subscribe(
      (data: any) => {
        for (let x = 0; x < this.object.system.salesDetails.length; x++) {
          if (this.object.system.salesDetails[x].system.objectDetail.main.uuid == data.object.main.uuid) {
            this.object.system.salesDetails[x].system.objectDetail = JSON.parse(JSON.stringify(data.object));
            this.loadingService.hide();
          }
        }
        this.changeCharge();
        this.loadingService.hide();
      }, error => {
        console.log("Error: roomOccupationDoctor:update", error);
        this.loadingService.hide();
      });
  }

  /**
   * Actualizamos los datos de otro cargo
   */
  otherChargeUpdate(salesDetail) {
    this.loadingService.show(true, "Actualizando el detalle del cargo, espere un momento...");
    salesDetail.system.objectDetail.main.dateRegister = moment(salesDetail.system.objectDetail.main.dateRegister).format('DD/MM/YYYY hh:mm:ss');
    salesDetail.system.objectDetail.main.subtotal = salesDetail.system.objectDetail.main.quantity * salesDetail.system.objectDetail.main.value
    if (this.object.main.enableTax) {
      salesDetail.system.objectDetail.main.tax = salesDetail.system.objectDetail.main.subtotal * 0.16;
      salesDetail.system.objectDetail.main.total = salesDetail.system.objectDetail.main.subtotal * 1.16;
    } else {
      salesDetail.system.objectDetail.main.tax = 0;
      salesDetail.system.objectDetail.main.total = salesDetail.system.objectDetail.main.subtotal;
    }
    this.session.getRequest("otherCharge:update", salesDetail).subscribe(
      (data: any) => {
        // Actualizamos el cargo
        for (let x = 0; x < this.object.system.salesDetails.length; x++) {
          if (salesDetail.main.uuid == this.object.system.salesDetails[x].main.uuid) {
            this.object.system.salesDetails[x] = data.object;
          }
        }
        this.loadingService.hide();
        this.changeCharge();
      },
      error => {
        console.log("Error: otherCharge:delete", error);
        this.loadingService.hide();
      });
  }

  /**
   * Eliminamos otros cargos del sistema
   */
  deleteOtherCharge(salesDetail) {
    this.loadingService.show(true, "Eliminando el cargo, espere un momento...");
    this.session.getRequest("otherCharge:delete", salesDetail).subscribe(
      (data: any) => {
        // Recorremos el arreglo para eliminar el object
        for (let x = 0; x < this.object.system.salesDetails.length; x++) {
          if (salesDetail.main.uuid == this.object.system.salesDetails[x].main.uuid) {
            this.object.system.salesDetails.splice(x, 1);
          }
        }
        this.loadingService.hide();
      },
      error => {
        console.log("Error: otherCharge:delete", error);
        this.loadingService.hide();
      });
  }

  /**
   * Obtiene la lista de ventas por empresa y estatus
   */
  getSalesDetails(sales) {
    return new Promise((resolve, reject) => {
      // Obtenemos el detalle de la venta
      for (let x = 0; x < sales.system.salesDetails.length; x++) {
        // Obtenemos el detalle del objecto de venta
        switch (sales.system.salesDetails[x].system.object) {
          case 'RoomOccupationRegister':
            this.getRoomOccupationRegister(sales.system.salesDetails[x].system.objectUuid).then((data: any) => {
              sales.system.salesDetails[x].system.objectDetail.main = data.object.main;
              sales.system.salesDetails[x].system.objectDetail.system = data.object.system;
              // Obtenemos el detalle del usuario que realizo la acción
              this.getUser(sales.system.salesDetails[x].system.objectDetail.system.user).then((data: any) => {
                sales.system.salesDetails[x].system.objectDetail.system.user = data.object;
              });
            });
            break;
          case 'RoomOccupation':
            this.getRoomOccupation(sales.system.salesDetails[x].system.objectUuid).then((data: any) => {
              sales.system.salesDetails[x].system.objectDetail.main = data.object.main;
              sales.system.salesDetails[x].system.objectDetail.system = data.object.system;
              // Obtenemos el detalle del usuario que realizo la acción
              this.getUser(sales.system.salesDetails[x].system.objectDetail.system.detail.system.user).then((data: any) => {
                sales.system.salesDetails[x].system.objectDetail.system.detail.system.user = data.object;
              });
            });
            break;
          case 'RoomOccupationDoctor':
              this.getRoomOccupationDoctor(sales.system.salesDetails[x].system.objectUuid).then((data: any) => {
                sales.system.salesDetails[x].system.objectDetail = data.object;
              });
            break;
          case 'OtherCharge':
            this.getOtherCharge(sales.system.salesDetails[x].system.objectUuid).then((data: any) => {
              sales.system.salesDetails[x].system.objectDetail = data.object;
              // Obtenemos el detalle del usuario que realizo la acción
              this.getUser(sales.system.salesDetails[x].system.objectDetail.system.user).then((data: any) => {
                sales.system.salesDetails[x].system.objectDetail.system.user = data.object;
              });
            });
            break;
        }
        if (sales.system.salesDetails[x].system.object != 'OtherCharge') {
          // Obtenemos el doctor que origino la venta del servicio
          this.getDoctor(sales.system.salesDetails[x].system.collaboratorUuid).then((data: any) => {
            sales.system.salesDetails[x].system.doctor = data.object;
          });
        }
      }
      resolve({ transaction: 'ok', object: sales });
    });
  }

  /**
   * Obtiene el detalle de los medicos que supervisaron al paciente
   */
  getRoomOccupationDoctor(uuid) {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo los doctores que supervisaron al paciente, espere un momento...");
      this.session.getRequest("roomOccupationDoctor:get", { main: { uuid: uuid } }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
          this.loadingService.hide();
        }, error => {
          console.log("Error: roomOccupationDoctor:get", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Obtiene el detalle de los productos consumidos en la habitación
   */
  getRoomOccupation(uuid) {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo los productos suministrados, espere un momento...");
      this.session.getRequest("roomOccupation:get", { main: { uuid: uuid } }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
          this.loadingService.hide();
        },
        error => {
          console.log("Error: roomOccupation:get", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Obtiene el detalle del otro cargo agregado al paciente
   */
  getOtherCharge(uuid) {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo otros cargos, espere un momento...");
      this.session.getRequest("otherCharge:get", { main: { uuid: uuid } }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
          this.loadingService.hide();
        },
        error => {
          console.log("Error: otherCharge:get", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Obtiene el detalle de los productos consumidos en la habitación
   */
  getRoomOccupationRegister(uuid) {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo el detalle de su ingreso, espere un momento...");
      this.session.getRequest("roomOccupationRegister:get", { main: { uuid: uuid } }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
          this.loadingService.hide();
        },
        error => {
          console.log("Error: roomOccupationRegister:get", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Obtiene el detalle del usuario que origino el movimiento
   */
  getDoctor(collaboratorUuid) {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo el detalle del doctor que origino el movimiento, espere un momento...");
      this.session.getRequest("collaborator:get", { main: { uuid: collaboratorUuid } }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
          this.loadingService.hide();
        },
        error => {
          console.log("Error: collaborator:get", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Obtiene el detalle del usuario que origino el movimiento
   */
  getUser(user) {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo el detalle del usuario que origino el movimiento, espere un momento...");
      this.session.getRequest("user:get", user).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
          this.loadingService.hide();
        },
        error => {
          console.log("Error: user:get", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Funcionalidad para crear pasar a pagar
   */
  openPayment() {
    const dialogRef = this.dialog.open(PaymentMethodComponent, {
      width: '50%',
      data: {object: this.object}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.show(true, "Obteniendo el saldo de los abonos...");
        this.getSalesPayment(this.object).then((data: any) => {
          this.object.system.salesPayment.main.amount = data.object.amount;
          this.object.system.salesPayment.main.paymentDate = data.object.paymentDate;
          if ( (this.object.main.total - this.object.system.salesPayment.main.amount) <= 0) {
            // Actualizamos el cierre de la cuenta
            this.loadingService.show(true, "Cerrando la cuenta...");
            this.session.getRequest("sales:close", this.object).subscribe(
              (data: any) => {
                this.router.navigate(['/admin/pointSale']);
                this.snackBar.open('La cuenta fue pagada en su totalidad', 'Success', {
                  duration: 5000
                });
              },
              error => {
                console.log("Error: sales:close", error);
                this.loadingService.hide();
              });
          }
          this.loadingService.hide();
        });
      }
    });
  }

  back(){
    this._locate.back();
  }

  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }
}
