import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from "@angular/common";
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import * as Rx from 'rxjs';
import { ProductImportComponent } from './product-import/product-import.component';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';
import { ProductImageComponent } from './product-image/product-image.component';

@Component({
  selector: 'app-admin-product',
  templateUrl: './admin-product.component.html',
  styleUrls: ['./admin-product.component.css']
})
export class AdminProductComponent implements OnInit {

  @ViewChild('drawer', { static: true }) drawerCtrl: MatDrawer;
  productMin = {
    system: {
      kindProduct: {
        main: {
          uuid: ''
        }
      },
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      },
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  };
  kindProductMin = {
    system: {
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      }
    },
    max: 100,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  };
  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100],
  };
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object: {
      main: {
        uuid: ''
      }
    },
    filtering: {
      main: {
        nameCharacteristicValue: ''
      }
    }
  }
  list = [];
  kindProductList = [];
  // MatPaginator Output
  pageEvent: PageEvent;
  constructor(private session: SessionService, public snackBar: MatSnackBar, public dialog: MatDialog, public loadingService: LoadingService) {
    this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.list = [];
          this.productMin.filter.name = 'likeNameOrCode';
        } else {
          this.list = [];
          this.productMin.filter.name = '';
          this.productMin.filter.value = '';
          val = 'false'
        }
        this.getProductList();
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged:');
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        console.log(error);
      });
  }

  ngOnInit() {
    this.getkindProductList();
    // this.drawerCtrl.opened = true;
  }

  // funcion para obtener la lista de medidas
  getProductList() {
    this.loadingService.wait();
    this.list = [];
    this.session.getRequest("product:listByCompanyAndKindProducts", this.productMin).subscribe(
      (data: any) => {
        this.list = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
      },
      error => {
        console.log("Error: product:listByCompanyAndKindProducts", error);
        this.loadingService.hide();
      }
    );
  }

  getkindProductList() {
    this.loadingService.wait();
    this.kindProductList = [];
    // verificamos en que pagina verificamos
    // this.kindProductMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("kindproduct:findAllByCompany", this.kindProductMin).subscribe(
      (data: any) => {
        this.kindProductList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
        if (this.kindProductList.length > 0) {
          this.productMin.system.kindProduct.main.uuid = this.kindProductList[0].main.uuid;
          this.getProductList();
        }
      },
      error => {
        console.log("kindproduct:findAllByCompany", error);
        this.loadingService.hide();
      }
    );
  }

  /*
  Funcionalidad para colocar el objeto en el menu de opciones
  */
  objectSelected(object) {
    for (let i = 0; i < this.list.length; i++) {
      this.list[i].system.selected = false;
    }
    if (object.main.uuid == this.metadata.object.main.uuid) {
      object.system.selected = false;
      this.metadata.object.main.uuid = '';
    } else {
      object.system.selected = true;
      this.metadata.object = JSON.parse(JSON.stringify(object));;
    }
  }

  /**
   * Metodo para obtener los productos por el tap presionado por el usuario
   */
  changeTab(tabSelect) {
    this.productMin.system.kindProduct.main=this.kindProductList[tabSelect.index].main;
    this.getProductList();
  }

  /**
   * Funcion para buscar
   */
  search() {
    this.metadata.searchBoxInput.next(this.productMin.filter.value);
  }

  /**
   * funcion para obtener los datos del paginado.
   */
  onPaginateChange(event) {
    this.productMin.offset = (event.pageIndex * event.pageSize);
    this.productMin.max = event.pageSize;
    this.getProductList();
  }

  /**
   *
   */
  handleUpdated(event) {
    this.productMin.offset = (this.pageEvent ? this.pageEvent.pageIndex * this.pageEvent.pageSize : this.productMin.offset);
    this.getProductList();
  }

  /**
   * Apertura el modal para importar productos por layaout
   */
  openImportProducts() {
    this.dialog.open(ProductImportComponent, {
      width: '80%',
    }).afterClosed().subscribe(result => {
      if (result) {
        //Las notificaciones se enviaron en el modal, NO se hace nada.
        //Se importo exitosamente productos.
        this.getProductList();
      }
    });
  }

  /**
   * funcion para abrir el modal y subir las imagenes
   */
  openImages(object) {
    this.dialog.open(ProductImageComponent, {
      width: '80%',
      data: object
    }).afterClosed().subscribe(result => {
      if (result) {
        //Las notificaciones se enviaron en el modal, NO se hace nada.
        //Se importo exitosamente productos.
        this.getProductList();
      }
    });
  }

  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
