import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import * as Rx from 'rxjs/Rx';
import { LoadingService } from '../../directive/loading/loading.module';
import { AdminNurseProductServiceComponent } from './admin-nurse-product-service/admin-nurse-product-service.component';
import { AdminNurseRoomOcupationDetailComponent } from './admin-nurse-room-ocupation-detail/admin-nurse-room-ocupation-detail.component';
import { AdminNursePatientMoveComponent } from './admin-nurse-patient-move/admin-nurse-patient-move.component';
import { AdminNurseEditComponent } from './admin-nurse-edit/admin-nurse-edit.component';

@Component({
  selector: 'app-admin-nurse',
  templateUrl: './admin-nurse.component.html',
  styleUrls: ['./admin-nurse.component.css']
})
export class AdminNurseComponent implements OnInit {
  @ViewChild('drawer', { static: true }) drawerCtrl: MatDrawer;
  floors = [];
  patientModel = {
    main: {
      uuid: ''
    },
    system: {
      physicalPerson: {},
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      },
      status: true
    }
  };
  doctorModel = {
    main: {
      uuid: ''
    },
    system: {
      role: 'Doctor',
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      },
      user: {
        main: {
          uuid: ''
        }
      }
    }
  };
  roomModel = {
    main: {
      uuid: '',
      nameOrNumber: '',
      order: 0
    },
    system: {
      floor: {
        main: {
          uuid: ''
        }
      }
    },
    status: true
  };
  detailModel = {
    main: {

    }
  }
  roomOccupationModel = {
    main: {
      uuid: '',
      status: 'Disponible',
      observation: '',
      subtotal: 0,
      tax: 0,
      total: 0
    },
    complement: {
      typeOccupation: '',
      cie10: ''
    },
    system: {
      patient: JSON.parse(JSON.stringify(this.patientModel)),
      doctor: JSON.parse(JSON.stringify(this.doctorModel)),
      specialistDoctor: JSON.parse(JSON.stringify(this.doctorModel)),
      room: JSON.parse(JSON.stringify(this.roomModel)),
      detail: JSON.parse(JSON.stringify(this.detailModel))
    }
  };

  constructor(private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, public dialog: MatDialog) { }

  ngOnInit() {
    this.getFloor();
  }

  /**
   * Encuentra todos los pisos asociados a la empresa indicada
   */
  findAllByCompany() {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo pisos, espere un momento...");
      this.session.getRequest("floor:findAllByCompany", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } }, status: true } }).subscribe(
        (data: any) => {
          let floors = [];
          // Obtenemos las habitaciones por piso
          for (let x = 0; x < data.object.instanceList.length; x++) {
            let floor = {
              main: {
                uuid: data.object.instanceList[x].main.uuid,
                name: data.object.instanceList[x].main.name,
              },
              system: {
                status: data.object.instanceList[x].system.status,
                company: data.object.instanceList[x].system.company,
                rooms: []
              }
            }
            this.findAllByFloor(data.object.instanceList[x]).then((data: any) => {
              floor.system.rooms = data.object.instanceList;
              floors.push(floor);
            });
          }
          resolve({ transaction: 'ok', object: { instanceList: floors } });
          this.loadingService.hide();
        },
        error => {
          console.log("Error: floor:findAllByCompany", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Encuentra todos los cuartos asociados al piso indicado
   */
  findAllByFloor(floor) {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo cuartos del piso " + floor.main.name + ", espere un momento...");
      this.session.getRequest("roomOccupation:findAllByFloorWithWarehouse", { system: { floor: { main: { uuid: floor.main.uuid } }, status: true } }).subscribe(
        (data: any) => {
          let rooms = [];
          // Obtenemos la ocupación del cuarto
          for (let y = 0; y < data.object.instanceList.length; y++) {
            this.findByRoomAndStatusNotEqual(data.object.instanceList[y]).then((data: any) => {
              rooms.push(data.object);
            });
          }
          resolve({ transaction: 'ok', object: { instanceList: rooms } });
          this.loadingService.hide();
        },
        error => {
          console.log("Erro: roomOccupation:findAllByFloorWithWarehouse", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Busca si existe ocupación enla habitación indicada
   */
  findByRoomAndStatusNotEqual(room) {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo detalle de la ocupación del cuarto " + room.main.nameOrNumber + ", espere un momento...");
      this.session.getRequest("roomOccupation:findByRoomAndStatusNotEqual", { main: { status: 'Terminado' }, system: { room: { main: { uuid: room.main.uuid } } } }).subscribe(
        (data: any) => {
          if (data.object) {
            resolve({ transaction: 'ok', object: data.object });
          } else {
            let roomOccupation = JSON.parse(JSON.stringify(this.roomOccupationModel));
            roomOccupation.system.room = room;
            resolve({ transaction: 'ok', object: roomOccupation });
          }
        },
        error => {
          console.log("Erro: roomOccupation:findByRoomAndStatusNotEqual", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Obtenemos la lista de pisos de la clinica
   */
  getFloor() {
    this.floors = [];
    this.findAllByCompany().then((data: any) => {
      this.floors = data.object.instanceList;
    });
  }

  /**
   * Modal para visualizar el detalle de lo consumido por el paciente
   */
  infoPatientDialog(object) {
    this.dialog.open(AdminNurseRoomOcupationDetailComponent, {
      width: '60%',
      height:'35rem',
      data: {
        object: object,
      }
    }).afterClosed().subscribe(result => {

    });
  }

  /**
   * Modal para agregar productos al consumo del paciente
   */
  registerProductDialog(object) {
    this.dialog.open(AdminNurseProductServiceComponent, {
      width: '95%',
      height:'30rem',
      disableClose: true,
      data: {
        object: object,
      }
    }).afterClosed().subscribe(result => {

    });
  }

  /**
   * Modal para agregar productos al consumo del paciente
   */
  movePatientDialog(object) {
    this.dialog.open(AdminNursePatientMoveComponent, {
      width: '90%',
      data: {
        object: object,
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.getFloor();
      }
    });
  }

  /**
   * Modal para agregar productos al consumo del paciente
   */
  editData(object) {
    this.dialog.open(AdminNurseEditComponent, {
      width: '90%',
      data: {
        object: object,
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        if(result.transaction == 'ok'){
          this.getFloor();
        }
      }
    });
  }

  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
