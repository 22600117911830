import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { SessionService } from '../../../service/session/session.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoadingService } from '../../../directive/loading/loading.module';
import { MatSnackBar } from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-working-hours-update',
  templateUrl: './admin-working-hours-update.component.html',
  styleUrls: ['./admin-working-hours-update.component.css']
})
export class AdminWorkingHoursUpdateComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  complexForm: FormGroup;
  currentDate = new Date();
  workingHoursDetails = {
    main: {
      uuid: '',
      day: '',
      timeInput: '',
      minuteInput: '',
      timeOutput: '',
      minuteOutput: '',
      openSchedule: false
    },
    complement:{
      startHr: moment(),
      endHr: moment(),
      stringStartHr:'',
      stringEndHr:''
    },
    system: {
      status: true,
      workingHours: {
        main: {
          uuid: ''
        }
      }
    }
  }

  workingHours = {
    main: {
      uuid: '',
      code: '',
      name: '',
      restrictCheck: false
    },
    system: {
      status: true,
      company: {
        main: {
          uuid: ''
        }
      },
      workingHoursDetails: []
    }
  }

  dayModel = {
    day: "",
    schedule:[],
    status: true
  }

  week = [];
  metadata = {
    disable:false
  };

  object = JSON.parse(JSON.stringify(this.workingHours));

  constructor(public fb: FormBuilder, protected sessionService: SessionService, private router: Router, private activatedRoute: ActivatedRoute, public loadingService: LoadingService, public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.getDaysList();
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params['uuid'] == 'new'){
        // no hacemos nada por el momento
        this.metadata.disable = false;
      }else{
        this.object.main.uuid = params['uuid'];
        // cargamos los datos de la BD
        this.sessionService.getRequest('workingHours:get', this.object).subscribe((data:any)=>{
          this.object = JSON.parse(JSON.stringify(data.object));
          this.metadata.disable = true;

          for (var i = 0; i < this.week.length; i++) {
            this.week[i].status = false;
            for (var j = 0; j < this.object.system.workingHoursDetails.length; j++) {
              if(this.week[i].day === this.object.system.workingHoursDetails[j].main.day){
                this.week[i].status = true;
                if(this.week[i].schedule[0].main.uuid === ''){
                  this.week[i].schedule[0].main.uuid = this.object.system.workingHoursDetails[j].main.uuid;
                  this.week[i].schedule[0].complement.startHr = moment(this.currentDate);
                  this.week[i].schedule[0].complement.startHr.set('hour',this.object.system.workingHoursDetails[j].main.timeInput);
                  this.week[i].schedule[0].complement.startHr.set('minute',this.object.system.workingHoursDetails[j].main.minuteInput);
                  this.week[i].schedule[0].complement.startHr.set('second',0);
                  this.week[i].schedule[0].complement.stringStartHr = this.FormatNumberLength(this.week[i].schedule[0].complement.startHr.hour(),2)+':'+this.FormatNumberLength(this.week[i].schedule[0].complement.startHr.minute().toString(),2);
                  this.week[i].schedule[0].complement.endHr = moment(this.currentDate);
                  this.week[i].schedule[0].complement.endHr.set('hour',this.object.system.workingHoursDetails[j].main.timeOutput);
                  this.week[i].schedule[0].complement.endHr.set('minute',this.object.system.workingHoursDetails[j].main.minuteOutput);
                  this.week[i].schedule[0].complement.endHr.set('second',0);
                  this.week[i].schedule[0].complement.stringEndHr = this.FormatNumberLength(this.week[i].schedule[0].complement.endHr.hour(),2)+':'+this.FormatNumberLength(this.week[i].schedule[0].complement.endHr.minute().toString(),2);
                  this.week[i].schedule[0].main.openSchedule = this.object.system.workingHoursDetails[j].main.openSchedule;
                }else {
                  var workingHoursDetailsTemp = JSON.parse(JSON.stringify(this.workingHoursDetails));
                  workingHoursDetailsTemp.main.day = this.object.system.workingHoursDetails[j].main.day;
                  workingHoursDetailsTemp.main.uuid = this.object.system.workingHoursDetails[j].main.uuid;
                  workingHoursDetailsTemp.complement.startHr = moment(this.currentDate);
                  workingHoursDetailsTemp.complement.startHr.set('hour',this.object.system.workingHoursDetails[j].main.timeInput);
                  workingHoursDetailsTemp.complement.startHr.set('minute',this.object.system.workingHoursDetails[j].main.minuteInput);
                  workingHoursDetailsTemp.complement.startHr.set('second',0);
                  workingHoursDetailsTemp.complement.stringStartHr = this.FormatNumberLength(workingHoursDetailsTemp.complement.startHr.hour(),2)+':'+this.FormatNumberLength(workingHoursDetailsTemp.complement.startHr.minute().toString(),2);
                  workingHoursDetailsTemp.complement.endHr = moment(this.currentDate);
                  workingHoursDetailsTemp.complement.endHr.set('hour',this.object.system.workingHoursDetails[j].main.timeOutput);
                  workingHoursDetailsTemp.complement.endHr.set('minute',this.object.system.workingHoursDetails[j].main.minuteOutput);
                  workingHoursDetailsTemp.complement.endHr.set('second',0);
                  workingHoursDetailsTemp.complement.stringEndHr = this.FormatNumberLength(workingHoursDetailsTemp.complement.endHr.hour(),2)+':'+this.FormatNumberLength(workingHoursDetailsTemp.complement.endHr.minute().toString(),2);
                  workingHoursDetailsTemp.main.openSchedule = this.object.system.workingHoursDetails[j].main.openSchedule;
                  this.week[i].schedule.push(workingHoursDetailsTemp);
                }
              }
            }
          }  // Fin de for week

        },
        (error)=>{
          console.log('Error:workingHours:get',error)
        })
      }
    });
  }

  getDaysList(){
    var days = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']
    //creamos el la lista de dias.
    for (var i = 0; i < days.length; i++) {
      var dayModelTemp = JSON.parse(JSON.stringify(this.dayModel));
      var workingHoursDetailsTemp = JSON.parse(JSON.stringify(this.workingHoursDetails));
      dayModelTemp.day = days[i];
      workingHoursDetailsTemp.main.day = days[i];
      workingHoursDetailsTemp.complement.startHr = moment(this.currentDate);
      workingHoursDetailsTemp.complement.startHr.set('hour',9);
      workingHoursDetailsTemp.complement.startHr.set('minute',0);
      workingHoursDetailsTemp.complement.startHr.set('second',0);
      workingHoursDetailsTemp.complement.endHr = moment(this.currentDate);
      workingHoursDetailsTemp.complement.endHr.set('hour',17);
      workingHoursDetailsTemp.complement.endHr.set('minute',0);
      workingHoursDetailsTemp.complement.endHr.set('second',0);
      workingHoursDetailsTemp.complement.stringStartHr = this.FormatNumberLength(workingHoursDetailsTemp.complement.startHr.hour(),2)+':'+this.FormatNumberLength(workingHoursDetailsTemp.complement.startHr.minute().toString(),2);
      workingHoursDetailsTemp.complement.stringEndHr = this.FormatNumberLength(workingHoursDetailsTemp.complement.endHr.hour(),2)+':'+this.FormatNumberLength(workingHoursDetailsTemp.complement.endHr.minute().toString(),2);
      dayModelTemp.schedule.push(workingHoursDetailsTemp);
      this.week.push(dayModelTemp);
    }
    console.log("week", this.week);
  }

  // Función para agregar un nuevo campo al horario de un dia.
  addSchedule(object){
    var workingHoursDetailsTemp = JSON.parse(JSON.stringify(this.workingHoursDetails));
    workingHoursDetailsTemp.main.day = object.day;
    workingHoursDetailsTemp.complement.startHr = moment(this.currentDate);
    workingHoursDetailsTemp.complement.startHr.set('hour',9);
    workingHoursDetailsTemp.complement.startHr.set('minute',0);
    workingHoursDetailsTemp.complement.startHr.set('second',0);
    workingHoursDetailsTemp.complement.endHr = moment(this.currentDate);
    workingHoursDetailsTemp.complement.endHr.set('hour',17);
    workingHoursDetailsTemp.complement.endHr.set('minute',0);
    workingHoursDetailsTemp.complement.endHr.set('second',0);
    workingHoursDetailsTemp.complement.stringStartHr = this.FormatNumberLength(workingHoursDetailsTemp.complement.startHr.hour(),2)+':'+this.FormatNumberLength(workingHoursDetailsTemp.complement.startHr.minute().toString(),2);
    workingHoursDetailsTemp.complement.stringEndHr = this.FormatNumberLength(workingHoursDetailsTemp.complement.endHr.hour(),2)+':'+this.FormatNumberLength(workingHoursDetailsTemp.complement.endHr.minute().toString(),2);
    object.schedule.push(workingHoursDetailsTemp);
  }

  /** Elimina el detalle de horario **/
  rmSchedule(objectDay, objectDetail, index){
    if(objectDetail.main.uuid == ''){
      objectDay.schedule.splice(index,1);
    }
    else{
      this.sessionService.getRequest('workingHours:deleteDetail', {main:{uuid: objectDetail.main.uuid}}).subscribe(
       (data:any)=>{
         objectDay.schedule.splice(index,1);
         this.snackBar.open('Eliminado exitoso', 'Información', {duration: 5000});
       },
       error => {
         console.log("workingHours:deleteDetail",error);
         this.snackBar.open(error.message, 'Error', {duration: 5000});
       })
     }
  }

  send(object) {
    // cargamos los datos de la BD
    this.loadingService.show(true,'Guardando horario de trabajo');
    object.system.workingHoursDetails = [];
    object.system.company.main.uuid = this.sessionService.getSessionObject().main.uuid;

    // Pasamos los dias al workingHoursDetails.
    for (var i = 0; i < this.week.length; i++) {
      for (var j = 0; j < this.week[i].schedule.length; j++){
        let tildePositionStart = this.week[i].schedule[j].complement.stringStartHr.indexOf(':');
        let hourStart = this.week[i].schedule[j].complement.stringStartHr.slice(0,tildePositionStart)
        let minuteStart = this.week[i].schedule[j].complement.stringStartHr.slice(tildePositionStart+1, this.week[i].schedule[j].complement.stringStartHr.length);
        this.week[i].schedule[j].complement.startHr.set('hour', parseInt(hourStart));
        this.week[i].schedule[j].complement.startHr.set('minute', parseInt(minuteStart));
        this.week[i].schedule[j].complement.startHr.set('second', 0);
        this.week[i].schedule[j].main.timeInput = this.week[i].schedule[j].complement.startHr.hour();
        this.week[i].schedule[j].main.minuteInput = this.week[i].schedule[j].complement.startHr.minute();
        let tildePositionEnd = this.week[i].schedule[j].complement.stringEndHr.indexOf(':');
        let hourEnd = this.week[i].schedule[j].complement.stringEndHr.slice(0,tildePositionEnd)
        let minuteEnd = this.week[i].schedule[j].complement.stringEndHr.slice(tildePositionEnd+1, this.week[i].schedule[j].complement.stringEndHr.length);
        this.week[i].schedule[j].complement.endHr.set('hour', parseInt(hourEnd));
        this.week[i].schedule[j].complement.endHr.set('minute', parseInt(minuteEnd));
        this.week[i].schedule[j].complement.endHr.set('second', 0);
        this.week[i].schedule[j].main.timeOutput = this.week[i].schedule[j].complement.endHr.hour();
        this.week[i].schedule[j].main.minuteOutput = this.week[i].schedule[j].complement.endHr.minute();
        this.week[i].schedule[j].main.openSchedule = this.week[i].schedule[j].main.openSchedule;
        this.week[i].schedule[j].system.status = this.week[i].status;
        if(this.week[i].schedule[j].system.status){
          object.system.workingHoursDetails.push(this.week[i].schedule[j]);
         }

      }
    }
    this.loadingService.hide();
    this.sessionService.getRequest('workingHours:update', object).subscribe((data:any)=>{
      this.metadata.disable = true;
      this.snackBar.open('Registro guardado exitosamente', 'Información', {duration: 5000});
      this.router.navigate(['/admin/schedule/workingHours']);
      this.loadingService.hide();
    },
    (error)=>{
      console.log('Error:workingHours:update',error)
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      this.loadingService.hide();
    })
  }

  /* Función para parseo de entero a string
  */
  FormatNumberLength(num, length) {
     var r = "" + num;
     while (r.length < length) {
         r = "0" + r;
     }
     return r;
 }

  /** Funcion para controlar si se abre o cierra el menu **/
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
