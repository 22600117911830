import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import {DomSanitizer , SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-product-image',
  templateUrl: './product-image.component.html',
  styleUrls: ['./product-image.component.css']
})
export class ProductImageComponent implements OnInit {
  productImage = {
    main:{
      uuid:''
    },
    image:{
      file:'',
      name:'',
      cover:false
    },
    system:{
      product:{
        main:{
          uuid:''
        }
      },
      status:true
    }
  }

  metadata = {
    image:{
      name:'',
      type:'',
      file:''
    }
  }

  object = JSON.parse(JSON.stringify(this.productImage));
  imagesList = [];
  constructor(public dialogRef: MatDialogRef<ProductImageComponent>, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.object.system.product.main.uuid = this.data.main.uuid;
    this.productImage.system.product.main.uuid = this.data.main.uuid;
    this.getImages();
  }

  /**
  Funcion para subir imagenes
  */
  uploadAFile(){
    if(this.imagesList.length == 0){
      this.object.image.cover = true;
    }else{
      this.object.image.cover = false;
    }
    // creamos el objeto  enviar a la BD
    this.object.image.file = this.metadata.image.file.replace(/\+/g,"-");
    this.object.image.name = this.metadata.image.name;
    this.session.getRequest("productImage:update",this.object).subscribe((data:any)=>{
      this.object.image.file = this.object.image.file.replace(/\-/g,"+");
      this.object.image.fileSafe = this.sanitizer.bypassSecurityTrustUrl(this.object.image.file);
      this.object.main.uuid = data.object.main.uuid;
      this.imagesList.push(this.object);
      this.metadata.image.file = '';
      this.metadata.image.name = '';
      this.metadata.image.type = '';
      this.object = JSON.parse(JSON.stringify(this.productImage));
    },error=>{
      console.log("Error: productImage:update",error);
    })
  }

  /** funcionalidad para obtener listado de imagenes
  */
  getImages(){
    this.loadingService.wait();
    this.session.getRequest("productImage:findAllByProductAndStatus",this.object).subscribe((data:any)=>{
      let imagesLists = data.object.instanceList;
      for(let i =0; i < imagesLists.length; i++){
        imagesLists[i].image.file = imagesLists[i].image.file.replace(/\-/g,"+");
        imagesLists[i].image.fileSafe = this.sanitizer.bypassSecurityTrustUrl(imagesLists[i].image.file);
      }
      this.imagesList = imagesLists;
      this.loadingService.hide();
    },error=>{
      console.log("Error: productImage:findAllByProductAndStatus",error);
      this.loadingService.hide();
    })
  }

  /**
   * Funcion para obtener la imagen de cada producto
   */
  loadImage(object){
    this.session.getRequest("productImage:get",object).subscribe((data:any)=>{
      object.image.file = data.object.image.file.replace(/\-/g,"+");
      // object.image.file = this.sanitizer.bypassSecurityTrustResourceUrl(data.object.image.file.replace(/\-/g,"+"));
    },error=>{
      console.log("error: productImage:get",error);
    })
  }

  /**
   * Funcionalidad para eliminar un productImage
   */
  delete(object){
    this.session.getRequest("productImage:delete",object).subscribe((data:any)=>{
      for(let i=0; i <this.imagesList.length; i++){
        if(this.imagesList[i].main.uuid == object.main.uuid){
          this.imagesList.splice(i,1);
        }
      }
    },error=>{
      console.log("error: productImage:delete",error);
    })
  }

  /**
   * Funcion para cerrar el modal de incremento salarial.
   */
  closeDialog(){
    this.dialogRef.close(false);
  }

}
