import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import * as moment from 'moment';
import { MatSnackBar} from '@angular/material/snack-bar';
import { OwnerClockCheckEntryExitWorkConfirmDeleteChecksComponent } from './owner-clock-check-entry-exit-work-confirm-delete-checks/owner-clock-check-entry-exit-work-confirm-delete-checks.component';


@Component({
  selector: 'app-owner-clock-check-entry-exit-work',
  templateUrl: './owner-clock-check-entry-exit-work.component.html',
  styleUrls: ['./owner-clock-check-entry-exit-work.component.css']
})
export class OwnerClockCheckEntryExitWorkComponent implements OnInit {
  companyClientList = [];

  addCheckEntryExitWork = {
    main:{
      startDate: '',
      endDate: ''
    },
    system:{
      company:{
        main:{
          uuid: this.session.getSessionObject().main.uuid
        }
      }
    }
  }

  metadata = {
    startDate: null,
    endDate: null
  }

  object = JSON.parse(JSON.stringify(this.addCheckEntryExitWork));

  constructor(public dialogRef: MatDialogRef<OwnerClockCheckEntryExitWorkComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    // this.getCompany();
  }

//    /** funcion para obtener las compañias cliente
//  **/
//  getCompany(){
//   this.session.getRequest('company:findAllByCompanyAndStatus',{system:{company:{main:{uuid:this.session.getSessionObject().main.uuid}}, status:true}}).subscribe(
//     (data:any)=>{
//       this.companyClientList = data.object.instanceList;
//     },
//     error => {
//       console.log("company:findAllByCompanyAndStatus",error);
//     });
// }

/** Abre el cuadro de dialogo para advertir que se guardaran los checks **/
openDialogConfirmSave(object) {
  const dialogRef = this.dialog.open(OwnerClockCheckEntryExitWorkConfirmDeleteChecksComponent, {
    width: '500px',
    data: {}
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      this.send(object);
    }
  });
}

send(object){
    this.loadingService.show(true,'Guardando Checks');
    object.main.startDate = moment(this.metadata.startDate).format('DD-MM-YYYY');
    object.main.endDate= moment(this.metadata.endDate).format('DD-MM-YYYY');

    this.session.getRequest('dependentMethods:addCheckEntryExitWork', object).subscribe(
      (data:any)=>{
        this.snackBar.open('Guardado Exitoso', 'Infomación', {duration: 5000});
        this.object = JSON.parse(JSON.stringify(this.addCheckEntryExitWork));
        this.metadata.startDate = null;
        this.metadata.endDate = null;
        this.close(true);
        this.loadingService.hide();
      },
      error => {
        console.log("dependentMethods:addCheckEntryExitWork",error);
        this.snackBar.open('Error al guardar ', 'Error', {duration: 5000});
        this.loadingService.hide();
      });
}

 close(status){
   let object = {
     transaction:'',
     code:'',
     object:{}
   };
   if(status){
     object.transaction = 'ok';
     object.object = this.object;
   }else{
     object.transaction = 'bad';
     object.code = 'dependentMethods:001';
   }
   this.dialogRef.close(object);
 }

}
