import { Component, OnInit, Inject } from '@angular/core';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import * as moment from 'moment';
import * as printJS from "print-js";

@Component({
  selector: 'app-assistant-point-sale-other-charge-update',
  templateUrl: './assistant-point-sale-other-charge-update.component.html',
  styleUrls: ['./assistant-point-sale-other-charge-update.component.css']
})
export class AssistantPointSaleOtherChargeUpdateComponent implements OnInit {
  saleClinicModel={
    main:{
      uuid:'',
      description:'', // EL diagnostico, curación etc
      concepto:'', //Material y consulta (diagnostico)
      total:0,    // Total cobrado
      date:'',
    },
    system:{
      saleClinic:{
        main:{
          uuid:''
        }
      }
    }
  }
  object = JSON.parse(JSON.stringify(this.saleClinicModel));

  constructor(public loadingService: LoadingService, private session: SessionService, public dialogRef: MatDialogRef<AssistantPointSaleOtherChargeUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar) {

  }

  ngOnInit() {
    if(this.data != 'new') {
      this.object.system.saleClinic.main.uuid = this.data.object;
    }
  }

  addOtherCharge(object){
    this.loadingService.wait();
    this.object.main.date = moment().format('DD-MM-YYYY hh:mm:ss');
    this.session.getRequest('saleClinicDetail:update', this.object).subscribe(
     (data: any) => {
        this.object = data.object;
        this.printTicket(this.object);
        // enviamos a impirmir el ticke de la compra
       this.loadingService.hide();
       this.closeDialog(true);
      }, error => {
        console.log('Error: saleClinicDetail:update', error);
        this.loadingService.hide();
      });
  }

  printTicket(object:any){
    this.loadingService.show(true,"Obteniendo ticket, espere un momento...");
    this.session.getRequest("saleClinicDetail:ticket",{main:{uuid:object.main.uuid}}).subscribe((data:any)=>{
      this.loadingService.hide();
      printJS({printable: data.object, type: 'pdf', base64: true})
    },error=>{
      console.log('Error: saleClinicDetail:ticket', error);
      this.loadingService.hide();
    })
  }

  /*
   * Cierra el modal
   */
  closeDialog(object) {
    this.dialogRef.close(object);
  }
}
