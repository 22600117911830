import { Injectable } from '@angular/core';
import { StorageTool } from '../service/session/storage.tool'
import { Subject } from 'rxjs';

export interface activeSession {
  user:string,
  oauth:string,
  lastAccess:Date
}

@Injectable({
  providedIn: 'root'
})
export class ActivedsessionService {

  private sessionList:activeSession[]= [];
  sessionLoaded: Subject<Boolean> = new Subject<Boolean>();

  constructor(private storage:StorageTool) {
    this.storage.getAkeyFromStorage("multisession").then((data:any)=>{
      if(data){
        this.sessionList = data;
        this.sessionLoaded.next(true);
      }
      console.log("sessionList:::",this.sessionList);
    }).catch(e=>{
      console.log(e)
    })
  }

  /**funcionalidad para guardar una session
  */
  public saveASession(object:activeSession){
    let aux:activeSession = {
      user:object.user,
      oauth:btoa(object.oauth),
      lastAccess:new Date()
    }
    this.storage.getAkeyFromStorage("multisession").then((data:any)=>{
      if(data){
        this.sessionList = data;
      }
      let found = false;
      for(let i = 0; i < this.sessionList.length; i++){
        if(this.sessionList[i].user == object.user){
          this.sessionList[i].lastAccess = new Date()
          this.sessionList[i].oauth = aux.oauth;
          found = true;
        }
      }
      if(!found)
        this.sessionList.push(aux)
      console.log("multisession:::",this.sessionList);
      this.storage.saveAKeyOnTheStorage("multisession",this.sessionList).then((data:any)=>{
        console.log("GUARDADO::::",data);
        this.sessionLoaded.next(true);
      }).catch(e=>{
      });
    }).catch(e=>{
      console.log(e)
    })
  }


  /**funcionalida para devolver la sessiones
  */
  public getSessions(){
    return this.sessionList;
  }


  /**funcionalidad para eliminar un session
  */
  public deleteASession(object:activeSession):Promise<Boolean>{
    return new Promise((resolve,reject)=>{
      this.sessionList = this.sessionList.filter((item)=>{
        return item.user != object.user;
      });
      this.storage.saveAKeyOnTheStorage("multisession",this.sessionList).then((data:any)=>{
        resolve(data)
      }).catch(e=>{
        reject(e)
      });
    });

  }




}
