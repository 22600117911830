import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MenuService } from '../menu/menu.service';
import { SessionService } from '../service/session/session.module';
import { LoadingService } from '../directive/loading/loading.module';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ActivedsessionService } from './activedsession.service';
import { SelectCompanyComponent } from './select-company/select-company.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  complexForm: FormGroup;
  object = {
    main: {
      username:'',
      password:''
    }
  }
  metadata = {
    showSessions:false,
    sessionList:[],
    first:true,
    editSession:false
  }
  constructor(public dialog: MatDialog, private router: Router, public fb: FormBuilder, public menuService: MenuService, private session:SessionService, public loadingService: LoadingService, private snackBar: MatSnackBar, private activedsession:ActivedsessionService) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'username': [null, Validators.required],
      'password': [null, Validators.compose([Validators.required])],
    });
   }

  ngOnInit() {
    console.log("ngOnInit");
    this.metadata.sessionList = this.activedsession.getSessions();
    if(this.metadata.sessionList.length > 0){
      this.metadata.showSessions = true;
    }
    this.activedsession.sessionLoaded.subscribe((value)=>{
      this.metadata.sessionList = this.activedsession.getSessions();
      if(this.metadata.sessionList.length > 0){
        this.metadata.showSessions = true;
      }
      console.log("Mostrar sessiones",this.metadata);
    });
  }

  /** funcion paa enviar el usuario y cotraseña
  */
  send(object){
    this.loadingService.show(true,"Iniciado sesión, espere un momento...")
    this.session.login(this.object).then((data) => {
      //Salvamos el u / p
      this.activedsession.saveASession({user:this.object.main.username,oauth:this.object.main.password,lastAccess:new Date()});
      this.loadingService.hide();
      this.snackBar.open("¡ Bienvenido !", "Aceptar", { duration: 2000 });
      let companies = this.session.getObjects();
      if (companies.length == 1) {
        let objectSelected =  companies[0].system.object;
        this.session.setObjectSession(objectSelected);
        // obtenemos el rol
        let role = this.session.getRoles()[0];
        switch (role) {
          case 'ROLE_TREASURY':
            this.router.navigate(['/admin/report']);
            this.menuService.setMenuType('admin');
            break;
          case 'ROLE_ADMINISTRATION':
            this.router.navigate(['/owner/medicalConsultation/dashboard']);
            this.menuService.setMenuType('owner');
            break;
          case 'ROLE_ASSISTANT':
            this.router.navigate(['/assistant/register/list']);
            this.menuService.setMenuType('assistant');
            break;
          case 'ROLE_DOCTOR':
            this.router.navigate(['/doctor/register/list']);
            this.menuService.setMenuType('doctor');
            break;
          case 'ROLE_SPECIALIST':
            console.log('')
            this.router.navigate(['/specialist/dashboard']);
            this.menuService.setMenuType('specialist');
            break;
          case 'ROLE_NURSE':
            this.router.navigate(['/nurse/census/list']);
            this.menuService.setMenuType('nurse');
            break;
          default:
            break;
        }
      } else {
        console.log('Multiples compañias', companies.length);
        this.selectCompany(companies);
      }
    }).catch((error) => {
      this.loadingService.hide();
      this.snackBar.open("¡ Usuario o Contraseña incorrectos !", "Error", { duration: 2000 });
      console.log('Error: ', error);
      this.metadata.showSessions = false;
    });
  }

  selectCompany(companies) {
    this.dialog.open(SelectCompanyComponent, {
      data: companies,
      width: '40%',
    }).afterClosed().subscribe(result => {
      if (result) {
       console.log(result);
        let objectSelected =  result.object.system.object;
        console.log(objectSelected);
        this.session.setObjectSession(objectSelected);
        // obtenemos el rol
        let role = this.session.getRoles()[0];
        switch (role) {
          case 'ROLE_TREASURY':
            this.router.navigate(['/admin/report']);
            this.menuService.setMenuType('admin');
            break;
          case 'ROLE_ADMINISTRATION':
            this.router.navigate(['/owner/medicalConsultation/dashboard']);
            this.menuService.setMenuType('owner');
            break;
          case 'ROLE_ASSISTANT':
            this.router.navigate(['/assistant/register/list']);
            this.menuService.setMenuType('assistant');
            break;
          case 'ROLE_DOCTOR':
            this.router.navigate(['/doctor/register/list']);
            this.menuService.setMenuType('doctor');
            break;
          case 'ROLE_SPECIALIST':
            this.router.navigate(['/specialist/dashboard']);
            this.menuService.setMenuType('specialist');
            break;
          case 'ROLE_NURSE':
            this.router.navigate(['/nurse/census/list']);
            this.menuService.setMenuType('nurse');
            break;

          default:
            break;
        }
      }
    });
  }

  /**Funcion para inicializar session de una usuario por sessiones activas
  */
  goToLogin(object){
    this.object.main.password = atob(object.oauth);
    this.object.main.username = object.user;
    this.send(this.object);
  }

  tootleToEditSessions(){
    this.metadata.editSession = !this.metadata.editSession;
  }

  deleteSession(object){
    this.activedsession.deleteASession(object).then((data)=>{
      this.metadata.sessionList = this.activedsession.getSessions();
      if(this.metadata.sessionList.length > 0){
        this.metadata.showSessions = true;
      }else{
        this.metadata.showSessions = false;
      }
    }).catch(e=>{
      console.log(e);
    })
  }
}
