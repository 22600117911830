import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatSnackBar} from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';

@Component({
  selector: 'app-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.css']
})
export class ProductPriceComponent implements OnInit {
  productPriceModel = {
    main:{
      uuid:'',
      price:0,
      direct:true, // false el precio se toma directo del input || true el precio se calcula segun formula
      formula:'',
      name:''
    },
    system:{
      product:{
        main:{
          uuid:''
        }
      }
    }
  };
  productPriceList = [];
  productPrice:any = JSON.parse(JSON.stringify(this.productPriceModel));
  @Input('product') object:{main:{uuid:''}} ;

  constructor(public loadingService:LoadingService, private session: SessionService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getPrices();
  }

  /*
   * Funcion para obtener el los precios de un producto
   */
  getPrices(){
    this.productPrice.system.product.main.uuid = this.object.main.uuid;
    this.loadingService.show(true,'Cargando recursos, espere un momento...')
    this.session.getRequest('productPrice:findAllByProduct',this.productPrice).subscribe((data:any)=>{
      this.productPriceList = data.object.instanceList;
      this.loadingService.hide();
      this.newProductPrice();
    },error=>{
      console.log("error:productPrice:findAllByProduct",error);
      this.loadingService.hide();
    })
  }

  /**
   * Función para crear un nuevo precio
   */
  newProductPrice(){
    this.productPriceList.push(JSON.parse(JSON.stringify(this.productPriceModel)));
  }

  /**
   * Agrega el precio al producto indicado
   */
  addPriceProduct(object,i,status){
    object.system.product.main.uuid = this.object.main.uuid;
    object.main.name = 'precio'+i+1;
    object.main.formula = object.main.formula.replace(/\+/g,"#");
    this.session.getRequest('productPrice:update',object).subscribe((data:any)=>{
      object.main.uuid = data.object.main.uuid;
      if(status == 'new'){
        this.newProductPrice();
      }
      this.snackBar.open('El registro se guardo correctamente', '', {
        duration: 8000
      });
    },error=>{
      console.log("Error: productPrice:update",error);
      this.snackBar.open('Verifique la infromación que se agrego', '', {
        duration: 8000
      });
    })
  }

  /**
   * Elimina el precio al producto indicado
   */
  deleteAProductPrice(object){
    this.session.getRequest('productPrice:delete',object).subscribe((data:any)=>{
      for(let i=0; i < this.productPriceList.length; i++){
        if(this.productPriceList[i].main.uuid == object.main.uuid){
          this.productPriceList.splice(i,1);
          this.snackBar.open('El registro se eliminado correctamente', '', {
            duration: 8000
          });
          break;
        }
      }
    },error=>{
      console.log("Error: productPrice:delete",error);
    })
  }
}
