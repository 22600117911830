import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
export interface menu {
    route: string
  }
@Injectable()
export class MenuService {
  object = {
    route:''
  };

  metadata = {
    typeMenu:''
  }

  menuChange: Subject<menu> = new Subject<menu>();
  constructor() {
    this.menuChange.next(this.object);
   }

   selected(value){
     this.object.route = value;
     this.menuChange.next(this.object);
     sessionStorage.setItem('router', value);
   }
   getSubMenuSelected(){
     return Observable.create( observer => {
       observer.next(this.object.route)
     });
   }
   clean(){
     this.object.route = '';
     this.menuChange.next(this.object);
   }

   /** función para setear el tipo de menu a mostrar
   */
   setMenuType(object:string):void {
     sessionStorage.setItem('menuType', object);
     this.metadata.typeMenu = object;
   }

   /** funcion para obtener el tipo de menu a mostrar
   */
   getMenuType():string{
     this.metadata.typeMenu = sessionStorage.getItem('menuType');
     return this.metadata.typeMenu;
   }

   /** funcion para determinar el tipo de menu a rederizar
   */
   findMyMenu(){
     let typeMenu = this.getMenuType();
     let array = [];
     switch(typeMenu){
       case 'admin':{
         array = [
           { route: '/admin/report', title:'Dashboard', icon:'timeline',  options:[], selected:true },
           { route: '/admin/nurse/dashboard', title:'Enfermeria', icon:'face',  options:[], selected:false},
           { route: '/admin/census/list', title:'Censo', icon:'query_stats',  options:[], selected:false},
           { route: '/admin/surgeries', title:'Agenda Quirúrgica', icon:'event',  options:[], selected:false },
           { route: '/admin/inventory/list', title:'Inventarios', icon:'dynamic_feed',  options:[], selected:false },
           { route: '/admin/product/list', title:'Articulos', icon:'scatter_plot',  options:[], selected:false },
           { route: '/admin/serviceCatalog/list', title:'Servicios', icon:'pan_tool',  options:[], selected:false },
           { route: '/admin/zone/list', title:'Zonas', icon:'branding_watermark',  options:[], selected:false },
           { route: '/admin/patient/list', title:'Pacientes', icon:'account_circle',  options:[], selected:false },
           { route: '/admin/treasury/list', title:'Gastos/Otros ingresos', icon:'inbox',  options:[], selected:false },
           { route: '/admin/pointSale/', title:'Caja', icon:'monetization_on',  options:[], selected:false },
           { route: '/admin/survey/', title:'Encuestas de satisfacción', icon:'description',  options:[], selected:false },
           { route: '/admin/reportCie10',  title:'Reporte Cie10', icon:'leaderboard',  options:[], selected:false },
           { route: '/admin/doctors-guards', title:'Colaboradores', icon:'people',  options:[], selected:false },
           { route: '/admin/tasks', title:'Pendientes', icon:'checklist',  options:[], selected:false },
           { route: '/admin/schedule/workingHours', title:'Horarios', icon:'alarm',  options:[], selected:false },
           { route: '/admin/access', title:'Accesos', icon:'account_circle',  options:[], selected:false },
           { route: '/admin/cashingByEmergencies', title:'Cobros por urgencias', icon:'monetization_on',  options:[], selected:false },
         ];
         break;
       }
       case 'specialist':{
         array = [
           { route: '/specialist/dashboard', title:'Dashboard', icon:'timeline',  options:[], selected:true },
           { route: '/specialist/census/list', title:'Censo', icon:'query_stats',  options:[], selected:false },
           { route: '/specialist/balance/consulting', title:'Caja de Consultorio', icon:'real_estate_agent',  options:[], selected:false },
           { route: '/specialist/medicalConsultation', title:'Consultorio', icon:'medical_services',  options:[], selected:false },
           { route: '/specialist/medicalConsultation/appointment', title:'Agenda', icon:'event',  options:[], selected:false },
           { route: '/owner/surgeries', title:'Agenda Quirúrgica', icon:'event',  options:[], selected:false },
           { route: '/patient/data', title:'Pacientes', icon:'account_circle',  options:[], selected:false },
         ];
         break;
       }
       case 'owner':{
         array = [
           { route: '/owner/medicalConsultation/dashboard', title:'Dashboard', icon:'timeline',  options:[], selected:true },
           { route: '/owner/census/list', title:'Censo', icon:'query_stats',  options:[], selected:false },
           { route: '/owner/expenseRecord/list', title:'Caja chica', icon:'inbox',  options:[], selected:false },
           { route: '/owner/expense/list', title:'Gastos/Otros ingresos', icon:'inbox',  options:[], selected:false },
           { route: '/owner/pointSale/list', title:'Caja', icon:'monetization_on',  options:[], selected:false },
           { route: '/owner/balance/consulting', title:'Caja de Consultorio', icon:'real_estate_agent',  options:[], selected:false },
           { route: '/owner/doctors-guards', title:'Colaboradores', icon:'people',  options:[], selected:false },
           { route: '/owner/medicalConsultation', title:'Consultorio', icon:'medical_services',  options:[], selected:false },
           { route: '/owner/medicalConsultation/appointment', title:'Agenda', icon:'event',  options:[], selected:false },
           { route: '/owner/surgeries', title:'Agenda Quirúrgica', icon:'event',  options:[], selected:false },
           { route: '/patient/data', title:'Pacientes', icon:'account_circle',  options:[], selected:false },
           { route: '/owner/report', title:'Reporte', icon:'leaderboard',  options:[], selected:false},
           { route: '/owner/reportCie10', title:'Reporte Cie10', icon:'leaderboard',  options:[], selected:false},
           { route: '/owner/slopeTracking/list', title:'Asignaciones', icon:'add_task',  options:[], selected:false},
           { route: '/owner/colaborator/list', title:'Alta de Colaboradores', icon:'people',  options:[], selected:false },
           { route: '/admin/access', title:'Accesos', icon:'account_circle',  options:[], selected:false },
           { route: '/admin/schedule/workingHours', title:'Horarios', icon:'alarm',  options:[], selected:false },
           { route: '/admin/survey/', title:'Encuestas de satisfacción', icon:'description',  options:[], selected:false },
           { route: '/owner/cashingByEmergencies/', title:'Cobros por urgencias', icon:'monetization_on',  options:[], selected:false },
           { route: '/owner/clock/', title:'Reloj checador', icon:'work_history',  options:[], selected:false },

         ];
         break;
       }
       case 'assistant':{
         array = [
           { route: '/assistant/register/list', title:'Dashboard', icon:'timeline',  options:[], selected:true },
           { route: '/assistant/census/list', title:'Censo', icon:'query_stats',  options:[], selected:true },
           { route: '/assistant/medicalAppointment', title:'Citas', icon:'date_range',  options:[], selected:true },
           { route: '/patient/data', title:'Pacientes', icon:'account_circle',  options:[], selected:false },
           { route: '/assistant/surgeries', title:'Agenda Quirúrgica', icon:'event',  options:[], selected:false },
           { route: '/assistant/pointSale', title:'Cobro por urgencia', icon:'monetization_on',  options:[], selected:false }
         ];
         break;
       }
       case 'doctor':{
         array = [
           { route: '/doctor/register/list', title:'Dashboard', icon:'timeline',  options:[], selected:true },
           { route: '/doctor/census/list', title:'Censo', icon:'query_stats',  options:[], selected:true },
           { route: '/owner/medicalConsultation', title:'Consultorio', icon:'medical_services',  options:[], selected:false },
           { route: '/owner/balance/consulting', title:'Caja de Consultorio', icon:'shopping_cart',  options:[], selected:false },
           { route: '/owner/medicalConsultation/appointment', title:'Agenda', icon:'event',  options:[], selected:false },
           { route: '/owner/surgeries', title:'Agenda Quirúrgica', icon:'event',  options:[], selected:false },
           { route: '/patient/data', title:'Pacientes', icon:'account_circle',  options:[], selected:false },
         ];
         break;
       }
       case 'nurse':{
         array = [
           { route: '/nurse/dashboard', title:'Dashboard', icon:'timeline',  options:[], selected:true },
           { route: '/nurse/census/list', title:'Censo', icon:'query_stats',  options:[], selected:true },
           { route: '/nurse/surgeries', title:'Agenda Quirúrgica', icon:'event',  options:[], selected:false },
           { route: '/nurse/pointSale', title:'Cobro por urgencia', icon:'monetization_on',  options:[], selected:false }
         ];
         break;
       }
       default:{
         // no hacemos nada
         array = []
         break;
       }
     }
     return array;
   }

}
