import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.module';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { LoadingService } from '../../directive/loading/loading.service';
import * as moment from 'moment';

@Component({
  selector: 'app-survey-to-answer',
  templateUrl: './survey-to-answer.component.html',
  styleUrls: ['./survey-to-answer.component.css']
})
export class SurveyToAnswerComponent implements OnInit {
  questionnaireModel = {
    main:{
      uuid:'',
      name:'',
      description:'',
      position:''
    },
    system:{
      object:'Company',
      objectUuid:''
    }
  }
  questionnaireAnswerModel = {
    main:{
      uuid:'',
      answer:'',
      answerDate:''
    },
    system:{
      kindQuestion:'',// MultipleOpenQuestion | OpenQuestion
      questionnaireUuid:'',
      user:{
        main:{
          uuid:''
        }
      },
      typeObject:'',//Employee, PhysicalPerson, InitialRegistration
      typeObjectUuid:'',//Identificador del objecto que responde la pregunta
      questionnaire:{
        main:{
          uuid:''
        }
      }

    }
  }
  object = JSON.parse(JSON.stringify(this.questionnaireModel));
  questionsList = [];
  questionnaireAnswerList = [];
  metadata = {
    patient:{
      main:{
        uuid:''
      }
    },
    type:''
  };
  classificationList = [];
  periodicEvaluationAppraisalModel = {
    main:{
      uuid:'',
      perioicEvaluation:'', //dd/MM/yyyy
      close:true,
    },
    system:{
      typeObject:'Patient',
      typeObjectUuid:'',
      questionnaire:{
        main:{
          uuid:''
        }
      }
    }
  }
  periodicEvaluationAppraisalAnswerModel = {
    main:{
      uuid:''
    },
    system:{
      questionnaireAnswer:{
        main:{
          uuid:''
        }
      },
      questionnaireUuid:'',
      periodicEvaluationAppraisal:{
        main:{
          uuid:''
        }
      }
    }
  }

  periodicEvaluationAppraisal = JSON.parse(JSON.stringify(this.periodicEvaluationAppraisalModel));
  constructor(private activatedRoute: ActivatedRoute, public snackBar: MatSnackBar, private session:SessionService, private router: Router, public loading: LoadingService) {}

  async ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['uuid'] != 'new') {
        this.loading.show(true, "Espere un momento.");
        this.object.main.uuid = params['uuid'];
        this.metadata.patient.main.uuid = params['patientUuid'];
        if(params['patientUuid'] == 'anonymous'){
          this.metadata.patient.main.uuid = this.generateUUID();
          this.metadata.type = 'Anonymous';
        }else{
          this.metadata.type = 'Patient';
        }
        // cargamos los datos de la BD
        this.session.getRequest('questionnaire:get',this.object).subscribe(async(data:any)=>{
          try{
            this.object = JSON.parse(JSON.stringify(data.object));
            if(this.object.main.questionnaireType == 'Periodo'){
              await this.getPeriodicEvaluationAppraisal();
            }
            this.findAllByQuestionnaire(this.object).then(async(data:any)=>{
              try{
                if(this.object.main.questionnaireType == 'Periodo'){
                  let assessment:any = await this.getAssament();
                  // verificamos si el total de preguntas es multiplo para saber si esta volviendo a contestar el formulario
                  if(assessment.totalQuestionAnswer % assessment.totalQuestions != 0){
                    this.getAnswers();
                  }
                }else{
                  this.getAnswers();
                }
              }catch(e){
                this.getAnswers();
              };
              // this.questionsList = data.instanceList;
              this.loading.hide();
            }).catch(e=>{
              console.log(e);
              this.loading.hide();
            });
          }catch(e){
            console.log(e);
            this.loading.hide();
          }
        },
        (error)=>{
          this.loading.hide();
          this.snackBar.open(error.message, 'Error', {duration: 5000});
          console.log('Error:survey:get',error)
        })

      }else{
        this.loading.show(true, "Espere un momento.");
        this.loading.hide();
      }
    });
  }

  getAssament(){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("questionnaire:assessmentByQuestionnaireAndTypeObjectAndTypeObjectUuid", { main: { uuid: this.object.main.uuid }, system:{ object: this.metadata.type, objectUuid: this.metadata.patient.main.uuid} }).subscribe((data:any)=>{
        let assessment = data.object;
        resolve(assessment);
      }, error=>{
        console.log("error: questionnaire:assessmentByQuestionnaireAndTypeObjectAndTypeObjectUuid", error);
        reject(error);
      });
    })
  }

  /**funcionalidad para obtener todas la preguntas de un cuestionario.
  */
  findAllByQuestionnaire(object) {
    return new Promise((resolve,reject)=>{
      this.session.getRequest("questionnaire:findAllByQuestion",{system:{questionnaire:{main:{uuid:object.main.uuid}}}}).subscribe(async(data:any)=>{
        //ordenamos por posición las preguntas
        let ordenados = data.object.instanceList.sort((a, b)=> {
          if (a.main.position < b.main.position) {
            return -1;
          }
          if (a.main.position > b.main.position) {
            return 1;
          }
          // a debe ser igual b
          return 0;
        });
        //Buscamos las clasificaciones para construrir el arreglo
        let auxList = ordenados.map((item)=>{
          return item.complement.classification
        });
        //creamos el arreglo de clasficiaciones
        let classificationListAux = auxList.filter((elemento, index) => {
            return auxList.indexOf(elemento) === index;
        });
        // creamos un arreglo de preguntas de la clasficiación correspondiente
        this.classificationList =  classificationListAux.map((item)=>{
          let object = {name:item,questionsList:[]}
          object.questionsList = data.object.instanceList.filter((item2)=>{
            return item2.complement.classification == item;
          });
          return object;
        });
        // this.questionsList = data.object.instanceList;
        for(let i=0; i < data.object.instanceList.length; i++){
          if(data.object.instanceList[i].main.dataType == 'Seleccion' || data.object.instanceList[i].main.dataType == 'Casilla' || data.object.instanceList[i].main.dataType == 'Desplegable'|| data.object.instanceList[i].main.dataType == 'Lineal'){
            await this.getAnswerIfMultipleOpenQuestion(data.object.instanceList[i]);
          }
        }
        resolve({ "transaction": 'ok', "instanceList": data.object.instanceList});
      },error=>{
        console.log("Error: questionnaire:findAllByQuestion",error);
        reject(error);
      })
    })
  }

  /**funcionalidad para obtener obtener multiple answer question de multipleopenquestion
  */
  getAnswerIfMultipleOpenQuestion(object){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("multipleOpenAnswer:findAllByMultipleOpenQuestion",{system:{multipleOpenQuestion:{main:{uuid:object.main.uuid}}}}).subscribe((data:any)=>{
        object.system.multipleOpenAnswerList  = data.object.instanceList;
        resolve({ transaction: "ok", instanceList: data.object.instanceList});
      },error=>{
        reject(error);
        console.log("error:",error);
      })
    });
  }

  /**función para contestar un pregunta
  */
  answerQuestion(object,type){
    console.log(type,object);
    if(object.main.dataType != 'Casilla'){
      let aux = JSON.parse(JSON.stringify(this.questionnaireAnswerModel));
      aux.main.answer = object.system.answer;
      aux.main.answerDate = moment().format('DD-MM-YYYY');
      aux.system.kindQuestion = type;
      aux.system.questionnaireUuid = object.main.uuid;
      aux.system.user.main.uuid = this.session.getUser().main.uuid;
      aux.system.typeObject = this.metadata.type;
      aux.system.typeObjectUuid = this.metadata.patient.main.uuid;
      aux.system.questionnaire.main.uuid = this.object.main.uuid;
      switch(object.main.dataType){
        case 'Cadena':
        case 'Número':
        //no hacemos nada
        break;
        case 'Fecha':
          aux.main.answer = moment(object.system.answer).format('DD-MM-YYYY');
        break;
        case 'Seleccion':
        case 'Lineal':
        case 'Desplegable':
        //no hacemos nada
        break;
        case 'Casilla':
          // no hacemos nada
        break;
      }
      /** verificar si hay una respuesta previa
      */
      for(let item of this.questionnaireAnswerList){
        if(aux.system.questionnaireUuid == item.system.questionnaireUuid){
          aux.main.uuid = item.main.uuid;
        }
      }
      this.saveAnswer(aux);
    }else{
      //
      for(let item of object.system.multipleOpenAnswerList){
        if(item.system.selected){
          let aux = JSON.parse(JSON.stringify(this.questionnaireAnswerModel));
          aux.main.answer = item.main.answer;
          aux.main.answerDate = moment().format('DD-MM-YYYY');
          aux.system.kindQuestion = type;
          aux.system.questionnaireUuid = object.main.uuid;
          aux.system.user.main.uuid = this.session.getUser().main.uuid;
          aux.system.typeObject = this.metadata.type;
          aux.system.questionnaire.main.uuid = this.object.main.uuid;
          /** verificar si hay una respuesta previa
          */
          for(let item of this.questionnaireAnswerList){
            if(aux.main.answer == item.main.answer && aux.system.questionnaireUuid == item.system.questionnaireUuid){
              aux.main.uuid = item.main.uuid;
            }
          }
          this.saveAnswer(aux);
        }else{

        }
      }
    }

  }

  /**Método para Guardar questionnaireAnswer
  */
  saveAnswer(object){
    this.loading.wait();
    object.system.typeObjectUuid = this.metadata.patient.main.uuid;
    this.session.getRequest("questionnaireAnswer:update",object).subscribe((data:any)=>{
      object.main.uuid = data.object.main.uuid;
      if(this.object.main.questionnaireType == 'Periodo'){
        this.createperiodicEvaluationAppraisalAnswer(object);
      }
      this.questionnaireAnswerList.push(object);
      this.loading.hide();
    },error=>{
      console.log("error:questionnaireAnswer:update",error);
      this.loading.hide();
    })
  }

  /**función para obtener las respuestas
  */
  getAnswers(){
    for(let h = 0; h < this.classificationList.length; h++){
      for(let i= 0; i < this.classificationList[h].questionsList.length; i++){
        let aux =  JSON.parse(JSON.stringify(this.questionnaireAnswerModel));
        switch(this.classificationList[h].questionsList[i].main.dataType){
          case 'Cadena':
          case 'Número':
          case 'Fecha':
            aux.system.kindQuestion = 'OpenQuestion';
          break;
          case 'Seleccion':
          case 'Lineal':
          case 'Desplegable':
          case 'Casilla':
            aux.system.kindQuestion = 'MultipleOpenQuestion';
          break;
        }
        aux.system.questionnaireUuid =  this.classificationList[h].questionsList[i].main.uuid;
        aux.system.typeObject = this.metadata.type;
        aux.system.typeObjectUuid = this.metadata.patient.main.uuid;
        this.session.getRequest("questionnaireAnswer:findAllByKindQuestionAndQuestionnaireUuidAndTypeObject",aux).subscribe((data:any)=>{
          if(data.object.instanceList.length > 0){
            for(let item of data.object.instanceList){
              this.questionnaireAnswerList.push(item);
            }
          }
          //realizamos el bindeo de las respuestas
          for(let item of this.questionnaireAnswerList){
            for(let i = 0; i < this.classificationList[h].questionsList.length; i++){
              if(this.classificationList[h].questionsList[i].main.dataType != 'Casilla' && this.classificationList[h].questionsList[i].main.uuid == item.system.questionnaireUuid){
                this.classificationList[h].questionsList[i].system.answer = item.main.answer;
              }else{
                if(this.classificationList[h].questionsList[i].main.dataType == 'Casilla' && this.classificationList[h].questionsList[i].main.uuid == item.system.questionnaireUuid){
                  for(let j=0; j < this.classificationList[h].questionsList[i].system.multipleOpenAnswerList.length; j++){
                    if(this.classificationList[h].questionsList[i].system.multipleOpenAnswerList[j].main.answer == item.main.answer){
                      this.classificationList[h].questionsList[i].system.multipleOpenAnswerList[j].system.selected = true;
                    }
                  }
                }
              }
            }
          }
        },error=>{
          console.log("error:questionnaireAnswer:findAllByKindQuestionAndQuestionnaireUuidAndTypeObject",error);
        })
      }
    }

    console.log("questionnaireAnswerList",this.questionnaireAnswerList);
  }


  /**funcionalidad para obtener un period evaluation
  */
  getPeriodicEvaluationAppraisal(){
    this.periodicEvaluationAppraisal.system.typeObjectUuid = this.metadata.patient.main.uuid;
    this.periodicEvaluationAppraisal.system.questionnaire.main.uuid =this.object.main.uuid;
    return new Promise((resolve,reject)=>{
      this.session.getRequest("periodicEvaluationAppraisal:isOpen",this.periodicEvaluationAppraisal).subscribe((data:any)=>{
        console.log(data)
        if(data.object){
          //ya existe una evaluacion continuamos con esta
          this.periodicEvaluationAppraisal = data.object
          resolve(true);
        }else{
          //Abrimos una evalucación nueva
          this.periodicEvaluationAppraisal.main.perioicEvaluation = moment().format('DD/MM/YYYY');
          this.session.getRequest("periodicEvaluationAppraisal:update",this.periodicEvaluationAppraisal).subscribe((data:any)=>{
            this.periodicEvaluationAppraisal = data.object
            resolve(true);
          },error=>{
            reject(error);
          })
        }
      },error=>{
        reject(error);
      })
    });
  }


  /***funcionalidad para crear un periodicEvaluationAppraisalAnswer
  */
  createperiodicEvaluationAppraisalAnswer(object){
    let aux = JSON.parse(JSON.stringify(this.periodicEvaluationAppraisalAnswerModel));
    aux.system.questionnaireAnswer.main.uuid = object.main.uuid;
    aux.system.kindQuestion = object.system.kindQuestion;
    aux.system.questionnaireUuid = object.system.questionnaireUuid;
    aux.system.periodicEvaluationAppraisal.main.uuid = this.periodicEvaluationAppraisal.main.uuid;
    this.session.getRequest("periodicEvaluationAppraisalAnswer:update",aux).subscribe((data:any)=>{
      console.log("periodicEvaluationAppraisalAnswer:update::::",data.object);
    },error=>{
      console.log(error);
    })
  }


  /**funcionalidad para cerrar
  */
  close(){
    if(this.object.main.questionnaireType == 'Periodo'){
      this.session.getRequest("periodicEvaluationAppraisal:close",this.periodicEvaluationAppraisal).subscribe((data:any)=>{
        this.periodicEvaluationAppraisal = data.object
      },error=>{
      })
    }

  }

   generateUUID() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }
}
