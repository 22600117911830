
/**Arreglo que contiene todos los endpoints para acceso a al backend.
* *Code:
* @param code código interno para el manejo en frontEnd
* @param endpoint  endpoint para pedir al backEnd
* *
*/
let urls: Array<{ code: string, endpoint: string }> = [
  {
    code: "api:login",
    endpoint: "api/login"
  }, {
    code: "user:sendCode",
    endpoint: "user/sendCode"
  }, {
    code: "session:create",
    endpoint: "validate/validate"
  }, {
    code: "test:get",
    endpoint: "get"
  }, {
    code: "productFamily:update",
    endpoint: "productFamily/update"
  }, {
    code: "productFamily:listByCompany",
    endpoint: "productFamily/listByCompany"
  }, {
    code: "productFamily:get",
    endpoint: "productFamily/get"
  }, {
    code: "characteristic:update",
    endpoint: "characteristic/update"
  }, {
    code: "characteristic:get",
    endpoint: "characteristic/get"
  }, {
    code: "characteristic:findAllByCompanyAndStatus",
    endpoint: "characteristic/findAllByCompanyAndStatus"
  }, {
    code: "characteristic:findAllByCompany",
    endpoint: "characteristic/listByCompany"
  }, {
    code: "maker:update",
    endpoint: "maker/update"
  }, {
    code: "maker:list",
    endpoint: "maker/list"
  }, {
    code: "maker:getAll",
    endpoint: "maker/getAll"
  }, {
    code: "maker:get",
    endpoint: "maker/get"
  }, {
    code: "maker:delete",
    endpoint: "maker/delete"
  }, {
    code: "transientdata:update",
    endpoint: "transientData/update"
  }, {
    code: "transientdata:get",
    endpoint: "transientData/get"
  }, {
    code: "transientdata:findAllByCompany",
    endpoint: "transientData/listByCompany"
  }, {
    code: "transientdata:findAllByCompanyAndStatus",
    endpoint: "transientData/findAllByCompanyAndStatus"
  }, {
    code: "unitMeasure:update",
    endpoint: "unitMeasure/update"
  }, {
    code: "unitMeasure:get",
    endpoint: "unitMeasure/get"
  }, {
    code: "unitMeasure:findAllByStatus",
    endpoint: "unitMeasure/findAllByStatus"
  }, {
    code: "unitMeasure:list",
    endpoint: "unitMeasure/list"
  }, {
    code: "kindproduct:update",
    endpoint: "kindProduct/update"
  }, {
    code: "kindproduct:get",
    endpoint: "kindProduct/get"
  }, {
    code: "kindproduct:findAllByCompanyAndStatus",
    endpoint: "kindProduct/findAllByCompanyAndStatus"
  }, {
    code: "kindProduct:findAllByCompanyAndSaleAndStatus",
    endpoint: "kindProduct/findAllByCompanyAndSaleAndStatus"
  }, {
    code: "kindproduct:findAllByCompany",
    endpoint: "kindProduct/listByCompany"
  }, {
    code: "product:listByCompanyAndKindProducts",
    endpoint: "product/listByCompanyAndKindProducts"
  }, {
    code: "product:update",
    endpoint: "product/update"
  }, {
    code: "product:get",
    endpoint: "product/get"
  }, {
    code: "productImage:update",
    endpoint: "productImage/update"
  }, {
    code: "productImage:findAllByProductAndStatus",
    endpoint: "productImage/findAllByProductAndStatus"
  }, {
    code: "productImage:get",
    endpoint: "productImage/get"
  }, {
    code: "productImage:delete",
    endpoint: "productImage/delete"
  }, {
    code: "product:findAllByCompany",
    endpoint: "product/listByCompany"
  }, {
    code: "warehouseProduct:findAllByWarehouseAndProductFamily",
    endpoint: "warehouseProduct/findAllByWarehouseAndProductFamily"
  }, {
    code: "warehouse:findAllByCompanyAndToSellAndStatus",
    endpoint: "warehouse/findAllByCompanyAndToSellAndStatus"
  }, {
    code: "productCharacteristic:update",
    endpoint: "productCharacteristic/create"
  }, {
    code: "productCharacteristic:findAllByProduct",
    endpoint: "productCharacteristic/listByProduct"
  }, {
    code: "productCharacteristic:delete",
    endpoint: "productCharacteristic/delete"
  }, {
    code: "productCharacteristicValue:create",
    endpoint: "productCharacteristicValue/create"
  }, {
    code: "productCharacteristicValue:listByProduct",
    endpoint: "productCharacteristicValue/listByProduct"
  }, {
    code: "productCharacteristicValue:findAllByProduct",
    endpoint: "productCharacteristicValue/findAllByProduct"
  }, {
    code: "productCharacteristicValue:delete",
    endpoint: "productCharacteristicValue/delete"
  }, {
    code: "productCharacteristicValue:update",
    endpoint: "productCharacteristicValue/update"
  }, {
    code: "productTransientData:update",
    endpoint: "productTransientData/create"
  }, {
    code: "productTransientData:findAllByProduct",
    endpoint: "productTransientData/listByProduct"
  }, {
    code: "productTransientData:delete",
    endpoint: "productTransientData/delete"
  }, {
    code: "productTransientDataValue:create",
    endpoint: "productTransientDataValue/create"
  }, {
    code: "productTransientDataValue:findAllByProduct",
    endpoint: "productTransientDataValue/listByProduct"
  }, {
    code: "productTransientDataValue:delete",
    endpoint: "productTransientDataValue/delete"
  }, {
    code: "productTransientDataValue:update",
    endpoint: "productTransientDataValue/update"
  }, {
    code: "productCompatibility:findAllByProduct",
    endpoint: "productCompatibility/listByProduct"
  }, {
    code: "productCompatibility:update",
    endpoint: "productCompatibility/create"
  }, {
    code: "productCompatibility:delete",
    endpoint: "productCompatibility/delete"
  }, {
    code: "productComposition:findAllByProduct",
    endpoint: "productComposition/listByProduct"
  }, {
    code: "productComposition:delete",
    endpoint: "productComposition/delete"
  }, {
    code: "productComposition:create",
    endpoint: "productComposition/create"
  }, {
    code: "productComposition:update",
    endpoint: "productComposition/update"
  }, {
    code: "productFamily:importProductFamily",
    endpoint: "productFamily/importProductFamily"
  }, {
    code: "productFamily:importProductFamilySave",
    endpoint: "productFamily/importSave"
  }, {
    code: "product:importProduct",
    endpoint: "product/importProduct"
  }, {
    code: "product:importProductSave",
    endpoint: "product/importSave"
  }, {
    code: "roomOccupationDoctor:moneyByDateBetweenAndPay",
    endpoint: "roomOccupationDoctor/moneyByDateBetweenAndPay"
  }, {
    code: "roomOccupationDoctor:findAllByDateBetween",
    endpoint: "roomOccupationDoctor/findAllByDateBetween"
  }, {
    code: "roomOccupationDoctor:findAllByDoctorAndDateBetween",
    endpoint: "roomOccupationDoctor/findAllByDoctorAndDateBetween"
  }, {
    code: "bank:list",
    endpoint: "bank/list"
  }, {
    code: "bank:update",
    endpoint: "bank/update"
  }, {
    code: "kindProduct:findAllByCompanyAndCompositionAndStatus",
    endpoint: "kindProduct/findAllByCompanyAndCompositionAndStatus"
  }, {
    code: "kindProduct:findAllByCompanyAndCompatibilityAndStatus",
    endpoint: "kindProduct/findAllByCompanyAndCompatibilityAndStatus"
  }, {
    code: "product:findAllByKindProduct",
    endpoint: "product/findAllByKindProduct"
  }, {
    code: "productFamily:treeviewByCompanyAndStatus",
    endpoint: "productFamily/treeviewByCompanyAndStatus"
  }, {
    code: "warehouse:update",
    endpoint: "warehouse/update"
  }, {
    code: "warehouse:get",
    endpoint: "warehouse/get"
  }, {
    code: "warehouse:findAllByCompany",
    endpoint: "warehouse/findAllByCompany"
  }, {
    code: "warehouse:findAllByCompanyAndStatus",
    endpoint: "warehouse/findAllByCompanyAndstatus"
  }, {
    code: "adjustmentType:findAllByCompany",
    endpoint: "adjustmentType/list"
  }, {
    code: "adjustmentType:findAllByCompanyAndStatus",
    endpoint: "adjustmentType/findAllByCompanyAndStatus"
  }, {
    code: "adjustmentType:update",
    endpoint: "adjustmentType/update"
  }, {
    code: "adjustmentType:get",
    endpoint: "adjustmentType/get"
  }, {
    code: "floor:findAllByCompany",
    endpoint: "floor/findAllByCompany"
  }, {
    code: "floor:update",
    endpoint: "floor/update"
  }, {
    code: "floor:get",
    endpoint: "floor/get"
  }, {
    code: "room:findAllByFloor",
    endpoint: "room/findAllByFloor"
  }, {
    code: "roomOccupation:findAllByFloorWithWarehouse",
    endpoint: "roomOccupation/findAllByFloorWithWarehouse"
  }, {
    code: "roomOccupation:findAllByFloorAndNotRoomOccupationAndNotWarehouse",
    endpoint: "roomOccupation/findAllByFloorAndNotRoomOccupationAndNotWarehouse"
  }, {
    code: "room:update",
    endpoint: "room/update"
  }, {
    code: "room:get",
    endpoint: "room/get"
  }, {
    code: "collaborator:update",
    endpoint: "collaborator/update"
  }, {
    code: "collaborator:get",
    endpoint: "collaborator/get"
  }, {
    code: "collaborator:findAllByCompany",
    endpoint: "collaborator/findAllByCompany"
  }, {
    code: "collaborator:findAllByCompanyAndRoleAndStatus",
    endpoint: "collaborator/findAllByCompanyAndRoleAndStatus"
  }, {
    code: "adjustment:list",
    endpoint: "adjustment/list"
  }, {
    code: "collaborator:findAllByCompany",
    endpoint: "collaborator/findAllByCompany"
  }, {
    code: "adjustment:update",
    endpoint: "adjustment/update"
  }, {
    code: "adjustment:get",
    endpoint: "adjustment/get"
  }, {
    code: "productTransientData:listByProduct",
    endpoint: "productTransientData/listByProduct"
  }, {
    code: "adjustment:validateAdjustment",
    endpoint: "adjustment/validateAdjustment"
  }, {
    code: "warehouseProduct:findAllProductByWarehouse",
    endpoint: "warehouseProduct/findAllProductByWarehouse"
  }, {
    code: "warehouseProduct:findByProductByWarehouse",
    endpoint: "warehouseProduct/findByProductByWarehouse"
  }, {
    code: "warehouseProduct:stockForWarehouseAndProductDetail",
    endpoint: "warehouseProduct/stockForWarehouseAndProductDetail"
  }, {
    code: "productPrice:findAllByProduct",
    endpoint: "productPrice/findAllByProduct"
  }, {
    code: "productPrice:update",
    endpoint: "productPrice/update"
  }, {
    code: "productPrice:delete",
    endpoint: "productPrice/delete"
  }, {
    code: "productCharacteristicValue:findAllByProduct",
    endpoint: "productCharacteristicValue/findAllByProduct"
  }, {
    code: "patient:findAllByCompany",
    endpoint: "patient/findAllByCompany"
  }, {
    code: "patient:get",
    endpoint: "patient/get"
  }, {
    code: "patient:update",
    endpoint: "patient/update"
  }, {
    code: "medicalNotes:findAllByPatient",
    endpoint: "medicalNotes/findAllByPatient"
  }, {
    code: "medicalNotes:update",
    endpoint: "medicalNotes/update"
  }, {
    code: "medicalNotes:last",
    endpoint: "medicalNotes/last"
  }, {
    code: "role:findAllByObjectTypeAndObjectId",
    endpoint: "role/findAllByObjectTypeAndObjectId"
  }, {
    code: "role:update",
    endpoint: "role/update"
  }, {
    code: "action:list",
    endpoint: "action/list"
  }, {
    code: "role:findAllByAction",
    endpoint: "role/findAllByAction"
  }, {
    code: "role:createRoleAction",
    endpoint: "role/createRoleAction"
  }, {
    code: "action:update",
    endpoint: "action/update"
  }, {
    code: "role:refreshRoles",
    endpoint: "role/refreshRoles"
  }, {
    code: "role:deleteRoleAction",
    endpoint: "role/deleteRoleAction"
  }, {
    code: "user:update",
    endpoint: "user/update"
  }, {
    code: "user:get",
    endpoint: "user/get"
  }, {
    code: "user:list",
    endpoint: "user/list"
  }, {
    code: "user:findAllRoleByUser",
    endpoint: "user/findAllRoleByUser"
  }, {
    code: "role:findAllByObjectTypeAndObjectId",
    endpoint: "role/findAllByObjectTypeAndObjectId"
  }, {
    code: "validate:userObjects",
    endpoint: "validate/userObjects"
  }, {
    code: "validate:findByUsername",
    endpoint: "validate/findByUsername"
  }, {
    code: "user:updateUserObject",
    endpoint: "user/updateUserObject"
  }, {
    code: "user:deleteByRolAndObjects",
    endpoint: "user/deleteByRolAndObjects"
  }, {
    code: "floor:findAllByCompany",
    endpoint: "floor/findAllByCompany"
  }, {
    code: "room:findAllByFloor",
    endpoint: "room/findAllByFloor"
  }, {
    code: "roomOccupation:countByDoctorAndDateRegisterBetween",
    endpoint: "roomOccupation/countByDoctorAndDateRegisterBetween"
  }, {
    code: "roomOccupation:findAllByRoom",
    endpoint: "roomOccupation/findAllByRoom"
  }, {
    code: "roomOccupation:findAllByFloor",
    endpoint: "roomOccupation/findAllByFloor"
  }, {
    code: "roomOccupation:findByRoomAndStatusNotEqual",
    endpoint: "roomOccupation/findByRoomAndStatusNotEqual"
  }, {
    code: "collaborator:findAllByCompanyAndRole",
    endpoint: "collaborator/findAllByCompanyAndRole"
  }, {
    code: "roomOccupation:update",
    endpoint: "roomOccupation/update"
  }, {
    code: "roomOccupationDoctor:update",
    endpoint: "roomOccupationDoctor/update"
  }, {
    code: "roomOccupationDoctor:updatePay",
    endpoint: "roomOccupationDoctor/updatePay"
  }, {
    code: "roomOccupationDoctor:get",
    endpoint: "roomOccupationDoctor/get"
  }, {
    code: "roomOccupation:updateCharge",
    endpoint: "roomOccupation/updateCharge"
  }, {
    code: "feedPaymentAnnotation:update",
    endpoint: "feedPaymentAnnotation/update"
  }, {
    code: "feedPayment:update",
    endpoint: "feedPayment/update"
  }, {
    code: "feedPayment:delete",
    endpoint: "feedPayment/delete"
  }, {
    code: "feedPayment:findAllBySales",
    endpoint: "feedPayment/findAllBySales"
  }, {
    code: "feedPayment:get",
    endpoint: "feedPayment/get"
  }, {
    code: "product:listByCompanyAndProductFamily",
    endpoint: "product/listByCompanyAndProductFamily"
  }, {
    code: "productPrice:importXlsx",
    endpoint: "productPrice/importXlsx"
  }, {
    code: "productPrice:createToLayout",
    endpoint: "productPrice/createToLayout"
  }, {
    code: "cie10:findAllByDescriptionLike",
    endpoint: "cie10/findAllByDescriptionLike"
  }, {
    code: "saleReport:simple",
    endpoint: "saleReport/simple"
  }, {
    code: "saleReport:detail",
    endpoint: "saleReport/detail"
  }, {
    code: "serviceCatalog:findAllByCompany",
    endpoint: "serviceCatalog/findAllByCompany"
  }, {
    code: "sales:updateCharge",
    endpoint: "sales/updateCharge"
  }, {
    code: "sales:updateReceive",
    endpoint: "sales/updateReceive"
  }, {
    code: "sales:close",
    endpoint: "sales/close"
  }, {
    code: "salesPayment:getPaymentTotalBySales",
    endpoint: "salesPayment/getPaymentTotalBySales"
  }, {
    code: "serviceCatalog:get",
    endpoint: "serviceCatalog/get"
  }, {
    code: "serviceCatalog:update",
    endpoint: "serviceCatalog/update"
  }, {
    code: "roomServiceCatalog:findAllByRoom",
    endpoint: "roomServiceCatalog/findAllByRoom"
  }, {
    code: "roomServiceCatalog:update",
    endpoint: "roomServiceCatalog/update"
  }, {
    code: "roomServiceCatalog:delete",
    endpoint: "roomServiceCatalog/delete"
  }, {
    code: "productPrice:findAllByProduct",
    endpoint: "productPrice/findAllByProduct"
  }, {
    code: "productCost:update",
    endpoint: "productCost/update"
  }, {
    code: "roomOccupationRegister:update",
    endpoint: "roomOccupationRegister/update"
  }, {
    code: "productCost:findByProduct",
    endpoint: "productCost/findByProduct"
  }, {
    code: "warehouseProduct:getProductFamilyByWarehouse",
    endpoint: "warehouseProduct/getProductFamilyByWarehouse"
  }, {
    code: "roomOccupationRegister:findByRoomOccupationAndUserAndDate",
    endpoint: "roomOccupationRegister/findByRoomOccupationAndUserAndDate"
  }, {
    code: "roomOccupation:get",
    endpoint: "roomOccupation/get"
  }, {
    code: "roomOccupationRegister:get",
    endpoint: "roomOccupationRegister/get"
  }, {
    code: "roomOccupation:updateProduct",
    endpoint: "roomOccupation/updateProduct"
  }, {
    code: "sales:findAllByPatientAndStatus",
    endpoint: "sales/findAllByPatientAndStatus"
  }, {
    code: "sales:get",
    endpoint: "sales/get"
  }, {
    code: "salesPayment:update",
    endpoint: "salesPayment/update"
  }, {
    code: "roomOccupationOtherChange:findAllByRoomOccupation",
    endpoint: "roomOccupationOtherChange/findAllByRoomOccupation"
  }, {
    code: "transfer:update",
    endpoint: "transfer/update"
  }, {
    code: "transfer:list",
    endpoint: "transfer/list"
  }, {
    code: "sales:findAllByCompanyAndStatus",
    endpoint: "sales/findAllByCompanyAndStatus"
  }, {
    code: "sales:listByCompanyAndStatus",
    endpoint: "sales/listByCompanyAndStatus"
  }, {
    code: "dashboard:findAllByTypeOccupationAndDateBetween",
    endpoint: "dashboard/findAllByTypeOccupationAndDateBetween"
  }, {
    code: "dashboard:findAllByCollaboratorSpecialistAndDateBetween",
    endpoint: "dashboard/findAllByCollaboratorSpecialistAndDateBetween"
  }, {
    code: "dashboard:findAllByTypeOccupationAndCollaboratorSpecialistAndDateBetween",
    endpoint: "dashboard/findAllByTypeOccupationAndCollaboratorSpecialistAndDateBetween"
  }, {
    code: "dashboard:findAllByRoleAndDateBetween",
    endpoint: "dashboard/findAllByRoleAndDateBetween"
  }, {
    code: "dashboard:findAllMoneyByCompanyAndRoleAndDateBetween",
    endpoint: "dashboard/findAllMoneyByCompanyAndRoleAndDateBetween"
  }, {
    code: "dashboard:totalSalesByCompanyAndRoleAndDateBetween",
    endpoint: "dashboard/totalSalesByCompanyAndRoleAndDateBetween"
  }, {
    code: "dashboard:totalOtherIncomeByCompanyAndDateBetween",
    endpoint: "dashboard/totalOtherIncomeByCompanyAndDateBetween"
  }, {
    code: "dashboard:diagnosisByCompanyAndRoleAndDateBetween",
    endpoint: "dashboard/diagnosisByCompanyAndRoleAndDateBetween"
  }, {
    code: "transfer:get",
    endpoint: "transfer/get"
  }, {
    code: "otherCharge:update",
    endpoint: "otherCharge/update"
  }, {
    code: "otherCharge:get",
    endpoint: "otherCharge/get"
  }, {
    code: "otherCharge:delete",
    endpoint: "otherCharge/delete"
  }, {
    code: "changeCharge:tax",
    endpoint: "changeCharge/tax"
  }, {
    code: "transfer:validateTransfer",
    endpoint: "transfer/validateTransfer"
  },{
    code: "expenseClassification:update",
    endpoint: "expenseClassification/update"
  },{
    code: "expenseClassification:get",
    endpoint: "expenseClassification/get"
  },{
    code: "expenseClassification:list",
    endpoint: "expenseClassification/list"
  },{
    code: "expenseClassification:findAllByCompany",
    endpoint: "expenseClassification/findAllByCompany"
  },{
    code: "otherIncomeClassification:update",
    endpoint: "otherIncomeClassification/update"
  },{
    code: "otherIncomeClassification:get",
    endpoint: "otherIncomeClassification/get"
  },{
    code: "otherIncomeClassification:list",
    endpoint: "otherIncomeClassification/list"
  },{
    code: "otherIncomeClassification:findAllByCompany",
    endpoint: "otherIncomeClassification/findAllByCompany"
  },{
    code: "provider:findAllByCompany",
    endpoint: "provider/findAllByCompany"
  },{
    code: "spending:update",
    endpoint: "spending/update"
  },{
    code: "spending:moneyByCompanyAndBetweenDate",
    endpoint: "spending/moneyByCompanyAndBetweenDate"
  },{
    code: "spendingGeneral:moneyByCompanyAndBetweenDate",
    endpoint: "spendingGeneral/moneyByCompanyAndBetweenDate"
  },{
    code: "spendingGeneral:update",
    endpoint: "spendingGeneral/update"
  },{
    code: "spendingGeneral:get",
    endpoint: "spendingGeneral/get"
  },{
    code: "spendingGeneral:findByExpenseClassificationAndDateBetween",
    endpoint: "spendingGeneral/findByExpenseClassificationAndDateBetween"
  },{
    code: "spending:findByExpenseClassificationAndDateBetween",
    endpoint: "spending/findByExpenseClassificationAndDateBetween"
  },{
    code: "otherIncome:moneyByCompanyAndBetweenDate",
    endpoint: "otherIncome/moneyByCompanyAndBetweenDate"
  },{
    code: "otherIncome:update",
    endpoint: "otherIncome/update"
  },{
    code: "otherIncome:get",
    endpoint: "otherIncome/get"
  },{
    code: "otherIncome:findByOtherIncomeClassificationAndDateBetween",
    endpoint: "otherIncome/findByOtherIncomeClassificationAndDateBetween"
  },{
    code: "otherIncome:list",
    endpoint: "otherIncome/list"
  },{
    code: "spending:list",
    endpoint: "spending/list"
  },{
    code: "spendingGeneral:list",
    endpoint: "spendingGeneral/list"
  },{
    code: "spending:findAllByCompany",
    endpoint: "spending/findAllByCompany"
  },{
    code: "expenseRecord:list",
    endpoint: "expenseRecord/list"
  },{
    code: "collaborator:findByUser",
    endpoint: "collaborator/findByUser"
  },{
    code: "pettyCash:findByCompanyAndOpen",
    endpoint: "pettyCash/findByCompanyAndOpen"
  },{
    code: "pettyCash:findAllByCompanyAndOpen",
    endpoint: "pettyCash/findAllByCompanyAndOpen"
  },{
    code: "pettyCash:findAllByCompany",
    endpoint: "pettyCash/findAllByCompany"
  },{
    code: "pettyCash:update",
    endpoint: "pettyCash/update"
  },{
    code: "pettyCash:get",
    endpoint: "pettyCash/get"
  },{
    code: "spending:get",
    endpoint: "spending/get"
  },{
    code: "spending:findAllByPettyCash",
    endpoint: "spending/findAllByPettyCash"
  },{
    code: "consultingRoom:update",
    endpoint: "consultingRoom/update"
  },{
    code: "consultingRoom:get",
    endpoint: "consultingRoom/get"
  },{
    code: "consultingRoom:findAllByDoctorAndDateTimeAppointmentBetween",
    endpoint: "consultingRoom/findAllByDoctorAndDateTimeAppointmentBetween"
  },{
    code: "consultingRoom:findAllByUserAndDateTimeAppointmentBetween",
    endpoint: "consultingRoom/findAllByUserAndDateTimeAppointmentBetween"
  },{
    code: "consultingRoom:summaryByDoctorAndStatusAndDateTimeAppointmentBetween",
    endpoint: "consultingRoom/summaryByDoctorAndStatusAndDateTimeAppointmentBetween"
  },{
    code: "consultingRoom:countByDoctorAndStatusAndTypeAppointmentAndDateTimeAppointmentBetween",
    endpoint: "consultingRoom/countByDoctorAndStatusAndTypeAppointmentAndDateTimeAppointmentBetween"
  },{
    code: "consultingRoom:countByDoctorAndStatusAndDateTimeAppointmentBetween",
    endpoint: "consultingRoom/countByDoctorAndStatusAndDateTimeAppointmentBetween"
  },{
    code: "consultingRoom:countByDoctorAndTypeOccupationAndDateBetween",
    endpoint: "consultingRoom/countByDoctorAndTypeOccupationAndDateBetween"
  },{
    code: "fileAttachment:upload",
    endpoint: "attachmentFile/upload"
  },{
    code: "fileAttachment:download",
    endpoint: "attachmentFile/download"
  },{
    code: "fileAttachment:findAllByMedicalNotes",
    endpoint: "attachmentFile/findAllByMedicalNotes"
  },{
    code: "attachmentFile:download",
    endpoint: "attachmentFile/download"
  },  {
    code: "attachmentFile:upload",
    endpoint: "attachmentFile/upload"
  },{
    code: "attachmentFile:findAllByMedicalNotes",
    endpoint: "attachmentFile/findAllByMedicalNotes"
  },{
    code: "attachmentFile:delete",
    endpoint:"attachmentFile/delete"
  },{
    code: "vitalSigns:findAllByPatientAndConsultingRoom",
    endpoint: "vitalSigns/findAllByPatientAndConsultingRoom"
  },{
    code: "vitalSigns:update",
    endpoint: "vitalSigns/update"
  },{
    code: "vitalSigns:findAllByPatientAndMedicalNotes",
    endpoint: "vitalSigns/findAllByPatientAndMedicalNotes"
  },{
    code: "officeExpense:findAllByDoctorAndDateExpense",
    endpoint: "officeExpense/findAllByDoctorAndDateExpense"
  },{
    code: "officeExpense:summaryByDoctorAndDateExpenditure",
    endpoint: "officeExpense/summaryByDoctorAndDateExpenditure"
  },{
    code: "officeExpense:delete",
    endpoint: "officeExpense/delete"
  },{
    code: "officeExpense:update",
    endpoint: "officeExpense/update"
  },{
    code: "officeExpense:summaryByDoctorAndDateExpense",
    endpoint: "officeExpense/summaryByDoctorAndDateExpense"
  },{
    code: "officeOtherIncome:findAllByDoctorAndDateExpenditure",
    endpoint: "officeOtherIncome/findAllByDoctorAndDateExpenditure"
  },{
    code: "officeOtherIncome:summaryByDoctorAndDateExpenditure",
    endpoint: "officeOtherIncome/summaryByDoctorAndDateExpenditure"
  },{
    code: "officeOtherIncome:delete",
    endpoint: "officeOtherIncome/delete"
  },{
    code: "officeOtherIncome:update",
    endpoint: "officeOtherIncome/update"
  },{
    code: "officeOtherIncome:summaryByDoctorAndDateExpenditure",
    endpoint: "officeOtherIncome/summaryByDoctorAndDateExpenditure"
  },{
    code: "medicalNotesCIE10:findAllByMedicalNotes",
    endpoint: "medicalNotesCIE10/findAllByMedicalNotes"
  },{
    code: "medicalNotesCIE10:countByDoctorAndDateMedicalBetween",
    endpoint: "medicalNotesCIE10/countByDoctorAndDateMedicalBetween"
  },{
    code: "medicalNotesCIE10:countByDoctorAndDateMedicalBetweenAndTypeNote",
    endpoint: "medicalNotesCIE10/countByDoctorAndDateMedicalBetweenAndTypeNote"
  },{
    code: "medicalNotesCIE10:update",
    endpoint: "medicalNotesCIE10/update"
  },{
    code: "medicalNotesCIE10:delete",
    endpoint: "medicalNotesCIE10/delete"
  },{
    code: "reportMedicalNotes:download",
    endpoint: "reportMedicalNotes/download"
  },{
    code: "consultingRoom:findAllByTypeAppointmentAndDateTimeAppointmentBetween",
    endpoint: "consultingRoom/findAllByTypeAppointmentAndDateTimeAppointmentBetween"
  },{
    code: "consultingRoom:findAllByStatusOrTypeOccupationAndDateBetween",
    endpoint: "consultingRoom/findAllByStatusOrTypeOccupationAndDateBetween"
  },{
    code: "room:findAllByAssignCalendar",
    endpoint: "room/findAllByAssignCalendar"
  },{
    code: "surgery:findAllByDateTimeSurgeryBetween",
    endpoint: "surgery/findAllByDateTimeSurgeryBetween"
  },{
    code: "surgery:update",
    endpoint: "surgery/update"
  },{
    code: "surgery:get",
    endpoint: "surgery/get"
  },{
    code: "surgery:delete",
    endpoint: "surgery/delete"
  },{
    code: "guardPay:findAllByCompanyAndDateBetween",
    endpoint: "guardPay/findAllByCompanyAndDateBetween"
  },{
    code: "surgery:findAllByRoomAndDateTimeSurgeryBetween",
    endpoint: "surgery/findAllByRoomAndDateTimeSurgeryBetween"
  },{
    code: "validate:userPasswdUpdate",
    endpoint: "validate/userPasswdUpdate"
  },{
    code: "validate:userPasswdChange",
    endpoint: "validate/userPasswdChange"
  },{
    code: "scheduleDoctor:update",
    endpoint: "scheduleDoctor/update"
  },{
    code: "scheduleDoctor:findByConsultingRoom",
    endpoint: "scheduleDoctor/findByConsultingRoom"
  },{
    code: "slopeTracking:findAllByDoctorAndStatus",
    endpoint: "slopeTracking/findAllByDoctorAndStatus"
  },{
    code: "slopeTracking:update",
    endpoint: "slopeTracking/update"
  },{
    code: "slopeTracking:get",
    endpoint: "slopeTracking/get"
  },{
    code: "slopeTracking:findAllByCollaborator",
    endpoint: "slopeTracking/findAllByCollaborator"
  },{
    code: "slopeTracking:findAllByCollaboratorAndStatus",
    endpoint: "slopeTracking/findAllByCollaboratorAndStatus"
  },{
    code: "earringAnnotation:findAllBySlopeTracking",
    endpoint: "earringAnnotation/findAllBySlopeTracking"
  },{
    code: "earringAnnotation:findAllByCollaborator",
    endpoint: "earringAnnotation/findAllByCollaborator"
  },{
    code: "earringAnnotation:update",
    endpoint: "earringAnnotation/update"
  },{
    code: "earringAnnotation:get",
    endpoint: "earringAnnotation/get"
  },{
    code: "feedback:findAllBySlopeTracking",
    endpoint: "feedback/findAllBySlopeTracking"
  },{
    code: "questionnaire:findByObjectAndObjectUuid",
    endpoint: "questionnaire/findAllByObjectAndObjectUuid"
  },{
    code: "questionnaire:get",
    endpoint: "questionnaire/get"
  },{
    code: "questionnaire:update",
    endpoint: "questionnaire/update"
  },{
    code: "openQuestion:update",
    endpoint: "openQuestion/update"
  },{
    code: "multipleOpenQuestion:update",
    endpoint: "multipleOpenQuestion/update"
  },{
    code: "multipleOpenAnswer:update",
    endpoint: "multipleOpenAnswer/update"
  },{
    code: "multipleOpenAnswer:delete",
    endpoint: "multipleOpenAnswer/delete"
  },{
    code: "questionnaire:findAllByQuestion",
    endpoint: "questionnaire/findAllByQuestion"
  },{
    code: "multipleOpenAnswer:findAllByMultipleOpenQuestion",
    endpoint: "multipleOpenAnswer/findAllByMultipleOpenQuestion"
  },{
    code: "sales:listByCompanyAndFeedPaymentAnnotation",
    endpoint: "sales/listByCompanyAndFeedPaymentAnnotation"
  },{
    code: "feedPaymentAnnotation:findBySale",
    endpoint: "feedPaymentAnnotation/findBySale"
  },{
    code:"questionnaireAnswer:findAllByKindQuestionAndQuestionnaireUuidAndTypeObject",
    endpoint:"questionnaireAnswer/findAllByKindQuestionAndQuestionnaireUuidAndTypeObject"
  },{
    code: "questionnaireAnswer:update",
    endpoint: "questionnaireAnswer/update"
  },{
    code: "sales:findAllByCompanyAndStatusAndPeriod",
    endpoint: "sales/findAllByCompanyAndStatusAndPeriod"
  },{
    code: "roomOccupation:updateDay",
    endpoint: "roomOccupation/updateDay"
  },{
    code: "workingHours:delete",
    endpoint: "workingHours/delete"
  },{
    code: "workingHours:findAllByCompany",
    endpoint: "workingHours/findAllByCompany"
  },{
    code: "workingHours:get",
    endpoint: "workingHours/get"
  },{
    code: "workingHours:deleteDetail",
    endpoint: "workingHours/deleteDetail"
  },{
    code: "workingHours:update",
    endpoint: "workingHours/update"
  },{
    code: "typePunishment:get",
    endpoint: "typePunishment/get"
  },{
    code: "typePunishment:update",
    endpoint: "typePunishment/update"
  },{
    code: "typePunishment:findAllByCompany",
    endpoint: "typePunishment/findAllByCompany"
  },{
    code: "attendanceRules:findAllByCompany",
    endpoint: "attendanceRules/findAllByCompany"
  },{
    code: "attendanceRules:get",
    endpoint: "attendanceRules/get"
  },{
    code: "workingHours:findAllByCompanyAndStatus",
    endpoint: "workingHours/findAllByCompanyAndStatus"
  },{
    code: "typePunishment:findAllByCompanyAndStatus",
    endpoint: "typePunishment/findAllByCompanyAndStatus"
  },{
    code: "attendanceRules:update",
    endpoint: "attendanceRules/update"
  },{
    code: "incidentManagement:findAllByCompanyAndBeatweenDate",
    endpoint: "incidentManagement/findAllByCompanyAndBeatweenDate"
  },{
    code: "typePunishment:findAllByCompanyAndStatus",
    endpoint: "typePunishment/findAllByCompanyAndStatus"
  },{
    code: "dependentMethods:createToReportIncidences",
    endpoint: "dependentMethods/createToReportIncidences"
  },{
    code: "checkEntryExitWork:downloadToReport",
    endpoint: "checkEntryExitWork/downloadToReport"
  },{
    code: "checkEntryExitWork:createToIncidence",
    endpoint: "checkEntryExitWork/createToIncidence"
  },{
    code: "checkEntryExitWork:createToIncidenceBeforeDelete",
    endpoint: "checkEntryExitWork/createToIncidenceBeforeDelete"
  },{
    code: "checkEntryExitWork:deleteByDateRange",
    endpoint: "checkEntryExitWork/deleteByDateRange"
  },{
    code: "payrollIncidence:delete",
    endpoint: "payrollIncidence/delete"
  },{
    code: "incidentManagement:findAllByCompanyAndDate",
    endpoint: "incidentManagement/findAllByCompanyAndDate"
  },{
    code: "incidentManagement:update",
    endpoint: "incidentManagement/update"
  },{
    code: "checkEntryExitWork:findAllByCompanyAndDate",
    endpoint: "checkEntryExitWork/findAllByCompanyAndDate"
  },{
    code: "checkEntryExitWork:delete",
    endpoint: "checkEntryExitWork/delete"
  },{
    code: "checkEntryExitWork:updateExtemporaneous",
    endpoint: "checkEntryExitWork/updateExtemporaneous"
  },{
    code: "dependentMethods:createToReportIncidences",
    endpoint: "dependentMethods/createToReportIncidences"
  },{
    code: "saleClinic:update",
    endpoint: "saleClinic/update"
  },{
    code: "saleClinic:close",
    endpoint: "saleClinic/close"
  },{
    code:"physicalPerson:findByNameAndSurnameAndLastnameAndDateBirth",
    endpoint:"physicalPerson/findByNameAndSurnameAndLastnameAndDateBirth"
  },{
    code:"saleClinicDetail:update",
    endpoint:"saleClinicDetail/update"
  },{
    code:"saleClinicDetail:get",
    endpoint:"saleClinicDetail/get"
  },{
    code:"saleClinicDetail:ticket",
    endpoint:"saleClinicDetail/ticket"
  },{
    code: "saleClinic:get",
    endpoint: "saleClinic/get"
  },{
    code:"saleClinic:findByCollaboratorAndStatus",
    endpoint:"saleClinic/findByCollaboratorAndStatus"
  },{
    code:"saleClinicDetail:findAllBySaleClinic",
    endpoint:"saleClinicDetail/findAllBySaleClinic"
  },{
    code:"saleClinic:findAllByStatus",
    endpoint:"saleClinic/findAllByStatus"
  },{
    code:"collaboratorSchedule:update",
    endpoint:"collaboratorSchedule/update"
  },{
    code:"collaboratorSchedule:get",
    endpoint:"collaboratorSchedule/get"
  },{
    code:"collaboratorSchedule:findByCollaborator",
    endpoint:"collaboratorSchedule/findByCollaborator"
  },{
    code:"workingHours:findAllByCompanyAndStatus",
    endpoint:"workingHours/findAllByCompanyAndStatus"
  },{
    code:"branch:list",
    endpoint:"branch/list"
  },{
    code:"motionRequest:update",
    endpoint:"motionRequest/update"
  },{
    code:"motionRequest:get",
    endpoint:"motionRequest/get"
  },{
    code:"validate:validateOwner",
    endpoint:"validate/validateOwner"
  },{
    code:"motionRequest:updateStatus",
    endpoint:"motionRequest/updateStatus"
  },{
    code:"sales:getAnonimous",
    endpoint:"sales/getAnonimous"
  },{
    code:"survey:dataByQuestionnaireAndAnswerDateBetween",
    endpoint:"survey/dataByQuestionnaireAndAnswerDateBetween"
  },{
    code:"dependentMethods:addCheckEntryExitWork",
    endpoint:"dependentMethods/addCheckEntryExitWork"
  }
];

export default urls;
