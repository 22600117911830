import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nurse-point-sale-product-service',
  templateUrl: './nurse-point-sale-product-service.component.html',
  styleUrls: ['./nurse-point-sale-product-service.component.css']
})
export class NursePointSaleProductServiceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
