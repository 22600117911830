import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import * as Rx from 'rxjs/Rx';
import { LoadingService } from '../../directive/loading/loading.module';
import { AssistantRoomOccupationDetailComponent } from './assistant-room-occupation-detail/assistant-room-occupation-detail.component';
import { AssistantPatientUpdateComponent } from './assistant-patient-update/assistant-patient-update.component';

@Component({
  selector: 'app-assistant-register',
  templateUrl: './assistant-register.component.html',
  styleUrls: ['./assistant-register.component.css']
})
export class AssistantRegisterComponent implements OnInit {

  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  floors = [];
  patientModel = {
    main: {
      uuid: ''
    },
    system: {
      physicalPerson: {},
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      },
      status: true
    }
  };
  doctorModel = {
    main: {
      uuid: ''
    },
    system: {
      role: 'Doctor',
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      },
      user: {
        main: {
          uuid:''
        }
      }
    }
  };
  roomModel = {
    main: {
      uuid: '',
      nameOrNumber: '',
      order: 0
    },
    system: {
      floor: {
        main: {
          uuid:''
        }
      }
    },
    status: true
  };
  detailModel = {
    main: {

    }
  }
  roomOccupationModel = {
    main: {
      uuid:'',
      status: 'Disponible',
      observation: '',
      subtotal: 0,
      tax: 0,
      total: 0
    },
    complement: {
      typeOccupation: '',
      cie10: ''
    },
    system: {
      patient: JSON.parse(JSON.stringify(this.patientModel)),
      doctor: JSON.parse(JSON.stringify(this.doctorModel)),
      room: JSON.parse(JSON.stringify(this.roomModel)),
      detail: JSON.parse(JSON.stringify(this.detailModel))
    }
  };

  constructor(private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, public dialog: MatDialog) { }

  ngOnInit() {
    this.getFloor();
  }

  findAllByCompany() {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo pisos, espere un momento...");
      this.session.getRequest("floor:findAllByCompany", {system: {company: {main: {uuid: this.session.getSessionObject().main.uuid} }, status: true} }).subscribe(
        (data: any) => {
          let floors = [];
          // Obtenemos las habitaciones por piso
          for (let x=0; x<data.object.instanceList.length; x++) {
            let floor = {
              main: {
                uuid: data.object.instanceList[x].main.uuid,
                name: data.object.instanceList[x].main.name,
              },
              system: {
                status: data.object.instanceList[x].system.status,
                company: data.object.instanceList[x].system.company,
                rooms: []
              }
            }
            this.findAllByFloor(data.object.instanceList[x]).then((data:any) => {
              floor.system.rooms = data.object.instanceList;
              floors.push(floor);
            });
          }
          resolve({transaction: 'ok', object:{instanceList: floors}});
          this.loadingService.hide();
        },
        error => {
          console.log("Error: floor:findAllByCompany", error);
          this.loadingService.hide();
        });
    });
  }

  findAllByFloor(floor) {
    return new Promise((resolve,reject) => {
      this.loadingService.show(true, "Obteniendo cuartos del piso " + floor.main.name  + ", espere un momento...");
      this.session.getRequest("roomOccupation:findAllByFloorWithWarehouse", {system: {floor: {main: {uuid: floor.main.uuid} }, status: true} }).subscribe(
        (data: any) => {
          let rooms = [];
          // Obtenemos la ocupación del cuarto
          for (let y=0; y<data.object.instanceList.length; y++) {
            this.findByRoomAndStatusNotEqual(data.object.instanceList[y]).then((data:any) => {
              rooms.push(data.object);
            });
          }
          resolve({transaction: 'ok', object: { instanceList: rooms}});
          this.loadingService.hide();
        },
        error => {
          console.log("Erro: roomOccupation:findAllByFloorWithWarehouse", error);
          this.loadingService.hide();
        });
      });
  }

  findByRoomAndStatusNotEqual(room) {
    return new Promise((resolve,reject) => {
      this.loadingService.show(true, "Obteniendo detalle de la ocupación del cuarto "+ room.main.nameOrNumber +", espere un momento...");
      this.session.getRequest("roomOccupation:findByRoomAndStatusNotEqual", {main: {status: 'Terminado' }, system: {room: {main: {uuid: room.main.uuid} } } }).subscribe(
        (data: any) => {
          if (data.object) {
            console.log('Existe ocuapción');
            resolve({transaction: 'ok', object:data.object});
          } else {
            console.log('Sin ocuapación', this.roomOccupationModel);
            let roomOccupation = JSON.parse(JSON.stringify(this.roomOccupationModel));
            roomOccupation.system.room = room;
            resolve({transaction: 'ok', object:roomOccupation});
          }
      },
      error => {
        console.log("Erro: roomOccupation:findByRoomAndStatusNotEqual", error);
        this.loadingService.hide();
      });
    });
  }

  // Obtenemos la lista de pisos de la clinica
  getFloor() {
    this.floors = [];
    this.findAllByCompany().then((data:any) => {
      this.floors = data.object.instanceList;
    });
  }

  infoPatientDialog(object) {
    this.dialog.open(AssistantRoomOccupationDetailComponent, {
      width: '60%',
      data: {
        object: object,
      }
    }).afterClosed().subscribe(result => {

    });
  }

  /**
   * Apertura la pantalla para registrar un nuevo paciente
   */
  addPatientDialog() {
    this.dialog.open(AssistantPatientUpdateComponent, {
      width: '70%',
    }).afterClosed().subscribe(result => {
      if (result.main.uuid !== '') {
        // this.snackBar.open('Información', 'El paciente se registro correctamente', {
        //   duration: 8000
        // });
      } else {
        this.snackBar.open('Error', 'No fue posible guardar el paciente', {
          duration: 8000
        });
      }
    });
  }

  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
