import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { NursePatientUpdateComponent } from './nurse-patient-update/nurse-patient-update.component';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-nurse-register-update',
  templateUrl: './nurse-register-update.component.html',
  styleUrls: ['./nurse-register-update.component.css']
})
export class NurseRegisterUpdateComponent implements OnInit {
  complexForm: FormGroup;
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  patientModel = {
    main: {
      uuid: ''
    },
    system: {
      physicalPerson: {
        main: {
          uuid: '',
          name: '',
          surname: '',
          lastname: ''
        }
      },
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      },
      status: true
    }
  };
  doctorModel = {
    main: {
      uuid: ''
    },
    system: {
      role: 'Doctor',
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      },
      user: {
        main: {
          uuid:''
        }
      }
    }
  };
  roomModel = {
    main: {
      uuid: '',
      nameOrNumber: '',
      order: 0
    },
    system: {
      floor: {
        main: {
          uuid:''
        }
      }
    },
    status: true
  };
  roomOccupationModel = {
    main: {
      uuid:'',
      status: 'Disponible',
      observation: '',
      subtotal: 0,
      tax: 0,
      total: 0
    },
    complement: {
      cie10: '',
      typeOccupation: ''
    },
    system: {
      patient: JSON.parse(JSON.stringify(this.patientModel)),
      doctor: JSON.parse(JSON.stringify(this.doctorModel)),
      specialistDoctor: JSON.parse(JSON.stringify(this.doctorModel)),
      room: JSON.parse(JSON.stringify(this.roomModel)),
      detail : {
        main: {
          date: '',
          hour: '',
          minute: '',
          typeMovement: 'Entrada',
          dateRegister: ''
        },
        system: {
          user: {
            main: {
              uuid: '',
              objectType: ''
            }
          }
        }
      }
    }
  };

  roomOccupationDoctorModel = {
    main: {
      uuid: '',
      price: 0,
      subTotal: 0,
      tax: 0,
      total: 0
    },
    system: {
      object: '',
      objectUuid: '',
      doctor: {
        main: {
          uuid: ''
        }
      },
      company: {
        main: {
          uuid: ''
        }
      },
      patient: {
        main: {
          uuid: ''
        }
      }
    }
  }

  metadata = {
    patient: {
      filter: {
        name: 'searchElement',
        value: '',
      },
      searching:false,
      searchBoxInput: new Rx.Subject<string>(),
    },
    doctor: {
      filter: {
        name: 'searchElement',
        value: '',
      },
      searching:false,
      searchBoxInput: new Rx.Subject<string>(),
    },
    specialistDoctor: {
      filter: {
        name: 'searchElement',
        value: '',
      },
      searching:false,
      searchBoxInput: new Rx.Subject<string>(),
    },
    cie10: {
      filter: {
        name: 'searchElement',
        value: '',
      },
      searching:false,
      searchBoxInput: new Rx.Subject<string>(),
    },
    patientList: [],
    doctorList: [],
    specialistDoctorList: [],
    cie10List:[]
  };
  object=JSON.parse(JSON.stringify(this.roomOccupationModel));
  constructor(protected session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute) {
    this.metadata.patient.searchBoxInput.debounceTime(700).switchMap(val => {
      this.metadata.patientList = [];
      if (val == '') {
        this.metadata.patient.filter.value = '';
        val = 'false';
      } else {
        this.getPatients();
      }
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });

    this.metadata.doctor.searchBoxInput.debounceTime(700).switchMap(val => {
      this.metadata.doctorList = [];
      if (val == '') {
        this.metadata.doctor.filter.value = '';
        val = 'false';
      } else {
        this.getDoctors('Doctor');
      }
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });

    this.metadata.specialistDoctor.searchBoxInput.debounceTime(700).switchMap(val => {
      this.metadata.specialistDoctorList = [];
      if (val == '') {
        this.metadata.specialistDoctor.filter.value = '';
        val = 'false';
      } else {
        this.getDoctors('Specialist doctor');
      }
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });

    this.metadata.cie10.searchBoxInput.debounceTime(700).switchMap(val => {
      this.metadata.cie10List = [];
      if (val == '') {
        this.metadata.cie10.filter.value = '';
        val = 'false';
      } else {
        this.getCie10();
      }
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      // this.object.system.room.main.uuid = params['uuid'];
      this.loadingService.wait();
      this.session.getRequest("room:get", {main: {uuid: params['uuid']} }).subscribe(
        (data: any) => {
          this.object.system.room = data.object;
          this.loadingService.hide();
        },
        error => {
          console.log("Error: room:get", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Apertura la pantalla para registrar un nuevo paciente
   */
  addPatientDialog() {
    this.dialog.open(NursePatientUpdateComponent, {
      width: '70%',
    }).afterClosed().subscribe(result => {
      if (result.main.uuid !== '') {
        this.object.system.patient = result;
        this.metadata.patient.filter.value = result.system.physicalPerson.main.name + " " + result.system.physicalPerson.main.surname + " " + result.system.physicalPerson.main.lastname;
      } else {
        this.metadata.patient.filter.value = '';
      }
    });
  }

  /**
   * Selecciona al doctor seleccionado
   */
  doctorSeleted(object, role) {
    if (role == 'Doctor') {
      this.object.system.doctor = object;
      this.metadata.doctor.filter.value = object.system.physicalPerson.main.name + ' ' + object.system.physicalPerson.main.lastname + ' ' + object.system.physicalPerson.main.surname;
      this.metadata.doctorList = [];
      this.metadata.doctor.searching = false;
    } else {
      this.object.system.specialistDoctor = object;
      this.metadata.specialistDoctor.filter.value = object.system.physicalPerson.main.name + ' ' + object.system.physicalPerson.main.lastname + ' ' + object.system.physicalPerson.main.surname;
      this.metadata.specialistDoctorList = [];
      this.metadata.specialistDoctor.searching = false;
    }
  }

  /**
   * Obtiene los doctores de acuerdo a la palabra escrita por el usuario
   */
  getDoctors(role) {
    this.loadingService.wait();
    var filtering = {};
    if (role == 'Specialist doctor') {
      this.metadata.specialistDoctorList = [];
      this.metadata.specialistDoctor.searching = true;
      filtering = this.metadata.specialistDoctor.filter;
    } else {
      this.metadata.doctorList = [];
      this.metadata.doctor.searching = true;
      filtering = this.metadata.doctor.filter;
    }
    this.session.getRequest("collaborator:findAllByCompanyAndRole", {system: {role: role, company: {main: {uuid: this.session.getSessionObject().main.uuid} } }, filter: filtering }).subscribe(
      (data: any) => {
        if (role == 'Specialist doctor') {
          this.metadata.specialistDoctorList = data.object.instanceList;
        } else {
          this.metadata.doctorList = data.object.instanceList;
          this.metadata.doctorList.push({
                "main": {
                    "uuid": "-",
                    "specialty": ""
                },
                "system": {
                    "physicalPerson": {
                        "main": {
                            "uuid": "",
                            "name": "SIN",
                            "lastname": "MEDICO",
                            "surname": "DE GUARDIA"
                        },
                        "complement": {
                            "dateBirth": "1994-03-08T06:00:00Z",
                            "curp": null,
                            "rfc": null,
                            "gender": "Masculino"
                        },
                        "system": {
                            "status": false,
                            "address": null,
                            "contacts": []
                        }
                    },
                    "user": {
                        "main": {
                            "uuid": ""
                        }
                    },
                    "role": "Doctor",
                    "company": {
                        "main": {
                            "uuid": this.session.getSessionObject().main.uuid
                        }
                    },
                    "status": true
                }
            });
        }
        this.loadingService.hide();
      },
      error => {
        console.log("Error: collaborator:findAllByCompanyAndRole", error);
        this.loadingService.hide();
      });
  }

  /**
   * Obtiene los pacientes de acuerdo a la palabra escrita por el usuario
   */
  getPatients() {
    this.loadingService.wait();
    this.metadata.patientList = [];
    this.metadata.patient.searching = true;
    this.session.getRequest("patient:findAllByCompany", {system: {company: {main: {uuid: this.session.getSessionObject().main.uuid} } }, filter: this.metadata.patient.filter }).subscribe(
      (data: any) => {
        this.metadata.patientList = data.object.instanceList;
        this.loadingService.hide();
      },
      error => {
        console.log("Error: patient:findAllByCompany", error);
        this.loadingService.hide();
      });
  }

  /**
   * Selecciona el paciente seleccionado
   */
  patientSeleted(object) {
    this.object.system.patient = object;
    this.metadata.patient.filter.value = object.system.physicalPerson.main.name + ' ' + object.system.physicalPerson.main.lastname + ' ' + object.system.physicalPerson.main.surname;
    this.metadata.patientList = [];
    this.metadata.patient.searching = false;
  }
  /**
   * Obtiene el listado de ci-10
   */
  getCie10() {
    this.loadingService.wait();
    this.metadata.cie10List = [];
    this.metadata.cie10.searching = true;
    this.session.getRequest("cie10:findAllByDescriptionLike", {main: {description: this.metadata.cie10.filter.value} } ).subscribe(
      (data: any) => {
        this.metadata.cie10List = data.object.instanceList;
        this.loadingService.hide();
      },
      error => {
        console.log("Error: cie10:findAllByDescriptionLike", error);
        this.loadingService.hide();
      });
  }

  /**
   * Selecciona el paciente seleccionado
   */
  cie10Seleted(object) {
    this.object.complement.cie10 = object.main.description;
    this.metadata.cie10.filter.value = object.main.description;
    this.metadata.cie10List = [];
    this.metadata.cie10.searching = false;
  }

  /**
   * Funcion para buscar paciente
   */
  searchPatient(value) {
    this.metadata.patient.searchBoxInput.next(value);
  }

  /**
   * Funcion para buscar doctor
   */
  searchDoctor(value, role) {
    if (role == 'Specialist doctor') {
      this.metadata.specialistDoctor.searchBoxInput.next(value);
    } else {
      this.metadata.doctor.searchBoxInput.next(value);
    }
  }

  /**
   * Funcion para buscar cie-10
   */
  searchCie10(value) {
    this.metadata.cie10.searchBoxInput.next(value);
  }

  send(object) {
    this.loadingService.show(true, "Espere un momento... se esta guardando el registro.");
    object.main.status = 'Ocupado';
    object.complement.cie10 = this.metadata.cie10.filter.value;
    object.system.detail.main.date = moment().format('DD/MM/YYYY HH:mm:ss');
    object.system.detail.main.hour = moment().format('hh');
    object.system.detail.main.minute = moment().format('mm');
    object.system.detail.main.typeMovement = 'Entrada';
    object.system.detail.main.dateRegister = moment().format('DD/MM/YYYY HH:mm:ss');
    object.system.detail.system.user.main.uuid = this.session.getUser().main.uuid;
    object.system.detail.system.user.main.objectType = 'Collaborator';
    this.session.getRequest("roomOccupation:update", object).subscribe(
      (data: any) => {
        let roomOccupationDoctor = JSON.parse(JSON.stringify(this.roomOccupationDoctorModel));
        roomOccupationDoctor.system.object = 'RoomOccupation';
        roomOccupationDoctor.system.objectUuid = data.object.main.uuid;
        roomOccupationDoctor.system.patient.main.uuid = data.object.system.patient.main.uuid;
        if (data.object.system.doctor.main.uuid != '') {
          this.loadingService.show(true, "Espere un momento... asociando la guardia al doctor.");
          roomOccupationDoctor.system.doctor.main.uuid = data.object.system.doctor.main.uuid;
          roomOccupationDoctor.system.company.main.uuid = this.session.getSessionObject().main.uuid;
          this.session.getRequest("roomOccupationDoctor:update", roomOccupationDoctor).subscribe(
            (data: any) => {
              this.snackBar.open('Ok', 'El registro se guardo correctamente', {
                duration: 8000
              });
              this.loadingService.hide();
              this.router.navigate(['/admin/nurse/dashboard']);
            }, error => {
              console.log("Error: roomOccupationDoctor:update", error);
              this.loadingService.hide();
            });
          } else {
            this.loadingService.hide();
            this.router.navigate(['/admin/nurse/dashboard']);
          }
      }, error => {
        console.log("Error: roomOccupation:update", error);
        this.loadingService.hide();
      });
  }

  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
