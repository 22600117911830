import { Component, OnInit, ViewChild, ViewEncapsulation, NgZone} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.module';
import { AdminCalendarIncidenceDayComponent }  from "./admin-calendar-incidence-day/admin-calendar-incidence-day.component";
import * as moment from 'moment';
import { CalendarEvent, CalendarMonthViewDay } from 'angular-calendar';

@Component({
  selector: 'app-admin-calendar-incidence',
  templateUrl: './admin-calendar-incidence.component.html',
  styleUrls: ['./admin-calendar-incidence.component.css']
})
export class AdminCalendarIncidenceComponent implements OnInit {
  @ViewChild('drawer', { static: true }) drawerCtrl: MatDrawer;

  metadata = {
    viewDate: new Date(),
    currentDate: new Date(),
    view:'month',
    currentDateIsoString:moment(new Date()).toISOString(),
    startDate:'',
    endDate:''
  }
  events:CalendarEvent[] = [];
  typePunishmentList = [];
  employeeList = [];
  daySelectedView:any = '';
  selectedMonthViewDay: CalendarMonthViewDay;
  locale: string = 'es';
  collaboratorMin = {
    system:{
      role:'',
      company:{
        main:{
          uuid:''
        }
      },
      status:true,
    },
    max: 100,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }

  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialog: MatDialog, private ngZone: NgZone) { }

  ngOnInit() {
    this.getCalendarIncidences();
    this.getEmployeeList();
    this.getTypePunishmentList();
  }
  /*
  Funcion para obtener las incidencias de un mes en especifico
  */
  getCalendarIncidences(){
    this.loadingService.wait();
    //funcion para obtener los eventos de calendario de un mes
    let startDateTemp = moment(this.metadata.viewDate).set('date', 1).format('MM/DD/YYYY');
    let startDate:any = new Date(startDateTemp);
    let endDate:any = new Date(startDateTemp);
    //colocamos el endDate con el primer dia del mes siguiente y le restamos un dia para obtener el ultimo dia del mes corrriente
    endDate.setMonth(endDate.getMonth() + 1);
    endDate = new Date(endDate.getTime() - 86400000);
    //cambiamos el formato
    startDate = moment(startDate).format('DD-MM-YYYY');
    endDate = moment(endDate).format('DD-MM-YYYY');
    this.session.getRequest('incidentManagement:findAllByCompanyAndBeatweenDate',{ system: {
      incidentEndDate: endDate,
      incidentStartDate: startDate,
      status: true,
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      }
    }}).subscribe((data:any)=>{
      this.loadingService.hide();
      // limpiamos la variable events, que es la que contiene los eventos de calendario.
      this.events = [];
      for (let i = 0; i < data.object.instanceList.length; i++) {
        let dateDay = new Date(data.object.instanceList[i].main.incidentDate);
        let utcDate = new Date(dateDay.getUTCFullYear(), dateDay.getUTCMonth(), dateDay.getUTCDate(), dateDay.getUTCHours(), dateDay.getUTCMinutes(), dateDay.getUTCSeconds());
        this.events.push({
          title: data.object.instanceList[i].system.collaborator.system.physicalPerson.main.name + ' ' + data.object.instanceList[i].system.collaborator.system.physicalPerson.main.lastname + ' ' + data.object.instanceList[i].system.collaborator.system.physicalPerson.main.surname+ ' - ' + data.object.instanceList[i].system.typePunishment.main.name,
          color: {
            primary: data.object.instanceList[i].system.typePunishment.main.colorGroup,
            secondary:data.object.instanceList[i].system.typePunishment.main.colorGroup,
          },
          start:utcDate,
        });
      }
    },error=>{
      this.loadingService.hide();
      console.log("Error:incidentManagement:findAllByCompanyAndBeatweenDate",error);
    })
  }
  //obtenemos los tipos de sanciones que existen en el sistema.
  getTypePunishmentList(){
    this.session.getRequest('typePunishment:findAllByCompanyAndStatus',{ system: {company: { main: { uuid: this.session.getSessionObject().main.uuid}},status: true}}).subscribe((data:any)=>{
      if (data.object.instanceList.length > 0) {
        this.typePunishmentList = [];
        this.typePunishmentList = data.object.instanceList;
      } else {
        this.snackBar.open('No hay tipos de sanciones dadas de alta, Ingrese una', 'Success', {duration: 5000});
      }
    },error=>{
      this.snackBar.open('No hay tipos de sanciones dadas de alta, Ingrese una', 'Error', {duration: 5000});
      console.log("error:typePunishment:findAllByCompanyAndStatus", error);
    })
  }
  //funcion para traer los datos de los trabajadores de la compañia con status true.
  getEmployeeList(){
    // this.session.getRequest('employee:findAllByCompanyAndStatus',{ system: {company: { main: { uuid: this.session.getSessionObject().main.uuid}},status: true}}).subscribe((data:any)=>{
    //   this.employeeList = [];
    //   this.employeeList = data.object.instanceList;
    // },error=>{
    //   console.log("error:employee:findAllByCompanyAndStatus",error);
    // })
    this.collaboratorMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("collaborator:findAllByCompany",this.collaboratorMin).subscribe((data:any)=>{
      this.employeeList = data.object.instanceList;
    },error=>{
      console.log("error",error);
    })
  }
  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach(day => {
      day.cssClass = '';
      if(this.daySelectedView != ''){
        if(day.date.getTime() == this.daySelectedView.date.getTime()){
          day.cssClass = 'cal-day-selected';
        }
      }
    });
  }

  /*
  */
  dayClicked(ev){
    this.daySelectedView = ev;
    this.dialog.open(AdminCalendarIncidenceDayComponent, {
      width: '60%',
      data:{
        typePunishmentList:this.typePunishmentList,
        employeeList:this.employeeList,
        daySelected:ev,
      }
    }).afterClosed().subscribe(result => {
      if(result){
        // console.log('......***.....',this.daySelectedView);
        // this.daySelectedView.events = result.events;
        // this.events = this.events.concat(result.events);
        this.getCalendarIncidences();
      }else{
        //No hacemos Nada
      }
    });
  }
  /*
  Funcion para enviar el reposte de incidencias por correo
  */
  getReportClockIssues(){
    let startDate = moment(this.metadata.startDate).format('DD-MM-YYYY');
    let endDate = moment(this.metadata.endDate).format('DD-MM-YYYY');
    this.loadingService.show(true,'Enviado reporte a su correo electrónico');
    this.session.getRequest('dependentMethods:createToReportIncidences',{
        system: {
          type: 'incidence',
          incidentEndDate: endDate,
          incidentStartDate: startDate,
          status: true,
          company: {
            main: {
              uuid: this.session.getSessionObject().main.uuid
            }
          }
        }
      }).subscribe((data:any)=>{
        this.loadingService.hide();
        this.snackBar.open(data.message, 'Success', {duration: 5000});
    },error=>{
      this.loadingService.hide();
      console.log("error:dependentMethods:createToReportIncidences",error);
    })
  }
  /*
  Funcion para llamar el proceeso de descarga de reporte en segundo plano
  */
  downloadReport(){
    let startDate = moment(this.metadata.startDate).format('DD-MM-YYYY');
    let endDate = moment(this.metadata.endDate).format('DD-MM-YYYY');
    // this.backgroud.downloadReportClockIssues(startDate,endDate, 'incidence')
    this.loadingService.show(true,'Espere un momento... Construyendo tu reporte.');

    this.ngZone.runOutsideAngular(()=>{
      this.session.getRequest('checkEntryExitWork:downloadToReport',{
          system: {
            type: 'incidence',
            incidentEndDate: endDate,
            incidentStartDate: startDate,
            status: true,
            company: {
              main: {
                uuid: this.session.getSessionObject().main.uuid
              }
            }
          }
        }).subscribe((data:any)=>{
        this.ngZone.run(() => {
          //  this.show(true,data.message,100);
          this.loadingService.hide();
           let elem = document.createElement('a');
           elem.href = data.object;
           elem.setAttribute('download', 'incidencias.xls');
           document.body.appendChild(elem);
           elem.click();
        });
      },error=>{
        console.log("Error;",error);
        this.loadingService.hide();
      })
    });
  }
  /*
  Funcion para llamar el proceso de actualizacion de reporte en segundo plano
  */
  updateIncidents(){
    let startDate = moment(this.metadata.startDate).format('DD-MM-YYYY');
    let endDate = moment(this.metadata.endDate).format('DD-MM-YYYY');
    // this.backgroud.getClockIssues(startDate,endDate)
    this.loadingService.show(true,'Espere un momento... Estamos actualizando las incidencias.');
    this.ngZone.runOutsideAngular(()=>{
      this.session.getRequest('checkEntryExitWork:createToIncidence',{
          system: {
            incidentEndDate: endDate,
            incidentStartDate: startDate,
            status: true,
            company: {
              main: {
                uuid: this.session.getSessionObject().main.uuid
              }
            }
          }
        }).subscribe((data:any)=>{
        this.ngZone.run(() => {
          this.loadingService.hide()
          this.snackBar.open('Se actualizaron las incidencias correctamente en el calendario, Recargue para ver los cambios', 'Success', {duration: 5000});
        });
      },error=>{
        console.log("Error:checkEntryExitWork:createToIncidence;",error);
        this.loadingService.hide();
      })
    });
  }

  /*
  Funcion para llamar el proceso de actualizacion de reporte en segundo plano
  */
  updateIncidentsBeforeDelete(){
    let startDate = moment(this.metadata.startDate).format('DD-MM-YYYY');
    let endDate = moment(this.metadata.endDate).format('DD-MM-YYYY');
    // this.backgroud.getClockIssuesBeforeDelete(startDate,endDate)
    this.loadingService.show(true,'Espere un momento... Estamos actualizando las incidencias.');
    this.ngZone.runOutsideAngular(()=>{
      this.session.getRequest('checkEntryExitWork:createToIncidenceBeforeDelete',{
          system: {
            incidentEndDate: endDate,
            incidentStartDate: startDate,
            status: true,
            company: {
              main: {
                uuid: this.session.getSessionObject().main.uuid
              }
            }
          }
        }).subscribe((data:any)=>{
        this.ngZone.run(() => {
           this.snackBar.open('Se actualizaron las incidencias correctamente en el calendario, recargue para ver los cambios.', 'Success', {duration: 5000});
           this.loadingService.hide();
        });
      },error=>{
        console.log("Error:checkEntryExitWork:createToIncidenceBeforeDelete",error);
        this.loadingService.hide();
      })
    });
  }

  /*
  Funcion para llamar el proceso de eliminar incidencias en segundo plano
  */
  deleteIncidents(){
    let startDate = moment(this.metadata.startDate).format('DD-MM-YYYY');
    let endDate = moment(this.metadata.endDate).format('DD-MM-YYYY');
    // this.backgroud.deleteIncidentManagement(startDate, endDate)
    this.loadingService.show(true,'Espere un momento... Estamos eliminando las incidencias.');
    this.ngZone.runOutsideAngular(()=>{
      this.session.getRequest('checkEntryExitWork:deleteByDateRange',{
          system: {
            incidentEndDate: endDate,
            incidentStartDate: startDate,
            status: true,
            company: {
              main: {
                uuid: this.session.getSessionObject().main.uuid
              }
            }
          }
        }).subscribe((data:any)=>{
        this.ngZone.run(() => {
           this.snackBar.open('Se eliminaron las incidencias correctamente en el calendario, recargue para ver los cambios', 'Success', {duration: 5000});
          this.loadingService.hide();
        });
      },error=>{
        console.log("Error:checkEntryExitWork:deleteByDateRange",error);
        this.loadingService.hide();
      })
    });
  }

  /*
  funcion para controlar si se abre o cierra el menu
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
