import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';

@Component({
  selector: 'app-nurse-room-consumption',
  templateUrl: './nurse-room-consumption.component.html',
  styleUrls: ['./nurse-room-consumption.component.css']
})
export class NurseRoomConsumptionComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;

  constructor(private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, public dialog: MatDialog) { }

  ngOnInit() {
  }

  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
