import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { SessionService } from './../../service/session/session.service';
import { LoadingService } from './../../directive/loading/loading.service';
import * as moment from 'moment';

@Component({
  selector: 'app-medical-records-details',
  templateUrl: './medical-records-details.component.html',
  styleUrls: ['./medical-records-details.component.css']
})
export class MedicalRecordsDetailsComponent implements OnInit {

  object:any={};
  vitalSignsList = [];
  dateMedical='';
  medicalNotesCIE10List = [];

  constructor(public dialogRef: MatDialogRef<MedicalRecordsDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  public session:SessionService,
  public loading:LoadingService,
  public snackBar: MatSnackBar,

) {
    this.object = data.object;
    // console.log(this.object);
    this.dateMedical = JSON.parse(JSON.stringify(this.object.main.dateMedical)) ;
  }

  ngOnInit() {
    this.getVitals();
    this.findAllCie10FromMedicalNote(this.object);
  }

  /**funcionalidad para obtener los signos vitales
  */
  getVitals(){
    this.session.getRequest("vitalSigns:findAllByPatientAndMedicalNotes",{system:{medicalNotes:{main:{uuid:this.object.main.uuid}},patient:{main:{uuid:this.object.system.patient.main.uuid}}}}).subscribe((data:any)=>{
      this.vitalSignsList = data.object.instanceList;
    },error=>{
      console.log(error);
    });
  }

  /*Funcion para obtener los cie10 asociados a una nota medica.
  */
  findAllCie10FromMedicalNote(medicalNotes){
    this.session.getRequest("medicalNotesCIE10:findAllByMedicalNotes", {system:{medicalNotes:{main:{uuid:medicalNotes.main.uuid}}}}).subscribe(
      (data: any) => {
        this.medicalNotesCIE10List = data.object.instanceList;
      },
      error => {
        console.log("Error: medicalNotesCIE10:findAllByMedicalNotes", error);
      });
  }

}
