import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as Rx from 'rxjs/Rx';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-treasury-expense-record-update',
  templateUrl: './admin-treasury-expense-record-update.component.html',
  styleUrls: ['./admin-treasury-expense-record-update.component.css']
})
export class AdminTreasuryExpenseRecordUpdateComponent implements OnInit {
  @ViewChild('drawer',{static:false})drawerCtrl: MatDrawer;
  complexForm: FormGroup;
  expenseClassificationList =[];
  expenseModel = {
    main: {
      uuid: '',
      voucherType: '',
      name: '',
      concept:'',
      dateExpenditure:'',
      amount:''
    },
    system: {
      branch: {
        main:{
          uuid: ''
        }
      },
      provider:{
        main:{
          uuid:''
        }
      },
      company: {
        main: {
          uuid: ''
        }
      },
      expenseClassification:{
        main:{
          uuid:'2c9fa81f7561194001756122af7d0020'
        }
      },
      pettyCash: {
        main: {
          uuid: ''
        }
      }
    }
  };
  metadata={
    dateExpenditure:moment().toISOString(),
  }
  object = JSON.parse(JSON.stringify(this.expenseModel));
  constructor(private router: Router, public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, private activatedRoute: ActivatedRoute) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'amount': [null, Validators.required],
      'voucherType': [null, Validators.required],
      'concept': [null, Validators.required],
      'dateExpenditure': [null, Validators.required],
      'name': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
    })
  }

  ngOnInit() {
    // Se obtienen las clasificaciones de gastos
    this.session.getRequest("expenseClassification:findAllByCompany", {system: {company: {main: {uuid: this.session.getSessionObject().main.uuid} } } }).subscribe(
      (data: any) => {
        this.expenseClassificationList = data.object.instanceList;
      },
      error => {
      console.log("expenseClassification:findAllByCompany", error);
    });

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['uuid'] == 'new') {
        this.session.getRequest('pettyCash:findByCompanyAndOpen', {system: {company: {main: {uuid: this.session.getSessionObject().main.uuid} } }, main: {open: true} }).subscribe((data:any)=>{
          this.object.system.pettyCash = data.object;
        }, error => {
          this.loadingService.hide();
          console.log("Error: pettyCash:findByCompanyAndOpen",error);
        });
      } else {
        this.loadingService.wait();
        this.object.main.uuid = params['uuid'];
        this.session.getRequest('spending:get',this.object).subscribe((data:any)=>{
          this.object = JSON.parse(JSON.stringify(data.object));
           this.metadata.dateExpenditure = moment(this.object.main.dateExpenditure).toISOString();
          this.loadingService.hide();
        }, error => {
          this.loadingService.hide();
          console.log("spending:get",error);
        });
      }
    });
  }

  send(object){
    this.loadingService.show(true,'Guardando registro');
    object.main.dateExpenditure = moment(this.metadata.dateExpenditure).format('DD/MM/YYYY hh:mm:ss');
    object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    object.system.expenseClassification.main.uuid = '2c9fa81f7561194001756122af7d0020';
    this.session.getRequest("spending:update", object).subscribe(
      (data:any) => {
        object.main.uuid = data.object.main.uuid;
        // evento a emitir cuando se crea o actaliza una categoria
        this.snackBar.open('El registro se guardo correctamente', '', {
          duration: 8000
        });
        this.loadingService.hide();
        this.router.navigate(['/admin/treasury/list']);
     },
     error => {
       this.loadingService.hide();
       console.log("spending:update",error);
     }
    );
  }

  /*
  funcion para controlar si se abre o cierra el menu
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
