import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from './service/session/session.module';
import { LoginComponent } from './login/login.component';
import { RestorePasswordComponent } from './login/restore-password/restore-password.component';
import { AdminDoctorComponent } from './admin/admin-doctor/admin-doctor.component';
import { AdminDoctorUpdateComponent } from './admin/admin-doctor/admin-doctor-update/admin-doctor-update.component';
import { AdminPatientComponent } from './admin/admin-patient/admin-patient.component';
import { AdminPatientUpdateComponent } from './admin/admin-patient/admin-patient-update/admin-patient-update.component';
import { AdminProductComponent } from './admin/admin-product/admin-product.component';
import { AdminProductUpdateComponent } from './admin/admin-product/admin-product-update/admin-product-update.component';
import { AdminZoneComponent } from './admin/admin-zone/admin-zone.component';
import { AdminZoneUpdateComponent } from './admin/admin-zone/admin-zone-update/admin-zone-update.component';
import { AdminInventoryComponent } from './admin/admin-inventory/admin-inventory.component';
import { AdminRoomComponent } from './admin/admin-room/admin-room.component';
import { AdminRoomUpdateComponent } from './admin/admin-room/admin-room-update/admin-room-update.component';
import { AdminWarehouseUpdateComponent } from './admin/admin-inventory/admin-warehouse-update/admin-warehouse-update.component';
import { AdminInventoryStockComponent } from './admin/admin-inventory/admin-inventory-stock/admin-inventory-stock.component';
import { AdminAdjustmentComponent } from './admin/admin-inventory/admin-adjustment/admin-adjustment.component';
import { AdminAdjustmentUpdateComponent } from './admin/admin-inventory/admin-adjustment/admin-adjustment-update/admin-adjustment-update.component';
import { AdminAjustmentValidateComponent } from './admin/admin-inventory/admin-adjustment/admin-ajustment-validate/admin-ajustment-validate.component';
import { AdminRoleComponent } from './admin/admin-role/admin-role.component';
import { AdminRoleDashboardComponent } from './admin/admin-role-dashboard/admin-role-dashboard.component';
import { AdminAccessComponent } from './admin/admin-access/admin-access.component';
import { AdminServiceCatalogComponent } from './admin/admin-service-catalog/admin-service-catalog.component';
import { AdminServiceCatalogUpdateComponent } from './admin/admin-service-catalog/admin-service-catalog-update/admin-service-catalog-update.component';
import { CategoriesComponent } from './admin/catalogs/categories/categories.component';
import { CategoriesUpdateComponent } from './admin/catalogs/categories/categories-update/categories-update.component';
import { CharacteristicComponent } from './admin/catalogs/characteristic/characteristic.component';
import { CharacteristicUpdateComponent } from './admin/catalogs/characteristic/characteristic-update/characteristic-update.component';
import { MakerComponent } from './admin/catalogs/maker/maker.component';
import { MakerUpdateComponent } from './admin/catalogs/maker/maker-update/maker-update.component';
import { MeasurementComponent } from './admin/catalogs/measurement/measurement.component';
import { MeasurementUpdateComponent } from './admin/catalogs/measurement/measurement-update/measurement-update.component';
import { AdjustmentTypeComponent } from './admin/admin-inventory/adjustment-type/adjustment-type.component';
import { AdjustmentTypeUpdateComponent } from './admin/admin-inventory/adjustment-type/adjustment-type-update/adjustment-type-update.component';
import { PointSaleComponent } from './admin/point-sale/point-sale.component';
import { PointSaleUpdateComponent } from './admin/point-sale/point-sale-update/point-sale-update.component';
import { AssistantRegisterComponent } from './assistant/assistant-register/assistant-register.component';
import { ProductPriceImportComponent } from './admin/admin-product/product-price-import/product-price-import.component';
import { NurseDashboardComponent } from './nurse/nurse-dashboard/nurse-dashboard.component';
import { NurseRoomConsumptionComponent } from './nurse/nurse-room-consumption/nurse-room-consumption.component';
import { NurseRegisterUpdateComponent } from './nurse/nurse-dashboard/nurse-register-update/nurse-register-update.component';
import { AdminNurseRegisterUpdateComponent } from './admin/admin-nurse/admin-nurse-register-update/admin-nurse-register-update.component';
import { AdminTransferComponent } from './admin/admin-inventory/admin-transfer/admin-transfer.component';
import { AdminTransferUpdateComponent } from './admin/admin-inventory/admin-transfer/admin-transfer-update/admin-transfer-update.component';
import { AdminTransferValidateComponent } from './admin/admin-inventory/admin-transfer/admin-transfer-validate/admin-transfer-validate.component';
import { AdminTransferApproveComponent } from './admin/admin-inventory/admin-transfer/admin-transfer-approve/admin-transfer-approve.component';
import { AdminTransferValidationComponent } from './admin/admin-inventory/admin-transfer/admin-transfer-validation/admin-transfer-validation.component';
import { AdminTransferDetailsComponent } from './admin/admin-inventory/admin-transfer/admin-transfer-details/admin-transfer-details.component';
import { AdminNurseComponent } from './admin/admin-nurse/admin-nurse.component';
import { AdminTreasuryExpenseClassificationComponent } from './admin/admin-treasury/admin-treasury-expense-classification/admin-treasury-expense-classification.component';
import { AdminTreasuryExpenseRecordComponent } from './admin/admin-treasury/admin-treasury-expense-record/admin-treasury-expense-record.component';
import { AdminTreasuryExpenseClassificationUpdateComponent } from './admin/admin-treasury/admin-treasury-expense-classification/admin-treasury-expense-classification-update/admin-treasury-expense-classification-update.component';
import { AdminTreasuryExpenseRecordUpdateComponent } from './admin/admin-treasury/admin-treasury-expense-record/admin-treasury-expense-record-update/admin-treasury-expense-record-update.component';
import { DoctorsGuardsComponent } from './admin/doctors-guards/doctors-guards.component';
import { OwnerFeePaymentComponent } from './owner/owner-point-sale/owner-fee-payment/owner-fee-payment.component';
import { OwnerDashboardComponent } from './owner/owner-dashboard/owner-dashboard.component';
import { OwnerReportComponent } from './owner/owner-report/owner-report.component';
import { OwnerExpenseRecordComponent } from './owner/owner-expense-record/owner-expense-record.component';
import { OwnerExpenseRecordUpdateComponent } from './owner/owner-expense-record/owner-expense-record-update/owner-expense-record-update.component';
import { OwnerPointSaleComponent } from './owner/owner-point-sale/owner-point-sale.component';
import { OwnerExpenseClassificationComponent } from './owner/owner-expense-classification/owner-expense-classification.component';
import { OwnerExpenseClassificationUpdateComponent } from './owner/owner-expense-classification/owner-expense-classification-update/owner-expense-classification-update.component';
import { OwnerExpenseComponent } from './owner/owner-expense/owner-expense.component';
import { OwnerExpenseUpdateComponent } from './owner/owner-expense/owner-expense-update/owner-expense-update.component';
import { OwnerDoctorsGuardsComponent } from './owner/owner-doctors-guards/owner-doctors-guards.component';
import { OwnerMedicalConsultationComponent } from './owner/medical-consultation/owner-medical-consultation/owner-medical-consultation.component';
import { AdminTreasuryExpenseComponent } from './admin/admin-treasury/admin-treasury-expense/admin-treasury-expense.component';
import { AdminTreasuryExpenseUpdateComponent } from './admin/admin-treasury/admin-treasury-expense/admin-treasury-expense-update/admin-treasury-expense-update.component';
import { MedicalConsultationComponent } from './owner/medical-consultation/medical-consultation.component';
import { CensusComponent } from './owner/census/census.component';
import { AdminCensusComponent } from './admin/admin-census/admin-census.component';
import { AdminCie10Component } from './admin/admin-cie10/admin-cie10.component';
import { DoctorRegisterComponent } from './doctor/doctor-register/doctor-register.component';
import { DoctorNurseRegisterUpdateComponent } from './doctor/doctor-register/doctor-nurse-register-update/doctor-nurse-register-update.component';
import { DoctorCensusComponent } from './doctor/doctor-census/doctor-census.component';
import { AssistantCensusComponent } from './assistant/assistant-census/assistant-census.component';
import { AssistantNurseRegisterUpdateComponent } from './assistant/assistant-register/assistant-nurse-register-update/assistant-nurse-register-update.component';
import { AssistantMedicalAppointmentComponent } from './assistant/assistant-medical-appointment/assistant-medical-appointment.component';
import { PatientBankDataComponent } from './directive/patient-bank-data/patient-bank-data.component';
import { AdminTreasuryOtherIncomeClassificationComponent } from './admin/admin-treasury/admin-treasury-other-income-classification/admin-treasury-other-income-classification.component';
import { AdminTreasuryOtherIncomeClassificationUpdateComponent } from './admin/admin-treasury/admin-treasury-other-income-classification/admin-treasury-other-income-classification-update/admin-treasury-other-income-classification-update.component';
import { AdminTreasuryOtherIncomeComponent } from './admin/admin-treasury/admin-treasury-other-income/admin-treasury-other-income.component';
import { AdminTreasuryOtherIncomeUpdateComponent } from './admin/admin-treasury/admin-treasury-other-income/admin-treasury-other-income-update/admin-treasury-other-income-update.component';
import { OwnerOtherIncomeClassificationComponent } from './owner/owner-other-income-classification/owner-other-income-classification.component';
import { OwnerOtherIncomeClassificationUpdateComponent } from './owner/owner-other-income-classification/owner-other-income-classification-update/owner-other-income-classification-update.component';
import { OwnerColaboratorComponent } from './owner/owner-colaborator/owner-colaborator.component';
import { OwnerColaboratorUpdateComponent } from './owner/owner-colaborator/owner-colaborator-update/owner-colaborator-update.component';
import { OwnerOtherIncomeComponent } from './owner/owner-other-income/owner-other-income.component';
import { OwnerOtherIncomeUpdateComponent } from './owner/owner-other-income/owner-other-income-update/owner-other-income-update.component';
import { OwnerSurgeriesComponent } from './owner/owner-surgeries/owner-surgeries.component';
import { OwnerBalanceConsultingComponent } from './owner/owner-balance-consulting/owner-balance-consulting.component';
import { OwnerMedicalConsultationDashboardComponent } from './owner/owner-medical-consultation-dashboard/owner-medical-consultation-dashboard.component';
import { SpecialistCensusComponent } from './specialist/specialist-census/specialist-census.component';
import { SpecialistDashboardComponent } from './specialist/specialist-dashboard/specialist-dashboard.component';
import { SpecialistBalanceConsultingComponent } from './specialist/specialist-balance-consulting/specialist-balance-consulting.component';
import { SpecialistMedicalConsultationComponent } from './specialist/specialist-medical-consultation/specialist-medical-consultation.component';
import { SpecialistMedicalConsultationUpdateComponent } from './specialist/specialist-medical-consultation/specialist-medical-consultation-update/specialist-medical-consultation-update.component';
import { SpecialistSurgeriesComponent } from './specialist/specialist-surgeries/specialist-surgeries.component';
import { OwnerSlopeTrackingComponent } from './owner/owner-slope-tracking/owner-slope-tracking.component';
import { AdminTasksComponent } from './admin/admin-tasks/admin-tasks.component';
import { AdminSurveyComponent } from './admin/admin-survey/admin-survey.component';
import { AdminSurveyUpdateComponent } from './admin/admin-survey/admin-survey-update/admin-survey-update.component';
import { SurveyToAnswerComponent } from './anonymous/survey-to-answer/survey-to-answer.component';
import { OwnerReportCie10Component } from './owner/owner-report-cie10/owner-report-cie10.component';
import { AdminReportDashboardComponent } from './admin/admin-report-dashboard/admin-report-dashboard.component';
import { AdminWorkingHoursComponent } from './admin/admin-working-hours/admin-working-hours.component';
import { AdminWorkingHoursUpdateComponent } from './admin/admin-working-hours/admin-working-hours-update/admin-working-hours-update.component';
import { AdminTypePunishmentComponent } from './admin/admin-type-punishment/admin-type-punishment.component';
import { AdminTypePunishmentUpdateComponent } from './admin/admin-type-punishment/admin-type-punishment-update/admin-type-punishment-update.component';
import { AdminAttendanceRuleComponent } from './admin/admin-attendance-rule/admin-attendance-rule.component';
import { AdminAttendanceRuleUpdateComponent } from './admin/admin-attendance-rule/admin-attendance-rule-update/admin-attendance-rule-update.component';
import { AdminCalendarIncidenceComponent } from './admin/admin-calendar-incidence/admin-calendar-incidence.component';
import { AdminTimeClockComponent } from './admin/admin-time-clock/admin-time-clock.component';
import { SurveyThanksComponent } from './anonymous/survey-thanks/survey-thanks.component';
import { AdminSurveyAnswerComponent } from './admin/admin-survey/admin-survey-answer/admin-survey-answer.component';
import { AssistantSurgeriesComponent } from './assistant/assistant-surgeries/assistant-surgeries.component';
import { NurseSurgeriesComponent } from './nurse/nurse-surgeries/nurse-surgeries.component';
import { NurseCensusComponent } from './nurse/nurse-census/nurse-census.component';
import { AdminSurgeriesComponent } from './admin/admin-surgeries/admin-surgeries.component';
import { AssistantPointSaleComponent } from './assistant/assistant-point-sale/assistant-point-sale.component';
import { OwnerSurveyComponent } from './owner/owner-survey/owner-survey.component';
import { AdminCashingByEmergenciesComponent } from './admin/admin-cashing-by-emergencies/admin-cashing-by-emergencies.component';
import { OwnerCashingByEmergenciesComponent } from './owner/owner-cashing-by-emergencies/owner-cashing-by-emergencies.component';
import { OwnerClockComponent } from './owner/owner-clock/owner-clock.component';
import { NursePointSaleComponent } from "./nurse/nurse-point-sale/nurse-point-sale.component";
import {OwnerMotionRequestComponent} from "./owner/owner-motion-request/owner-motion-request.component";

const routes: Routes = [
  { path :'' ,redirectTo:'login' , pathMatch:'full' },
  { path:'login',component:LoginComponent },
  { path:'restore/password', component: RestorePasswordComponent},
  { path:'admin/nurse/dashboard', component:AdminNurseComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/report', component:AdminReportDashboardComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/doctor/list', component:AdminDoctorComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/doctor/update/:uuid', component:AdminDoctorUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/patient/list', component:AdminPatientComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/patient/update/:uuid', component:AdminPatientUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/treasury/list', component:AdminTreasuryExpenseRecordComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/treasury/update/:uuid', component:AdminTreasuryExpenseRecordUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/treasury/expense/list', component:AdminTreasuryExpenseComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/treasury/expense/update/:uuid', component:AdminTreasuryExpenseUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/treasury/expenseClassification/list', component:AdminTreasuryExpenseClassificationComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/treasury/expenseClassification/update/:uuid', component:AdminTreasuryExpenseClassificationUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/treasury/otherIncomeClassification/list', component:AdminTreasuryOtherIncomeClassificationComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/treasury/otherIncomeClassification/update/:uuid', component:AdminTreasuryOtherIncomeClassificationUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/treasury/otherIncome/list', component:AdminTreasuryOtherIncomeComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/treasury/otherIncome/update/:uuid', component:AdminTreasuryOtherIncomeUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/product/list', component:AdminProductComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/product/import/price', component:ProductPriceImportComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/product/update/:uuid', component:AdminProductUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/zone/list', component:AdminZoneComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/zone/update/:uuid', component:AdminZoneUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/schedule/workingHours', component:AdminWorkingHoursComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/schedule/workingHours/update/:uuid', component:AdminWorkingHoursUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/schedule/typePunishment', component:AdminTypePunishmentComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/schedule/typePunishment/update/:uuid', component:AdminTypePunishmentUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/schedule/attendanceRule', component:AdminAttendanceRuleComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/schedule/attendanceRule/update/:uuid', component:AdminAttendanceRuleUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/calendar/incidence', component:AdminCalendarIncidenceComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/timeClock', component:AdminTimeClockComponent, canActivate:[AuthenticationGuard] },

  { path:'admin/inventory/list', component:AdminInventoryComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/transfer/list', component:AdminTransferComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/transfer/update/:uuid', component:AdminTransferUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/category/update/:uuid', component:CategoriesUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/category/list', component:CategoriesComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/characteristic/update/:uuid', component:CharacteristicUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/characteristic/list', component:CharacteristicComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/maker/update/:uuid', component:MakerUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/maker/list', component:MakerComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/unitMeasure/update/:uuid', component:MeasurementUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/unitMeasure/list', component:MeasurementComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/adjustmentType/update/:uuid', component:AdjustmentTypeUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/adjustmentType/list', component:AdjustmentTypeComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/room/update/:uuid', component:AdminRoomUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/room/list', component:AdminRoomComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/warehouse/update/:uuid', component:AdminWarehouseUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/warehouse/stock', component:AdminInventoryStockComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/adjustment/list', component:AdminAdjustmentComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/transfer/validate/:uuid', component:AdminTransferValidateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/transfer/approve/:uuid', component:AdminTransferApproveComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/transfer/validation/:uuid', component:AdminTransferValidationComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/transfer/details/:uuid', component:AdminTransferDetailsComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/role/list', component:AdminRoleComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/role/dashboard', component:AdminRoleDashboardComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/adjustment/update/:uuid', component:AdminAdjustmentUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/adjustment/validate/:uuid', component:AdminAjustmentValidateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/serviceCatalog/update/:uuid', component:AdminServiceCatalogUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/serviceCatalog/list', component:AdminServiceCatalogComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/access', component:AdminAccessComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/pointSale', component:PointSaleComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/pointSale/update/:uuid', component:PointSaleUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/register/update/:uuid', component:AdminNurseRegisterUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/doctors-guards', component:DoctorsGuardsComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/survey', component:AdminSurveyComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/survey/update/:uuid', component:AdminSurveyUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/cashingByEmergencies', component:AdminCashingByEmergenciesComponent, canActivate:[AuthenticationGuard] },
  { path:'assistant/census/list', component:AssistantCensusComponent, canActivate:[AuthenticationGuard] },
  { path:'assistant/register/list', component:AssistantRegisterComponent, canActivate:[AuthenticationGuard] },
  { path:'assistant/register/update/:uuid', component:AssistantNurseRegisterUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'assistant/medicalAppointment', component:AssistantMedicalAppointmentComponent, canActivate:[AuthenticationGuard] },
  { path:'doctor/census/list', component:DoctorCensusComponent, canActivate:[AuthenticationGuard] },
  { path:'doctor/register/list', component:DoctorRegisterComponent, canActivate:[AuthenticationGuard] },
  { path:'doctor/register/update/:uuid', component:DoctorNurseRegisterUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'nurse/dashboard', component:NurseDashboardComponent, canActivate:[AuthenticationGuard] },
  { path:'nurse/surgeries', component:NurseSurgeriesComponent, canActivate:[AuthenticationGuard] },
  { path:'nurse/room/consumption/:uuid', component:NurseRoomConsumptionComponent, canActivate:[AuthenticationGuard] },
  { path:'nurse/register/update/:uuid', component:NurseRegisterUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'nurse/census/list', component:NurseCensusComponent, canActivate:[AuthenticationGuard] },
  { path:'specialist/surgeries', component:SpecialistSurgeriesComponent, canActivate:[AuthenticationGuard] },
  { path:'specialist/medicalConsultation/appointment', component:SpecialistMedicalConsultationUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'specialist/medicalConsultation', component:SpecialistMedicalConsultationComponent, canActivate:[AuthenticationGuard] },
  { path:'specialist/balance/consulting', component:SpecialistBalanceConsultingComponent, canActivate:[AuthenticationGuard] },
  { path:'specialist/census/list', component:SpecialistCensusComponent, canActivate:[AuthenticationGuard] },
  { path:'specialist/dashboard', component:SpecialistDashboardComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/pointSale/feePayment/:uuid', component:OwnerFeePaymentComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/census/list', component:CensusComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/expenseRecord/list', component:OwnerExpenseRecordComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/expenseRecord/update/:uuid', component:OwnerExpenseRecordUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/dashboard', component:OwnerDashboardComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/report', component:OwnerReportComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/reportCie10', component:OwnerReportCie10Component, canActivate:[AuthenticationGuard] },
  { path:'owner/pointSale/list', component:OwnerPointSaleComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/expenseClassification/list', component:OwnerExpenseClassificationComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/expenseClassification/update/:uuid', component:OwnerExpenseClassificationUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/otherIncomeClassification/list', component:OwnerOtherIncomeClassificationComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/otherIncomeClassification/update/:uuid', component:OwnerOtherIncomeClassificationUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/otherIncome/list', component:OwnerOtherIncomeComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/otherIncome/update/:uuid', component:OwnerOtherIncomeUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/expense/list', component:OwnerExpenseComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/expense/update/:uuid', component:OwnerExpenseUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/doctors-guards', component:OwnerDoctorsGuardsComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/medicalConsultation', component:MedicalConsultationComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/medicalConsultation/appointment', component:OwnerMedicalConsultationComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/colaborator/list', component:OwnerColaboratorComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/colaborator/update/:uuid', component:OwnerColaboratorUpdateComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/medicalConsultation/dashboard', component:OwnerMedicalConsultationDashboardComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/slopeTracking/list', component:OwnerSlopeTrackingComponent, canActivate:[AuthenticationGuard] },
  { path:'patient/data', component:PatientBankDataComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/surgeries', component:OwnerSurgeriesComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/balance/consulting', component:OwnerBalanceConsultingComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/survey', component:OwnerSurveyComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/cashingByEmergencies', component:OwnerCashingByEmergenciesComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/clock', component:OwnerClockComponent, canActivate:[AuthenticationGuard] },
  { path:'owner/motionRequest/:uuid', component:OwnerMotionRequestComponent },

  { path:'admin/tasks', component:AdminTasksComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/census/list', component:AdminCensusComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/surgeries', component:AdminSurgeriesComponent, canActivate:[AuthenticationGuard] },
  { path:'admin/reportCie10', component:AdminCie10Component, canActivate:[AuthenticationGuard] },
  { path:'survey/:uuid/:patientUuid', component:SurveyToAnswerComponent },
  { path:'admin/survey/results/:uuid', component:AdminSurveyAnswerComponent, canActivate:[AuthenticationGuard]  },
  { path:'survey/thanks', component:SurveyThanksComponent },
  { path:'assistant/surgeries', component:AssistantSurgeriesComponent, canActivate:[AuthenticationGuard] },
  { path:'assistant/pointSale', component:AssistantPointSaleComponent, canActivate:[AuthenticationGuard] },
  { path:'nurse/pointSale', component:NursePointSaleComponent, canActivate:[AuthenticationGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
