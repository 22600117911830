import { Component, OnInit, Inject } from '@angular/core';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import * as moment from 'moment';
@Component({
  selector: 'app-owner-cashing-by-emergencies-settle',
  templateUrl: './owner-cashing-by-emergencies-settle.component.html',
  styleUrls: ['./owner-cashing-by-emergencies-settle.component.css']
})
export class OwnerCashingByEmergenciesSettleComponent implements OnInit {
  saleClinicModel={
    main:{
      uuid:'',
      total:0.0,
      cashFund:0.0,
      status:'Abierto',
      starDate:'',
      endDate:'',
      totalAttended: 0,
      totalReceived:0.0,
    },
    system:{
      collaborator:{
        main:{
          uuid:''
        }
      },
      otherIncome:{
        main:{
          uuid:''
        }
      }
    }
  }

  object = JSON.parse(JSON.stringify(this.saleClinicModel));

  constructor(public loadingService: LoadingService, private session: SessionService, public dialogRef: MatDialogRef<OwnerCashingByEmergenciesSettleComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar) { }

  ngOnInit() {
    if(this.data != 'new') {
      // this.loadingService.wait();
      console.log('aqui', this.data)
      this.object.main.uuid = this.data;
      this.session.getRequest('saleClinic:get', {main:{uuid: this.object.main.uuid }}).subscribe(
        (data: any) => {
          this.object = data.object;
          this.loadingService.hide();
        }, error => {
          console.log('Error: saleClinic:get', error);
          this.loadingService.hide();
        });
    }
  }

  send(object) {
    this.loadingService.wait();
    this.object.main.status = 'Liquidado';
    this.object.main.starDate = moment(this.object.main.starDate).format("DD-MM-YYYY hh:mm:ss"); 
    this.object.main.endDate = moment(this.object.main.endDate).format("DD-MM-YYYY hh:mm:ss"); 
    this.session.getRequest('saleClinic:update', this.object).subscribe(
      (data: any) => {
        this.object = data.object;
        this.loadingService.hide();
        this.close(true);
      }, error => {
        console.log('Error: saleClinic:update', error);
        this.loadingService.hide();
      });
  }

   /*
   * Cierra el modal
   */
   close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'saleClinic:001';
    }
    this.dialogRef.close(object);
  }

}
