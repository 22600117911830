import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';
import * as moment from 'moment';

@Component({
  selector: 'app-doctors-guards',
  templateUrl: './doctors-guards.component.html',
  styleUrls: ['./doctors-guards.component.css']
})
export class DoctorsGuardsComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;

  collaboratorModel = {
    main:{
      uuid:'',
      specialty: '',
      total: 0.0,
      outstanding: 0.0,
      liquidated: 0.0
    },
    system:{
      physicalPerson:{},
      user:{
        main:{
          uuid:''
        }
      },
      role:'',
      company:{
        main:{
          uuid:''
        }
      },
      status:true,
      guards: []
    }
  };

  metadata = {
    startDate: moment().startOf('month').toISOString(),
    endDate: moment().endOf('month').toISOString(),
  }
  doctorList = [];

  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    /*
    this.findAllByCompanyAndRoleAndStatus('Doctor').then((data: any) => {
      this.doctorList = [];
      for (let x=0; x<data.object.instanceList.length; x++) {
        var doctor = JSON.parse(JSON.stringify(this.collaboratorModel));
        doctor.main.uuid = data.object.instanceList[x].main.uuid;
        doctor.main.specialty = data.object.instanceList[x].main.specialty;
        doctor.main.total = 0;
        doctor.main.liquidated = 0;
        doctor.main.outstanding = 0;
        doctor.system.physicalPerson = data.object.instanceList[x].system.physicalPerson;
        doctor.system.user = data.object.instanceList[x].user;
        doctor.system.role = data.object.instanceList[x].role;
        doctor.system.company = data.object.instanceList[x].company;
        doctor.system.status = data.object.instanceList[x].status;
        this.findAllByDoctorAndDateBetween(doctor).then((data: any) => {
          if (data.object.main.total > 0) {
            this.doctorList.push(data.object);
          }
        });
      }
    });
    */
    this.doctorList = [];
    this.findAllByCompanyAndDateBetween().then((data: any) => {
      console.log('1) ', data.object.instanceList)
      this.doctorList = data.object.instanceList;
    });
  }

  findAllByCompanyAndRoleAndStatus(role) {
    return new Promise((resolve, reject) => {
      this.loadingService.wait();
      this.session.getRequest("collaborator:findAllByCompanyAndRoleAndStatus", {system: {status: true, role: role, company: {main: {uuid: this.session.getSessionObject().main.uuid} } } }).subscribe((data:any)=>{
        resolve({ transaction: 'ok', object: {instanceList: data.object.instanceList, total: data.object.total} });
        this.loadingService.hide();
      },error=>{
        console.log("Error: collaborator:findAllByCompanyAndRoleAndStatus",error);
        this.loadingService.hide();
      })
    });
  }

  findAllByCompanyAndDateBetween() {
    return new Promise((resolve, reject) => {
      this.loadingService.wait();
      this.session.getRequest("guardPay:findAllByCompanyAndDateBetween", {system: {status: true, roomOccupationDetail: {main: {startDate: moment(this.metadata.startDate).format('YYYY-MM-DD'), endDate: moment(this.metadata.endDate).format('YYYY-MM-DD')}}, company: {main: {uuid: this.session.getSessionObject().main.uuid} } } }).subscribe((data:any)=>{
        resolve({ transaction: 'ok', object: {instanceList: data.object.instanceList, total: data.object.total} });
        this.loadingService.hide();
      },error=>{
        console.log("Error: guardPay:findAllByCompanyAndDateBetween",error);
        this.loadingService.hide();
      })
    });
  }

  send(guard) {
    // pasamos la fecha de compleaño al formato que recibe al BD
    this.loadingService.wait();
    guard.main.payDate = moment().format('DD/MM/YYYY HH:mm:ss');
    this.session.getRequest("roomOccupationDoctor:updatePay", guard).subscribe(
      (data:any) => {
        this.snackBar.open('El registro se guardo correctamente', '', {
          duration: 8000
        });
        this.loadingService.hide();
      }, error => {
        console.log("Error: roomOccupationDoctor:updatePay", error);
        this.loadingService.hide();
      });
  }

  changePeriod() {
    /*this.findAllByCompanyAndRoleAndStatus('Doctor').then((data: any) => {
      this.doctorList = [];
      for (let x=0; x<data.object.instanceList.length; x++) {
        var doctor = JSON.parse(JSON.stringify(this.collaboratorModel));
        doctor.main.uuid = data.object.instanceList[x].main.uuid;
        doctor.main.specialty = data.object.instanceList[x].main.specialty;
        doctor.main.total = 0;
        doctor.main.liquidated = 0;
        doctor.main.outstanding = 0;
        doctor.system.physicalPerson = data.object.instanceList[x].system.physicalPerson;
        doctor.system.user = data.object.instanceList[x].user;
        doctor.system.role = data.object.instanceList[x].role;
        doctor.system.company = data.object.instanceList[x].company;
        doctor.system.status = data.object.instanceList[x].status;
        this.findAllByDoctorAndDateBetween(doctor).then((data: any) => {
          if (data.object.main.total > 0) {
            this.doctorList.push(data.object);
          }
        });
      }
    });*/
    this.doctorList = [];
    this.findAllByCompanyAndDateBetween().then((data: any) => {
      console.log('2) ', data.object.instanceList);
      this.doctorList = data.object.instanceList;
    });
  }

  findAllByDoctorAndDateBetween(doctorModel:any) {
    return new Promise((resolve, reject) => {
      this.loadingService.wait();
      this.session.getRequest("roomOccupationDoctor:findAllByDoctorAndDateBetween", {system: { doctor: doctorModel, roomOccupationDetail: {main: {startDate: moment(this.metadata.startDate).format('DD/MM/YYYY HH:mm:ss'), endDate: moment(this.metadata.endDate).endOf('date').format('DD/MM/YYYY HH:mm:ss')} } } }).subscribe(
        (data: any) => {
          var total = 0;
          var liquidated = 0;
          var outstanding = 0;
          for (let x=0; x<data.object.instanceList.length; x++) {
            total = total + data.object.instanceList[x].main.total;
            if (data.object.instanceList[x].main.pay) {
              liquidated = liquidated + data.object.instanceList[x].main.total;
            } else {
              outstanding = outstanding + data.object.instanceList[x].main.total;
            }
            if (data.object.instanceList[x].system.object == 'RoomOccupationRegister') {
              this.getRoomOcupationRegister(data.object.instanceList[x].system.objectUuid).then((result: any) => {
                data.object.instanceList[x].main.payDate = moment(result.object).format('DD/MM/YYYY');
              });
            } else {
              this.getRoomOcupation(data.object.instanceList[x].system.objectUuid).then((result: any) => {
                data.object.instanceList[x].main.payDate = moment(result.object).format('DD/MM/YYYY');
              });
            }
          }
          doctorModel.main.total=total;
          doctorModel.main.liquidated=liquidated;
          doctorModel.main.outstanding=outstanding;
          if (data.object.instanceList.length > 0) {
            doctorModel.system.guards = data.object.instanceList;
          }
          resolve({ transaction: 'ok', object: doctorModel});
          this.loadingService.hide();
        }, error => {
          console.log("Error: roomOccupationDoctor:findAllByDoctorAndDateBetween", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Obtenemos la fecha de la guardía del doctor
   */
   getRoomOcupationRegister(uuid:any) {
     return new Promise((resolve, reject) => {
       this.loadingService.wait();
       this.session.getRequest("roomOccupationRegister:get", { main: {uuid: uuid} }).subscribe(
         (data: any) => {
           resolve({ transaction: 'ok', object: data.object.main.dateRegister});
           this.loadingService.hide();
         }, error => {
           console.log("Error: roomOccupationRegister:get", error);
           this.loadingService.hide();
         });
     });
   }

  /**
   * Obtenemos la fecha de la guardía del doctor
   */
   getRoomOcupation(uuid:any) {
     return new Promise((resolve, reject) => {
       this.loadingService.wait();
       this.session.getRequest("roomOccupation:get", { main: {uuid: uuid} }).subscribe(
         (data: any) => {
           resolve({ transaction: 'ok', object: data.object.system.detail.dateRegister});
           this.loadingService.hide();
         }, error => {
           console.log("Error: roomOccupation:get", error);
           this.loadingService.hide();
         });
     });
   }

  /**
   * Obtiene el detalle del usuario que origino el movimiento
   */
  getDoctor(collaboratorUuid) {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo el detalle del doctor que origino el movimiento, espere un momento...");
      this.session.getRequest("collaborator:get", {main:{uuid: collaboratorUuid }}).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
          this.loadingService.hide();
        },
        error => {
          console.log("Error: collaborator:get", error);
          this.loadingService.hide();
        });
    });
  }

  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
