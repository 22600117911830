import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from './../../service/session/session.module';
import { LoadingService } from './../../directive/loading/loading.module';
import * as moment from 'moment';
import { startOfDay, endOfDay, addMinutes, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { SpecialistSurgeriesUpdateComponent } from './specialist-surgeries-update/specialist-surgeries-update.component';

@Component({
  selector: 'app-specialist-surgeries',
  templateUrl: './specialist-surgeries.component.html',
  styleUrls: ['./specialist-surgeries.component.css']
})
export class SpecialistSurgeriesComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;

  view: CalendarView = CalendarView.Week;

  CalendarView = CalendarView;

  viewDate: Date = new Date();
  viewDateDay: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  colors: any = {
    red: {
      primary: '#cd7672',
      secondary: '#cd7672',
    },
    blue: {
      primary: '#138086',
      secondary: '#138086',
    },
    yellow: {
      primary: '#534666',
      secondary: '#534666',
    },
    purple: {
      primary: '#cd7672',
      secondary: '#cd7672',
    },
    gray:{
      primary: '#eeb462',
      secondary: '#eeb462',
    },
    primeravez:{
      primary: '#9e91b1',
      secondary: '#9e91b1',
    },
    not:{
      primary: '#607d8b',
      secondary: '#607d8b',
    }
  };

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = false;

  surgeryMin = {
    main:{
      dateTimeSurgeryEnd:'',
      dateTimeSurgeryStart:'',
    },
    system:{
      doctor:{
        main:{
          uuid:''
        }
      }
    }
  }
  todaysurgeryMin= {
    main:{
      dateTimeSurgeryEnd:'',
      dateTimeSurgeryStart:''
    }
  }

  collaboratorMin = {
    system:{
      role:'',
      company:{
        main:{
          uuid:''
        }
      },
      status:true,
      max: 100,
      offset: 0,
    },
    filter:{
      name:'searchElement',
      value: ''
    }
  }

  doctorList = [];
  floors = [];
  metadata = {
    firtsTime:true,
    loadded:true,
    totalIncome: 0.0,
    room:{
      main:{
        uuid:''
      }
    },
    date: moment().clone().startOf('day').toISOString(),
  }
  surgeryModel = {
    main: {
      uuid:'',
      dateTimeSurgery:'',
      status:'',
      minute:'30',
      date:''
    },
    system: {
      room:{
        main:{
          uuid:''
        }
      },
      patient: {
        main: {
          uuid: ''
        }
      },
      doctor:{
        main:{
          uuid:''
        }
      }
    }
  };
  rowList = [];

  constructor(private session: SessionService,public dialog: MatDialog, public loadingService: LoadingService, public snackBar: MatSnackBar, private router: Router) { }

  async ngOnInit() {
    this.findAllByCompanyAndRoleAndStatus();
    this.floors = [];
    await this.findAllByCompany();
    //creamos los listado de la matriz
    this.createRowTables();
  }

  beforeViewRender(ev){
    if( this.surgeryMin.main.dateTimeSurgeryStart != moment(ev.period.start).subtract(1, "days").format('DD-MM-YYYY HH:mm')){
      if(this.metadata.firtsTime){
        this.metadata.firtsTime = false;
        setTimeout(()=>{
          this.surgeryMin.main.dateTimeSurgeryStart =  moment(ev.period.start).subtract(1, "days").format('DD-MM-YYYY HH:mm');
          this.surgeryMin.main.dateTimeSurgeryEnd =  moment(ev.period.end).add(1, "days").format('DD-MM-YYYY HH:mm');
          this.getConsultingRoomByDates();
         }, 2000);
      }else{
        this.surgeryMin.main.dateTimeSurgeryStart =  moment(ev.period.start).subtract(1, "days").format('DD-MM-YYYY HH:mm');
        this.surgeryMin.main.dateTimeSurgeryEnd =  moment(ev.period.end).add(1, "days").format('DD-MM-YYYY HH:mm');
        this.getConsultingRoomByDates();
      }
    }
  }

  getConsultingRoomByDates(){
    this.loadingService.show(true,"Espere un momento...");
    this.session.getRequest("validate:findByUsername", {
      system: {
        user: this.session.getUser()
      }
    }).subscribe((data: any) => {
      this.session.getRequest("surgery:findAllByDateTimeSurgeryBetween", this.surgeryMin).subscribe((data:any)=>{
        // console.log(data.object.instanceList);
        this.events = [];
        for(let item of data.object.instanceList){
          this.bindenAgentsToRow(item);
          let date = moment(item.main.dateTimeSurgery).toDate();
          let color:any = {
            primary:'',
            secondary:''
          };
          color = this.colors.red;
          let text = '';
          if(item.system.patient){
            text = item.system.room.main.nameOrNumber+' | ' + item.system.patient.system.physicalPerson.main.name + ' '+ item.system.patient.system.physicalPerson.main.lastname + ' '+ item.system.patient.system.physicalPerson.main.surname;
          }else{
            text = 'No disponible'
          }
          let auxEvent =
          {
            start: date,
            end: addMinutes(date, item.main.minute ? item.main.minute : 30 ),
            title: text,
            color: color,
            allDay: false,
            resizable: {
              beforeStart: false,
              afterEnd: false,
            },
            draggable: false,
            meta:{main:item.main}
          };
          this.events.push(auxEvent);
        }
        this.loadingService.hide();
        //realizamos el bindeo para ver los eventos
      }, error=>{
        this.loadingService.hide();
        console.log(error);
      });
    }, error=>{
      this.loadingService.hide();
      console.log(error);
    });
  }

  dayClicked(event): void {
    // console.log(event)
    this.createAppointMent(event.day.date,'new');
  }

  hourSegmentClicked(event):void{
    // console.log(event);
    this.createAppointMent(event.date,'new');

  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  addEvent(): void {
    this.events = [
      {
        title: 'Nueva cita',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: this.colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  createAppointMentRox(date,object){
    this.metadata.room = JSON.parse(JSON.stringify(object.system.room));
    this.createAppointMent(date,object.main.uuid == '' ? 'new': object.main.uuid);
  }

  createAppointMent(date,uuidSurgery){
    let doctor = {};
    let surgeryAux:any = {
      main:{
        uuid:''
      }
    };
    if(uuidSurgery == 'new'){
      surgeryAux.main.uuid = "";
    }else{
      surgeryAux.main.uuid = uuidSurgery;
      // for(let item of this.rowList){
      //   if(item.date == date){
      //     for(let item2 of item.surgeries){
      //       if(item2.system.room.main.uuid == uuidRoom){
      //         surgeryAux = JSON.parse(JSON.stringify(item2));
      //         surgeryAux.main.date = item.date;
      //       }
      //     }
      //   }
      // };
    }

    this.dialog.open(SpecialistSurgeriesUpdateComponent, {
      width: '80%',
      height: '90%',
      data: { object: date, surgery:surgeryAux, doctor:doctor, room:this.metadata.room  }
    }).afterClosed().subscribe(result => {
      if(result){
        if(result.transaction == 'ok'){
          this.getConsultingRoomByDates();
        }
      }
    });
  }

  handleEvent(action: string, event: CalendarEvent): void {
    switch(action){
      case 'Clicked':
        this.createAppointMent(event.start,event.meta.main.uuid)
      break;
      default:
      // console.log("No hacemos nada");
      break;
    }
  }

  /** funcionalidad para obtener role por company y status
  */
  findAllByCompanyAndRoleAndStatus(){
    this.loadingService.wait();
    this.collaboratorMin.system.role = 'Specialist doctor';
    this.collaboratorMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("collaborator:findAllByCompanyAndRoleAndStatus",this.collaboratorMin).subscribe((data:any)=>{
      this.doctorList = data.object.instanceList;
      this.surgeryMin.system.doctor.main.uuid = data.object.instanceList[0].main.uuid;
      this.loadingService.hide();
    },error=>{
      console.log("error: findAllByCompanyAndRoleAndStatus",error);
      this.loadingService.hide();
    })
  }



  /**
   * Encuentra todos los pisos asociados a la empresa indicada
   */
  findAllByCompany() {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo pisos, espere un momento...");
      this.session.getRequest("room:findAllByAssignCalendar", {main:{assignCalendar:true}}).subscribe(
        async(data: any) => {
          this.floors = data.object.instanceList;
          // console.log("Floors::::::::::: > :::::::::::",this.floors);
          resolve({ transaction: 'ok', object: { instanceList: [] } });
          this.loadingService.hide();
        },
        error => {
          console.log("Error: floor:findAllByCompany", error);
          this.loadingService.hide();
        });
    });
  }

  createRowTables(){
    for(let  i = 7; i <= 23; i++){
      let aux:any = {
        date:'',
        surgeries:[]
      }
      aux.date = moment(this.viewDateDay).set('hour',i).set('minute',0).set('second',0).set('millisecond',0).toDate();
      let aux2 = JSON.parse(JSON.stringify(aux));
      aux2.date = moment(this.viewDateDay).set('hour',i).set('minute',30).set('second',0).set('millisecond',0).toDate();
      let surgeriesAux = [];
      for(let item of this.floors){
        let sModel = JSON.parse(JSON.stringify(this.surgeryModel));
        sModel.system.room.main = item.main;
        surgeriesAux.push(sModel);
      }
      aux.surgeries = JSON.parse(JSON.stringify(surgeriesAux));
      aux2.surgeries  = JSON.parse(JSON.stringify(surgeriesAux));

      this.rowList.push(aux);
      this.rowList.push(aux2);
    }
    // console.log("ROW:::::",this.rowList);
  }


  /** funcionalidad para realizar el bindeo del row
  */
  bindenAgentsToRow(object){
    for(let i = 0 ; i < this.rowList.length; i++){
      let dateAux = moment(object.main.dateTimeSurgery).toDate();
      if(this.rowList[i].date.getTime() == dateAux.getTime()){
        for(let j = 0; j < this.rowList[i].surgeries.length; j++){
          if(this.rowList[i].surgeries[j].system.room.main.uuid == object.system.room.main.uuid){
            this.rowList[i].surgeries[j] = JSON.parse(JSON.stringify(object));
          }
        }
      }

    }
  }

  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }
}
