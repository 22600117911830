import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatSnackBar,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';

@Component({
  selector: 'app-admin-access-access',
  templateUrl: './admin-access-access.component.html',
  styleUrls: ['./admin-access-access.component.css']
})
export class AdminAccessAccessComponent implements OnInit {

  userObject = {
    main: {
      uuid: ''
    },
    user: {
      main: {
        uuid: '',
        username: ''
      }
    },
    role: {
      main: {
        uuid: ''
      }
    },
    system: {
      objectIdTemp: '',
      objectId: '',
      objectType: 'Company',
      listObjects:[]
    }
  };
  rolesList = [];
  objectsList= [];
  objectsAssingList= [];
  branchList = [];
  object = JSON.parse(JSON.stringify(this.userObject));
  user:any = {
    main:{
      uuid:"",
      username:"",
      password:"",
      passwordConfirm:"",
    },
    complement:{
      enabled:"true",
      accountExpired:"false",
      accountLocked:"false",
      passwordExpired:"false"
    },
    system:{
      roles:[],
      status:true,
      type: "Company",
      objectUuid: "",
      physicalPerson: {
        main:{
          uuid:''
        },
        complement:{
          rfc:''
        }
      }
    }
  };
  metadata = {
    userType:'',
    userObjectUuid:''
  }
  constructor(protected session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar,public dialogRef: MatDialogRef<AdminAccessAccessComponent>, @Inject(MAT_DIALOG_DATA) public data: any,) {
  }

  ngOnInit() {
    this.user = JSON.parse(JSON.stringify(this.data.object));
    this.metadata.userType = this.user.system.type;
    this.metadata.userObjectUuid = this.user.system.objectUuid;
    // cargamos los recursos requeridos
    this.getRoles().then(data=>{
    this.object.user.main.username = this.user.main.username;
    this.object.user.main.uuid = this.user.main.uuid;
    this.object.system.objectIdTemp = this.metadata.userObjectUuid;
    this.object.system.objectId = this.session.getSessionObject().main.uuid;
    this.object.role.main.uuid = '';
    this.object.system.objectType = this.user.system.type;
    this.getRolByUser().then(data=>{
      if(this.user.system.roles.length > 0){
         this.object.role = this.user.system.roles[0];
      }
      this.getObjectsAssing(this.user);
    }).catch(error=>{
      console.log('Error:getRolByUser',error);
    })
    this.objectsList = [];
    this.object.system.listObjects = [];
    });
  }

  /*
  funcion para obtener el rol de un usuario
  */
  getRolByUser(){
    return new Promise((resolve,reject)=>{
      this.session.getRequest('user:findAllRoleByUser',{user:{main:{uuid:this.user.main.uuid}}}).subscribe((data:any)=>{
        this.user.system.roles = data.object.instanceList;
        resolve(true);
      },error=>{
        reject(error);
        console.log('Error:user:findAllRoleByUser',error);
      })
    })
  }

  //obtenemos los roles registrados en el sistema
  getRoles(){
   return new Promise(resolve=>{
    this.session.getRequest('role:findAllByObjectTypeAndObjectId',{system:{objectType: 'Company', objectId:this.session.getSessionObject().main.uuid}}).subscribe(
      (data:any)=>{
        if(data.object.instanceList.length > 0){
          this.rolesList = data.object.instanceList;
          resolve(this.rolesList);
        }
        else{
          console.log("No hay roles registrados");
        }
      },
      error => {
        console.log("role:findAllByObjectTypeAndObjectId",error);
      })
    })
  }

  //obtenemos los objetos asignados al usuario
  getObjectsAssing(user){
   return new Promise(resolve=>{
      this.objectsAssingList = [];
      this.session.getRequest('validate:userObjects', user).subscribe(
        (data:any)=>{
          this.objectsAssingList = data.objectList;
          resolve(this.objectsAssingList);
        },
        error => {
          console.log("validate:userObjects",error);
        })
    })
  }
  /**funcion a ejecutar cuando se selecciona un rol
  */
  async radioRoleSelected(ev){
    let changingRole = await this.changeRole(this.object);
    if(changingRole){
      for(let item of this.rolesList){
        if(item.main.uuid == ev.value){
          this.object.role.main = item.main;
        }
      }
      this.objectsList = [];
      this.object.system.listObjects = [];
      this.object.system.objectId = this.metadata.userObjectUuid;
      this.object.system.listObjects.push({
        main:this.session.getSessionObject().main
      })
      this.addRol();
    }else{
      this.snackBar.open('Hubo un error intente nuevamente', 'Información', {duration: 5000});
      this.object.role.main.uuid = '';
    }
  }

  /** Permite asignar el rol al usuario **/
   addRol(){
     this.loadingService.show(true,'Guardando accesos de usuario');
      this.session.getRequest('user:updateUserObject', this.object).subscribe(
        (data:any)=>{
          // console.log("¡Guardado Exitoso!");
          this.loadingService.hide();
          this.user.system.type = this.object.system.objectType;
          this.session.getRequest('user:update', this.user).subscribe((data:any)=>{
            this.snackBar.open('Registro guardado exitosamente', 'Información', {duration: 5000});
            this.loadingService.hide();
          },
          (error)=>{
            console.log('Error:user:update',error)
            this.snackBar.open(error.message, 'Error', {duration: 5000});
            this.loadingService.hide();
          })
        },
        error => {
          console.log("user:updateUserObject",error);
          this.snackBar.open(error.message, 'Error', {duration: 5000});
          this.loadingService.hide();
        })
   }

   /** Permite modifcar el rol asignado al usuario **/
   changeRole(object){
     return new Promise((resolve,reject)=>{
       this.loadingService.wait();
       this.object.system.objectId = '';
       this.object.role.main.uuid = '';
       this.objectsList = [];
       this.objectsAssingList = [];
       this.object.system.listObjects = [];

       this.session.getRequest('user:deleteByRolAndObjects', object).subscribe(
        (data:any)=>{
          // console.log("¡Listo para cambio de rol!");
          // this.snackBar.open('Listo para cambio de rol', 'Información', {duration: 5000});
          this.loadingService.hide();
          resolve(true);
        },
        error => {
          console.log("user:deleteByRolAndObjects",error);
          this.snackBar.open('Error al modificar rol', 'Error', {duration: 5000});
          this.loadingService.hide();
          reject(false);
        })
     });
   }

  close(status){
    this.dialogRef.close(status);
  }

}
