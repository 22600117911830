import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SessionService } from '../../service/session/session.service';
import * as Rx from 'rxjs/Rx';
import { LoadingService } from '../../directive/loading/loading.module';

@Component({
  selector: 'app-admin-type-punishment',
  templateUrl: './admin-type-punishment.component.html',
  styleUrls: ['./admin-type-punishment.component.css']
})
export class AdminTypePunishmentComponent implements OnInit {

  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  typePunishmentMin = {
    main: {
      uuid: '',
      name: '',
      daySalary: '',
      default: ''
    },
    system: {
      status: true,
      company: {
        main: {
          uuid: ''
        }
      }
    },
    filter: {
      name: "",
      value: ""
    },
    max: 10,
    offset: 0
  };

  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100],
  };
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object: {
      main: {
        uuid: ''
      }
    }
  }
  typePunishmentList = [];

  // MatPaginator Output
  pageEvent: PageEvent;
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) {
    this.typePunishmentMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.typePunishmentList = [];
          this.typePunishmentMin.filter.name = 'searchElement';
        } else {
          this.typePunishmentList = [];
          this.typePunishmentMin.filter.name = '';
          this.typePunishmentMin.filter.value = '';
          val = 'false'
        }
        this.getTypePunishmentList();
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged:');
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        console.log(error);
      });
  }

  ngOnInit() {
    this.getTypePunishmentList();
  }

  getTypePunishmentList() {
    this.loadingService.wait();
    this.session.getRequest('typePunishment:findAllByCompany', this.typePunishmentMin).subscribe(
      (data: any) => {
        this.typePunishmentList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        for (let i = 0; i < this.typePunishmentList.length; i++) {
          this.typePunishmentList[i].system.selected = false
        }
        this.loadingService.hide();
      },
      error => {
        console.log("typePunishment:findAllByCompany", error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        this.loadingService.hide();
      })
  }

  /*
  Funcionalidad para colocar el objeto en el menu de opciones
  */
  objectSelected(object) {
    for (let i = 0; i < this.typePunishmentList.length; i++) {
      this.typePunishmentList[i].system.selected = false;
    }
    if (object.main.uuid == this.metadata.object.main.uuid) {
      object.system.selected = false;
      this.metadata.object.main.uuid = '';
    } else {
      object.system.selected = true;
      this.metadata.object = JSON.parse(JSON.stringify(object));;
    }
  }

  /*
    *Funcion para buscar
  */
  search() {
    this.metadata.searchBoxInput.next(this.typePunishmentMin.filter.value);
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event) {
    this.typePunishmentMin.offset = (event.pageIndex * event.pageSize);
    this.typePunishmentMin.max = event.pageSize;
    this.getTypePunishmentList();
  }

  handleUpdated(event) {
    this.typePunishmentMin.offset = (this.pageEvent ? this.pageEvent.pageIndex * this.pageEvent.pageSize : this.typePunishmentMin.offset);
    this.getTypePunishmentList();
  }
  /*
  funcion para controlar si se abre o cierra el menu
  */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
