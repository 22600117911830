import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import * as Rx from 'rxjs/Rx';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { OwnerFeePaymentUpdateComponent } from './owner-fee-payment-update/owner-fee-payment-update.component';
import { OwnerFeedPaymentAnnotationComponent } from './owner-feed-payment-annotation/owner-feed-payment-annotation.component';

@Component({
  selector: 'app-owner-fee-payment',
  templateUrl: './owner-fee-payment.component.html',
  styleUrls: ['./owner-fee-payment.component.css']
})
export class OwnerFeePaymentComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;
  feedPaymentList = [];
  object: any = {
    main: {
      uuid: '',
      saleNumber: '',
      enableTax: false,
    },
    complement: {
      description: ''
    },
    system: {
      patient: {
        system: {
          physicalPerson: {
            main: {
              name: '',
              lastname: '',
              surname: ''
            }
          },
        }
      },
      salesPayment: {
        main: {
          amount: 0.0,
          paymentDate: '',
        }
      },
      salesDetails: [
        {
          main: {
            uuid: ''
          },
          system: {
            objectDetail: {
              system: {
                user: {
                  system: {
                    physicalPerson: {
                      main: {
                        name: '',
                        surname: '',
                        lastname: ''
                      }
                    }
                  }
                },
                object: '',
                room: {
                  main: {
                    nameOrNumber: '',
                  }
                }
              }
            }
          }
        },
      ]
    }
  };
  metadata = {
    totalReceive: 0.0,
    receive: 0.0
  }

  constructor(
    protected session: SessionService,
    public dialog: MatDialog,
    public loadingService: LoadingService,
    public snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location:Location,
    public dialogRef: MatDialogRef<OwnerFeePaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if(this.data != 'new'){
      this.object.main.uuid = this.data;
      // cargamos los datos de la BD
      this.loadingService.show(true, "Espere un momento... Obteniendo el detalle.")
      this.session.getRequest('sales:get', this.object).subscribe((data: any) => {
        this.object = JSON.parse(JSON.stringify(data.object));

        // obtenemos el detalle
        for (let x=0; x<data.object.system.salesDetails.length; x++) {
          if (data.object.system.salesDetails[x].system.object == 'OtherCharge') {
            // Obtenemos el dato y validamos que sea hospitalario
            this.session.getRequest("otherCharge:get", {main: { uuid: data.object.system.salesDetails[x].system.objectUuid}}).subscribe(
              (dat: any) => {
                if (dat.object.main.status == 'Hospitalario' || dat.object.main.status == 'Otro hospitalario') {
                  this.metadata.totalReceive = this.metadata.totalReceive + dat.object.main.total;
                }
                this.loadingService.hide();
              }, error => {
                console.log("Error: otherCharge:get", error);
                this.loadingService.hide();
              });
          } else {
            this.metadata.totalReceive = this.metadata.totalReceive + data.object.system.salesDetails[x].main.total;
          }
        }

        this.feedPaymentList = [];
        this.getFeedPaymentList().then((data: any) => {
          this.feedPaymentList = data.object.instanceList;
          if (this.feedPaymentList.length == 0) {
            this.payment(this.object, 'sales');
          } else {
            for (let x=0; x<this.feedPaymentList.length; x++) {
              this.metadata.receive = this.metadata.receive + this.feedPaymentList[x].main.pay;
            }
          }
          this.loadingService.hide();
        });
      }, (error) => {
        console.log('Error: sales:get', error)
        this.loadingService.hide();
      });
    }
  }

  ngOnInit() {

  }

  getFeedPaymentList() {
    return new Promise((resolve, reject) => {
      // Se obtienen las clasificaciones de gastos
      this.session.getRequest("feedPayment:findAllBySales", {system: {sales: {main: {uuid: this.object.main.uuid} } } }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: { instanceList: data.object.instanceList } });
        },
        error => {
          console.log("Error: feedPayment:findAllBySales", error);
      });
    });
  }

  payment(object, type) {
    let dialogRef = this.dialog.open(OwnerFeePaymentUpdateComponent, {
      width: '70%',
      data: {object: object, type: type},
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.feedPaymentList = [];
          this.getFeedPaymentList().then((data: any) => {
            this.metadata.receive = 0;
            this.feedPaymentList = data.object.instanceList;
            for (let x=0; x < this.feedPaymentList.length; x++) {
              this.metadata.receive = this.metadata.receive + this.feedPaymentList[x].main.pay;
            }
            this.object.system.feedPayments = this.feedPaymentList;
            this.loadingService.hide();
          });
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'price_list:001';
    }
    this.dialogRef.close(object);
  }



  closeAnnotation(totalReceive, receive) {
    console.log(this.object);
    let dialogRef = this.dialog.open(OwnerFeedPaymentAnnotationComponent, {
      width: '70%',
      data: this.object,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          this.object.system.received = 3;
          this.session.getRequest('sales:updateReceive', this.object).subscribe((data: any) => {
            this.close(true);
          }, (error) => {
            console.log('Error: sales:updateReceive', error)
            this.loadingService.hide();
          });
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  closeUpdate(totalReceive, receive){
    console.log("totalReceive",totalReceive);
    console.log("receive",receive)
    this.object.system.received = 3;
    // if (totalReceive == receive) {
    //   this.object.system.received = 3;
    // }
    // if (totalReceive > receive) {
    //   this.object.system.received = 2;
    // }

    this.session.getRequest('sales:updateReceive', this.object).subscribe((data: any) => {
      this.close(true);
    }, (error) => {
      console.log('Error: sales:updateReceive', error)
      this.loadingService.hide();
    });
  }

  closeJustUpdate(totalReceive,receive){
    console.log("totalReceive",totalReceive);
    console.log("receive",receive)
    // this.object.system.received = 1;
     this.object.system.received = 2;
    this.session.getRequest('sales:updateReceive', this.object).subscribe((data: any) => {
      this.close(true);
    }, (error) => {
      console.log('Error: sales:updateReceive', error)
      this.loadingService.hide();
    });
  }

  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
  back(){
    this.location.back();
  }

}
