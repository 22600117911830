import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import * as moment from 'moment';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import { NursePatientUpdateComponent } from '../../../nurse/nurse-dashboard/nurse-register-update/nurse-patient-update/nurse-patient-update.component';
import { PatientMedicalRecordsComponent } from '../../../directive/patient-medical-records/patient-medical-records.component';

@Component({
  selector: 'app-owner-consultation-patient',
  templateUrl: './owner-consultation-patient.component.html',
  styleUrls: ['./owner-consultation-patient.component.css']
})
export class OwnerConsultationPatientComponent implements OnInit {
  patientMin = {
    system:{
      company:{
        main:{
          uuid:''
        }
      },
    },
    max: 5,
    offset: 0,
    sort:"sPhysicalPerson.mName",
    order:"asc",
    filter:{
      name:'',
      value: ''
    }
  }
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  pageEvent: PageEvent;
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    },
    patient:{
      main:{
        uuid:''
      }
    }
  }
  list = [];

  constructor(
    private session: SessionService,
    public loadingService: LoadingService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<OwnerConsultationPatientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog:MatDialog,
  ) { }

  ngOnInit() {
    this.patientMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.list = [];
        this.patientMin.filter.name = 'searchElement';
      }else{
        this.list = [];
        this.patientMin.filter.name = '';
        this.patientMin.filter.value = '';
        val = 'false'
      }
      this.findAllByCompany();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
        console.log('error logged: ', error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
      });
    this.findAllByCompany();
  }

  findAllByCompany() {
    this.loadingService.wait();
    this.patientMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("patient:findAllByCompany", this.patientMin).subscribe((data:any)=>{
      this.list = data.object.instanceList;
      this.paginatorModel.total = data.object.total;
      this.loadingService.hide();
    },error=>{
      console.log("error",error);
      this.loadingService.hide();
    })
  }

  /**
   * Funcion para buscar
   */
  search() {
    this.metadata.searchBoxInput.next(this.patientMin.filter.value);
  }

  /**
   * Apertura la pantalla para registrar un nuevo paciente
   */
  editPatientDialog(objectUuid) {
    if(objectUuid == 'new'){
      this.dialog.open(NursePatientUpdateComponent, {
        width: '70%',
      }).afterClosed().subscribe(result => {
        if (result.main.uuid !== '') {
          this.findAllByCompany();
        } else {
          this.patientMin.filter.value = '';
        }
      });
    }else{
      this.dialog.open(NursePatientUpdateComponent, {
        width: '70%',
        data: objectUuid,
      }).afterClosed().subscribe(result => {
        if (result.main.uuid !== '') {
          this.findAllByCompany();
        } else {
          this.patientMin.filter.value = '';
        }
      });
    }

  }

  /**funcionalidad para abrir el panel de notas medicas del paciente
  */
  openPatientMedicalNotes(patient){
    this.dialog.open(PatientMedicalRecordsComponent, {
      width: '90%',
      height: '90%',
      data: patient,
      // disableClose:true
    }).afterClosed().subscribe(result => {
      if(result){
        if(result.transaction == 'ok'){
          console.log(result);
        }
      }
    });
  }

  /**
   * funcion para obtener los datos del paginado.
   */
  onPaginateChange(event) {
    this.patientMin.offset = (event.pageIndex * event.pageSize);
    this.patientMin.max = event.pageSize;
    this.findAllByCompany();
  }

  /**funcionalidad para selecionar un paciente.
  **/
  patientSelected(object){
    this.metadata.patient = JSON.parse(JSON.stringify(object));
    this.close(true);
  }



  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.metadata.patient;
    }else{
      object.transaction = 'bad';
      object.code = 'owner:patient:consultation:001';
    }
    this.dialogRef.close(object);
  }

}
