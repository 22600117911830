import { Component, OnInit, Inject } from '@angular/core';
import { LoadingService } from '../../directive/loading/loading.module';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.css']
})
export class SelectCompanyComponent implements OnInit {
  result = {
    transaction:'ok',
    object:{},
    code:'selectedObjectComponent:001'
  }
  companyList = [];
  metadata = {
    company:{
      main:{
        uuid:''
      }
    }
  }
  object:any = {}
  constructor(public dialogRef: MatDialogRef<SelectCompanyComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) {
    console.log('companies ', data);
    this.companyList = data;
  }

  ngOnInit() {
  }
  /**
   * funcionalidad para seeleccionar una compañia
   */
  selectedCompany(){
    for(let item of this.companyList){
      if(this.metadata.company.main.uuid == item.system.object.main.uuid){
        this.object = JSON.parse(JSON.stringify(item));
      }
    }
    this.close(true);
  }

    /** funcionalidad para cerrar sesión
  */
    close(status){

  
      if(status){
        this.result.object = this.object;
      }else{
        this.result.transaction = 'bad';
        this.result.code = 'selectedObjectComponent:002';
      }
      this.dialogRef.close(this.result);
    }

}
