import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { SessionService } from './../../service/session/session.module';
import { LoadingService } from './../../directive/loading/loading.module';
import { MedicalRecordsDetailsComponent  } from '../medical-records-details/medical-records-details.component';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';

@Component({
  selector: 'app-patient-medical-records',
  templateUrl: './patient-medical-records.component.html',
  styleUrls: ['./patient-medical-records.component.css']
})
export class PatientMedicalRecordsComponent implements OnInit {

  metadata = {
    patientYear: 0,
    patientMonth:0,
    patient:{
      main:{
        uuid:''
      }
    },
    doctor:{
      main:{
        uuid:''
      }
    }
  }
  physicalPerson = {
    main: {
      uuid: '',
      name: '',
      lastname: '',
      surname: ''
    },
    complement: {
      dateBirth: '',
      curp: '',
      rfc: '',
      gender: ''
    },
    system: {
      status:true,
    }
  };

  medicalNotesModel = {
    main: {
      uuid:'',
      // Fecha y hora de la cita
      dateMedical: '',
      note: '',
      typeNote: '',
      share:true,
      horaryNote:'',
    },
    system: {
      doctor: {
        main: {
          uuid: ''
        }
      },
      patient: {
        main: {
          uuid: ''
        },
        system: {
          physicalPerson:this.physicalPerson,
        }
      }
    }
  }
  object = JSON.parse(JSON.stringify(this.medicalNotesModel));
  medicalNotesList = [];
  // ========= Modelo de signos vitales  =========
  vitalSignsModel = {
    main:{
      uuid:'',
      dataType:'',
      value:''
    },
    system:{
      patient:{
        main:{
          uuid:''
        }
      },
      consultingRoom:{
        main:{
          uuid:''
        }
      },
      medicalNotes:{
        main:{
          uuid:''
        }
      }
    }
  }
  horaryNoteList = ['Matutino', 'Vespertino', 'Nocturno'];
  vitalSignsList = [];
  vitalSignsTypes =[
    'Presión arterial',
    'Pulso',
    'Temperatura',
    'Oxigenación',
    'Dextrosa',
    'Peso',
    'Estatura',
    'IMC'
  ]
  // ========= fin Modelo de signos vitales  =========
  medicalNotesCIE10Model ={
    main:{
      uuid:''
    },
    system:{
      cie10:{
        main:{
          uuid:''
        }
      },
      medicalNotes:{
        main:{
          uuid:''
        }
      }
    }
  }
  medicalNotesCIE10List = [];
  cie10List = [];
  searchCie10 = new Rx.Subject<string>();
  minCie10={main: {description: ''} };
  constructor(
    private session: SessionService,
    public loadingService: LoadingService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<PatientMedicalRecordsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog:MatDialog
  ) {
    // console.log("Aqui datos enviados al modal::::::",this.data);
    this.searchCie10.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.cie10List = [];
        } else {
          this.cie10List = [];
          this.minCie10.main.description= '';
        }
        this.getCie10();
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged:');
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
        console.log(error);
      });
   }

  async ngOnInit() {
    this.object = JSON.parse(JSON.stringify(this.medicalNotesModel));
    this.object.main.dateMedical = moment().format('DD/MM/YYYY hh:mm:ss');
    // Obtenemos los datos del paciente
    this.session.getRequest("validate:findByUsername", {
      system: {
        user: this.session.getUser()
      }
    }).subscribe(async(data: any) => {
      // console.log("validate:findByUsername",data);
      this.metadata.doctor.main.uuid = data.object.main.uuid;
      this.object.system.doctor.main.uuid = this.metadata.doctor.main.uuid;
    }, error=>{
      this.loadingService.hide();
      console.log(error);
    });
    await this.getPatient(this.data.main.uuid);
  }

  getPatient(uuid: string) {
    return new Promise((resolve,reject)=>{
      this.loadingService.show(true,"Cargando datos del paciente...");
      this.session.getRequest("patient:get", { main: { uuid: uuid} }).subscribe((data: any) => {
        this.object.system.patient = data.object;
        // Calculamos los años de paciente
        this.metadata.patientYear = moment().diff(moment(data.object.system.physicalPerson.complement.dateBirth), 'years',false);
        var years = moment().diff(moment(data.object.system.physicalPerson.complement.dateBirth), 'years',true);
        var fraction = years % 1;
        var months = Math.trunc(fraction * 12)
        this.metadata.patientMonth = months;

        this.loadingService.hide();
        for(let item of this.vitalSignsTypes){
          let aux = JSON.parse(JSON.stringify(this.vitalSignsModel));
          aux.main.dataType = item;
          aux.system.patient.main.uuid = this.object.system.patient.main.uuid;
          // aux.system.consultingRoom.main.uuid = this.metadata.consultingRoom.main.uuid;
          this.vitalSignsList.push(aux);
        }
        // Obtenemos el historico de citas del paciente seleccionado
        this.findAllByPatient(this.object.system.patient);
        resolve(true);
      },
      error => {
        console.log("Error: patient:get",error);
        this.loadingService.hide();
        reject(error);
      });
    })

  }

  /**funcionalidad para cargar las notas medicas por pacientes.
  */
  findAllByPatient(patient) {
    this.loadingService.show(true,"Cargado notas medicas anteriores...");
    this.medicalNotesList = [];
    this.session.getRequest("medicalNotes:findAllByPatient", { system: {patient: patient} }).subscribe((data: any) => {
      this.medicalNotesList = data.object.instanceList.filter((item)=>{
        return item.main.share == true || item.system.doctor.main.uuid == this.metadata.doctor.main.uuid;
      });
      this.loadingService.hide();
    }, error => {
      console.log("Error: medicalNotes:findAllByPatient",error);
      this.loadingService.hide();
    });
  }


    getMedicalConsulting(patient) {
      if (patient.main.uuid != '') {
        this.loadingService.show(true, "Espere un momento... Obteniendo el ultimo registro.");
        this.session.getRequest("medicalNotes:last", {system: {patient: patient} }).subscribe(
          (data:any) => {
            if (data.object != '') {
              this.object.main.note = data.object.main.note;
            }
            this.loadingService.hide();
          }, error => {
            console.log("Error: medicalNotes:last",error);
            this.loadingService.hide();
          });
      } else {
        this.snackBar.open('Información', 'Debe seleccionar un paciente, por favor', {
          duration: 8000
        });
      }
    }

    send(medicalNote) {
      if(medicalNote.main.typeNote != '' && medicalNote.main.note != ''){
        this.loadingService.show(true, "Espere un momento... Se esta guardando el registro.");
        this.session.getRequest("medicalNotes:update", medicalNote).subscribe(
          async (data:any) => {
            this.object = data.object;
            this.snackBar.open('La nota medica se guardo correctamente', '', {
              duration: 8000
            });
            //Guardamos medicalNotesCIE10 si existe
            for(let item of this.medicalNotesCIE10List){
              item.system.medicalNotes.main = this.object.main;
              this.saveCie10(item);
            }
            //Actualizamos la nota medica con signos vitales
            for(let item of this.vitalSignsList){
              if(item.main.value != ''){
                item.system.medicalNotes ={
                  main:{
                    uuid:this.object.main.uuid
                  }
                };
                await this.saveSigVitals(item);
              }
            }
            // Validamos si el objeto ya esta en a lista de hitorias clinicas
            let found = true;
            for (let x=0; x<this.medicalNotesList.length; x++) {
              if (this.medicalNotesList[x].main.uuid == this.object.main.uuid) {
                found = false;
              }
            }
            if (found) {
              this.medicalNotesList.unshift(this.object)
            }
            this.loadingService.hide();

          }, error => {
            console.log("Error: medicalNotes:update",error);
            this.loadingService.hide();
          });
      }

    }
    saveSigVitals(object){
      this.session.getRequest("vitalSigns:update",object).subscribe((data:any)=>{
        object.main.uuid = data.object.main.uuid;
      },error=>{
        console.log(error);
      });
    }

    //calculamos indice de masa corporal
    calulateIMC(object){

      if(object.main.dataType == 'Estatura' || object.main.dataType == 'Peso'){
        let peso = '';
        let est = '';
        let imc:any = '';
        for(let item of this.vitalSignsList){
          if(item.main.dataType == 'Estatura'){
            est = item.main.value;
          }
          if(item.main.dataType == 'Peso'){
            peso= item.main.value;
          }
        }
        if(peso != '' && est != ''){
          imc = parseFloat(peso)/(parseFloat(est)*parseFloat(est));
        }
        if(imc != ''){
          for(let i = 0 ; i < this.vitalSignsList.length; i++){
            if(this.vitalSignsList[i].main.dataType == 'IMC'){
              this.vitalSignsList[i].main.value = Math.round(imc*100)/100;
            }
          }
        }
      }

    }


    openHistory(medicalNote) {
      this.dialog.open(MedicalRecordsDetailsComponent, {
        width: '60%',
        height: '90%',
        data: { object: medicalNote}
      }).afterClosed().subscribe(result => {
        if (result) {
          // this.findAllUsersByCompany();
        }
      });
    }

    clean(){
      this.object = JSON.parse(JSON.stringify(this.medicalNotesModel));
      this.object.system.doctor.main.uuid = this.metadata.doctor.main.uuid;
      this.vitalSignsList = [];
      this.medicalNotesCIE10List = [];
    }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.metadata;
    }else{
      object.transaction = 'bad';
      object.code = 'officeExpenses:001';
    }
    this.dialogRef.close(object);
  }


  /**
   * Obtiene el listado de ci-10
   */
  getCie10() {
    this.loadingService.wait();
    this.session.getRequest("cie10:findAllByDescriptionLike", this.minCie10).subscribe(
      (data: any) => {
        this.cie10List = data.object.instanceList;
        this.loadingService.hide();
      },
      error => {
        console.log("Error: cie10:findAllByDescriptionLike", error);
        this.loadingService.hide();
      });
  }

  /**
   * Funcion para buscar paciente
   */
  searchCie() {
    this.searchCie10.next(this.minCie10.main.description);
  }

  /**funcion para selecciona run cie10
  */
  cie10Selected(object){
    let aux  = JSON.parse(JSON.stringify(this.medicalNotesCIE10Model));
    aux.system.cie10.main =object.main;
    this.medicalNotesCIE10List.push(aux);
    setTimeout(()=>{
        this.minCie10.main.description = '';
    }, 500);
    // console.log(this.medicalNotesCIE10List);
  }
  /**funcion para guardar un cie10 a una nota medica
  */
  saveCie10(object){
    this.session.getRequest("medicalNotesCIE10:update", object).subscribe(
      (data: any) => {
        object.main.uuid = data.object.main.uuid;
      },
      error => {
        console.log("Error: medicalNotesCIE10:update", error);
      });
  }

  /**función para eliminar un cie10 de una nota mediaca
  */
  deleteCie10(object,index){
    if(object.main.uuid != ''){
      this.loadingService.show(true,"Espere un momento...");
      this.session.getRequest("medicalNotesCIE10:delete", object).subscribe(
        (data: any) => {
          this.medicalNotesCIE10List.splice(index,1);
          this.loadingService.hide();
        },
        error => {
          console.log("Error: medicalNotesCIE10:delete", error);
          this.loadingService.hide();
        });
    }else{
      this.medicalNotesCIE10List.splice(index,1);
    }
  }

  /*Funcion para obtener los cie10 asociados a una nota medica.
  */
  findAllCie10FromMedicalNote(medicalNotes){
    this.session.getRequest("medicalNotesCIE10:findAllByMedicalNotes", {system:{medicalNotes:{main:{uuid:medicalNotes.main.uuid}}}}).subscribe(
      (data: any) => {
        for(let i = 0; i < data.object.instanceList.length; i++){
          data.object.instanceList[i].main.uuid = '';
          data.object.instanceList[i].system.medicalNotes.main.uuid = '';
        }
        this.medicalNotesCIE10List = data.object.instanceList;
      },
      error => {
        console.log("Error: medicalNotesCIE10:findAllByMedicalNotes", error);
      });
  }

}
