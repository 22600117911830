import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { MatSnackBar, MatDialog } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';

@Component({
  selector: 'app-admin-tasks',
  templateUrl: './admin-tasks.component.html',
  styleUrls: ['./admin-tasks.component.css']
})
export class AdminTasksComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;
  slopeTrackingModel = {
    main:{
      uuid:'',
      note:''
    },
    system:{
      doctor:{
        main:{
          uuid:''
        }
      },
      collaborator:{
        main:{
          uuid:''
        }
      },
      status:'Iniciada',
      createDate:''//dd/MM/yyyy hh:mm:ss
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }

  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    doctor:{
      main:{
        uuid:''
      }
    },
    saving:true
  }


  editorConfig = {
    height: '25em',
    readOnly:true
  }
  object:any = JSON.parse(JSON.stringify(this.slopeTrackingModel))
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, private router: Router, private dialog:MatDialog) {
    this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // this.send();
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged: ', error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
      });
   }

  ngOnInit() {
    this.session.getRequest('slopeTracking:get',{main:{uuid:'2c9fa81f82f90ce20182f98fb035003c'}}).subscribe((data:any)=>{
      this.object = JSON.parse(JSON.stringify(data.object));
      this.loadingService.hide();
    },(error)=>{
      this.loadingService.hide();
      console.log('Error: slopeTracking:get',error)
    })
  }

  send(){
    console.log(this.object);
    this.metadata.saving = false;
    this.object.system.createDate = moment().format('DD/MM/yyyy hh:mm:ss');
    this.session.getRequest('slopeTracking:update', this.object).subscribe(
      (data:any)=>{
        this.metadata.saving = true;
      },
      error => {
        console.log("slopeTracking:update",error);
        this.metadata.saving = true;
      })
  }

    /**
     * Funcion para buscar
     */
      saving() {
        this.metadata.searchBoxInput.next('guardando');
      }


    /* funcionalidad para cambiar el menú
    */
    toogleMenu(ev) {
      this.drawerCtrl.opened = ev;
    }

}
