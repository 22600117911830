import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.css']
})
export class RestorePasswordComponent implements OnInit {
  metadata={
    step:'step1'
  }
  objectModel={
    main:{
      code:'',
      username:'',
      password:'',
      repeatPassword:''
    }
  }
  object = JSON.parse(JSON.stringify(this.objectModel));

  constructor(private router: Router, public fb: FormBuilder, private session:SessionService, public loadingService: LoadingService, private snackBar: MatSnackBar) {

  }

  ngOnInit() {
  }

  changeStep(step){
    this.metadata.step = step;
  }

  /*
  Funcion para enviar codigo al email
  */
  sendEmail(){
    this.loadingService.show(true,"Enviado email, espere por favor");
    this.session.getRequest("user:sendCode",this.object).subscribe((data:any)=>{
      this.loadingService.hide();
      this.changeStep('step2')
    },error=>{
      this.loadingService.hide();
      this.snackBar.open('El usuario no existe, verifique su información', 'Error', {duration: 5000});
      console.log("Error:user:sendCode",error)
    })
  }
  /*
  funcion verificar el codigo
  */
  validateCode(){
    this.loadingService.show(true,"Verificando código...")
    this.session.getRequest("user:validateCode",this.object).subscribe((data:any)=>{
      this.loadingService.hide();
      this.changeStep('step3')
    },error=>{
      this.loadingService.hide();
      this.snackBar.open(error, 'Error', {duration: 5000});
      console.log("Error:user:validateCode",error)
    })
  }

  /*
  Funcion para cambiar contraseña
  */
  changePassword(){
    if(this.object.main.password == this.object.main.repeatPassword){
      this.loadingService.show(true,"Verificando código...")
      this.session.getRequest("user:newPassword",this.object).subscribe((data:any)=>{
        this.snackBar.open('La contraseña se cambio exitosamente', 'Aceptar', {duration: 5000});
        this.loadingService.hide();
        this.router.navigate(['login']);
      },error=>{
        this.loadingService.hide();
        this.snackBar.open(error, 'Error', {duration: 5000});
        console.log("Error:user:newPassword",error)
      })
    }else{
      this.snackBar.open("La contrasela debe coincidir", 'Error', {duration: 5000});
    }
  }

}
