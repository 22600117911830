import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import * as Rx from 'rxjs/Rx';
import { LoadingService } from '../../directive/loading/loading.module';
import * as moment from 'moment';

@Component({
  selector: 'app-owner-dashboard',
  templateUrl: './owner-dashboard.component.html',
  styleUrls: ['./owner-dashboard.component.css']
})
export class OwnerDashboardComponent implements OnInit {
  @ViewChild('drawer', { static: true }) drawerCtrl: MatDrawer;
  floors = [];
  patientModel = {
    main: {
      uuid: ''
    },
    system: {
      physicalPerson: {},
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      },
      status: true
    }
  };
  doctorModel = {
    main: {
      uuid: ''
    },
    system: {
      role: 'Doctor',
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      },
      user: {
        main: {
          uuid: ''
        }
      }
    }
  };
  roomModel = {
    main: {
      uuid: '',
      nameOrNumber: '',
      order: 0
    },
    system: {
      floor: {
        main: {
          uuid: ''
        }
      }
    },
    status: true
  };
  detailModel = {
    main: {
    }
  };
  roomOccupationModel = {
    main: {
      uuid: '',
      status: 'Disponible',
      observation: '',
      subtotal: 0,
      tax: 0,
      total: 0
    },
    complement: {
      typeOccupation: '',
      cie10: ''
    },
    system: {
      patient: JSON.parse(JSON.stringify(this.patientModel)),
      doctor: JSON.parse(JSON.stringify(this.doctorModel)),
      specialistDoctor: JSON.parse(JSON.stringify(this.doctorModel)),
      room: JSON.parse(JSON.stringify(this.roomModel)),
      detail: JSON.parse(JSON.stringify(this.detailModel)),
      sale: {
        main: {
          uuid: '',
          saleNumber: '',
          typeDiscount: '',
          discountValue: 0.0,
          discountAmount: 0.0,
          subtotal: 0.0,
          total: 0.0,
          tax: 0.0,
          status: '',
          expeditionDate: '',
          enableTax: false
        },
        complement: {
          description: '',
          paymentMethod: ''
        },
        system: {
          status: true,
          collaborator: {
            main: {
              uuid: ''
            }
          },
          company: {
            main: {
              uuid: ''
            }
          },
          patient: {
            main: {
              uuid: ''
            }
          }
        }
      }
    }
  };
  spending={
    main: {
      openDate: '',
      closeAmount: 0.0,
      openAmount: 0.0,
    }
  };
  constructor(private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) { }

  ngOnInit() {
    this.floors = [];
    this.findAllByCompany().then((data: any) => {
      this.floors = data.object.instanceList;
    });
    this.findBySpending();
  }

  /**
   * Encuentra todos los pisos asociados a la empresa indicada
   */
  findAllByCompany() {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo pisos, espere un momento...");
      this.session.getRequest("floor:findAllByCompany", { sort: 'mOrder', order: 'asc', system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } }, status: true } }).subscribe(
        (data: any) => {
          let floors = [];
          // Obtenemos las habitaciones por piso
          for (let x = 0; x < data.object.instanceList.length; x++) {
            let floor = {
              main: {
                uuid: data.object.instanceList[x].main.uuid,
                name: data.object.instanceList[x].main.name,
              },
              system: {
                status: data.object.instanceList[x].system.status,
                company: data.object.instanceList[x].system.company,
                rooms: []
              }
            }
            this.findAllByFloor(data.object.instanceList[x]).then((data: any) => {
              floor.system.rooms = data.object.instanceList;
              floors.push(floor);
            });
          }
          resolve({ transaction: 'ok', object: { instanceList: floors } });
          this.loadingService.hide();
        },
        error => {
          console.log("Error: floor:findAllByCompany", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Encuentra todos los cuartos asociados al piso indicado
   */
  findAllByFloor(floor) {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo cuartos del piso " + floor.main.name + ", espere un momento...");
      this.session.getRequest("roomOccupation:findAllByFloorWithWarehouse", { system: { floor: { main: { uuid: floor.main.uuid } }, status: true } }).subscribe(
        (data: any) => {
          let rooms = [];
          // Obtenemos la ocupación del cuarto
          for (let y = 0; y < data.object.instanceList.length; y++) {
            this.findByRoomAndStatusNotEqual(data.object.instanceList[y]).then((data: any) => {
              // Validamos si existe ocupación en la habitación
              if (data.object.main.uuid != '') {
                // Obtenemos el total de lo consumido en la consulta
                let roomOccupation = JSON.parse(JSON.stringify(this.roomOccupationModel));
                roomOccupation.main = data.object.main;
                roomOccupation.complement = data.object.complement;
                roomOccupation.system.patient = JSON.parse(JSON.stringify(data.object.system.patient));
                roomOccupation.system.doctor = JSON.parse(JSON.stringify(data.object.system.doctor));
                roomOccupation.system.specialistDoctor = JSON.parse(JSON.stringify(data.object.system.specialistDoctor));
                roomOccupation.system.room = JSON.parse(JSON.stringify(data.object.system.room));
                roomOccupation.system.detail = JSON.parse(JSON.stringify(data.object.system.detail));
                this.findByPatientAndStatus(roomOccupation.system.patient, 'Abierta').then((data: any) => {
                  roomOccupation.system.sale = data.object;
                  rooms.push(roomOccupation);
                  this.loadingService.hide();
                });
              }
            });
          }
          resolve({ transaction: 'ok', object: { instanceList: rooms } });
          this.loadingService.hide();
        },
        error => {
          console.log("Erro: roomOccupation:findAllByFloorWithWarehouse", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Obtiene el total consumo por Paciente
   */
  findByPatientAndStatus(patient, status) {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo el total de consumo, espere un momento...");
      this.session.getRequest("sales:findAllByPatientAndStatus", { main: {status: status }, system: {patient: patient}  }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
        },
        error => {
          console.log("Erro: sales:findAllByPatientAndStatus", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Busca si existe ocupación enla habitación indicada
   */
  findByRoomAndStatusNotEqual(room) {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo detalle de la ocupación del cuarto " + room.main.nameOrNumber + ", espere un momento...");
      this.session.getRequest("roomOccupation:findByRoomAndStatusNotEqual", { main: { status: 'Terminado' }, system: { room: { main: { uuid: room.main.uuid } } } }).subscribe(
        (data: any) => {
          if (data.object) {
            resolve({ transaction: 'ok', object: data.object });
          } else {
            let roomOccupation = JSON.parse(JSON.stringify(this.roomOccupationModel));
            roomOccupation.system.room = room;
            resolve({ transaction: 'ok', object: roomOccupation });
          }
        },
        error => {
          console.log("Erro: roomOccupation:findByRoomAndStatusNotEqual", error);
          this.loadingService.hide();
        });
    });
  }

  findBySpending() {
    this.loadingService.wait();
    this.session.getRequest('pettyCash:findByCompanyAndOpen', { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } }, main: { open: true } }).subscribe((data: any) => {
      if (data.object != '') {
        // Asignamos el detalle de la apertura y cierre del turno
        this.spending = JSON.parse(JSON.stringify(data.object));
        this.spending.main.openDate = moment(this.spending.main.openDate).format('DD/MM/YYYY HH:mm:SS');
      }
      this.loadingService.hide();
    }, (error) => {
      console.log('pettyCash:findByCompanyAndOpen', error);
      this.loadingService.hide();
    });
  }

  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
