import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../service/session/session.service';
// import { ProcessOutsideService } from "../../directive/process-outside/process-outside.module";
import { LoadingService } from '../../directive/loading/loading.module';
// import { MapCheckComponent } from "./map-check/map-check.component";
import * as moment from 'moment';


@Component({
  selector: 'app-admin-time-clock',
  templateUrl: './admin-time-clock.component.html',
  styleUrls: ['./admin-time-clock.component.css']
})
export class AdminTimeClockComponent implements OnInit {

  @ViewChild('drawer', { static: true }) drawerCtrl: MatDrawer;
  physicalPerson = {
    main:{
      uuid: '',
      name: '',
      lastname: '',
      surname: ''
    },
    complement:{
      dateBirth: '',
      curp: '',
      rfc: '',
      gender: ''
    },
    system :{
      status:true,
    }
  };

  collaboratorModel = {
    main:{
      uuid:'',
      specialty: ''
    },
    system:{
      physicalPerson:this.physicalPerson,
      user:{
        main:{
          uuid:''
        }
      },
      role:'',
      company:{
        main:{
          uuid:''
        }
      },
      status:true,
      checks: [],
      viewCheckEntryExitWork: 'No'
    }
  }

  checkModel = {
    main: {
      uuid: '',
      year: '',
      month: '',
      day: '',
      hour: '',
      minute: '',
      second: '',
      entryExit: '', // true si es entrada, false salida,
      fullDate: '',
      isValid: false
    },
    complement: {
      latitude: '',
      length: ''
    },
    system: {
      collaborator: {
        main: {
          uuid: ''
        }
      },
      inUsed: false
    },
    filter: {
      value: ''
    }
  }

  checkEntryExitWorkModel = {
    main: {
      uuid: '',
      year: '',
      month: '',
      day: '',
      hour: '',
      minute: '',
      second: '',
      entryExit: '', // true si es entrada, false salida,
      fullDate: ''
    },
    complement: {
      latitude: '',
      length: ''
    },
    system: {
      collaborator: {
        main: {
          uuid: ''
        }
      },
      inUsed: false
    }
  }
  instanceList = [];
  instanceNewList = [];
  currentDate = new Date();
  nameDay = '';
  metadata = {
    dateCheckEntryExitWork: moment(),
    currentDateIsoString: null,
    stringDateCheck: '',
    startDate: '',
    endDate: '',
    showTodo:true
  }
  //variable para activar o descativar el boton de dia siguiente.
  isToday = true;
  // employmentInformationList = [];
  checkEntryExitWork = JSON.parse(JSON.stringify(this.checkEntryExitWorkModel));

  branchMin = {
    system:{
      company:{
        main:{
          uuid:''
        }
      }
    },
    max:100,
    offset:0,
    filter:{
      name:"findAllByCompanyAndLikeName",
      value:""
    }
  }
  brachList = [];
  checkEntryExitWorkMin = {
    type:'',
    system: {
      department:{
        main:{
          uuid:''
        }
      },
      branch:{
        main:{
          uuid:''
        }
      },
      collaborator: {
        system: {
          company: {
            main: {
              uuid: ''
            }
          }
        }
      },
      create: ''
    }
  }
  checkEntryExitWorkMinList = [];
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    //lista de empleados
    this.nameDay = this.nameOfDay(this.currentDate.getDay());
    this.metadata.currentDateIsoString = moment(this.currentDate).toISOString();
    //funcion para traer los datos de los trabajadores de la compañia con status true.
    let month = (this.currentDate.getMonth() - 1);
    // this.showChecksFromFilter('todo');
    this.checkEntryExitWorkMin.system.collaborator.system.company.main.uuid = this.session.getSessionObject().main.uuid ;
    this.checkEntryExitWorkMinList.push(JSON.parse(JSON.stringify(this.checkEntryExitWorkMin)));
    this.getAllCheckEntryExitWork();
  }

  // funcón para obtener las entradas y salidas.
  getAllCheckEntryExitWork() {
    return new Promise((resolve,reject)=>{
      // obtenemso todos los trabajadores activos de la compañia.
      this.loadingService.wait();
      // this.employmentInformationList = data.object.instanceList;
      this.session.getRequest("checkEntryExitWork:findAllByCompanyAndDate", { system: { collaborator: { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } } }, create: moment(this.currentDate).format('DD-MM-YYYY') } }).subscribe((data: any) => {
        this.loadingService.hide();
        //  Contiene los trabajadores que checaron en el día
        // this.instanceList = [];
        // Recorremos la lista de checadas del día
        for (let x = 0; x < data.object.instanceList.length; x++) {
          // Validamos si el trabajador ya se guardo en su primera checada
          let indice = this.isExist(this.instanceList, data.object.instanceList[x]);
          if (indice >= 0) {
            //Recorremos la lista para ubicar en donde se encuentra el trabajador ya posicionado
            if (data.object.instanceList[x].main.uuid) {
              let check = JSON.parse(JSON.stringify(this.checkModel));
              check.main.uuid = data.object.instanceList[x].main.uuid;
              check.main.hour = data.object.instanceList[x].main.hour;
              check.main.minute = data.object.instanceList[x].main.minute;
              check.main.second = data.object.instanceList[x].main.second;
              check.main.entryExit = data.object.instanceList[x].main.entryExit;
              check.main.isValid = data.object.instanceList[x].main.isValid;
              // check.complement.latitude = data.object.instanceList[x].complement.latitude;
              // check.complement.length = data.object.instanceList[x].complement.length;
              this.instanceList[indice].system.checks.push(check);
            }
          } else {
            let employee = JSON.parse(JSON.stringify(this.collaboratorModel));
            let objeto = JSON.parse(JSON.stringify(data.object.instanceList[x].system.collaborator));
            employee.main.uuid = objeto.main.uuid;
            employee.main.code = objeto.main.code;
            employee.main.fullName = objeto.system.physicalPerson.main.name + ' '+ objeto.system.physicalPerson.main.lastname +' '+objeto.system.physicalPerson.main.surname;
            employee.system.physicalPerson = objeto.system.physicalPerson;
            employee.main.isValid = data.object.instanceList[x].main.isValid;
            if (data.object.instanceList[x].main.uuid) {
              var check = JSON.parse(JSON.stringify(this.checkModel));
              check.main.uuid = data.object.instanceList[x].main.uuid;
              check.main.hour = data.object.instanceList[x].main.hour;
              check.main.minute = data.object.instanceList[x].main.minute;
              check.main.second = data.object.instanceList[x].main.second;
              check.main.entryExit = data.object.instanceList[x].main.entryExit;
              // check.complement.latitude = data.object.instanceList[x].complement.latitude;
              // check.complement.length = data.object.instanceList[x].complement.length;
              employee.system.checks.push(check);
            }
            employee.identifier = 'todo'
            this.instanceList.push(employee);
          }
        }
        this.instanceNewList = this.instanceList;
        resolve(true)
      }, error => {
        console.log("error:checkEntryExitWork:findAllByCompanyAndDate", error)
        this.loadingService.hide();
        reject(error);
      })
    })
  }
  /***
  ** Metodo para busacr
  **/
  search() {
    if (this.checkModel.filter.value.length > 2) {
      this.instanceList = this.instanceList.filter(item => {
        return item.system.physicalPerson.main.name.toLowerCase().indexOf(this.checkModel.filter.value.toLowerCase()) > -1;
      })
    } else {
      this.instanceList = this.instanceNewList;
    }
  }

  //Función para nombrar en Español los días de la semana
  nameOfDay(number) {
    var nameDay = '';
    switch (number) {
      case 0:
        nameDay = 'Domingo';
        break;
      case 1:
        nameDay = 'Lunes';
        break;
      case 2:
        nameDay = 'Martes';
        break;
      case 3:
        nameDay = 'Miércoles';
        break;
      case 4:
        nameDay = 'Jueves';
        break;
      case 5:
        nameDay = 'Viernes';
        break;
      case 6:
        nameDay = 'Sabado';
        break;
    }
    return nameDay;
  }

  isExist(elementList, element) {
    for (var x = 0; x < elementList.length; x++) {
      if (elementList[x].main.uuid === element.system.collaborator.main.uuid) {
        return x;
      }
    }
    return -1;
  }

  //Fución para mostrar un día anterior
  async afterDay() {
    this.currentDate.setDate(this.currentDate.getDate() - 1);
    this.metadata.dateCheckEntryExitWork = moment(this.currentDate);
    this.nameDay = this.nameOfDay(this.currentDate.getDay());
    this.metadata.currentDateIsoString = moment(this.currentDate).toISOString();
    if (moment(this.currentDate).format('L') == moment(new Date()).format('L')) {
      this.isToday = true;
    } else {
      this.isToday = false;
    }
    //funcion para traer los datos de los trabajadores de la compañia con status true.
    this.instanceList = []

    for(let item of this.checkEntryExitWorkMinList){
      this.checkEntryExitWorkMin = JSON.parse(JSON.stringify(item));
      await this.showChecksFromFilter(this.checkEntryExitWorkMin.type);
    }
    // this.getAllCheckEntryExitWork();
  };
  //Fución para mostrar un día posterior
  async nextDay() {
    this.currentDate.setDate(this.currentDate.getDate() + 1);
    this.metadata.dateCheckEntryExitWork = moment(this.currentDate);
    this.nameDay = this.nameOfDay(this.currentDate.getDay());
    this.metadata.currentDateIsoString = moment(this.currentDate).toISOString();
    if (moment(this.currentDate).format('L') == moment(new Date()).format('L')) {
      this.isToday = true;
    } else {
      this.isToday = false;
    }
    //funcion para traer los datos de los trabajadores de la compañia con status true.
    this.instanceList = []

    for(let item of this.checkEntryExitWorkMinList){
      this.checkEntryExitWorkMin = JSON.parse(JSON.stringify(item));
      await this.showChecksFromFilter(this.checkEntryExitWorkMin.type);
    }
    // this.getAllCheckEntryExitWork();
  };

  //funcion para obtener la hora entre un grupo de horarios.
  getHoursFromCheckHours(list) {
    var sumHors = 0;
    var sumMin = 0;
    // recorremos todos los horarios que existen.
    for (var i = 0; i < list.length - 1; i++) {
      //validamos que el siguiente sea una salida y el actual sea entrada.
      if (list[i + 1].main.entryExit && list[i].main.entryExit == false) {
        var secoundsTemp = (list[i].main.minute * 60) + (list[i].main.hour * 3600);
        var secoundsTemp2 = (list[i + 1].main.minute * 60) + (list[i + 1].main.hour * 3600);
        var totalSds = secoundsTemp2 - secoundsTemp;
        sumHors = sumHors + Math.trunc(totalSds / 3600);
        sumMin = sumMin + Math.trunc((totalSds % 3600) / 60);
      }
      if (sumMin >= 60) {
        sumMin = sumMin - 60;
        sumHors = sumHors + 1;
      }
      i = i + 1;
    }
    //console.log(list);
    var result = sumHors + ' Hrs. ' + sumMin + ' Min.';
    return result;
  }

  async seletedDayByCalendar(object) {
    console.log(object)
    this.currentDate = new Date(moment(object).format())
    this.metadata.dateCheckEntryExitWork = moment(this.currentDate);
    this.nameDay = this.nameOfDay(this.currentDate.getDay());
    if (moment(this.currentDate).format('L') == moment(new Date()).format('L')) {
      this.isToday = true;
    } else {
      this.isToday = false;
    }
    //funcion para traer los datos de los trabajadores de la compañia con status true.
    this.instanceList = []
    ;
    for(let item of this.checkEntryExitWorkMinList){
      this.checkEntryExitWorkMin = JSON.parse(JSON.stringify(item));
      await this.showChecksFromFilter(this.checkEntryExitWorkMin.type);
    }
    // this.getAllCheckEntryExitWork();
  }

  getHourNice(hrs, min) {
    var d = new Date(1972, 1, 1, hrs, min, 0, 0);
    return moment(d).format('LT');
  }

  // función para eliminar una hora de relog checador
  deleteAHr(object, fatherObject) {
    console.log("Borrar", object);
    //eliminamos el object de la bd
    this.session.getRequest('checkEntryExitWork:delete', object).subscribe((data: any) => {
      this.snackBar.open('Se eliminó correctamente el check', 'success', { duration: 5000 });
      // si se elimino correctamente y ahora eliminamos el objeto del arreglo.
      for (let i = 0; i < fatherObject.system.checks.length; i++) {
        if (fatherObject.system.checks[i].main.uuid == object.main.uuid) {
          fatherObject.system.checks.splice(i, 1);
        }
      }
    }, error => {
      console.log('Error:checkEntryExitWork:delete', error)
    })
  }

  // funcion para editar un horario.
  activateEdit(object, fatherObject) {
    this.checkEntryExitWork = JSON.parse(JSON.stringify(this.checkEntryExitWorkModel));
    this.checkEntryExitWork.main.fullDate = moment(this.metadata.dateCheckEntryExitWork).format('LT');
    //desactivamos todos los inUsed.
    for (var i = 0; i < this.instanceList.length; i++) {
      for (var j = 0; j < this.instanceList[i].system.checks.length; j++) {
        this.instanceList[i].system.checks[j].system.inUsed = false;
      }
    }
    object.system.inUsed = true;
    this.checkEntryExitWork = JSON.parse(JSON.stringify(object));
    this.metadata.dateCheckEntryExitWork.set('hour', this.checkEntryExitWork.main.hour);
    this.metadata.dateCheckEntryExitWork.set('minute', this.checkEntryExitWork.main.minute);
    this.metadata.dateCheckEntryExitWork.set('second', this.checkEntryExitWork.main.second);
    this.checkEntryExitWork.main.fullDate = this.metadata.dateCheckEntryExitWork.format('LT');
    this.metadata.stringDateCheck = this.FormatNumberLength(this.metadata.dateCheckEntryExitWork.hour(), 2) + ':' + this.FormatNumberLength(this.metadata.dateCheckEntryExitWork.minute().toString(), 2);
    console.log(this.checkEntryExitWork);
    this.activateClock(fatherObject);
  }

  //funcion para activar el agregado de un chequeo en el reloj checador.
  activateClock(object) {
    if (this.checkEntryExitWork.system.inUsed) {
      if (object.system.viewCheckEntryExitWork == 'Si') {
        object.system.viewCheckEntryExitWork = 'No';
        this.checkEntryExitWork.system.inUsed = false;
        this.checkEntryExitWork.main.uuid = '';
      } else {
        //colocamos todos en no.
        for (let i = 0; i < this.instanceList.length; i++) {
          this.instanceList[i].system.viewCheckEntryExitWork = 'No'
        }
        object.system.viewCheckEntryExitWork = 'Si';
      }
    } else {
      this.checkEntryExitWork.system.inUsed = true;
      if (object.system.viewCheckEntryExitWork == 'No') {
        object.system.viewCheckEntryExitWork = 'Si';
      } else {
        object.system.viewCheckEntryExitWork = 'No';
        this.checkEntryExitWork.main.uuid = '';
      }
    }
  }

  /**
   * Guardar chekeo
   */
  saveCheckEntryExitWork(employee) {
    let tildePosition = this.metadata.stringDateCheck.indexOf(':');
    let hour = this.metadata.stringDateCheck.slice(0, tildePosition)
    let minute = this.metadata.stringDateCheck.slice(tildePosition + 1, this.metadata.stringDateCheck.length);
    this.metadata.dateCheckEntryExitWork.set('hour', parseInt(hour));
    this.metadata.dateCheckEntryExitWork.set('minute', parseInt(minute));
    this.metadata.dateCheckEntryExitWork.set('second', 0);
    this.checkEntryExitWork.main.year = this.metadata.dateCheckEntryExitWork.year();
    this.checkEntryExitWork.main.month = this.metadata.dateCheckEntryExitWork.month() + 1;
    this.checkEntryExitWork.main.day = this.metadata.dateCheckEntryExitWork.date();
    this.checkEntryExitWork.main.hour = this.metadata.dateCheckEntryExitWork.hour();
    this.checkEntryExitWork.main.minute = this.metadata.dateCheckEntryExitWork.minute();
    this.checkEntryExitWork.main.second = this.metadata.dateCheckEntryExitWork.second();
    this.checkEntryExitWork.system.collaborator.main = employee.main;
    if (this.checkEntryExitWork.main.entryExit == "true") {
      this.checkEntryExitWork.main.entryExit = true;
    } else {
      this.checkEntryExitWork.main.entryExit = false;
    }
    console.log(this.checkEntryExitWork);
    this.loadingService.show(true, 'Actualizando Registro...')
    this.session.getRequest('checkEntryExitWork:updateExtemporaneous', this.checkEntryExitWork).subscribe((data: any) => {
      this.loadingService.hide();
      //si vien el uuid de checkEntryExitWork desde un inicio es una actualizacion y se realiza otro tratamiento.
      if (this.checkEntryExitWork.main.uuid == '') {
        this.checkEntryExitWork.main.uuid = data.object.main.uuid;
        this.snackBar.open('Se agregó correctamente el check', 'success', { duration: 5000 });
        employee.system.checks.push(this.checkEntryExitWork);
        //limpiamos el objeto.
        this.checkEntryExitWork = JSON.parse(JSON.stringify(this.checkEntryExitWorkModel));
        this.checkEntryExitWork.main.fullDate = moment(this.metadata.dateCheckEntryExitWork).format('LT');
        employee.system.viewCheckEntryExitWork = 'No'
      } else {
        for (var i = 0; i < employee.system.checks.length; i++) {
          if (employee.system.checks[i].main.uuid == this.checkEntryExitWork.main.uuid) {
            employee.system.checks[i] = this.checkEntryExitWork;
            break;
          }
        }
        this.checkEntryExitWork = JSON.parse(JSON.stringify(this.checkEntryExitWorkModel));
        employee.system.viewCheckEntryExitWork = 'No'
        this.snackBar.open('Se actualizó correctamente el check', 'success', { duration: 5000 });
      }

      // verificamos que tipo de /entrada/salida toca y actualizamos los checks
      this.verifyAndOrderCheks(this.checkEntryExitWork, employee).then((data) => {
        console.log("cheks verificados", data);
      }).catch((err) => {
        console.log(err);
      })
    }, error => {
      console.log('error:checkEntryExitWork:updateExtemporaneous', error)
      this.loadingService.hide();
    })
  }

  verifyAndOrderCheks(object, fatherObject) {
    return new Promise((resolve) => {
      let checkTemp = fatherObject.system.checks;
      if (checkTemp.length > 0) {
        var item = {};
        for (var i = 0; i < checkTemp.length; i++) {
          for (var j = 0; j < i; j++) {
            // validamos que hora es mas grande.
            var dateI = new Date();
            var dateJ = new Date();
            dateI.setHours(checkTemp[i].main.hour);
            dateI.setMinutes(checkTemp[i].main.minute);
            dateI.setSeconds(checkTemp[i].main.second);
            dateJ.setHours(checkTemp[j].main.hour);
            dateJ.setMinutes(checkTemp[j].main.minute);
            dateJ.setSeconds(checkTemp[j].main.second);
            if (dateI.getTime() < dateJ.getTime()) {
              item = checkTemp[j];
              checkTemp[j] = checkTemp[i];
              checkTemp[i] = item;
            }

          }
        }
        resolve(checkTemp);
        console.log(checkTemp)
      } else {
        //si no hay cheks, regresamos el estado como una entrada.
        resolve(checkTemp);
      }
    });
  }

  /**
   * Vemos el mapa de registro del check in
   */
  // openMapCheck(object) {
  //   this.dialog.open(MapCheckComponent, {
  //     width: '60%',
  //     data: {
  //       object: object
  //     }
  //   }).afterClosed().subscribe(result => {
  //     console.log('*** ', result);
  //   });
  // }

  /*
  Funcion para enviar el reporte de incidencias por correo
  */
  getReportClockIssues() {
    let startDate = moment(this.metadata.startDate).format('DD-MM-YYYY');
    let endDate = moment(this.metadata.endDate).format('DD-MM-YYYY');
    this.loadingService.show(true, 'Enviado reporte a su correo electrónico');
    this.session.getRequest('dependentMethods:createToReportIncidences', {
      system: {
        type: 'timeClock',
        incidentEndDate: endDate,
        incidentStartDate: startDate,
        status: true,
        company: {
          main: {
            uuid: this.session.getSessionObject().main.uuid
          }
        }
      }
    }).subscribe((data: any) => {
      this.loadingService.hide();
      this.snackBar.open(data.message, 'Success', { duration: 5000 });
    }, error => {
      this.loadingService.hide();
      console.log("error:dependentMethods:createToReportIncidences", error);
    })
  }

  /*
  Funcion para llamar el proceeso de descarga de reporte en segundo plano
  */
  downloadReport() {
    this.loadingService.show(true,"Espere un momento");
    let startDate = moment(this.metadata.startDate).format('DD-MM-YYYY');
    let endDate = moment(this.metadata.endDate).format('DD-MM-YYYY');
    this.session.getRequest('checkEntryExitWork:downloadToReport',{
      system: {
        type: 'timeClock',
        incidentEndDate: endDate,
        incidentStartDate: startDate,
        status: true,
        company: {
          main: {
            uuid: this.session.getSessionObject().main.uuid
          }
        }
      }
    }).subscribe((data:any)=>{
      let elem = document.createElement('a');
      elem.href = data.object;
      elem.setAttribute('download', 'incidencias.xls');
      document.body.appendChild(elem);
      elem.click();
      this.loadingService.hide();
  },error=>{
    console.log("Error;",error);
    this.loadingService.hide();
  })

  }


  /**funcionalidad para seleccionar un filtro
  */
  selectedFilter(object,type){
    //desactivamos el check global
    if(this.metadata.showTodo){
      this.instanceList = [];
      this.checkEntryExitWorkMinList.pop();
    }
    this.metadata.showTodo = false;
    //
    object.selected = !object.selected;
    this.checkEntryExitWorkMin.system.collaborator.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.checkEntryExitWorkMin.type = type;
    if(type == 'branch'){
      this.checkEntryExitWorkMin.system.branch.main.uuid = object.main.uuid;
    }else{
      this.checkEntryExitWorkMin.system.department.main.uuid = object.main.uuid;
    }
    if(object.selected){
      this.checkEntryExitWorkMinList.push(JSON.parse(JSON.stringify(this.checkEntryExitWorkMin)));
      this.showChecksFromFilter(type);
    }else{
      this.checkEntryExitWorkMinList.pop();
      //eliminamos los objectos de la seleccion del filtro
      this.instanceList = this.instanceList.filter((item)=>{
        return item.identifier != object.main.uuid;
      });
    }
  }


  getTodo(){
    this.metadata.showTodo = !this.metadata.showTodo;
    this.instanceList = [];
    //reseteamos todo.
    // this.getBrachList();
    //
    this.checkEntryExitWorkMinList = [];
    if(this.metadata.showTodo){
      this.checkEntryExitWorkMin.system.collaborator.system.company.main.uuid = this.session.getSessionObject().main.uuid;
      this.checkEntryExitWorkMin.type = '';
      this.checkEntryExitWorkMinList.push(JSON.parse(JSON.stringify(this.checkEntryExitWorkMin)));
    }
    this.showChecksFromFilter('todo');

  }

  async showChecksFromFilter(type){
    return new Promise(async(resolve,reject)=>{
      try{
        switch(type){
          default:
          this.checkEntryExitWorkMin.type = type;
          if(this.metadata.showTodo){
            await this.getAllCheckEntryExitWork();
          }else{
            this.instanceList = this.instanceList.filter((item)=>{
              return item.identifier != 'todo';
            });
          }
          break;
        }
        resolve(true);
      }catch(e){
        console.log("error->showChecksFromFilter",e);
        reject(e)
      }
    })


  }



  /*
  funcion para controlar si se abre o cierra el menu
  */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }
  /*
  funcion para parseo de entero a string
  */
  FormatNumberLength(num, length) {
    var r = "" + num;
    while (r.length < length) {
      r = "0" + r;
    }
    return r;
  }

}
