import { Component, OnInit, Inject  } from '@angular/core';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-nurse-room-ocupation-detail',
  templateUrl: './admin-nurse-room-ocupation-detail.component.html',
  styleUrls: ['./admin-nurse-room-ocupation-detail.component.css']
})
export class AdminNurseRoomOcupationDetailComponent implements OnInit {
  sales = {
    system: {
      salesDetails: []
    }
  };
  patientModel = {
    main: {
      uuid: ''
    },
    system: {
      physicalPerson: {},
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      },
      status: true
    }
  };
  doctorModel = {
    main: {
      uuid: ''
    },
    system: {
      role: 'Doctor',
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      },
      user: {
        main: {
          uuid: ''
        }
      }
    }
  };
  roomModel = {
    main: {
      uuid: '',
      nameOrNumber: '',
      order: 0
    },
    system: {
      floor: {
        main: {
          uuid: ''
        }
      }
    },
    status: true
  };
  detailModel = {
    main: {

    }
  }
  roomOccupation = {
    main: {
      uuid: '',
      status: 'Disponible',
      observation: '',
      subtotal: 0,
      tax: 0,
      total: 0
    },
    complement: {
      typeOccupation: '',
      cie10: ''
    },
    system: {
      patient: JSON.parse(JSON.stringify(this.patientModel)),
      doctor: JSON.parse(JSON.stringify(this.doctorModel)),
      specialistDoctor: JSON.parse(JSON.stringify(this.doctorModel)),
      room: JSON.parse(JSON.stringify(this.roomModel)),
      detail: JSON.parse(JSON.stringify(this.detailModel))
    }
  };
  constructor(public loadingService: LoadingService, private session: SessionService, public dialogRef: MatDialogRef<AdminNurseRoomOcupationDetailComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar) {
    this.roomOccupation = JSON.parse(JSON.stringify(data.object));
  }

  ngOnInit() {
    // Obtenemos el detalle consumido por la ocupación en servicios
    this.getSales();
  }

  /**
   * Obtenemos la venta del paciente y que se encuentre abierta
   */
  getSales() {
    this.loadingService.show(true, "Obteniendo detalle de la estancia, espere un momento...");
    this.session.getRequest("sales:findAllByPatientAndStatus", {main: {status: 'Abierta'}, system: {patient: {main: {uuid: this.roomOccupation.system.patient.main.uuid}}} }).subscribe(
      (data: any) => {
        this.sales = data.object;
        // Obtenemos el detalle de la venta
        for (let x=0; x<this.sales.system.salesDetails.length; x++) {
          // Obtenemos el detalle del objecto de venta
          switch (this.sales.system.salesDetails[x].system.object) {
            case 'RoomOccupationRegister':
              this.getRoomOccupationRegister(this.sales.system.salesDetails[x].system.objectUuid).then((data: any) => {
                this.sales.system.salesDetails[x].system.objectDetail = data.object;
                // Obtenemos el detalle del usuario que realizo la acción
                this.getUser(this.sales.system.salesDetails[x].system.objectDetail.system.user).then((data: any) => {
                  this.sales.system.salesDetails[x].system.objectDetail.system.user = data.object;
                });
              });
            break;
            case 'RoomOccupation':
              this.getRoomOccupation(this.sales.system.salesDetails[x].system.objectUuid).then((data: any) => {
                this.sales.system.salesDetails[x].system.objectDetail = data.object;
                // Obtenemos el detalle del usuario que realizo la acción
                this.getUser(this.sales.system.salesDetails[x].system.objectDetail.system.detail.system.user).then((data: any) => {
                  this.sales.system.salesDetails[x].system.objectDetail.system.detail.system.user = data.object;
                });
              });
            break;
          }
        }
        this.loadingService.hide();
      },
      error => {
        console.log("Error: sales:findAllByPatientAndStatus", error);
        this.loadingService.hide();
      });
  }

  /**
   * Obtiene el detalle del usuario que origino el movimiento
   */
  getUser(user) {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo el detalle del usuario que origino el movimiento, espere un momento...");
      this.session.getRequest("user:get", user).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
          this.loadingService.hide();
        },
        error => {
          console.log("Error: user:get", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Obtiene el detalle de los productos consumidos en la habitación
   */
  getRoomOccupationRegister(uuid) {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo el detalle de su ingreso, espere un momento...");
      this.session.getRequest("roomOccupationRegister:get", { main: { uuid: uuid } }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
          this.loadingService.hide();
        },
        error => {
          console.log("Error: roomOccupationRegister:get", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Obtiene el detalle de los productos consumidos en la habitación
   */
  getRoomOccupation(uuid) {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo los productos suministrados, espere un momento...");
      this.session.getRequest("roomOccupation:get", { main: { uuid: uuid } }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
          this.loadingService.hide();
        },
        error => {
          console.log("Error: roomOccupation:get", error);
          this.loadingService.hide();
        });
    });
  }
}
