import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-adjustment-update',
  templateUrl: './admin-adjustment-update.component.html',
  styleUrls: ['./admin-adjustment-update.component.css']
})
export class AdminAdjustmentUpdateComponent implements OnInit {
  @ViewChild('drawer', { static: true }) drawerCtrl: MatDrawer;
  warehouseList = [];
  adjustmentTypeList = [];
  statusList = [];
  adjustmentProductsListFilter = [];
  productList = [];
  productAdjustmentList = [];
  transientDatas = [];
  transientDatasAux = [];
  transientDatasValue = [];
  productSelectedList = [];
  adjustmentProductModel = {
    main: {
      uuid: '',
      quantity: 0
    },
    system: {
      product: {
        main: {
          uuid: '',
          code: '',
          name: ''
        },
        system: {
          transientDatas: this.transientDatasValue
        }
      },
      company: {
        main: {
          uuid: ''
        }
      }
    },
    filter: {
      name: 'likeNameOrCode',
      value: ''
    }
  }
  adjustmentModel = {
    main: {
      uuid: '',
      dateAdjustment: '',
      status: 'Por validar',
      subTotal: 0,
      total: 0,
      discount: 0,
    },
    complement: {
      comment: '-'
    },
    system: {
      warehouse: {
        main: {
          uuid: ''
        }
      },
      adjustmentType: {
        main: {
          uuid: ''
        }
      },
      company: {
        main: {
          uuid: ''
        }
      },
      status: true,
      products: this.productAdjustmentList,
      employee: {
        main: {
          uuid: ''
        }
      }
    },
    inventoryMovement: {
      main: {
        uuid: '',
        object: 'Adjustment',
        objectUuid: '',
        date: ''
      },
      system: {
        status: true
      }
    }
  }
  metadata = {
    dateAdjustment: '',
    searching: false
  }
  object = JSON.parse(JSON.stringify(this.adjustmentModel));
  constructor(public fb: FormBuilder, public session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, private router: Router, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.listAllWarehouse();
    this.listAllAdjustmentType();
    // this.listAllProducts();
    this.adjustmentProductModel.system.product.main.name = '';
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['uuid'] == 'new') {
        // this.getEmployeeByUser();
      } else {
        this.loadingService.wait();
        this.object.main.uuid = params['uuid'];
        // cargamos los datos de la BD
        this.session.getRequest('adjustment:get', { uuid: this.object.main.uuid }).subscribe((data: any) => {
          this.object = JSON.parse(JSON.stringify(data.object));
          this.metadata.dateAdjustment = this.object.main.dateAdjustment;
          this.productSelectedList = data.object.system.products;
          this.loadingService.hide();
        },
          (error) => {
            this.loadingService.hide();
            console.log('Error:adjustment:get', error)
          })
      }
    });
  }

  /**
   * Lista de almacenes por company y activos
   */
  listAllWarehouse() {
    this.session.getRequest("warehouse:findAllByCompanyAndStatus", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } }, status: true }).subscribe(
      (data: any) => {
        this.warehouseList = data.object.instanceList;
      },
      error => {
        console.log("warehouse:findAllByCompanyAndStatus", error);
      });
  }

  /**
   * Lista de tipos de ajuste de inventario por company y activos
   */
  listAllAdjustmentType() {
    this.session.getRequest("adjustmentType:findAllByCompanyAndStatus", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } }, status: true }).subscribe(
      (data: any) => {
        this.adjustmentTypeList = data.object.instanceList;
      },
      error => {
        console.log("adjustmentType:findAllByCompanyAndStatus", error);
      });
  }

  /**
   * Función para busca un productos
   */
  productFindAllByNameLike(name) {
    if (name != '') {
      this.adjustmentProductsListFilter = [];
      this.metadata.searching = true;
      this.adjustmentProductModel.system.company.main.uuid = this.session.getSessionObject().main.uuid;
      this.adjustmentProductModel.filter.value = name;
      this.session.getRequest('product:findAllByCompany', this.adjustmentProductModel).subscribe(
        (data: any) => {
          this.adjustmentProductsListFilter = data.object.instanceList;
          this.metadata.searching = false;
        },
        error => {
          console.log("product:findAllByCompany", error);
          this.snackBar.open(error.message, 'Error', { duration: 5000 });
        })
    } else {
      this.adjustmentProductsListFilter = [];
    }
  }

  /*
   * Funcion para agregar un producto al ajuste de inventario
   */
  addProductToSelectedList(object) {
    let adjustmentType = ''
    // determinamos que tipo de ajuste de salida es:
    for (let item of this.adjustmentTypeList) {
      if (this.object.system.adjustmentType.main.uuid == item.main.uuid) {
        adjustmentType = item.main.typeMovement;
        break;
      }
    }
    this.adjustmentProductModel.system.product.main.name = '';
    if (adjustmentType != 'Salida') {
      let adjustmentProduct = JSON.parse(JSON.stringify(this.adjustmentProductModel));
      adjustmentProduct.system.product.main.uuid = object.main.uuid;
      adjustmentProduct.system.product.main.name = object.main.name;
      adjustmentProduct.system.product.main.nameCharacteristicValue = object.main.nameCharacteristicValue;
      adjustmentProduct.system.product.main.code = object.main.code;
      adjustmentProduct.system.company.main = this.session.getSessionObject().main;
      adjustmentProduct.system.product.system.transientDatas = [];
      this.productSelectedList.push(adjustmentProduct);
      this.adjustmentProductsListFilter = [];
    } else {
      this.getProductsAvailableByWharehouse(object, this.object.system.warehouse).then((data: any) => {
        if (data.main.uuid != null && data.main.uuid != 'null') {
          let adjustmentProduct = JSON.parse(JSON.stringify(this.adjustmentProductModel));
          adjustmentProduct.system.product.main.uuid = object.main.uuid;
          adjustmentProduct.system.product.main.name = object.main.name;
          adjustmentProduct.system.product.main.nameCharacteristicValue = object.main.nameCharacteristicValue;
          adjustmentProduct.system.product.main.code = object.main.code;
          adjustmentProduct.system.company.main = this.session.getSessionObject().main;
          adjustmentProduct.system.product.system.transientDatas = [];
          this.productSelectedList.push(adjustmentProduct);
          this.adjustmentProductsListFilter = [];
        } else {
          this.snackBar.open('No hay existencias de este producto en el inventario.', 'Aceptar', {
            duration: 8000
          });
        }
      })
    }
  }

  /**
   * Función para colocar el producto seleccionado
   */
  adjustmentProductSeleted(object) {
    // Limpiamos el modelo para otro producsto
    this.transientDatasAux = [];
    this.adjustmentProductModel.system.product.main.name = '';

    // Buscamos los datos transitorios de dicho producto
    this.session.getRequest("productTransientData:listByProduct", { system: { product: { main: { uuid: object.main.uuid } }, max: 20 } }).subscribe(
      (data: any) => {
        this.transientDatas = data.object.instanceList;
        for (let transientData of this.transientDatas) {
          this.transientDatasAux.push({
            main: {
              uuid: '',
              value: ''
            },
            system: {
              product: transientData.system.product,
              transientData: transientData.system.transientData
            }
          });
        }
        this.transientDatasValue = this.transientDatasAux;
        // Asignamos el producto
        this.object.system.products.push({
          main: {
            uuid: '',
            quantity: 0
          },
          system: {
            product: {
              main: {
                uuid: object.main.uuid,
                code: object.main.code,
                name: object.main.name
              },
              system: {
                transientDatas: this.transientDatasValue
              }
            }
          }
        });
        this.adjustmentProductsListFilter = [];
      },
      error => {
        console.log("productTransientData:listByProduct", error);
      });
  }

  /**
   * Elimina productos del ajuste de inventarios
   */
  deleteAdjustmentProduct(index, object) {
    if (object.main.uuid == '') {
      this.object.system.products.splice(index, 1);
    }
    else {
    }
  }

  /**
   * Envia a guardar el ajuste de inventario
   */
  send(object) {
    this.object.system.products = this.productSelectedList;
    // this.object.system.user.main.uuid = this.session.getUser().main.uuid;
    object.main.dateAdjustment = moment(this.metadata.dateAdjustment).format("DD-MM-YYYY HH:mm:ss");
    object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    object.main.status = 'Por validar';
    this.session.getRequest("adjustment:update", object).subscribe(
      (data: any) => {
        // evento a emitir cuando se crea o actaliza una categoria
        this.snackBar.open('El ajuste de inventario se guardo correctamente', '', {
          duration: 8000
        });
        this.loadingService.hide();
        this.router.navigate(['/admin/adjustment/list']);
      },
      error => {
        console.log("adjustment:update", error);
      }
    );
  }

  /**
   * Se elimina el producto de la lista de productos
   */
  deleteFromProductSelectedList(object) {
    if (object.main.uuid != '') {
      this.session.getRequest("adjustment:deleteAdjustmentProduct", object).subscribe(
        (data: any) => {
          // evento a emitir cuando se crea o actaliza una categoria
          this.snackBar.open('El registro se elimino correctamente', 'Aceptar', {
            duration: 8000
          });
          this.loadingService.hide();
          for (let i = 0; i < this.productSelectedList.length; i++) {
            if (this.productSelectedList[i].main.uuid == object.main.uuid) {
              this.productSelectedList.splice(i, 1);
              break;
            }
          }
        },
        error => {
          console.log("adjustment:deleteAdjustmentProduct", error);
        }
      );
    } else {
      for (let i = 0; i < this.productSelectedList.length; i++) {
        if (this.productSelectedList[i].system.product.main.uuid == object.system.product.main.uuid) {
          this.productSelectedList.splice(i, 1);
          break;
        }
      }
    }
  }

  /**
   * Funcion para determinar cuantos elmentos hay en el inventario.
   */
  changeQuantity(object) {
    let adjustmentType = ''
    // determinamos que tipo de ajuste de salida es:
    for (let item of this.adjustmentTypeList) {
      if (this.object.system.adjustmentType.main.uuid == item.main.uuid) {
        adjustmentType = item.main.typeMovement;
        break;
      }
    }
    if (adjustmentType == 'Salida') {
      this.getProductsAvailableByWharehouse(object.system.product, this.object.system.warehouse).then((data: any) => {
        if (object.main.quantity > data.main.quantity) {
          this.snackBar.open('La cantidad ingresada supera el maximo de existencias en el inventario...', 'Aceptar', {
            duration: 8000
          });
          object.main.quantity = data.main.quantity;
        }
      })
    }
  }

  /**
   * Funcion para encontrar productos en inventario segun consulta de productos
   */
  getProductsAvailableByWharehouse(product, wharehouse) {
    return new Promise((resolve => {
      this.session.getRequest("warehouseProduct:findByProductByWarehouse", { system: { product: { main: { uuid: product.main.uuid } }, warehouse: { main: { uuid: wharehouse.main.uuid } } } }).subscribe(
        (data: any) => {
          resolve(data.object);
        },
        error => {
          console.log("warehouseProduct:findByProductByWarehouse", error);
          this.loadingService.hide();
        }
      );
    }))
  }

  /**
   * Funcion para controlar si se abre o cierra el menu
   */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }
}
