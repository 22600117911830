import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import * as moment from 'moment';

@Component({
  selector: 'app-owner-office-expense-details',
  templateUrl: './owner-office-expense-details.component.html',
  styleUrls: ['./owner-office-expense-details.component.css']
})
export class OwnerOfficeExpenseDetailsComponent implements OnInit {
  officeExpenseModel = {
    main:{
      uuid:'',
      reason:'',
      amount:'',
      dateExpense:'',
    },
    system:{
      doctor:{
        main:{
          uuid:''
        }
      },
      user:{
        main:{
          uuid:''
        }
      }
    }
  };

  officeExpenseList = [];
  inconingList = [];
  otherIncomeList = [];

  metadata = {
    totalInconing:0,
    totalOfficeExpense:0,
    totalOtherIncome:0,
    total:0,
    doctor:{
      main:{
        uuid:''
      }
    }
  }
  constructor(
    private session: SessionService,
    public loadingService: LoadingService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<OwnerOfficeExpenseDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog:MatDialog
  ) {
    console.log("Aqui datos enviados al modal::::::",this.data);
    this.metadata.totalInconing = 0;
    for(let item of this.data.inconingList){
      this.metadata.totalInconing = this.metadata.totalInconing + item.main.pay;
    }
   }

   ngOnInit() {
     this.metadata.doctor = this.data.doctor;
     this.inconingList = this.data.inconingList;
     this.getSpendingList();
   }

   /**función para obtener los gastos de un doctor por una fecha
   */
   getSpendingList(){
     this.loadingService.show(true,"Espere un momento");
     let date =  moment().format('DD/MM/YYYY hh:mm:ss')
     this.session.getRequest("officeExpense:findAllByDoctorAndDateExpense",{main:{dateExpense:date},system:{doctor:{main:{uuid:this.metadata.doctor.main.uuid}}}}).subscribe((data:any)=>{
       this.officeExpenseList = data.object.instanceList;
       console.log("officeExpenseList",this.officeExpenseList);
       // this.addSpending();
       // this.caluclateSpending();
       this.getOtherIncomeList();
       this.loadingService.hide();
     },error=>{
       console.log(error);
       this.loadingService.hide();
     })
   }

   getOtherIncomeList(){
     this.loadingService.show(true, "Espere un momento");
     let date =  moment().format('DD/MM/YYYY hh:mm:ss')
     this.metadata.totalOtherIncome = 0;
     this.otherIncomeList = [];
     this.session.getRequest("officeOtherIncome:findAllByDoctorAndDateExpenditure",{main:{dateExpenditure:date},system:{doctor:{main:{uuid:this.metadata.doctor.main.uuid}}}}).subscribe((data:any)=>{
       for (let x=0; x<data.object.instanceList.length; x++){
         this.otherIncomeList.push(data.object.instanceList[x]);
         this.metadata.totalOtherIncome = this.metadata.totalOtherIncome + data.object.instanceList[x].main.amount;
       }
       this.caluclateSpending();
       this.loadingService.hide();
     },error=>{
       console.log(error);
       this.loadingService.hide();
     })
   }

   /**funcion para agregar un nuevo objeto a la cuenta de gastos
   */
   // addSpending(){
   //   let aux = JSON.parse(JSON.stringify(this.officeExpenseModel));
   //   aux.system.doctor.main.uuid = this.metadata.doctor.main.uuid;
   //   aux.system.user.main.uuid = this.session.getUser().main.uuid;
   //   aux.main.dateExpense = moment().format('DD/MM/YYYY hh:mm:ss');
   //   this.officeExpenseList.unshift(aux);
   // }

   /**funcionalidad para guardar un gasto
   */
   save(object){
     this.loadingService.show(true,"Espere un momento...");
     if(object.main.uuid != ''){
       object.main.dateExpense = moment(object.main.dateExpense).format('DD/MM/YYYY hh:mm:ss');
     }
     this.session.getRequest("officeExpense:update",object).subscribe((data:any)=>{
       // if(object.main.uuid == ''){
       //   this.addSpending();
       // }
       object.main.uuid = data.object.main.uuid;
       object.main.dateExpense = data.object.main.dateExpense;
       this.caluclateSpending();
       this.loadingService.hide();
     },error=>{
       console.log(error);
       this.loadingService.hide();
     })
   }

   /**funcionalidad para eliminar un gasto
   */
   delete(object){
     if(object.main.uuid != ''){
       this.loadingService.show(true,"Espere un momento...");
       this.session.getRequest("officeExpense:delete",object).subscribe((data:any)=>{
         for(let i = 0; this.officeExpenseList.length; i++){
           if(this.officeExpenseList[i].main.uuid == object.main.uuid){
             this.officeExpenseList.splice(i,1);
             break;
           }
         }
         this.loadingService.hide();
         this.caluclateSpending();
       },error=>{
         console.log(error);
         this.loadingService.hide();
       })
     }
   }

   /**función para calcular el total de gastos
   */
   caluclateSpending(){
     this.metadata.totalOfficeExpense = 0;
     for(let item of this.officeExpenseList){
       let i:any =this.metadata.totalOfficeExpense  + (item.main.amount*1);
       this.metadata.totalOfficeExpense = i;
     }
     this.metadata.total = this.metadata.totalInconing - this.metadata.totalOfficeExpense + this.metadata.totalOtherIncome;
   }


   close(status){
     let object = {
       transaction:'',
       code:'',
       object:{}
     };
     if(status){
       object.transaction = 'ok';
       object.object = this.metadata;
     }else{
       object.transaction = 'bad';
       object.code = 'officeExpenses:001';
     }
     this.dialogRef.close(object);
   }

}
