import { Component, OnInit, ViewChild} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent } from '@angular/material';
import { SessionService } from '../../service/session/session.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { LoadingService } from '../../directive/loading/loading.module';

@Component({
  selector: 'app-admin-working-hours',
  templateUrl: './admin-working-hours.component.html',
  styleUrls: ['./admin-working-hours.component.css']
})
export class AdminWorkingHoursComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  list = [];
  workingHoursMin = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      }
    },
    max:10,
    offset:0,
  }

  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };

  // MatPaginator Output
  pageEvent: PageEvent;

  constructor(private session: SessionService, public dialog: MatDialog, public snackBar: MatSnackBar, public loadingService: LoadingService) {
  }

  ngOnInit() {
    this.workingHoursMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.listWorkingHours();
  }

  /** Funcion para obtener la lista de horarios laborales **/
  listWorkingHours(){
    this.list = [];
    this.session.getRequest('workingHours:findAllByCompany', this.workingHoursMin).subscribe(
      (data:any)=>{
        this.list = data.object.instanceList
        this.paginatorModel.total = data.object.total;
      },
      error => {
        console.log("workingHours:findAllByCompany",error);
      })
  }

  delete(object) {
    this.loadingService.wait();
    this.session.getRequest('workingHours:delete', { main: { uuid: object.main.uuid } }).subscribe(
      (data: any) => {
        this.snackBar.open('Horario laboral eliminado correctamente', 'Información', { duration: 5000 });
        this.listWorkingHours();
        this.loadingService.hide();
      },
      error => {
        this.loadingService.hide();
        console.log("workingHours:delete", error.message);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
      })
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.workingHoursMin.offset = (event.pageIndex * event.pageSize);
    this.workingHoursMin.max = event.pageSize;
    this.listWorkingHours();
  }

  /**
   * Funcionalidad para abrir el menu del sistema
   * @param ev - Evento que se recibe del HTML para abrir o cerrar el menu
   */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
