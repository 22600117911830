import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import * as moment from 'moment';
import { startOfDay, endOfDay, addMinutes, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';

@Component({
  selector: 'app-owner-surgeries-availability',
  templateUrl: './owner-surgeries-availability.component.html',
  styleUrls: ['./owner-surgeries-availability.component.css']
})
export class OwnerSurgeriesAvailabilityComponent implements OnInit {
  view: CalendarView = CalendarView.Day;
  CalendarView = CalendarView;
  viewDateToday: Date = new Date();
  activeDayIsOpen: boolean = false;
  colors: any = {
    red: {
      primary: '#838383',
      secondary: '#838383',
    },
    blue: {
      primary: '#138086',
      secondary: '#138086',
    },
    yellow: {
      primary: '#534666',
      secondary: '#534666',
    },
    purple: {
      primary: '#cd7672',
      secondary: '#cd7672',
    },
    gray:{
      primary: '#eeb462',
      secondary: '#eeb462',
    },
    primeravez:{
      primary: '#9e91b1',
      secondary: '#9e91b1',
    },
    not:{
      primary: '#607d8b',
      secondary: '#607d8b',
    }
  };
  metadata = {
    date: moment().clone().startOf('day').toISOString(),
    firtsTime:true,
    dateSelected:''
  }
  todayEvents = [];
  surgeryMin = {
    main:{
      dateTimeSurgeryStart:'',
      dateTimeSurgeryEnd: ''
    },
    system:{
      room:{
        main:{
          uuid:''
        }
      }
    }
  };

  constructor(
    private session: SessionService,
    public loadingService: LoadingService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<OwnerSurgeriesAvailabilityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog:MatDialog
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.metadata.date = this.data.main.dateTimeSurgery;
    this.metadata.dateSelected = this.data.main.dateTimeSurgery;
    this.surgeryMin.main.dateTimeSurgeryEnd = moment(this.metadata.date).format('DD-MM-YYYY HH:mm');
    this.surgeryMin.main.dateTimeSurgeryStart = moment(this.metadata.date).format('DD-MM-YYYY HH:mm');
    this.viewDateToday = this.data.main.dateTimeSurgery;
    this.surgeryByDay();
  }

  /**fucnión para obtener las cirugias del dia seleccionado.
  */
  surgeryByDay(){
    this.loadingService.show(true,"Espere un momento...");


    this.session.getRequest("surgery:findAllByDateTimeSurgeryBetween", this.surgeryMin).subscribe((data:any)=>{
      // console.log(data.object.instanceList);
      this.todayEvents = [];
      for(let item of data.object.instanceList){
        if(item.system.room.main.uuid ==  this.data.system.room.main.uuid){
          let date = moment(item.main.dateTimeSurgery).toDate();
          let color:any = {
            primary:'',
            secondary:''
          };
          color = this.colors.blue;
          let text = '';
          if(item.system.patient){
            text =item.system.room.main.nameOrNumber +' | ' + item.main.cie10 +' | '+ item.system.patient.system.physicalPerson.main.name + ' '+ item.system.patient.system.physicalPerson.main.lastname + ' '+ item.system.patient.system.physicalPerson.main.surname;
          }else{
            text = 'No disponible'
          }
          let auxEvent =
          {
            start: date,
            end: addMinutes(date, item.main.minute ? item.main.minute : 30 ),
            title: text,
            color: color,
            allDay: false,
            resizable: {
              beforeStart: false,
              afterEnd: false,
            },
            draggable: false,
            meta:{main:item.main}
          };
          this.todayEvents.push(auxEvent);
        }

      }
      this.loadingService.hide();
      //realizamos el bindeo para ver los eventos
    },error=>{
      this.loadingService.hide();
      // console.log(error);
    });
  }


  handleEvent(event): void {
    // console.log(event);
    this.metadata.dateSelected = event.date;
    this.close(true);
  }

  beforeViewRender(ev){
    if( this.surgeryMin.main.dateTimeSurgeryStart != moment(ev.period.start).subtract(1, "days").format('DD-MM-YYYY HH:mm')){
      if(this.metadata.firtsTime){
        this.metadata.firtsTime = false;
        setTimeout(()=>{
          this.surgeryMin.main.dateTimeSurgeryStart =  moment(ev.period.start).subtract(1, "days").format('DD-MM-YYYY HH:mm');
          this.surgeryMin.main.dateTimeSurgeryEnd =  moment(ev.period.end).add(1, "days").format('DD-MM-YYYY HH:mm');
          this.surgeryByDay();
         }, 2000);
      }else{
        this.surgeryMin.main.dateTimeSurgeryStart =  moment(ev.period.start).subtract(1, "days").format('DD-MM-YYYY HH:mm');
        this.surgeryMin.main.dateTimeSurgeryEnd =  moment(ev.period.end).add(1, "days").format('DD-MM-YYYY HH:mm');
        this.surgeryByDay();
      }
    }
  }


  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.metadata.dateSelected;
    }else{
      object.transaction = 'bad';
      object.code = 'surgery:001';
    }
    this.dialogRef.close(object);
  }
}
