import { Component, OnInit, ViewChild, Inject  } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-admin-transfer-approve',
  templateUrl: './admin-transfer-approve.component.html',
  styleUrls: ['./admin-transfer-approve.component.css']
})
export class AdminTransferApproveComponent implements OnInit {
@ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  object:any = {
    main:{
      uuid:''
    }
  }
  metadata = {
    dateTransfer:''
  }
  constructor(public fb: FormBuilder, public session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, private router: Router, private activatedRoute: ActivatedRoute, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params['uuid'] == 'new'){

      }else{
        this.loadingService.wait();
        this.object.main.uuid = params['uuid'];
        // cargamos los datos de la BD
        this.session.getRequest('transfer:get',this.object).subscribe((data:any)=>{
        this.object = JSON.parse(JSON.stringify(data.object));
        this.metadata.dateTransfer = moment(this.object.main.dateTransfer).format('DD-MM-YYYY HH:mm:ss');
        this.loadingService.hide();
        // console.log(this.object);
      },
      (error)=>{
        this.loadingService.hide();
        console.log('Error:transfer:get',error)
      })
    }
  });
  }
  /*
  Funcion para abrir el modal de validación de la nomina.
  */
  openCommentsModal(object) {
    this.dialog.open(DialogTransferIncomplete, {
      width: '40%',
      data: {
        object: object
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        //Se importo exitosamente Trabajadores
        object.complement.comment = result;
        this.sendAndStatus(this.object,'Incompleto')
      } else {
        //No hacemos Nada
      }
    });
  }

  /** Envia a guardar el ajuste de inventario **/
  sendAndStatus(object,status){
    object.main.status = status;
     this.object.main.dateTransfer=this.metadata.dateTransfer;
     this.loadingService.show(true,'Guardando el registro, espero un momento...')
    this.session.getRequest("transfer:update", object).subscribe(
      (data:any) => {
        // evento a emitir cuando se crea o actaliza una categoria
        this.snackBar.open('El regsitro se guardo correctamente', '', {
          duration: 8000
        });
        this.loadingService.hide();
        this.router.navigate(['/admin/transfer/list']);
     },
     error => {
       console.log("transfer:update",error);
       this.loadingService.hide();
     }
    );
  }
  /*
  funcion para controlar si se abre o cierra el menu
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
@Component({
  selector: 'dialog-transfer-observation',
  templateUrl: 'dialog-transfer-observation.html',
})
export class DialogTransferIncomplete {
  metadata = {
    comment:''
  }
  constructor(
    public dialogRef: MatDialogRef<DialogTransferIncomplete>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    /*
    funcion para cerrar el modal de incremento salarial.
    */
    closeDialog(object){
      this.dialogRef.close(object);
    }

}
