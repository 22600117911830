import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent, MatSnackBar } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-role',
  templateUrl: './admin-role.component.html',
  styleUrls: ['./admin-role.component.css']
})
export class AdminRoleComponent implements OnInit {
  complexForm: FormGroup;
  roleModel = {
    main:{
      uuid:'',
      authority:'',
      description:''
    },
    system:{
      objectId:'',
      objectType:'',
      status:true
    }
  };

  metadata = {
    saving:false,
    objectType:"Company",
    company:{
      main:{
        uuid:''
      }
    }
  };
  typeList = ['Company'];
  roleList = [];
  object = JSON.parse(JSON.stringify(this.roleModel));
  constructor(public dialogRef: MatDialogRef<AdminRoleComponent>, @Inject(MAT_DIALOG_DATA) public data:any, public snackBar:MatSnackBar, public session:SessionService, public fb: FormBuilder) {
    this.complexForm = fb.group({
      'authority': [null, Validators.compose([Validators.required])],
      'description': [null, Validators.compose([Validators.required])]
    });
   }

  ngOnInit() {
    this.metadata.company = this.session.getSessionObject();
  }

  loadObjectReosurce(object){
    switch(object) {
       case 'Branch': {
          //statements;
          break;
       }
       case 'Company': {
          //statements;
          this.object.system.objectId = this.metadata.company.main.uuid;
          break;
       }
       default: {
          //statements;
          break;
       }
    }
  }

  /**Funcion para convertir los espacios en guiones bajos */
  changeInputName(object){
    let aux = object.replace(/ /g, "_");
    let aux2 = aux.toUpperCase();
    object = aux2;
    this.object.main.authority = object;
  }

  send(object) {
    if(object.main.uuid == ''){
      object.main.authority = 'ROLE_'+object.main.authority;
    }
    this.metadata.saving = true;
    this.session.getRequest('role:update', object).subscribe((data: any) => {
      this.object = JSON.parse(JSON.stringify(data.object));
      this.snackBar.open('El registro se guardo exitosamente', '', {duration: 8000});
      this.metadata.saving = false;
      //close modal
      this.closeModal(true);
    },(error) => {
      this.metadata.saving = false;
      console.log('Error: get:update', error)
    });
  }

/** Funcion para cerrar un modal
*/
  closeModal(status): void {
    this.dialogRef.close(status);
  }

}
