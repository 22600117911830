import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ViewEncapsulation, EventEmitter } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';
import * as moment from 'moment';
import { startOfDay, endOfDay, addMinutes, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { MedicalConsultationHistoryComponent } from './medical-consultation-history/medical-consultation-history.component';
import { OwnerOfficeExpenseDetailsComponent } from './owner-office-expense-details/owner-office-expense-details.component';
import { OwnerConsultationPatientComponent } from './owner-consultation-patient/owner-consultation-patient.component';

@Component({
  selector: 'app-medical-consultation',
  templateUrl: './medical-consultation.component.html',
  styleUrls: ['./medical-consultation.component.css'],
  encapsulation:ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default
})
export class MedicalConsultationComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;

  metadata = {
    date: moment().clone().startOf('day').toISOString(),
    patientYear: 0,
    patientMonth:0,
    totalPatientToDay: 0,
    totalPatientAttended: 0,
    totalIncome: 0,
    totalOfficeExpense:0,
    totalOtherIncome: 0,
    total:0,
    consultingRoom: {
      main: {
        uuid: '',
        status: '',
        dateTimeAppointment: ''
      }
    },
    doctor:{
      main:{
        uuid:''
      }
    }
  }
  officeExpenseList = [];

  physicalPerson = {
    main: {
      uuid: '',
      name: '',
      lastname: '',
      surname: ''
    },
    complement: {
      dateBirth: '',
      curp: '',
      rfc: '',
      gender: ''
    },
    system: {
      status:true,
    }
  };

  consultingRoomModel = {
    main: {
      uuid: '',
      // Fecha y hora de la cita
      dateTimeAppointment: '',
      // Pago del paciente por la cita
      pay: 0.0,
      // Estatus de la cita
      status: '',
      minute:'30',
      typeAppointment:''// medica, cirugía ( morado ), seguimiento, primera vez, no disponible (GRIS).
    },
    system: {
      // paciente al que pertence la consulta
      patient: {
        main: {
          uuid: ''
        },
        system: {
          physicalPerson:this.physicalPerson,
        }
      },
      // Doctor al que pertence la consulta
      doctor: {
        main: {
          uuid: '',
        },
      }
    }
  };

  horaryNoteList = ['Matutino', 'Vespertino', 'Nocturno'];

  medicalNotesModel = {
    main: {
      uuid:'',
      // Fecha y hora de la cita
      dateMedical: '',
      note: '',
      typeNote: '',
      share:true,
      horaryNote:'',
    },
    system: {
      doctor: {
        main: {
          uuid: ''
        }
      },
      patient: {
        main: {
          uuid: ''
        },
        system: {
          physicalPerson:this.physicalPerson,
        }
      }
    }
  }

  view: CalendarView = CalendarView.Day;

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  CalendarView = CalendarView;

  viewDate: Date = new Date();
  todayViewDate:Date = new Date();

  colors: any = {
    red: {
      primary: '#838383',
      secondary: '#838383',
    },
    blue: {
      primary: '#138086',
      secondary: '#138086',
    },
    yellow: {
      primary: '#534666',
      secondary: '#534666',
    },
    purple: {
      primary: '#cd7672',
      secondary: '#cd7672',
    },
    gray:{
      primary: '#eeb462',
      secondary: '#eeb462',
    },
    primeravez:{
      primary: '#9e91b1',
      secondary: '#9e91b1',
    },
    not:{
      primary: '#607d8b',
      secondary: '#607d8b',
    },
    endoscopio:{
      primary: '#1F618D',
      secondary: '#1F618D',
    }
  };

  toDate = new Date().toISOString().split("T");
  events: CalendarEvent[] = [];
  object = JSON.parse(JSON.stringify(this.medicalNotesModel));
  medicalNotesList = [];

  imageModel = {
    type:'',
    name:'',
    file:''
  }
  files = [];
  attachmentFileModel = {
    main:{
      uuid:''
    },
    image:{
      fileName:'',
      fileType:'',
      fileAttachment:''
    },
    system:{
      medicalNotes:{
        main:{
          uuid:''
        }
      }
    }
  }

  // ========= Modelo de signos vitales  =========
  vitalSignsModel = {
    main:{
      uuid:'',
      dataType:'',
      value:''
    },
    system:{
      patient:{
        main:{
          uuid:''
        }
      },
      consultingRoom:{
        main:{
          uuid:''
        }
      },
      medicalNotes:{
        main:{
          uuid:''
        }
      }
    }
  }
  vitalSignsList = [];
  vitalSignsTypes =[
    'Presión arterial',
    'Pulso',
    'Temperatura',
    'Oxigenación',
    'Dextrosa',
    'Peso',
    'Estatura',
    'IMC'
  ]
  // ========= fin Modelo de signos vitales  =========
  inconingList = [];
  medicalNotesCIE10Model ={
    main:{
      uuid:'',
      cie10Complement:''
    },
    system:{
      cie10:{
        main:{
          uuid:''
        }
      },
      medicalNotes:{
        main:{
          uuid:''
        }
      }
    }
  }
  medicalNotesCIE10List = [];
  cie10List = [];
  searchCie10 = new Rx.Subject<string>();
  minCie10={main: {description: ''} };

  globalConsultingRoomMin = {
    main:{
      dateTimeAppointmentEnd:'',
      dateTimeAppointmentStart:''
    },
    system:{
      doctor:{
        main:{
          uuid:''
        }
      }
    }
  }

  constructor(private session: SessionService, public dialog: MatDialog, public loadingService: LoadingService, public snackBar: MatSnackBar, private router: Router, private activatedRoute:ActivatedRoute) {
    this.searchCie10.debounceTime(700)
      .switchMap(val => {
        if (val != '') {
          this.cie10List = [];
        } else {
          this.cie10List = [];
          this.minCie10.main.description= '';
        }
        this.getCie10();
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged:', error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
      });
  }

  ngOnInit() {
    // Obtenemos la lista de citas para el día de hoy
    this.findAllByUserAndBetweenDate();
    this.activatedRoute.queryParams.subscribe(params => {
      if(params.patientUuid){
        this.loadPatientFromQuery(params.patientUuid);
      }
    });
  }

  openHistory(medicalNote) {
    this.dialog.open(MedicalConsultationHistoryComponent, {
      width: '60%',
      height: '90%',
      data: { object: medicalNote}
    }).afterClosed().subscribe(result => {
      if (result) {
        // this.findAllUsersByCompany();
      }
    });
  }

  printMedicalNote(medicalNote){
    this.loadingService.show(true, 'Espere un momento...');
    this.session.getRequest('reportMedicalNotes:download', {main: medicalNote.main, system: {doctor: {main: this.object.system.doctor.main}}}).subscribe((data: any) => {
      let elem = document.createElement('a');
      elem.href = data.object.base64;
      elem.setAttribute('download', data.object.nameFile);
      document.body.appendChild(elem);
      elem.click();
      this.loadingService.hide();
    }, error => {
      console.log("Error: reportMedicalNotes:download", error);
      this.loadingService.hide();
    });
  }

  findAllByUserAndBetweenDate() {
    this.loadingService.show(true,"Espere un momento...");
    this.getconsultingRoomfindAllByDoctorAndDateTimeAppointmentBetween().then((data:any)=>{
      this.events = [];
      this.metadata.totalPatientAttended = 0;
      this.metadata.totalIncome = 0;
      this.metadata.totalOfficeExpense = 0;
      this.metadata.total = 0;
      this.inconingList = [];
      for(let item of data){
        let date = moment(item.main.dateTimeAppointment).toDate();
        let color:any = {
          primary:'',
          secondary:''
        };
        this.metadata.totalPatientToDay = data.length;
        switch(item.main.status){
          case 'Nueva':
            color = this.colors.blue;
          break;
          case 'Atendida':
            color = this.colors.yellow;
          break
          case 'Cancelada':
            color = this.colors.red;
          break
        };
        switch(item.main.typeAppointment){
          case 'Cirugía':
            color = this.colors.purple;
          break;
          case 'No disponible':
            color = this.colors.gray;
          break
          case 'Primera vez':
            color = this.colors.primeravez;
          break
          case 'Endoscopia':
            color = this.colors.endoscopio;
          break
        };
        if(item.main.status == 'Cancelada'){
          color = this.colors.red;
        }
        if (item.main.status == 'Atendida') {
          this.metadata.totalPatientAttended++;
          this.metadata.totalIncome = this.metadata.totalIncome + item.main.pay;
          color = this.colors.yellow;
          this.inconingList.push(item);
        }
        if(item.main.status == 'No asistió'){
          color = this.colors.not;
        }
        let text = '';
        let patientUuid = '';
        if(item.system.patient){
          text = item.system.patient.system.physicalPerson.main.name + ' ' + item.system.patient.system.physicalPerson.main.lastname + ' ' + item.system.patient.system.physicalPerson.main.surname +' ('+ moment(item.main.dateTimeAppointment).format('HH:mm') +')';
          patientUuid = item.system.patient.main.uuid;
        }else{
          text = item.text;
          patientUuid = '';
        }
        let event = {
          start: date,
          end: addMinutes(date, item.main.minute == 0 ? 30 : item.main.minute ),
          title: text,
          color: color,
          consultingRoomUuid: item.main.uuid,
          patientUuid: patientUuid,
          doctorUuid: item.system.doctor.main.uuid,
          dateTimeAppointment: item.main.dateTimeAppointment,

          allDay: false,
          resizable: {
            beforeStart: false,
            afterEnd: false,
          },
          draggable: false,
        };
        this.events.push(event);
      }
      this.metadata.totalPatientToDay = this.metadata.totalPatientToDay - this.metadata.totalPatientAttended;
      this.loadingService.hide();
      if(data.length >0) {
        this.metadata.doctor = data[0].system.doctor;
      }
      this.getSpendingList();
    }).catch(e=>{
      console.log(e);
    })
  }

  async handleEvent(event: any) {
    if(event.patientUuid != ''){
      this.object = JSON.parse(JSON.stringify(this.medicalNotesModel));
      this.object.main.dateMedical = moment(event.dateTimeAppointment).format('DD/MM/YYYY hh:mm:ss');
      // Obtenemos los datos del paciente
      await this.getPatient(event.patientUuid);
      this.object.system.doctor.main.uuid = event.doctorUuid;
      this.vitalSignsList = [];
      this.medicalNotesCIE10List = [];
      this.getConsultingRoom(event.consultingRoomUuid);

      // Validamos el total de pacientes y el monto
      this.findAllByUserAndBetweenDate();
    }

  }

  changeDate(){
    let date:any = this.todayViewDate;
    this.metadata.date = date;
    this.findAllByUserAndBetweenDate();
  }

  getConsultingRoom(uuid: string) {
    this.loadingService.show(true,"Cargando datos del paciente...");
    this.session.getRequest("consultingRoom:get", { main: { uuid: uuid} }).subscribe((data: any) => {
      this.metadata.consultingRoom = data.object;
      //buscamos los signos vitales de una consulta
      //cargamos signos vitales predeterminados.
      for(let item of this.vitalSignsTypes){
        let aux = JSON.parse(JSON.stringify(this.vitalSignsModel));
        aux.main.dataType = item;
        aux.system.patient.main.uuid = this.object.system.patient.main.uuid;
        aux.system.consultingRoom.main.uuid = this.metadata.consultingRoom.main.uuid;
        this.vitalSignsList.push(aux);
      }
      this.session.getRequest("vitalSigns:findAllByPatientAndConsultingRoom",{system:{consultingRoom:{main:{uuid:this.metadata.consultingRoom.main.uuid}},patient:{main:{uuid:this.object.system.patient.main.uuid}}}}).subscribe((data:any)=>{
        for(let item of data.object.instanceList){
          for(let i = 0; i < this.vitalSignsList.length; i++){
            if(this.vitalSignsList[i].main.dataType == item.main.dataType){
              this.vitalSignsList[i] = JSON.parse(JSON.stringify(item));
            }
          }
        }
      },error=>{
        console.log('Error: vitalSigns:findAllByPatientAndConsultingRoom', error);
      });
    },
    error => {
      console.log("Error: consultingRoom:get",error);
      this.loadingService.hide();
    });
  }

  getPatient(uuid: string) {
    return new Promise((resolve,reject)=>{
      this.loadingService.show(true,"Cargando datos del paciente...");
      this.session.getRequest("patient:get", { main: { uuid: uuid} }).subscribe((data: any) => {
        this.object.system.patient = data.object;
        // Calculamos los años de paciente
        this.metadata.patientYear = moment().diff(moment(data.object.system.physicalPerson.complement.dateBirth), 'years',false);
        var years = moment().diff(moment(data.object.system.physicalPerson.complement.dateBirth), 'years',true);
        var fraction = years % 1;
        var months = Math.trunc(fraction * 12)
        this.metadata.patientMonth = months;

        this.loadingService.hide();
        // Obtenemos el historico de citas del paciente seleccionado
        this.findAllByPatient(this.object.system.patient);
        resolve(true);
      },
      error => {
        console.log("Error: patient:get",error);
        this.loadingService.hide();
        reject(error);
      });
    })

  }

  findAllByPatient(patient) {
    this.loadingService.show(true,"Cargado notas medicas anteriores...");
    this.medicalNotesList = [];
    this.session.getRequest("medicalNotes:findAllByPatient", { system: {patient: patient} }).subscribe((data: any) => {
      for (let x=0; x<data.object.instanceList.length; x++) {
        console.log('Doctor de la nota ', data.object.instanceList[x].main);
        if (data.object.instanceList[x].system.doctor.main.uuid == this.metadata.doctor.main.uuid || data.object.instanceList[x].main.share == true) {
          this.medicalNotesList.push(data.object.instanceList[x]);
          console.log('aqui', this.medicalNotesList)
        }
      }
      // this.medicalNotesList = data.object.instanceList;
      this.loadingService.hide();
    }, error => {
      console.log("Error: medicalNotes:findAllByPatient",error);
      this.loadingService.hide();
    });
  }

  getMedicalConsulting(patient) {
    if (patient.main.uuid != '') {
      this.loadingService.show(true, "Espere un momento... Obteniendo el ultimo registro.");
      this.session.getRequest("medicalNotes:last", {system: {patient: patient} }).subscribe(
        (data:any) => {
          if (data.object != '') {
            this.object.main.note = data.object.main.note;
            this.findAllCie10FromMedicalNote(data.object);
          }
          this.loadingService.hide();
        }, error => {
          console.log("Error: medicalNotes:last",error);
          this.loadingService.hide();
        });
    } else {
      this.snackBar.open('Información', 'Debe seleccionar un paciente, por favor', {
        duration: 8000
      });
    }
  }

  send(medicalNote) {
    if(medicalNote.main.typeNote != '' && medicalNote.main.note != ''){
      this.loadingService.show(true, "Espere un momento... Se esta guardando el registro.");
      this.session.getRequest("medicalNotes:update", medicalNote).subscribe(
        async (data:any) => {
          this.object = data.object;
          this.snackBar.open('La nota medica se guardo correctamente', '', {
            duration: 8000
          });
          //Guardamos medicalNotesCIE10 si existe
          for(let item of this.medicalNotesCIE10List){
            item.system.medicalNotes.main = this.object.main;
            this.saveCie10(item);
          }
          //Verificamos si existe una consulta previa, si no existe creamos una nueva
          if(this.metadata.consultingRoom.main.uuid != ''){
            //Actualizamos la nota medica con signos vitales
            for(let item of this.vitalSignsList){
              if(item.main.value != ''){
                item.system.medicalNotes ={
                  main:{
                    uuid:this.object.main.uuid
                  }
                };
                await this.saveSigVitals(item);
              }
            }
            // Actualizamos el estatus
            await this.updateConsultingRoom();
          }else{
            await this.createConsultingRoom();
            //Actualizamos la nota medica y la consulta con signos vitales
            for(let item of this.vitalSignsList){
              if(item.main.value != ''){
                item.system.medicalNotes ={
                  main:{
                    uuid:this.object.main.uuid
                  }
                };
                item.system.consultingRoom.main.uuid = this.metadata.consultingRoom.main.uuid;
                await this.saveSigVitals(item);
              }
            }
          }

          // Validamos si el objeto ya esta en a lista de hitorias clinicas
          let found = true;
          for (let x=0; x<this.medicalNotesList.length; x++) {
            if (this.medicalNotesList[x].main.uuid == this.object.main.uuid) {
              found = false;
            }
          }
          if (found) {
            this.medicalNotesList.unshift(this.object)
          }
          this.findAllByUserAndBetweenDate();
          this.loadingService.hide();

        }, error => {
          console.log("Error: medicalNotes:update",error);
          this.loadingService.hide();
        });
    }

  }

  clean(){
    this.object = JSON.parse(JSON.stringify(this.medicalNotesModel));
    this.medicalNotesList = [];
    this.vitalSignsList = [];
    this.medicalNotesCIE10List = [];
    this.findAllByUserAndBetweenDate();
  }

  updateConsultingRoom() {
    return new Promise((resolve,reject)=>{
      this.loadingService.show(true, "Espere un momento... La consulta quedo guardada.");
      this.metadata.consultingRoom.main.status = 'Atendida';
      this.metadata.consultingRoom.main.dateTimeAppointment = moment(this.metadata.consultingRoom.main.dateTimeAppointment).format('DD/MM/YYYY HH:mm:ss');
      this.session.getRequest("consultingRoom:update", this.metadata.consultingRoom).subscribe(
        (data:any) => {
          this.metadata.consultingRoom = data.object;
          this.snackBar.open('La cita medica, ya puede ser cobrada por la asistente', '', {
            duration: 8000
          });
          this.getSpendingList();
          resolve(true);
          this.loadingService.hide();
        }, error => {
          console.log("Error: consultingRoom:update",error);
          this.loadingService.hide();
          reject(error);
        });
    })
  }

  /**funcionalidad para crear un consulta nueva
  */
  createConsultingRoom(){
    return new Promise((resolve,reject)=>{
      this.loadingService.show(true, "Espere un momento... La consulta quedo guardada.");
      let consultingRoomAux = JSON.parse(JSON.stringify(this.consultingRoomModel));
      consultingRoomAux.system.patient.main = this.object.system.patient.main;
      consultingRoomAux.system.doctor.main = this.metadata.doctor.main;
      consultingRoomAux.main.dateTimeAppointment = moment().format('DD/MM/YYYY HH:mm:ss');
      consultingRoomAux.main.status = 'Atendida';
      consultingRoomAux.main.typeAppointment = 'Seguimiento';
      this.session.getRequest("consultingRoom:update", consultingRoomAux).subscribe(
        (data:any) => {
          this.metadata.consultingRoom = data.object;
          this.snackBar.open('La cita medica, ya puede ser cobrada por la asistente', '', {
            duration: 8000
          });
          this.getSpendingList();
          resolve(true);
          this.loadingService.hide();
        }, error => {
          console.log("Error: consultingRoom:update",error);
          this.loadingService.hide();
          reject(error);
        });
    });
  }

  saveSigVitals(object){
    this.session.getRequest("vitalSigns:update",object).subscribe((data:any)=>{
      object.main.uuid = data.object.main.uuid;
    },error=>{
      console.log(error);
    });
  }

  //calculamos indice de masa corporal
  calulateIMC(object){
    if(object.main.dataType == 'Estatura' || object.main.dataType == 'Peso'){
      let peso = '';
      let est = '';
      let imc:any = '';
      for(let item of this.vitalSignsList){
        if(item.main.dataType == 'Estatura'){
          est = item.main.value;
        }
        if(item.main.dataType == 'Peso'){
          peso= item.main.value;
        }
      }
      if(peso != '' && est != ''){
        imc = parseFloat(peso)/(parseFloat(est)*parseFloat(est));
      }
      if(imc != ''){
        for(let i = 0 ; i < this.vitalSignsList.length; i++){
          if(this.vitalSignsList[i].main.dataType == 'IMC'){
            this.vitalSignsList[i].main.value = Math.round(imc*100)/100;
          }
        }
      }
    }

  }

  /**calculate total spending
  */
  calculateExpeding() {
    this.metadata.total =  this.metadata.totalIncome - this.metadata.totalOfficeExpense + this.metadata.totalOtherIncome;
  }

  getSpendingList(){
    this.loadingService.show(true,"Espere un momento");
    let date =  moment().format('DD/MM/YYYY hh:mm:ss')
    this.session.getRequest("officeExpense:findAllByDoctorAndDateExpense",{main:{dateExpense:date},system:{doctor:{main:{uuid:this.metadata.doctor.main.uuid}}}}).subscribe((data:any)=>{
      this.officeExpenseList = data.object.instanceList;
      this.getOtherIncomeList();
      this.loadingService.hide();
    },error=>{
      console.log(error);
      this.loadingService.hide();
    })
  }

  getOtherIncomeList(){
    this.loadingService.show(true, "Espere un momento");
    let date =  moment().format('DD/MM/YYYY hh:mm:ss')
    this.metadata.totalOtherIncome = 0;
    this.session.getRequest("officeOtherIncome:findAllByDoctorAndDateExpenditure",{main:{dateExpenditure:date},system:{doctor:{main:{uuid:this.metadata.doctor.main.uuid}}}}).subscribe((data:any)=>{
      for (let x=0; x<data.object.instanceList.length; x++){
        this.metadata.totalOtherIncome = this.metadata.totalOtherIncome + data.object.instanceList[x].main.amount;
      }
      this.caluclateSpending();
      this.loadingService.hide();
    },error=>{
      console.log(error);
      this.loadingService.hide();
    })
  }

  /**función para calcular el total de gastos
  */
  caluclateSpending(){
    this.metadata.totalOfficeExpense = 0;
    for(let item of this.officeExpenseList){
      let i:any =this.metadata.totalOfficeExpense  + (item.main.amount*1);
      this.metadata.totalOfficeExpense = i;
    }
    this.calculateExpeding();
  }

  /**funcionalidad para crear ver el detalle de gastos por dia
  */
  openDetailExpenses(doctor){
    this.dialog.open(OwnerOfficeExpenseDetailsComponent, {
      width: '95%',
      height: '90%',
      data: {doctor:doctor,inconingList:this.inconingList},
      // disableClose:true
    }).afterClosed().subscribe(result => {
      if(result){
        if(result.transaction == 'ok'){
          this.metadata.totalOfficeExpense = result.object.totalOfficeExpense;
          this.calculateExpeding();
        }
      }
    });
  }

  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

  /**funcionalidad para buscar un paciente y cargarlo en el consultorio
  */
  loadPatient(){
    this.dialog.open(OwnerConsultationPatientComponent, {
      width: '75%',
      height: '90%',
      // disableClose:true
    }).afterClosed().subscribe(async(result) => {
      if(result){
        if(result.transaction == 'ok'){
          //cargamos el paciente
          this.clean();
          // ahora que pasa cuando seleccionamos al paciente?????
          this.object = JSON.parse(JSON.stringify(this.medicalNotesModel));
          this.object.main.dateMedical = moment().format('DD/MM/YYYY hh:mm:ss');
          // Obtenemos los datos del paciente
          await this.getPatient(result.object.main.uuid);
          await this.getDoctor();
          this.object.system.doctor.main.uuid = this.metadata.doctor.main.uuid;
          this.vitalSignsList = [];
          for(let item of this.vitalSignsTypes){
            let aux = JSON.parse(JSON.stringify(this.vitalSignsModel));
            aux.main.dataType = item;
            aux.system.patient.main.uuid = this.object.system.patient.main.uuid;
            aux.system.consultingRoom.main.uuid = this.metadata.consultingRoom.main.uuid;
            this.vitalSignsList.push(aux);
          }
          // this.getConsultingRoom(event.consultingRoomUuid);
          // Validamos el total de pacientes y el monto
          this.findAllByUserAndBetweenDate();
        }
      }
    });
  }

   async loadPatientFromQuery(uuid){
    // ahora que pasa cuando seleccionamos al paciente?????
    this.object = JSON.parse(JSON.stringify(this.medicalNotesModel));
    this.object.main.dateMedical = moment().format('DD/MM/YYYY hh:mm:ss');
    // Obtenemos los datos del paciente
    await this.getPatient(uuid);
    await this.getDoctor();
    this.object.system.doctor.main.uuid = this.metadata.doctor.main.uuid;
    this.vitalSignsList = [];
    for(let item of this.vitalSignsTypes){
      let aux = JSON.parse(JSON.stringify(this.vitalSignsModel));
      aux.main.dataType = item;
      aux.system.patient.main.uuid = this.object.system.patient.main.uuid;
      aux.system.consultingRoom.main.uuid = this.metadata.consultingRoom.main.uuid;
      this.vitalSignsList.push(aux);
    }
  }

  /**
   * Obtiene el listado de ci-10
   */
  getCie10() {
    this.loadingService.wait();
    this.session.getRequest("cie10:findAllByDescriptionLike", this.minCie10).subscribe(
      (data: any) => {
        this.cie10List = data.object.instanceList;
        this.loadingService.hide();
      },
      error => {
        console.log("Error: cie10:findAllByDescriptionLike", error);
        this.loadingService.hide();
      });
  }

  /**
   * Funcion para buscar paciente
   */
  searchCie() {
    this.searchCie10.next(this.minCie10.main.description);
  }

  /**funcion para selecciona run cie10
  */
  cie10Selected(object){
    let aux  = JSON.parse(JSON.stringify(this.medicalNotesCIE10Model));
    aux.system.cie10.main =object.main;
    this.medicalNotesCIE10List.push(aux);
    setTimeout(()=>{this.minCie10.main.description = ''; }, 500);
  }
  /**funcion para guardar un cie10 a una nota medica
  */
  saveCie10(object){
    this.session.getRequest("medicalNotesCIE10:update", object).subscribe(
      (data: any) => {
        object.main.uuid = data.object.main.uuid;
        this.snackBar.open('Cie10 actualizado...', '', {
          duration: 8000
        });
      },
      error => {
        console.log("Error: medicalNotesCIE10:update", error);
      });
  }

  /**función para eliminar un cie10 de una nota mediaca
  */
  deleteCie10(object,index){
    if(object.main.uuid != ''){
      this.loadingService.show(true,"Espere un momento...");
      this.session.getRequest("medicalNotesCIE10:delete", object).subscribe(
        (data: any) => {
          this.medicalNotesCIE10List.splice(index,1);
          this.loadingService.hide();
        },
        error => {
          console.log("Error: medicalNotesCIE10:delete", error);
          this.loadingService.hide();
        });
    }else{
      this.medicalNotesCIE10List.splice(index,1);
    }
  }

  /*Funcion para obtener los cie10 asociados a una nota medica.
  */
  findAllCie10FromMedicalNote(medicalNotes){
    this.session.getRequest("medicalNotesCIE10:findAllByMedicalNotes", {system:{medicalNotes:{main:{uuid:medicalNotes.main.uuid}}}}).subscribe(
      (data: any) => {
        for(let i = 0; i < data.object.instanceList.length; i++){
          data.object.instanceList[i].main.uuid = '';
          data.object.instanceList[i].system.medicalNotes.main.uuid = '';
        }
        this.medicalNotesCIE10List = data.object.instanceList;
      },
      error => {
        console.log("Error: medicalNotesCIE10:findAllByMedicalNotes", error);
      });
  }

  getDoctor(){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("validate:findByUsername", {
        system: {
          user: this.session.getUser()
        }
      }).subscribe((data: any) => {
        this.metadata.doctor.main.uuid = data.object.main.uuid;
        resolve(true);
      },error=>{
        this.loadingService.hide();
        reject(false);
      });
    })

  }

  getScheduleDoctor(uuid):Promise<string>{
    return new Promise((resolve,reject)=>{
      let scheduleDoctorObject = {
        system:{
          consultingRoom:{
            main:{
              uuid:uuid
            }
          }
        }
      }
      this.session.getRequest("scheduleDoctor:findByConsultingRoom", scheduleDoctorObject).subscribe(
        async (data:any) => {
          resolve(data.object.main.description);
        }, error => {
          console.log("Error: scheduleDoctor:findByConsultingRoom",error);
          resolve("No disponible");
        });
    });
  }


  getconsultingRoomfindAllByDoctorAndDateTimeAppointmentBetween(){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("consultingRoom:findAllByUserAndDateTimeAppointmentBetween",{
        system: {
          user: this.session.getUser()
        }, main: {
          dateTimeAppointmentStart: moment(this.metadata.date).format('DD/MM/YYYY hh:mm:ss'),
          dateTimeAppointmentEnd: moment(this.metadata.date).format('DD/MM/YYYY hh:mm:ss'),
        }
      }).subscribe(async(data:any)=>{
        // console.log(data.object.instanceList);
        this.events = [];
        for(let i = 0; i < data.object.instanceList.length; i++){
          if(data.object.instanceList[i].system.patient){
          }else{
            try{
              data.object.instanceList[i].text = await this.getScheduleDoctor(data.object.instanceList[i].main.uuid);
            }catch(e){
              data.object.instanceList[i].text  ="No disponible";
            }
          }
        }
        resolve(data.object.instanceList);
        //realizamos el bindeo para ver los eventos
      },error=>{
        reject(error);
        // console.log(error);
      });
    });
  }

}
