import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';
import { MatDrawer } from '@angular/material/sidenav';
import * as moment from 'moment';

@Component({
  selector: 'app-owner-balance-consulting',
  templateUrl: './owner-balance-consulting.component.html',
  styleUrls: ['./owner-balance-consulting.component.css']
})
export class OwnerBalanceConsultingComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  metadata = {
    totals:{
      consulting:0,
      expenses:0,
      income:0,
      total:0
    },
    dates:{
      startDate:'',
      endDate:''
    }
  }
  officeExpenseMin = {
    main:{
      dateExpense:''
    },
    system:{
      doctor:{
        main:{
          uuid:''
        }
      }
    }
  }

  officeOtherIncomeMin = {
    main:{
      dateExpenditure:''
    },
    system:{
      doctor:{
        main:{
          uuid:''
        }
      }
    }
  }
  consultingRoomMin = {
    main:{
      dateTimeAppointmentEnd:'',
      dateTimeAppointmentStart:''
    },
    system:{
      doctor:{
        main:{
          uuid:''
        }
      }
    }
  }
  doctor = {
    main:{
      uuid:''
    }
  }
  officeExpenseList = [];
  officeOtherIncomeList = [];
  consultingRoomList = [];

  constructor(private session: SessionService,
    public dialog: MatDialog,
    public loadingService: LoadingService,
    public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.metadata.dates.startDate =moment().startOf('week').toISOString();
    this.metadata.dates.endDate =moment().toISOString();
    //buscamos el doctor del usuario
    this.getDataDoctor().then((data)=>{
      this.doctor = JSON.parse(JSON.stringify(data));
      this.consultingRoomMin.system.doctor.main.uuid = this.doctor.main.uuid;
      this.officeExpenseMin.system.doctor.main.uuid = this.doctor.main.uuid;
      this.officeOtherIncomeMin.system.doctor.main.uuid = this.doctor.main.uuid;
      this.getDatas();
    }).catch(e=>{
      console.log(e);
    })
  }

  /**funcionalidad para obtener los datos del doctor
  */
  getDataDoctor(){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("validate:findByUsername", {
        system: {
          user: this.session.getUser()
        }
      }).subscribe(async(data: any) => {
        console.log("validate:findByUsername",data);
        resolve(data.object);
      }, error=>{
        console.log(error);
        reject(error);
      });
    });
  }

  /**funcionalidad para obtener los ingresos de un doctor
  */
  getConsultingRoomByDates(){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("consultingRoom:findAllByDoctorAndDateTimeAppointmentBetween",this.consultingRoomMin).subscribe((data:any)=>{
        this.consultingRoomList = data.object.instanceList;
        resolve(true);
      },error=>{
        reject(error);
        console.log(error);
      });
    })
  }

  /**funcionalidad para obtener los gastos de una fecha
  */
  getSpendingList(date){
    return new Promise((resolve,reject)=>{
      let aux = JSON.parse(JSON.stringify(this.officeExpenseMin));
      aux.main.dateExpense = date;
      this.session.getRequest("officeExpense:findAllByDoctorAndDateExpense",aux).subscribe((data:any)=>{
        this.officeExpenseList = this.officeExpenseList.concat(data.object.instanceList);
        resolve(true);
      },error=>{
        reject(error);
        console.log(error);
      })
    });
  }

  /**función para obtener los gastos de un doctor por una fecha
  */
  getOtherIncomeList(date){
    return new Promise((resolve,reject)=>{
      let aux = JSON.parse(JSON.stringify(this.officeExpenseMin));
      aux.main.dateExpenditure = date;
      this.session.getRequest("officeOtherIncome:findAllByDoctorAndDateExpenditure",aux).subscribe((data:any)=>{
        this.officeOtherIncomeList = this.officeOtherIncomeList.concat(data.object.instanceList);
        resolve(true);
      },error=>{
        console.log(error);
        reject(error);
      })
    });
  }

  async getDatas(){
    try{
      this.loadingService.show(true,"Bucando resultados...");
      this.consultingRoomMin.main.dateTimeAppointmentStart =  moment(this.metadata.dates.startDate).subtract(1, "days").format('DD-MM-YYYY');
      this.consultingRoomMin.main.dateTimeAppointmentEnd =  moment(this.metadata.dates.endDate).add(1, "days").format('DD-MM-YYYY');
      this.consultingRoomList = [];
      this.officeExpenseList = [];
      this.officeOtherIncomeList = [];
      await this.getConsultingRoomByDates();
      //funcionalidad para obtener los dias dentro del rango de fechas dado
      let days = moment(this.metadata.dates.endDate).diff(moment(this.metadata.dates.startDate),'days');
      let datesArrays = [];
      for(let i = 0; i <= days; i++){
        datesArrays.push(moment(this.metadata.dates.startDate).add(i,"days").format('DD/MM/YYYY hh:mm:ss'));
      }
      for(let items of datesArrays){
        await this.getSpendingList(items);
        await this.getOtherIncomeList(items);
      }
      this.calculatetotals();
      this.loadingService.hide();
    }catch(e){
      this.loadingService.hide(),
      console.log(e);
    }
  };


  /**función para calcular los totales del balance
  */
  calculatetotals(){
    this.metadata.totals.consulting = 0;
    this.metadata.totals.expenses = 0;
    this.metadata.totals.income = 0;
    this.metadata.totals.total = 0;
    for(let consulting of this.consultingRoomList){
      if(consulting.main.status == 'Atendida'){
        this.metadata.totals.consulting += consulting.main.pay;
      }
    }
    for(let expense of this.officeExpenseList){
      this.metadata.totals.expenses+=(expense.main.amount*1)
    }
    for(let otherIncome of this.officeOtherIncomeList){
      this.metadata.totals.income+=(otherIncome.main.amount*1)
    }
    this.metadata.totals.total = this.metadata.totals.consulting + this.metadata.totals.income - this.metadata.totals.expenses;
  }

  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }


}
