import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nurse-point-sale-payment-method',
  templateUrl: './nurse-point-sale-payment-method.component.html',
  styleUrls: ['./nurse-point-sale-payment-method.component.css']
})
export class NursePointSalePaymentMethodComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
