import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-product-price-import',
  templateUrl: './product-price-import.component.html',
  styleUrls: ['./product-price-import.component.css']
})
export class ProductPriceImportComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    image:{
      name:'',
      type:'',
      file:''
    },
    message:'',
    errors: false,
    numError: 0,
    numWarning: 0,
    numProcess: 0,
    offset: 0,
    max: 500,
    byGlobal:false,
    bySearching:false
  }
  list = [];
  object:any = {};
  productFamilyList = [];
  productFamilyMin = {
    system: {
      company:{
        main:{
          uuid:''
        }
      }
    },
    max: 100,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }
  productMin = {
    system: {
      company:{
        main:{
          uuid:''
        }
      },
      productFamily:{
        main:{
          uuid:''
        }
      }
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    },
    searching:false
  }
  productListFilter = [];
  productListSearching = [];
  productList = [];
  pageEvent: PageEvent;
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };

  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) {
    this.metadata.searchBoxInput.debounceTime(700)
    .switchMap(val => {
      // console.log('called once',val)
      if(val != ''){
        this.productList = [];
        this.productMin.filter.name = 'likeNameOrCode';
      }else{
        this.productList = [];
        this.productMin.filter.name = '';
        this.productMin.filter.value = '';
        val = 'false'
      }
      this.findAllProductBySearching();
      return val;
    }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
    console.log('error logged:');
    this.snackBar.open(error.message, 'Error', {duration: 5000});
    console.log(error);
  });
  }

  ngOnInit() {
    this.findAllCategories();
  }

  /**Habilitar el tipos de clasificación de descarga de productos
  */

  selectedClasification(type){
    if(type == 'global'){
      this.metadata.byGlobal = !this.metadata.byGlobal;
      this.metadata.bySearching = !this.metadata.byGlobal;
    }else{
      this.metadata.bySearching = !this.metadata.bySearching;
      this.metadata.byGlobal = !this.metadata.bySearching;
    }
  }

  /** funcionalidad para obtener productos por categoria.
  */
  findAllCategories(){
    this.loadingService.wait()
    this.productFamilyMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("productFamily:listByCompany", this.productFamilyMin).subscribe(
      (data: any) => {
        // this.productFamilyList = data.object.instanceList;
        for(let item of data.object.instanceList){
          if(item.system.status){
            this.productFamilyList.push(item);
          }
        }
        this.loadingService.hide();
      },
      error => {
        console.log("productFamily:listByCompany", error);
        this.loadingService.hide();
      }
    );
  }

  /** funcion para obtener productos por caracteristica
  */
  findAllProductByProductFamily(){
    this.loadingService.wait()
    this.productMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    // this.productMin.system.productFamily.main.uuid = object.main.uuid;
    this.session.getRequest("product:listByCompanyAndProductFamily", this.productMin).subscribe(
      (data: any) => {
        this.productListFilter = data.object.instanceList;
        this.loadingService.hide();
        this.paginatorModel.total = data.object.total;
      },
      error => {
        console.log("productFamily:listByCompany", error);
        this.loadingService.hide();
      }
    );
  }
  /** funcion para obtener productos por caracteristica
  */
  findAllProductBySearching(){
    this.productMin.searching = true;
    this.productMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("product:listByCompanyAndProductFamily", this.productMin).subscribe(
      (data: any) => {
        this.productListSearching = data.object.instanceList;
        this.productMin.searching = false;
      },
      error => {
        console.log("productFamily:listByCompany", error);
        this.productMin.searching = false;
      }
    );
  }

  productSelected(object){
    this.productList.push({main:object.main});
    this.productListSearching = [];
    this.productMin.filter.value = '';
  }

  deleteProduct(object){
    for(let i=0; i < this.productList.length; i++){
      if(this.productList[i].main.uuid == object.main.uuid){
        this.productList.splice(i,1);
      }
    }
  }

  AddProduct(object){
    object.system.selected = true;
    let found = false;
    for(let item of this.productList){
      if(object.main.uuid == item.main.uuid){
        found = true;
      }
    }
    if(!found){
      this.productList.push(object);
    }else{
      this.snackBar.open('El producto ya fue agregado', '', {
        duration: 8000
      });
    }
  }

  /**Funcion para buscar
  */
search(){
  this.metadata.searchBoxInput.next(this.productMin.filter.value);
}
// funcion para obtener los datos del paginado.
onPaginateChange(event){
  this.productMin.offset = (event.pageIndex * event.pageSize);
  this.productMin.max = event.pageSize;
  this.findAllProductByProductFamily();
}

handleUpdated(event) {
  this.productMin.offset = (this.pageEvent ? this.pageEvent.pageIndex * this.pageEvent.pageSize  : this.productMin.offset);
  this.findAllProductByProductFamily();
}


  /** Carga el archivo con los montos de precios de productos a importar**/
  uploadAFile(){
    console.log("Archivo...", this.metadata);
    this.loadingService.show(true, 'Realizando importación...');
    this.metadata.numProcess = 0;
    this.metadata.numError = 0;
    this.list = [];
    this.metadata.image.file = this.metadata.image.file.replace(/\+/g,"-");
    this.session.getRequest("productPrice:importXlsx", {file: this.metadata.image, main: { uuid: this.object.companyUuid}}).subscribe((data:any)=>{
      this.snackBar.open(data.message, 'Información', {duration: 5000});
      this.metadata.message = data.message;
      if(data.object.numError >0 || data.object.numWarning >0){
        this.metadata.errors = true;
        this.metadata.numError = data.object.numError;
        this.metadata.numWarning = data.object.numWarning;
      }
      this.metadata.numProcess = data.object.numProcess;
      if(data.object.productPriceListError.length > 0){
        this.list = data.object.productPriceListError;
      }
      this.loadingService.hide();
    },error=>{
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      this.loadingService.hide();
      console.log("productPrice:importXlsx",error);
    })
  }

  /*
  Funcion para descargar el excel con los datos de productos para asignar precios y costos.
  */
  getProductPrice(type){
    this.loadingService.show(true,'Descargando, espere un momento...');
    let leyaout = {
      main:{
        uuid: this.session.getSessionObject().main.uuid,
        offset:this.metadata.offset,
        max:this.metadata.max
      },
      system:{
        products:[]
      }
    }
    if(type != 'global'){
      leyaout.system.products = this.productList;
      if(this.metadata.offset != 0){
        leyaout.main.offset--;
      }
    }
    this.session.getRequest('productPrice:createToLayout', leyaout).subscribe(
      (data:any)=>{
        var elem = document.createElement('a');
        elem.href = data.object.base64;
        elem.setAttribute('target',"blank");
        elem.setAttribute('download', data.object.nameFile);
        document.body.appendChild(elem);
        elem.click();
        this.loadingService.hide();
      },
      error => {
        console.log("productPrice:createToLayout",error);
        this.loadingService.hide();
        this.snackBar.open('Error en la descarga', 'Error', {duration: 5000});
      })
  }

  /*
  funcion para controlar si se abre o cierra el menu
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
