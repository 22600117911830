import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { SessionService } from '../../../service/session/session.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { LoadingService } from '../../../directive/loading/loading.module';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-admin-attendance-rule-update',
  templateUrl: './admin-attendance-rule-update.component.html',
  styleUrls: ['./admin-attendance-rule-update.component.css']
})
export class AdminAttendanceRuleUpdateComponent implements OnInit {

  @ViewChild('drawer', { static: true }) drawerCtrl: MatDrawer;
  complexForm: FormGroup;

  attendanceRules ={
    main:{
      uuid:'',
      weightingType: '',
      weightingValue: ''
    },
    system:{
      status: true,
      company: {
        main:{
          uuid:''
        }
      },
      workingHours:{
        main:{
          uuid:''
        }
      },
      typePunishment:{
        main:{
          uuid:''
        }
      }
    }
  }

  workingHoursList = [];
  typePunishmentList = [];
  weightingType = ['Minutos','Retardos','Días','Registros o Chequeos'];
  metadata = {
    disable:false
  };

  object = JSON.parse(JSON.stringify(this.attendanceRules));

  constructor(public fb: FormBuilder, protected sessionService: SessionService, private router: Router, private activatedRoute: ActivatedRoute, public loadingService: LoadingService, public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.listworkingHours();
    this.listTypePunishment();

    this.activatedRoute.params.subscribe((params: Params) => {
      if(params['uuid'] == 'new'){
        // no hacemos nada por el momento
        this.metadata.disable = false;
      }else{
        this.object.main.uuid = params['uuid'];
        // cargamos los datos de la BD
        this.sessionService.getRequest('attendanceRules:get', this.object).subscribe((data:any)=>{
          this.object = JSON.parse(JSON.stringify(data.object));
          this.metadata.disable = true;

          if(this.object.main.weightingType == 'minutes')
            this.object.main.weightingType = 'Minutos'
          if(this.object.main.weightingType == 'delays')
            this.object.main.weightingType = 'Retardos'
          if(this.object.main.weightingType == 'days')
            this.object.main.weightingType = 'Días'
          if(this.object.main.weightingType == 'records')
            this.object.main.weightingType = 'Registros o Chequeos'

        },
        (error)=>{
          console.log('Error:attendanceRules:get',error)
        })
      }
    });
  }

  /** Obtiene los horarios de trabajo por cliente **/
  listworkingHours(){
    this.sessionService.getRequest("workingHours:findAllByCompanyAndStatus", {system:{status:true,company:{main:{uuid: this.sessionService.getSessionObject().main.uuid}}}}).subscribe(
      (data:any)=>{
        this.workingHoursList = data.object.instanceList;
      },error=>{
        console.log("Erro:workingHours:findAllByCompanyAndStatus",error)
    });
  }

  // Obtenemos los tipos de sanciones que existen en el sistema.
  listTypePunishment(){
    this.sessionService.getRequest("typePunishment:findAllByCompanyAndStatus", {system:{status:true,company:{main:{uuid: this.sessionService.getSessionObject().main.uuid}}}}).subscribe(
      (data:any)=>{
        if (data.object.instanceList.length > 0) {
          this.typePunishmentList = [];
          this.typePunishmentList = data.object.instanceList;
        } else {
          this.snackBar.open('¡No hay tipos de sanciones dadas de alta!', 'Error', {duration: 5000});
        }
      },error=>{
        console.log("Erro:typePunishment:findAllByCompanyAndStatus",error)
    });
  }

  send(object) {
    if(object.main.weightingType == 'Minutos')
      object.main.weightingType = 'minutes'
    if(object.main.weightingType == 'Retardos')
      object.main.weightingType = 'delays'
    if(object.main.weightingType == 'Días')
      object.main.weightingType = 'days'
    if(object.main.weightingType == 'Registros o Chequeos')
      object.main.weightingType = 'records'
    object.system.company.main.uuid = this.sessionService.getSessionObject().main.uuid;

    this.sessionService.getRequest('attendanceRules:update', object).subscribe((data:any)=>{
      //this.object = JSON.parse(JSON.stringify(data.object));
      this.metadata.disable = true;
      this.snackBar.open('Registro guardado exitosamente', 'Información', {duration: 5000});
      this.router.navigate(['/admin/schedule/attendanceRule']);
      this.loadingService.hide();
    },
    (error)=>{
      console.log('Error:attendanceRules:update',error)
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      this.loadingService.hide();
    })
  }

  /*
  funcion para controlar si se abre o cierra el menu
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
