import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-owner-other-income-classification-update',
  templateUrl: './owner-other-income-classification-update.component.html',
  styleUrls: ['./owner-other-income-classification-update.component.css']
})
export class OwnerOtherIncomeClassificationUpdateComponent implements OnInit {

  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;
  complexForm: FormGroup;
  otherIncomeClassificationModel = {
    main: {
      uuid: '',
      code: '',
      name: ''
    },
    complement: {
      description: '',
    },
    system: {
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      }
    }
  };
  object = JSON.parse(JSON.stringify(this.otherIncomeClassificationModel));
  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, private router: Router, private activatedRoute: ActivatedRoute, public snackBar: MatSnackBar) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'code': [null, Validators.required],
      'name': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
    })
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['uuid'] != 'new') {
        this.loadingService.wait();
        this.object.main.uuid = params['uuid'];
        this.session.getRequest('otherIncomeClassification:get', this.object).subscribe((data: any) => {
          this.object = JSON.parse(JSON.stringify(data.object));
          this.loadingService.hide();
        }, error => {
          this.loadingService.hide();
          console.log("otherIncomeClassification:get", error);
        });
      }
    });
  }

  send(object) {
    this.loadingService.show(true, 'Guardando registro');
    object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("otherIncomeClassification:update", object).subscribe(
      (data: any) => {
        object.main.uuid = data.object.main.uuid;
        // evento a emitir cuando se crea o actaliza una categoria
        this.snackBar.open('El registro se guardo correctamente', '', {
          duration: 8000
        });
        this.loadingService.hide();
        this.router.navigate(['/owner/otherIncomeClassification/list']);
      },
      error => {
        this.loadingService.hide();
        console.log("otherIncomeClassification:update", error);
      }
    );
  }
  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
