import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar, MatDialogRef } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import * as Rx from 'rxjs/Rx';
import { LoadingService } from '../../../directive/loading/loading.module';
// import * as moment from 'moment';

@Component({
  selector: 'app-product-import',
  templateUrl: './product-import.component.html',
  styleUrls: ['./product-import.component.css']
})
export class ProductImportComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  metadata = {
    image:{
      name:'',
      type:'',
      file:''
    },
    file:{
      name:'',
      type:'',
      file:''
    },
    message:'',
    errors: false,
    numError: 0,
    numWarning: 0,
    numProcess: 0
  }
  list = [];

  constructor(public dialogRef: MatDialogRef<ProductImportComponent>, private session: SessionService,  public snackBar: MatSnackBar,public loadingService: LoadingService) { }

  ngOnInit() {
  }
  uploadAFile(){
    console.log("Archivo...",this.metadata);
    this.loadingService.show(true, 'Realizando importación...');
    this.metadata.image.file = this.metadata.image.file.replace(/\+/g,"-");
    this.session.getRequest("product:importProduct", {file: this.metadata.image, main:{uuid: this.session.getSessionObject().main.uuid}}).subscribe((data:any)=>{
      this.snackBar.open(data.message, 'Información', {duration: 5000});
      this.metadata.message = data.message;
      if(data.object.numError >0 || data.object.numWarning >0){
        this.metadata.errors = true;
        this.metadata.numError = data.object.numError;
        this.metadata.numWarning = data.object.numWarning;
      }
      this.metadata.numProcess = data.object.numProcess;
      if(data.object.productListError.length > 0){
        this.list = data.object.productListError;
      }
      this.loadingService.hide();
    },error=>{
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      this.loadingService.hide();
      console.log("product:importProduct",error);
    })
  }

  /*
  Funcion para descargar reporte de excel de datos de trabajadores.
  */
  getReportErrors(){
    // this.loadingService.show(true,'Descargando, espere un momento...');
    this.session.getRequest('product:createToReportErrors', {lista: this.list}).subscribe(
      (data:any)=>{
        var elem = document.createElement('a');
        elem.href = data.object;
        elem.setAttribute('download', 'layaout-product-errors.xlsx');
        document.body.appendChild(elem);
        elem.click();
        this.loadingService.hide();
      },
      error => {
        console.log("product:createToReportErrors",error);
        this.loadingService.hide();
        this.snackBar.open('Error en la descarga', 'Error', {duration: 5000});
      })
  }

  /** funcion para descargar el layout de proveedores
  */
  getProvider(){
    this.session.getRequest("provider:layoutProvider",{system:{company:{main:{uuid:this.session.getSessionObject().main.uuid}}}}).subscribe((data:any)=>{
      console.log(data);
      let elem = document.createElement('a');
      elem.href = data.object;
      elem.setAttribute('download', 'proveedores.csv');
      document.body.appendChild(elem);
      elem.click();
    },error=>{
      console.log("error:provider:layoutTax");
    })
  }

  /** Metodo para obtener el listado de productos
  */
  getLayoutProductProvider(){
    this.session.getRequest("productProvider:layoutImportProductProvider",{system:{company:{main:{uuid:this.session.getSessionObject().main.uuid}}}}).subscribe((data:any)=>{
      console.log(data);
      let elem = document.createElement('a');
      elem.href = data.object;
      elem.setAttribute('download', 'layout-product-provider.csv');
      document.body.appendChild(elem);
      elem.click();
    },error=>{
      console.log("Error:productProvider:layoutImportProductProvider",error);
    })
  }
  /** funcionalidad para importar la relación de producto proveedor
  */
  uploadProductProvider(){
    // this.object.system.user.main.uuid = this.session.getUser().main.uuid;
    // this.loadingService.show(true,"Esto tomará unos minutos, Se esta importando los datos...");
    let object = {
      file:{
        name:'',
        type:'',
        file:''
      },
      system:{
        company:{main:{uuid:''}}
      }
    };
    object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    object.file.file = this.metadata.file.file.replace(/\+/g,"-");
    object.file.name = this.metadata.file.name;
    object.file.type = this.metadata.file.type;
    this.session.getRequest("productProvider:importProductProvider", object).subscribe(
      (data:any) => {
        // evento a emitir cuando se crea o actaliza una categoria
        this.snackBar.open('Relación producto-proveedor importada exitosamente', '', {
          duration: 8000
        });
        this.loadingService.hide();
        this.closeDialog(true);
     },
     error => {
       console.log("productProvider:importProductProvider",error);
       this.loadingService.hide();
     }
    );
  }
  /*
  funcion para cerrar el modal de incremento salarial.
  */
  closeDialog(object){
    this.dialogRef.close(object);
  }

}
