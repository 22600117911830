import { Component, OnInit, ViewChild, Inject  } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-admin-ajustment-validate',
  templateUrl: './admin-ajustment-validate.component.html',
  styleUrls: ['./admin-ajustment-validate.component.css']
})
export class AdminAjustmentValidateComponent implements OnInit {

  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
    adjustmentModel = {
      main: {
        uuid: '',
        dateAdjustment: '',
        status: '',
        subTotal:0,
        total:0,
        discount:0,
      },
      complement: {
        comment: ''
      },
      system: {
        warehouse: {
          main: {
            uuid: ''
          }
        },
        adjustmentType: {
          main: {
            uuid: ''
          }
        },
        company: {
          main: {
            uuid: ''
          }
        },
        status: true,
        products: [],
        employee:{
          main:{
            uuid:''
          }
        }
      },
      inventoryMovement: {
        main: {
          uuid: '',
          object: 'Adjustment',
          objectUuid: '',
          date: ''
        },
        system: {
          status: true
        }
      }
    }
    adjustmentProductModel= {
      main: {
        uuid: '',
        quantity: 0
      },
      system: {
        product: {
          main: {
            uuid:'',
            code: '',
            name: ''
          },
          system: {
            transientDatas: []
          }
        }
      }
    }
    transientDataProductsList = [];
    transientDataProductsAvailableList = [];
    adjustmentProductsListFilter = [];
    object = JSON.parse(JSON.stringify(this.adjustmentModel));
    metadata = {
      next: false,
      dateAdjustment:'',
      isLinear:true,
      searching:false
    }
    constructor(public fb: FormBuilder, public session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, private router: Router, private activatedRoute: ActivatedRoute, public dialog: MatDialog) {
    }

    ngOnInit() {
      this.activatedRoute.params.subscribe((params: Params) => {
        if(params['uuid'] == 'new'){

        }else{
          this.loadingService.wait();
          this.object.main.uuid = params['uuid'];
          // cargamos los datos de la BD
          this.session.getRequest('adjustment:get',{uuid:this.object.main.uuid}).subscribe((data:any)=>{
          this.object = JSON.parse(JSON.stringify(data.object));
          this.metadata.dateAdjustment = moment(this.object.main.dateAdjustment).format('DD-MM-YYYY');
          this.loadingService.hide();
          // console.log(this.object);
        },
        (error)=>{
          this.loadingService.hide();
          console.log('Error:adjustment:get',error)
        })
      }
    });
    }
    /** Envia a guardar el ajuste de inventario **/
    sendAndStatus(object,status){
      object.main.status = status;
       this.object.main.dateAdjustment=this.metadata.dateAdjustment
       this.loadingService.show(true,'Guardando el registro, espero un momento...')
      this.session.getRequest("adjustment:update", object).subscribe(
        (data:any) => {
          // evento a emitir cuando se crea o actaliza una categoria
          this.snackBar.open('El regsitro se guardo correctamente', '', {
            duration: 8000
          });
          this.loadingService.hide();
          this.router.navigate(['/inventory/analyst/adjustment']);
       },
       error => {
         console.log("adjustment:update",error);
         this.loadingService.hide();
       }
      );
    }
    /*
    Funcion para abrir el modal
    */

      /*
      Funcion para abrir el modal de validación de la nomina.
      */
      openCommentsModal(object) {
        this.dialog.open(DialogAdjusmentIncomplete, {
          width: '40%',
          data: {
            object: object
          }
        }).afterClosed().subscribe(result => {
          if (result) {
            //Se importo exitosamente Trabajadores
            object.complement.comment = result;
            this.sendAndStatus(this.object,'Incompleto')
          } else {
            //No hacemos Nada
          }
        });
      }

      nextAndCompleteTransientData(){
        for(let item of this.object.system.products){
          item.system.products = [];
          this.AddToAdjustmentProductList(item);
          // for(let i=0; i < item.main.quantity; i++){
          // }
        }
      }
      nextLoadTrasientDataByProduct(){
        for(let i=0; i <this.object.system.products.length; i++){
          this.getProductsAvailableByWharehouse(this.object.system.products[i].system.product,this.object.system.warehouse,i).then((data)=>{
            this.getTrasientDataFromInventory(this.object.system.products[i]);
            this.object.system.products[i].main.value = '';
          });
        }
      }

      AddToAdjustmentProductList(object){
        // Buscamos los datos transitorios de dicho producto
        this.session.getRequest("productTransientData:listByProduct", {system:{product:{main:{uuid:object.system.product.main.uuid}},max:200}}).subscribe(
          (data: any) => {
            for( let i=0; i < object.main.quantity; i++){
              let adjustmentProduct = {
                main:{
                  uuid:''
                },
                system:{
                  transientDatas:[]
                }
              };
              for(let transientData of data.object.instanceList ){
                adjustmentProduct.system.transientDatas.push({
                  main: {
                    uuid:'',
                    value:''
                  },
                  system: {
                    product: transientData.system.product,
                    transientData: transientData.system.transientData
                  }
                });
              }
              object.system.products.push(adjustmentProduct);
            }
          },error => {
            console.log("productTransientData:listByProduct", error);
        });
      }
      /*
      funcion para enviar a validado y proceder con el ajuste de inventario
      */
      send(){
        this.object.main.status = 'Validado';
         this.object.main.dateAdjustment = moment().format('DD-MM-YYYY HH:mm:ss');
         for(let i=0; i < this.object.system.products.length; i++){
           for(let item of this.transientDataProductsList){
             if(item.system.product.main.uuid == this.object.system.products[i].system.product.main.uuid){
               this.object.system.products[i].system.product.system.transientDatas=this.object.system.products[i].system.product.system.transientDatas.concat(item.system.product.system.transientDatas)
             }
           }
         }
         // console.log(this.object);
         this.loadingService.show(true,'Guardando el registro, espero un momento...')
        this.session.getRequest("adjustment:validateAdjustment", this.object).subscribe(
          (data:any) => {
            // evento a emitir cuando se crea o actaliza una categoria
            this.snackBar.open('El registro se guardo correctamente', '', {
              duration: 8000
            });
            this.loadingService.hide();
            this.router.navigate(['/admin/adjustment/list']);
         },
         error => {
           console.log("adjustment:validateAdjustment",error);
           this.loadingService.hide();
         }
        );
      }
      //Funcion para obtener lo datos transitorios de productos por almacen y Cantidad
      getTrasientDataFromInventory(object){
      // console.log(object);
        this.session.getRequest("warehouseProduct:stockForWarehouseAndProductDetail",{main:{uuid:object.main.uuid,quantity:object.main.quantity}}).subscribe(
          (data:any) => {
            // evento a emitir cuando se crea o actaliza una categoria
            object.system.products = data.object.splice(0,object.main.quantity);
         },
         error => {
           console.log("warehouseProduct:stockForWarehouseAndProductDetail",error);
           this.loadingService.hide();
         }
        );
      }
      //
      productFindAllByNameLike(object){
        if(object.main.value != ''){
          this.adjustmentProductsListFilter = [];
          this.metadata.searching = true;
          this.session.getRequest("warehouseProduct:searchWarehouseProductByTransientData",{main:{uuid:object.main.uuid,value:object.main.value}}).subscribe(
            (data:any) => {
              this.metadata.searching = false;
              console.log(data);
              this.adjustmentProductsListFilter = data.object;
              this.loadingService.hide();
           },
           error => {
             console.log("warehouseProduct:searchWarehouseProductByTransientData",error);
             this.loadingService.hide();
           }
          );
        }else{
          this.adjustmentProductsListFilter = [];
        }
      }
      // Funcion para quitar un producto de las lista de productos a enviar al ajuste
      deleteAWhareHouseProduct(object,item){
        for(let i=0; i < object.system.products.length; i++){
          if(item.main.uuid == object.system.products[i].main.uuid){
            object.system.products.splice(i,1);
          }
        }
      }
      addProductToSelectedList(object,item){
        // console.log('oko');
        if(object.system.products.length < object.main.quantity){
          let found = false;
          for(let item2 of object.system.products){
            if(item2.main.uuid == item.main.uuid){
              found = true;
              break;
            }
          }
          if(!found){
            object.system.products.push(item);
          }else{
            this.snackBar.open('Este artículo ya se encuentra agregado', 'Aceptar', {
              duration: 8000
            });
          }
        }else{
          this.snackBar.open('El artículo no se puede agregar debido a que supera la cantidad seleccionada en el ajuste', 'Aceptar', {
            duration: 8000
          });
        }
        this.adjustmentProductsListFilter = [];
      }
      /*
      Funcion para encontrar productos en inventario segun consulta de productos
      */
      getProductsAvailableByWharehouse(product,wharehouse,index){
        return new Promise((resolve=>{
          this.session.getRequest("warehouseProduct:findByProductByWarehouse", {system:{product:{main:{uuid:product.main.uuid}},warehouse:{main:{uuid:wharehouse.main.uuid}}}}).subscribe(
            (data:any) => {
              this.object.system.products[index].main.uuid = data.object.main.uuid;
              resolve(data.object);
           },
           error => {
             console.log("warehouseProduct:findByProductByWarehouse",error);
             this.loadingService.hide();
           }
          );
        }))
      }
      /** funcionalidad para generar codigo a los transientData
      */
      generateValueOfTrasientData(object){
        // console.log("generateValueOfTrasientData:",object);
        let value = object.system.product.main.code;
        // recorremos lo datos transitorios para agregar este codigo
        for(let i = 0; i < object.system.products.length; i++){
          for(let j = 0; j < object.system.products[i].system.transientDatas.length; j++){
            object.system.products[i].system.transientDatas[j].main.value = value;
          }
        }
      }
      generateValueGlobal(object){
        for(let i = 0; i < object.system.products.length; i++){
          this.generateValueOfTrasientData(object.system.products[i]);
        }
      }
    //funcion para controlar si se abre o cierra el menu
    toogleMenu(ev){
      this.drawerCtrl.opened = ev;
    }

}

@Component({
  selector: 'dialog-adjustment-observation',
  templateUrl: 'dialog-adjustment-observation.html',
})
export class DialogAdjusmentIncomplete {
  metadata = {
    comment:''
  }
  constructor(
    public dialogRef: MatDialogRef<DialogAdjusmentIncomplete>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    /*
    funcion para cerrar el modal de incremento salarial.
    */
    closeDialog(object){
      this.dialogRef.close(object);
    }

}
