import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent } from '@angular/material';
import { SessionService } from '../../service/session/session.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { LoadingService } from '../../directive/loading/loading.module';

@Component({
  selector: 'app-admin-attendance-rule',
  templateUrl: './admin-attendance-rule.component.html',
  styleUrls: ['./admin-attendance-rule.component.css']
})
export class AdminAttendanceRuleComponent implements OnInit {

  @ViewChild('drawer', { static: true }) drawerCtrl: MatDrawer;
  list = [];
  attendanceRulesMin = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      }
    },
    max:10,
    offset:0,
  }

  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };

  // MatPaginator Output
  pageEvent: PageEvent;

  constructor(private session: SessionService, public dialog: MatDialog, public snackBar: MatSnackBar, public loadingService: LoadingService) {
    this.attendanceRulesMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
  }

  ngOnInit() {
    this.listAttendanceRules();
  }

  /** Funcion para obtener la lista de horarios laborales **/
  listAttendanceRules(){
    this.list = [];
    this.session.getRequest('attendanceRules:findAllByCompany', this.attendanceRulesMin).subscribe(
      (data:any)=>{
        this.list = data.object.instanceList
        this.paginatorModel.total = data.object.total;
      },
      error => {
        console.log("attendanceRules:findAllByCompany",error);
      })
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.attendanceRulesMin.offset = (event.pageIndex * event.pageSize);
    this.attendanceRulesMin.max = event.pageSize;
    this.listAttendanceRules();
  }

  handleUpdated(event) {
    this.attendanceRulesMin.offset = (this.pageEvent ? this.pageEvent.pageIndex * this.pageEvent.pageSize  : this.attendanceRulesMin.offset);
    this.listAttendanceRules();
  }
  /*
  funcion para controlar si se abre o cierra el menu
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
