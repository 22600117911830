import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { MatSnackBar } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-owner-colaborator-update',
  templateUrl: './owner-colaborator-update.component.html',
  styleUrls: ['./owner-colaborator-update.component.css']
})
export class OwnerColaboratorUpdateComponent implements OnInit {

  complexForm: FormGroup;
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;

  physicalPerson = {
    main:{
      uuid: '',
      name: '',
      lastname: '',
      surname: ''
    },
    complement:{
      dateBirth: '',
      curp: '',
      rfc: '',
      gender: ''
    },
    system :{
      status:true,
    }
  };

  collaboratorModel = {
    main:{
      uuid:'',
      specialty: ''
    },
    system:{
      physicalPerson:this.physicalPerson,
      user:{
        main:{
          uuid:''
        }
      },
      role:'',
      company:{
        main:{
          uuid:''
        }
      },
      status:true
    }
  };

  metadata = {
    dateBirth : null
  }

  object = JSON.parse(JSON.stringify(this.collaboratorModel));
  constructor(public fb: FormBuilder, protected session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, private router: Router, private activatedRoute: ActivatedRoute) {
    this.complexForm = fb.group({
      'name': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      'lastname': [null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(100)])],
      'surname': [null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(100)])],
      'dateBirth': [null, Validators.compose([Validators.required])],
      'gender': [null, Validators.compose([Validators.required])],
      'role': [null, Validators.compose([Validators.required])],
      'specialty': [null],
      'curp': [null, Validators.compose([Validators.pattern('[A-Za-z]{4}[0-9]{6}[H,M][A-Za-z]{5}[0-9]{2}')])],
      'rfcP': [null, Validators.compose([Validators.pattern('[A-Za-z]{4}[0-9]{6}[A-Za-z0-9]{3}')])],
    })
  }

  ngOnInit() {
    // this.generateCode();
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params['uuid'] == 'new'){
        // no hacemos nada por el momento
      }else{
        this.object.main.uuid = params['uuid'];
        // cargamos los datos de la BD
        this.loadingService.wait();
        this.session.getRequest('collaborator:get',this.object).subscribe((data:any)=>{
          this.object = JSON.parse(JSON.stringify(data.object));
          this.metadata.dateBirth = moment(this.object.system.physicalPerson.complement.dateBirth).utc().toISOString();
          this.loadingService.hide();
        },
        (error)=>{
          console.log('Error:collaborator:get',error)
          this.loadingService.hide();
        })
      }
    });
  }

  send(object) {
    // pasamos la fecha de compleaño al formato que recibe al BD
    object.system.physicalPerson.complement.dateBirth = moment(this.metadata.dateBirth).format("DD-MM-YYYY");
    object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.loadingService.show(true, "Espere un momento... Se esta guardando el registro.")
    this.session.getRequest("collaborator:update", object).subscribe(
      (data:any) => {
        object.main.uuid = data.object.main.uuid;
        this.snackBar.open('El registro se guardo correctamente', '', {
          duration: 8000
        });
        this.loadingService.hide();
        this.router.navigate(['/owner/colaborator/list']);

      },
      error => {
        console.log("Error: collaborator:update",error);
        this.loadingService.hide();
      }
    );
  }

  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
