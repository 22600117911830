import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import {MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-zone-update',
  templateUrl: './admin-zone-update.component.html',
  styleUrls: ['./admin-zone-update.component.css']
})
export class AdminZoneUpdateComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  complexForm: FormGroup;
  floorModel = {
    main: {
      uuid: '',
      name: ''
    },
    system: {
      company: {
        main:{
          uuid: ''
        }
      },
      status: true
    }
  }
  object = JSON.parse(JSON.stringify(this.floorModel));
  constructor(public fb: FormBuilder, private session: SessionService, public snackBar: MatSnackBar, private router: Router, private activatedRoute: ActivatedRoute, public loadingService:LoadingService) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'name': [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(100)])],
    })
   }

   ngOnInit() {
     this.activatedRoute.params.subscribe((params: Params) => {
       if(params['uuid'] == 'new'){

       }else{
         this.loadingService.wait();
          this.object.main.uuid = params['uuid'];
          // cargamos los datos de la BD
          this.session.getRequest('floor:get',this.object).subscribe((data:any)=>{
            this.object = JSON.parse(JSON.stringify(data.object));
            this.loadingService.hide();
          },
          (error)=>{
            this.loadingService.hide();
            console.log('Error:floor:get',error)
          })

       }
     });
   }

   /*
   funcion para guardar un kit de producto
   */
   send(object){
     object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
     object.system.status = true;
     this.loadingService.show(true,'Guardando registro');
     this.session.getRequest("floor:update",object).subscribe(
       (data:any) => {
         this.snackBar.open('El registro se guardo correctamente', '', {
           duration: 8000
         });
         this.loadingService.hide();
         this.router.navigate(['/admin/zone/list']);
      },
      error => {
        console.log("floor:update",error);
        this.loadingService.hide();
      }
     );
   }


  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
