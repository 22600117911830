import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { SessionService  } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import * as moment from 'moment';

@Component({
  selector: 'app-specialist-medical-consultation-history',
  templateUrl: './specialist-medical-consultation-history.component.html',
  styleUrls: ['./specialist-medical-consultation-history.component.css']
})
export class SpecialistMedicalConsultationHistoryComponent implements OnInit {
  object: any = {};
  vitalSignsList = [];
  dateMedical = '';
  medicalNotesCIE10List = [];

  metadata = {
    doctor:{
      main:{
        uuid:''
      }
    }
  }
  constructor(public dialogRef: MatDialogRef<SpecialistMedicalConsultationHistoryComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public session: SessionService,
    public loading: LoadingService,
    public snackBar: MatSnackBar,

  ) {
    this.object = data.object;
    this.dateMedical = JSON.parse(JSON.stringify(this.object.main.dateMedical));
  }

  ngOnInit() {
    this.getVitals();
    this.getDoctor();
    this.findAllCie10FromMedicalNote(this.object);
  }

  /**funcionalidad para obtener los signos vitales
  */
  getVitals() {
    this.session.getRequest("vitalSigns:findAllByPatientAndMedicalNotes", { system: { medicalNotes: { main: { uuid: this.object.main.uuid } }, patient: { main: { uuid: this.object.system.patient.main.uuid } } } }).subscribe((data: any) => {
      this.vitalSignsList = data.object.instanceList;
    }, error => {
      console.log(error);
    });
  }

  /**funcionalidad para compartir una nota medica
  */
  shareMedicalNote(object) {
    this.loading.show(true, "Espere un momento...");
    let objectAux = JSON.parse(JSON.stringify(object));
    objectAux.main.dateMedical = moment(this.dateMedical).format('DD/MM/YYYY hh:mm:ss');
    this.session.getRequest("medicalNotes:update", objectAux).subscribe((data: any) => {
      // this.snackBar.open('Nota medica actualizada correctamente', 'Aceptar', { duration: 5000 });
      this.loading.hide();
    }, error => {
      this.loading.hide();
      console.log(error);
    });
  }

  /*Funcion para obtener los cie10 asociados a una nota medica.
  */
  findAllCie10FromMedicalNote(medicalNotes) {
    this.session.getRequest("medicalNotesCIE10:findAllByMedicalNotes", { system: { medicalNotes: { main: { uuid: medicalNotes.main.uuid } } } }).subscribe(
      (data: any) => {
        this.medicalNotesCIE10List = data.object.instanceList;
      },
      error => {
        console.log("Error: medicalNotesCIE10:findAllByMedicalNotes", error);
      });
  }

  printMedicalNote(medicalNote) {
    this.loading.show(true, 'Espere un momento...');
    this.session.getRequest('reportMedicalNotes:download', { main: medicalNote.main, system: { doctor: { main: this.metadata.doctor.main } } }).subscribe((data: any) => {
      let elem = document.createElement('a');
      elem.href = data.object.base64;
      elem.setAttribute('download', data.object.nameFile);
      document.body.appendChild(elem);
      elem.click();
      this.loading.hide();
    }, error => {
      console.log("Error: reportMedicalNotes:download", error);
      this.loading.hide();
    });
  }

  getDoctor(){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("validate:findByUsername", {
        system: {
          user: this.session.getUser()
        }
      }).subscribe((data: any) => {
        console.log(data)
        this.metadata.doctor.main.uuid = data.object.main.uuid;
        resolve(true);
      },error=>{
        this.loading.hide();
        reject(false);
      });
    });
  }
}
