import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material';
import {SessionService} from '../../../service/session/session.module';
import {LoadingService} from '../../../directive/loading/loading.module';
import {FormControl, Validators, FormBuilder, FormGroup} from '@angular/forms';
import {NurseSurgeriesAvailabilityComponent} from '../nurse-surgeries-availability/nurse-surgeries-availability.component';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';

@Component({
  selector: 'app-nurse-surgeries-update',
  templateUrl: './nurse-surgeries-update.component.html',
  styleUrls: ['./nurse-surgeries-update.component.css']
})
export class NurseSurgeriesUpdateComponent implements OnInit {
// ========= Datos de paciente  =========
  patientMin = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      },
      max: 10,
      offset: 0,
    },
    filter: {
      name: '',
      value: ''
    }
  };
  patientList = [];

  //========== Fin datos de paciente ======


  //Doctor
  doctorMin = {
    system: {
      role: '',
      company: {
        main: {
          uuid: ''
        }
      }
    },
    filter: {
      name: '',
      value: ''
    }
  };

  doctorList = [];
  ///
  surgeryModel = {
    main: {
      uuid: '',
      dateTimeSurgery: '',
      status: '',
      minute: '30',
      date: '',
      cie10: '',
      fullname: '',
      isAmbulatory: false,
    },
    complement: {
      observation: ''
    },
    system: {
      room: {
        main: {
          uuid: ''
        }
      },
      patient: {
        main: {
          uuid: ''
        }
      },
      doctor: {
        main: {
          uuid: ''
        }
      }
    }
  };

  patientWithOut = {
    main: {
      uuid: '2c9fa81f87949422018795a98e5a0112'
    },
    system: {
      physicalPerson: {
        main: {
          name: 'ANÓNIMO',
          lastname: '',
          surname: ''
        }
      }
    }
  };

  metadata = {
    searchPatient: new Rx.Subject<string>(),
    searchDoctor: new Rx.Subject<string>(),
    date: '',
    hour: '',
    min: '',
    useAnomimus: 'withName',
    useDoctor: 'withName'
  };
  statusList = [
    'Nueva',
    'Cancelada',
    'No asistió'
  ];
  floors = [];
  object = JSON.parse(JSON.stringify(this.surgeryModel));


  cie10List = [];
  searchCie10 = new Rx.Subject<string>();
  minCie10 = {main: {description: ''}};
  physicalPerson = {
    main: {
      uuid: '',
      name: '',
      lastname: '',
      surname: ''
    },
    complement: {
      dateBirth: '',
      curp: '',
      rfc: '',
      gender: ''
    },
    system: {
      status:true,
    }
  };

  consultingRoomModel = {
    main: {
      uuid: '',
      // Fecha y hora de la cita
      dateTimeAppointment: '',
      // Pago del paciente por la cita
      pay: 0.0,
      // Estatus de la cita
      status: 'Nueva',
      minute:'30',
      typeAppointment:'Cirugía'// medica, cirugía ( morado ), seguimiento, primera vez, no disponible (GRIS).
    },
    system: {
      // paciente al que pertence la consulta
      patient: {
        main: {
          uuid: ''
        },
        system: {
          physicalPerson:this.physicalPerson,
        }
      },
      // Doctor al que pertence la consulta
      doctor: {
        main: {
          uuid: '',
        },
      }
    }
  };

  constructor(
    private session: SessionService,
    public loadingService: LoadingService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NurseSurgeriesUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {
    this.patientMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.metadata.searchPatient.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.patientList = [];
          this.patientMin.filter.name = 'searchElement';
        } else {
          this.patientList = [];
          this.patientMin.filter.name = '';
          this.patientMin.filter.value = '';
          val = 'false';
        }
        this.getPatients();
        return val;
      }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });

    this.metadata.searchDoctor.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.patientList = [];
          this.doctorMin.filter.name = 'searchElement';
        } else {
          this.doctorList = [];
          this.doctorMin.filter.name = '';
          this.doctorMin.filter.value = '';
          val = 'false';
        }
        this.getDoctors();
        return val;
      }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });

    this.searchCie10.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.cie10List = [];
        } else {
          this.cie10List = [];
          this.minCie10.main.description = '';
        }
        this.getCie10();
        return val;
      }).subscribe(results => {
      // Modificaciones sobre cada letra si se requiere
    }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });
  }

  ngOnInit() {
    console.log(this.data);
    this.floors = [];
    this.findAllRoom();
    if (this.data.surgery.main.uuid == '') {
      this.metadata.date = moment(this.data.object).toISOString();
      this.metadata.hour = moment(this.data.object).format('HH');
      this.metadata.min = moment(this.data.object).format('mm');
      //cargamos los datos del doctor
      if (this.data.doctor.main) {
        this.object.system.doctor.main.uuid = this.data.doctor.main.uuid;
        this.doctorMin.filter.value = this.data.doctor.system.physicalPerson.main.name + ' ' + this.data.doctor.system.physicalPerson.main.lastname + ' ' + this.data.doctor.system.physicalPerson.main.surname;
      }
      if (this.data.room.main) {
        this.object.system.room.main.uuid = this.data.room.main.uuid;
      }
    } else {
      console.log('Buscamos la consultas');
      this.loadingService.show(true, 'Espere un momento...');
      this.object.main.uuid = this.data.surgery.main.uuid;
      this.session.getRequest('surgery:get', this.object).subscribe((data: any) => {
        this.object = JSON.parse(JSON.stringify(data.object));
        this.metadata.date = moment(this.object.main.dateTimeSurgery).toISOString();
        this.metadata.hour = moment(this.object.main.dateTimeSurgery).format('HH');
        this.metadata.min = moment(this.object.main.dateTimeSurgery).format('mm');
        let doctor = this.object.system.doctor;
        if (doctor.main.uuid != '') {
          this.doctorMin.filter.value = doctor.system.physicalPerson.main.name + ' ' + doctor.system.physicalPerson.main.lastname + ' ' + doctor.system.physicalPerson.main.surname;
          this.metadata.useDoctor = 'withName';
        } else {
          this.metadata.useDoctor = 'notName';
        }
        if (this.object.system.patient) {
          let patient = this.object.system.patient;
          this.patientMin.filter.value = patient.system.physicalPerson.main.name + ' ' + patient.system.physicalPerson.main.lastname + ' ' + patient.system.physicalPerson.main.surname;
        } else {
          this.object.system.patient = {
            main: {
              uuid: ''
            }
          };
        }
        this.loadingService.hide();
      }, error => {
        console.log(error);
        this.loadingService.hide();
      });
    }
  }


  /**
   * Obtiene los pacientes de acuerdo a la palabra escrita por el usuario
   */
  getPatients() {
    this.loadingService.wait();
    this.patientList = [];
    this.session.getRequest('patient:findAllByCompany', this.patientMin).subscribe(
      (data: any) => {
        this.patientList = data.object.instanceList;
        this.loadingService.hide();
      },
      error => {
        console.log('Error: patient:findAllByCompany', error);
        this.loadingService.hide();
      });
  }

  /**
   * Funcion para buscar paciente
   */
  searchPatient(value) {
    this.metadata.searchPatient.next(value);
  }
  patientSelected(ev) {
    console.log(ev.option.value);
    for (let item of this.patientList) {
      if (ev.option.value == item.system.physicalPerson.main.name + ' ' + item.system.physicalPerson.main.lastname + ' ' + item.system.physicalPerson.main.surname) {
        this.object.system.patient.main = item.main;
      }
    }
  }

  /**funcioanlidades de doctor
   */
  /**
   * Obtiene los doctores de acuerdo a la palabra escrita por el usuario
   */
  getDoctors() {
    this.loadingService.wait();
    this.doctorMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.doctorMin.system.role = 'Specialist doctor';
    this.session.getRequest('collaborator:findAllByCompanyAndRole', this.doctorMin).subscribe(
      (data: any) => {
        this.doctorList = data.object.instanceList;
        this.loadingService.hide();
      }, error => {
        console.log('Error: collaborator:findAllByCompanyAndRole', error);
        this.loadingService.hide();
      });
  }

  /**
   * Funcion para buscar paciente
   */
  searchDoctor(value) {
    this.metadata.searchDoctor.next(value);
  }


  doctorSelected(ev) {
    console.log(ev.option.value);
    for (let item of this.doctorList) {
      if (ev.option.value == item.system.physicalPerson.main.name + ' ' + item.system.physicalPerson.main.lastname + ' ' + item.system.physicalPerson.main.surname) {
        this.object.system.doctor.main = item.main;
        this.object.main.fullname = item.system.physicalPerson.main.name + ' ' + item.system.physicalPerson.main.lastname + ' ' + item.system.physicalPerson.main.surname;
      }
    }
  }

  /**funcionalidad para guardar una cita
   */
  send(object) {
    this.object.main.dateTimeSurgery = moment(this.metadata.date).format('DD/MM/YYYY') + ' ' + this.metadata.hour + ':' + this.metadata.min + ':00';
    if (this.object.main.uuid == '') {
      this.object.main.status = 'Nueva';
    }
    this.loadingService.show(true, 'Espere un momento...');
    this.session.getRequest('surgery:update', object).subscribe((data: any) => {
      this.loadingService.hide();
      if(this.object.main.uuid == '' && this.object.system.doctor.main.uuid != ''){
        // si la cirugia es nueva creamos el consultorio
        let consultingRoomAux =  JSON.parse(JSON.stringify(this.consultingRoomModel));
        consultingRoomAux.system.patient.main = this.object.system.patient.main;
        consultingRoomAux.system.doctor.main = this.object.system.doctor.main;
        consultingRoomAux.main.minute = this.object.main.minute;
        consultingRoomAux.main.dateTimeAppointment = moment(this.metadata.date).format('DD/MM/YYYY') +' '+ this.metadata.hour+':'+this.metadata.min+':00';
        this.saveConsultingRoom(consultingRoomAux);
      }
      this.object.main.uuid = data.object.main.uuid;
      this.snackBar.open('Cita creada exitosamente', 'Aceptar', {duration: 5000});
      this.close(true);
    }, error => {
      this.loadingService.hide();
    });
  }

  saveConsultingRoom(object){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("consultingRoom:update", object).subscribe(
        (data:any) => { 
          resolve(true);
          this.loadingService.hide();
        }, error => {
          console.log("Error: consultingRoom:update",error);
          this.loadingService.hide();
          reject(error);
        });
    })
  }


  /**
   * Encuentra todos los pisos asociados a la empresa indicada
   */
  findAllRoom() {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, 'Obteniendo pisos, espere un momento...');
      this.session.getRequest('room:findAllByAssignCalendar', {main: {assignCalendar: true}}).subscribe(
        async (data: any) => {
          this.floors = data.object.instanceList;
          if (this.floors.length > 0) {
            this.object.system.room.main.uuid = this.floors[0].main.uuid;
          }
          resolve({transaction: 'ok', object: {instanceList: []}});
          this.loadingService.hide();
        },
        error => {
          console.log('Error: floor:findAllByCompany', error);
          this.loadingService.hide();
        });
    });
  }

  viewAvalability() {
    let h: any = this.metadata.hour;
    let m: any = this.metadata.min;
    this.object.main.dateTimeSurgery = moment(this.metadata.date).set('hour', h).set('minute', m).set('millisecond', 0).toDate();
    this.dialog.open(NurseSurgeriesAvailabilityComponent, {
      width: '80%',
      height: '90%',
      data: this.object
    }).afterClosed().subscribe(result => {
      if (result) {
        if (result.transaction == 'ok') {
          this.metadata.date = moment(result.object).toISOString();
          this.metadata.hour = moment(result.object).format('HH');
          this.metadata.min = moment(result.object).format('mm');
          //se selecciona un horarios para cargar.
          console.log(result.object);
        }
      }
    });
  }

  delete(object) {
    this.loadingService.show(true, 'Espere un momento...');
    this.session.getRequest('surgery:delete', object).subscribe((data: any) => {
      this.loadingService.hide();
      this.close(true);
    }, error => {
      console.log(error);
      this.loadingService.hide();
    });
  }

  deleteCie10() {
    this.object.main.cie10 = '';
    this.object.complement.observation = '';
  }

  /**funcion para selecciona run cie10
   */
  cie10Selected(object) {
    console.log(object);
    this.object.main.cie10 = object.main.description;
  }

  /**
   * Obtiene el listado de ci-10
   */
  getCie10() {
    this.loadingService.wait();
    this.session.getRequest('cie10:findAllByDescriptionLike', this.minCie10).subscribe(
      (data: any) => {
        this.cie10List = data.object.instanceList;
        this.loadingService.hide();
      },
      error => {
        console.log('Error: cie10:findAllByDescriptionLike', error);
        this.loadingService.hide();
      });
  }

  /**
   * Funcion para buscar paciente
   */
  searchCie() {
    this.searchCie10.next(this.minCie10.main.description);
  }

  changePatientMode() {
    console.log(this.metadata.useAnomimus);
    if (this.metadata.useAnomimus == 'notName') {
      // let patient = this.patientWithOut;
      // this.patientMin.filter.value = patient.system.physicalPerson.main.name;
      // this.searchPatient(this.patientMin.filter.value);
      this.object.system.patient = JSON.parse(JSON.stringify(this.patientWithOut));
    } else {
      this.patientMin.filter.value = '';
      this.object.system.patient = {
        main: {
          uuid: ''
        }
      };
    }
  }

  changeDoctorMode() {
    if (this.metadata.useDoctor == 'notName') {
      // let patient = this.patientWithOut;
      // this.patientMin.filter.value = patient.system.physicalPerson.main.name;
      // this.searchPatient(this.patientMin.filter.value);
      this.object.system.doctor.main.uuid = '';
    } else {
      this.doctorMin.filter.value = '';
      this.object.system.doctor = {
        main: {
          uuid: ''
        }
      };
    }
  }

  close(status) {
    let object = {
      transaction: '',
      code: '',
      object: {}
    };
    if (status) {
      object.transaction = 'ok';
      object.object = this.object;
    } else {
      object.transaction = 'bad';
      object.code = 'surgery:001';
    }
    this.dialogRef.close(object);
  }
}
