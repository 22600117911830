import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-survey-thanks',
  templateUrl: './survey-thanks.component.html',
  styleUrls: ['./survey-thanks.component.css']
})
export class SurveyThanksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
