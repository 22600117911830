import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import * as moment from 'moment';

@Component({
  selector: 'app-owner-medical-consultation-consulting-table',
  templateUrl: './owner-medical-consultation-consulting-table.component.html',
  styleUrls: ['./owner-medical-consultation-consulting-table.component.css']
})
export class OwnerMedicalConsultationConsultingTableComponent implements OnInit {
  metadata = {
    totals:{
      consulting:0,
    },
    dates:{
      startDate:'',
      endDate:''
    }
  }
  consultingRoomMin = {
    main:{
      dateTimeAppointmentEnd:'',
      dateTimeAppointmentStart:''
    },
    system:{
      doctor:{
        main:{
          uuid:''
        }
      }
    }
  }
  doctor = {
    main:{
      uuid:''
    }
  }
  consultingRoomList = [];

  constructor(
    private session: SessionService,
    public loadingService: LoadingService,
    public dialogRef: MatDialogRef<OwnerMedicalConsultationConsultingTableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(this.data);
  }

  ngOnInit() {
    this.metadata.dates.startDate = this.data.startDate;
    this.metadata.dates.endDate = this.data.endDate;
    this.getDataDoctor().then((data)=>{
      this.doctor = JSON.parse(JSON.stringify(data));
      this.consultingRoomMin.system.doctor.main.uuid = this.doctor.main.uuid;
      this.getDatas();
    }).catch(e=>{
      console.log(e);
    })
  }

  /**funcionalidad para obtener los datos del doctor
  */
  getDataDoctor(){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("validate:findByUsername", {
        system: {
          user: this.session.getUser()
        }
      }).subscribe(async(data: any) => {
        console.log("validate:findByUsername",data);
        resolve(data.object);
      }, error=>{
        console.log(error);
        reject(error);
      });
    });
  }

  async getDatas(){
    try{
      this.loadingService.show(true,"Bucando resultados...");
      this.consultingRoomMin.main.dateTimeAppointmentStart =  moment(this.metadata.dates.startDate).subtract(1, "days").format('DD-MM-YYYY');
      this.consultingRoomMin.main.dateTimeAppointmentEnd =  moment(this.metadata.dates.endDate).add(1, "days").format('DD-MM-YYYY');
      this.consultingRoomList = [];
      await this.getConsultingRoomByDates();
      this.calculatetotals();
      this.loadingService.hide();
    }catch(e){
      this.loadingService.hide(),
      console.log(e);
    }
  };

  /**funcionalidad para obtener los ingresos de un doctor
  */
  getConsultingRoomByDates(){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("consultingRoom:findAllByDoctorAndDateTimeAppointmentBetween",this.consultingRoomMin).subscribe((data:any)=>{
        this.consultingRoomList = data.object.instanceList;
        resolve(true);
      },error=>{
        reject(error);
        console.log(error);
      });
    })
  }

  /**función para calcular los totales del balance
  */
  calculatetotals(){
    this.metadata.totals.consulting = 0;
    for(let consulting of this.consultingRoomList){
      if(consulting.main.status == 'Atendida'){
        this.metadata.totals.consulting += consulting.main.pay;
      }
    }
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = {};
    }else{
      object.transaction = 'bad';
      object.code = 'category:001';
    }
    this.dialogRef.close(object);
  }

}
