import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import * as Rx from 'rxjs/Rx';
import { LoadingService } from '../../directive/loading/loading.module';

@Component({
  selector: 'app-admin-service-catalog',
  templateUrl: './admin-service-catalog.component.html',
  styleUrls: ['./admin-service-catalog.component.css']
})
export class AdminServiceCatalogComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  patientMin = {
    system:{
      company:{
        main:{
          uuid:''
        }
      },
      max: 10,
      offset: 0,
      filter:{
        name:'',
        value: ''
      }
    }
  }
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  pageEvent: PageEvent;
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  list = [];
  constructor(private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) { }

  ngOnInit() {
    // this.drawerCtrl.opened = true;
    this.findAllByCompany();
  }

  /**
   * Obtiene el catalago de servicios por empresa indicada
   */
  findAllByCompany() {
    this.loadingService.wait();
    this.patientMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("serviceCatalog:findAllByCompany", this.patientMin).subscribe((data:any)=>{
      this.list = data.object.instanceList;
      this.paginatorModel.total = data.object.total;
      this.loadingService.hide();
    },error=>{
      console.log("error: serviceCatalog:findAllByCompany",error);
      this.loadingService.hide();
    })
  }

  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

  /**
   * Asigna los servicios al cuarto para que se carguen al incorporar a un paciente
   */
  customService(object) {

  }

}
