import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';
import * as moment from 'moment';
import { PatientMedicalRecordsComponent } from '../../directive/patient-medical-records/patient-medical-records.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-doctor-census',
  templateUrl: './doctor-census.component.html',
  styleUrls: ['./doctor-census.component.css']
})
export class DoctorCensusComponent implements OnInit {
  @ViewChild('drawer', { static: true }) drawerCtrl: MatDrawer;
  floors = [];
  patientModel = {
    main: {
      uuid: ''
    },
    system: {
      physicalPerson: {},
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      },
      status: true
    }
  };
  doctorModel = {
    main: {
      uuid: ''
    },
    system: {
      role: 'Doctor',
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      },
      user: {
        main: {
          uuid: ''
        }
      }
    }
  };
  roomModel = {
    main: {
      uuid: '',
      nameOrNumber: '',
      order: 0
    },
    system: {
      floor: {
        main: {
          uuid: ''
        }
      }
    },
    status: true
  };
  detailModel = {
    main: {
    }
  };
  roomOccupationModel = {
    main: {
      uuid: '',
      status: 'Disponible',
      observation: '',
      subtotal: 0,
      tax: 0,
      total: 0
    },
    complement: {
      typeOccupation: '',
      cie10: ''
    },
    other: {
      year: 0
    },
    system: {
      patient: JSON.parse(JSON.stringify(this.patientModel)),
      doctor: JSON.parse(JSON.stringify(this.doctorModel)),
      specialistDoctor: JSON.parse(JSON.stringify(this.doctorModel)),
      room: JSON.parse(JSON.stringify(this.roomModel)),
      detail: JSON.parse(JSON.stringify(this.detailModel)),
      sale: {
        main: {
          uuid: '',
          saleNumber: '',
          typeDiscount: '',
          discountValue: 0.0,
          discountAmount: 0.0,
          subtotal: 0.0,
          total: 0.0,
          tax: 0.0,
          status: '',
          expeditionDate: '',
          enableTax: false
        },
        complement: {
          description: '',
          paymentMethod: ''
        },
        system: {
          status: true,
          collaborator: {
            main: {
              uuid: ''
            }
          },
          company: {
            main: {
              uuid: ''
            }
          },
          patient: {
            main: {
              uuid: ''
            }
          }
        }
      }
    }
  };
  metadata = {
    allRoom: true,
    showFloor: true
  }
  constructor(private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, public dialog: MatDialog) { }

  async ngOnInit() {
    this.changeAll();
  }

  async changeAll() {
    this.floors = [];
    let data:any = await this.findAllByCompany();
    this.floors = data.object.instanceList;
    // Comenzamos a ordenar los pisos
    let floorList = [];
    for (let y=0; y<this.floors.length; y++) {
      // Recorremos el arreglo para validar si el número es menor al que se encuentra ordenado
      let aux = {};
      let exist = true;
      for (let z=0; z<floorList.length; z++) {
        if (floorList[z].main.order > this.floors[y].main.order) {
          aux = floorList[z];
          floorList[z] = this.floors[y];
          exist = false;
        }
      }
      if (exist) {
        floorList.push(this.floors[y]);
      } else {
        floorList.push(aux);
      }
    }
    this.floors = floorList;
  }

  /**
   * Encuentra todos los pisos asociados a la empresa indicada
   */
  findAllByCompany() {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo pisos, espere un momento...");
      this.session.getRequest("floor:findAllByCompany", { filter: {name: 'allByOrder'}, sort: 'mOrder', order: 'asc', system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } }, status: true } }).subscribe(
        async (data: any) => {
          let floors = [];
          // Obtenemos las habitaciones por piso
          for (let x = 0; x < data.object.instanceList.length; x++) {
            let floor = {
              main: {
                uuid: data.object.instanceList[x].main.uuid,
                name: data.object.instanceList[x].main.name,
                order: data.object.instanceList[x].main.order,
              },
              system: {
                status: data.object.instanceList[x].system.status,
                company: data.object.instanceList[x].system.company,
                rooms: []
              }
            }
            let item:any = await this.findAllByFloor(data.object.instanceList[x]);
            floor.system.rooms = item.object.instanceList;
            floors.push(floor);
          }
          resolve({ transaction: 'ok', object: { instanceList: floors } });
          this.loadingService.hide();
        },
        error => {
          console.log("Error: floor:findAllByCompany", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Encuentra todos los cuartos asociados al piso indicado
   */
  findAllByFloor(floor) {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo cuartos del piso " + floor.main.name + ", espere un momento...");
      this.session.getRequest("roomOccupation:findAllByFloorWithWarehouse", { system: { floor: { main: { uuid: floor.main.uuid } }, status: true } }).subscribe(
        async (data: any) => {
          let rooms = [];
          // Obtenemos la ocupación del cuarto
          for (let y = 0; y < data.object.instanceList.length; y++) {
            let roomOccupationObject:any = await this.findByRoomAndStatusNotEqual(data.object.instanceList[y]);

            // Validamos si existe ocupación en la habitación
            if (roomOccupationObject.object.main.uuid != '') {
              // Obtenemos el total de lo consumido en la consulta
              let roomOccupation = JSON.parse(JSON.stringify(this.roomOccupationModel));
              roomOccupation.main = roomOccupationObject.object.main;
              roomOccupation.complement = roomOccupationObject.object.complement;
              roomOccupation.system.patient = JSON.parse(JSON.stringify(roomOccupationObject.object.system.patient));
              // Calculamos los años de paciente
              let minutos = 0.0166667 * moment(new Date()).diff( moment(roomOccupationObject.object.system.patient.system.physicalPerson.complement.dateBirth) ) * 0.001;
              let horas = 0.0166667 * minutos;
              let dias = 0.0416667 * horas;
              let anios = 0.00273973 * dias;
              roomOccupation.other.year = Math.ceil(anios);
              roomOccupation.system.doctor = JSON.parse(JSON.stringify(roomOccupationObject.object.system.doctor));
              roomOccupation.system.specialistDoctor = JSON.parse(JSON.stringify(roomOccupationObject.object.system.specialistDoctor));
              roomOccupation.system.room = JSON.parse(JSON.stringify(roomOccupationObject.object.system.room));
              roomOccupation.system.detail = JSON.parse(JSON.stringify(roomOccupationObject.object.system.detail));
              let sale:any = await this.findByPatientAndStatus(roomOccupation.system.patient, 'Abierta');
              roomOccupation.system.sale = sale.object;
              rooms.push(roomOccupation);
              this.loadingService.hide();
            } else {
              if (this.metadata.allRoom) {
                let roomOccupation = JSON.parse(JSON.stringify(this.roomOccupationModel));
                roomOccupation.system.room = JSON.parse(JSON.stringify(roomOccupationObject.object.system.room));
                rooms.push(roomOccupation);
              }
              this.loadingService.hide();
            }
          }
          resolve({ transaction: 'ok', object: { instanceList: rooms } });
          this.loadingService.hide();
        },error => {
          console.log("Error: roomOccupation:findAllByFloorWithWarehouse", error);
          this.loadingService.hide();
        });
    });
  }

  sortByRoom(floor) {
    return new Promise((resolve, reject) => {
      let rooms = [];
      for (let y=0; y<floor.system.rooms.length; y++) {
        // Recorremos el arreglo para validar si el número es menor al que se encuentra ordenado
        let aux = {};
        let exist = true;
        for (let z=0; z<rooms.length; z++) {
          if (rooms[z].system.room.main.order > floor.system.rooms[y].system.room.main.order) {
            aux = rooms[z];
            rooms[z] = floor.system.rooms[y];
            exist = false;
          }
        }
        if (exist) {
          rooms.push(floor.system.rooms[y]);
        } else {
          rooms.push(aux);
        }
      }
      console.log('cuartos ordenados ', rooms);
      resolve({ transaction: 'ok', object: {instanceList: rooms} });
    });
  }

  /**
   * Obtiene el total consumo por Paciente
   */
  findByPatientAndStatus(patient, status) {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo el total de consumo, espere un momento...");
      this.session.getRequest("sales:findAllByPatientAndStatus", { main: {status: status }, system: {patient: patient}  }).subscribe(
        async (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
        },
        error => {
          console.log("Erro: sales:findAllByPatientAndStatus", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Busca si existe ocupación enla habitación indicada
   */
  findByRoomAndStatusNotEqual(room) {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo detalle de la ocupación del cuarto " + room.main.nameOrNumber + ", espere un momento...");
      this.session.getRequest("roomOccupation:findByRoomAndStatusNotEqual", { main: { status: 'Terminado' }, system: { room: { main: { uuid: room.main.uuid } } } }).subscribe(
        async (data: any) => {
          if (data.object) {
            resolve({ transaction: 'ok', object: data.object });
          } else {
            let roomOccupation = JSON.parse(JSON.stringify(this.roomOccupationModel));
            roomOccupation.system.room = room;
            resolve({ transaction: 'ok', object: roomOccupation });
          }
        },
        error => {
          console.log("Erro: roomOccupation:findByRoomAndStatusNotEqual", error);
          this.loadingService.hide();
        });
    });
  }

  /**funcionalidad para abrir el panel de notas medicas del paciente
   */
  openPatientMedicalNotes(patient){
    this.dialog.open(PatientMedicalRecordsComponent, {
      width: '90%',
      height: '90%',
      data: patient,
      // disableClose:true
    }).afterClosed().subscribe(result => {
      if(result){
        if(result.transaction == 'ok'){
          console.log(result);
        }
      }
    });
  }

  getDays(object){
    let initilaDate = moment(object.system.detail.main.date)
    let days = moment().diff(initilaDate,'days') + 1;
    return days;
  }

  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }
}
