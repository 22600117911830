import { Component, OnInit, Inject } from '@angular/core';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-owner-feed-payment-annotation',
  templateUrl: './owner-feed-payment-annotation.component.html',
  styleUrls: ['./owner-feed-payment-annotation.component.css']
})
export class OwnerFeedPaymentAnnotationComponent implements OnInit {
  feedPaymentAnnotationModel = {
    main: {
      uuid: '',
      annotation: '',
      dateAnnotation: ''
    },
    system: {
      feedPayment: {
        main: {
          uuid:''
        }
      }
    }
  }

  object = JSON.parse(JSON.stringify(this.feedPaymentAnnotationModel));

  constructor(public loadingService: LoadingService, private session: SessionService, public dialogRef: MatDialogRef<OwnerFeedPaymentAnnotationComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar) {
    this.object.system.feedPayment = data.system.feedPayments[0];
    console.log(data.system.feedPayments)
  }

  ngOnInit() {
  }

  send(object:any) {
    this.loadingService.show(true, "Espere un momento... se esta guardando el registro.");
    this.session.getRequest('feedPaymentAnnotation:update', object).subscribe((data: any) => {
      this.object = data.object;
      this.loadingService.hide();
      this.close(true);
    }, (error) => {
      console.log('Error: feedPaymentAnnotation:update', error)
      this.loadingService.hide();
    });
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'price_list:001';
    }
    this.dialogRef.close(object);
  }

}
