import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import * as moment from 'moment';


@Component({
  selector: 'app-owner-sale-report',
  templateUrl: './owner-sale-report.component.html',
  styleUrls: ['./owner-sale-report.component.css']
})
export class OwnerSaleReportComponent implements OnInit {
  object:any={};
  constructor(protected session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<OwnerSaleReportComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data:any) {
    this.object = JSON.parse(JSON.stringify(data.object));
  }

  ngOnInit() {
  }
  report(reportType) {
    this.loadingService.show(true, 'Espere un momento...');
    if (reportType == 'simple') {
      this.session.getRequest('saleReport:simple', {main: this.object.main, reportType: 'download'}).subscribe((data: any) => {
        let elem = document.createElement('a');
        elem.href = data.object.base64;
        elem.setAttribute('download', data.object.nameFile);
        document.body.appendChild(elem);
        elem.click();
        this.loadingService.hide();
      }, error => {
        console.log("Error: saleReport:simple", error);
        this.loadingService.hide();
      });
    } else {
      this.session.getRequest('saleReport:detail', {main: this.object.main, reportType: 'download'}).subscribe((data: any) => {
        let elem = document.createElement('a');
        console.log(data.object.base64);
        elem.href = data.object.base64;
        elem.setAttribute('download', data.object.nameFile);
        document.body.appendChild(elem);
        elem.click();
        this.loadingService.hide();
      }, error => {
        console.log("Error: saleReport:simple", error);
        this.loadingService.hide();
      });
    }
  }
}
