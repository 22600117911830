import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import * as Rx from 'rxjs';
// import { LoadingService } from '../../../directive/loading/loading.module';
// import * as moment from 'moment';


@Component({
  selector: 'app-characteristic',
  templateUrl: './characteristic.component.html',
  styleUrls: ['./characteristic.component.css']
})
export class CharacteristicComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;

  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  characteristicMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  characteristicList = [];
  // MatPaginator Output
  pageEvent: PageEvent;
  constructor(private session: SessionService, public snackBar: MatSnackBar) {
    this.characteristicMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
  //   this.metadata.searchBoxInput.debounceTime(700)
  //   .switchMap(val => {
  //     // console.log('called once',val)
  //     if(val != ''){
  //       this.characteristicList = [];
  //       this.characteristicMin.filter.name = 'searchElement';
  //     }else{
  //       this.characteristicList = [];
  //       this.characteristicMin.filter.name = '';
  //       this.characteristicMin.filter.value = '';
  //       val = 'false'
  //     }
  //     this.getCharacteristicList();
  //     return val;
  //   }).subscribe(results => {
  //     // Modificaciones sobre cada letra si se requiere
  //   }, error => {
  //   console.log('error logged:');
  //   this.snackBar.open(error.message, 'Error', {duration: 5000});
  //   console.log(error);
  // });
   }

  ngOnInit() {
    this.getCharacteristicList();
  }

  // funcion para obtener la lista de kit de productos
  getCharacteristicList() {
  // this.loadingService.wait();
    this.characteristicList = [];
    // verificamos en que pagina verificamos
    this.characteristicMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("characteristic:findAllByCompany", this.characteristicMin).subscribe(
      (data: any) => {
        this.characteristicList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        // this.loadingService.hide();
      },
      error => {
        console.log("characteristic:findAllByCompany", error);
        // this.loadingService.hide();
      }
    );
  }

  /*
  Funcion para inactivar una categorias
  */
  disableACharacteristic(object){
    object.system.status = false;
    this.session.getRequest("characteristic:update",object).subscribe(
      data => {
        this.snackBar.open('La característica se desactivo correctamente', '', {
          duration: 8000
        });
        for (var _i = 0; _i < this.characteristicList.length; _i++) {
          if (object.main.uuid == this.characteristicList[_i].main.uuid) {
            this.characteristicList.splice(_i, 1);
            break;
          }
        }
     },
     error => {
       console.log("Erro: characteristic:update",error);
     }
    );
  }

  /*
  Funcionalidad para colocar el objeto en el menu de opciones
  */
  objectSelected(object){
    for(let i=0; i < this.characteristicList.length; i++){
      this.characteristicList[i].system.selected = false;
    }
    if(object.main.uuid == this.metadata.object.main.uuid){
      object.system.selected = false;
      this.metadata.object.main.uuid = '';
    }else{
      object.system.selected = true;
      this.metadata.object = JSON.parse(JSON.stringify(object));;
    }
  }

  /*
    *Funcion para buscar
  */
  search(){
    this.metadata.searchBoxInput.next(this.characteristicMin.filter.value);
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.characteristicMin.offset = (event.pageIndex * event.pageSize);
    this.characteristicMin.max = event.pageSize;
    this.getCharacteristicList();
  }

  handleUpdated(event) {
    this.characteristicMin.offset = (this.pageEvent ? this.pageEvent.pageIndex * this.pageEvent.pageSize  : this.characteristicMin.offset);
    this.getCharacteristicList();
  }
 /*
 funcion para controlar si se abre o cierra el menu
 */
 toogleMenu(ev){
   this.drawerCtrl.opened = ev;
 }

}
