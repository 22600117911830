import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { SingleDataSet, Label, Color, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import * as moment from 'moment';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';
import * as pluginLabels from 'chartjs-plugin-labels';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-owner-report',
  templateUrl: './owner-report.component.html',
  styleUrls: ['./owner-report.component.css']
})
export class OwnerReportComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;

  metadata = {
    dates: {
      startDate: moment().clone().startOf('month').toISOString(),
      endDate: moment().clone().endOf('month').toISOString(),
    },
    system: {
      roomOccupationDetail: {
        main: {
          startDate: moment().clone().startOf('month').format('DD/MM/YYYY hh:mm:ss'),
          endDate: moment().clone().endOf('month').format('DD/MM/YYYY hh:mm:ss'),
        }
      }
    },
    startDate: '',
    endDate: '',
    openAmount: 0.0,
    totalSpending: 0.0,
    totalSpendingGeneral: 0.0,
    honoraryGuards: 0.0,
    totalPatient: 0
  }

  patientTypeOccupationList = [];

  collaboratorModeyList = [];

  totalSales = 0.0;

  totalOtherIncome = 0.0;

  // Grafica de pastel
  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      position: 'left',
    },
    plugins: {
      labels: {
        render: 'percentage',
        fontColor: ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
        precision: 2
      }
    },
  };
  public pieChartLabels: Label[] = [];
  public pieChartData: SingleDataSet = [];
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartColors = [{
    backgroundColor: [
      'rgba(255,0,0,1)', 'rgba(0,255,200,1)', 'rgba(0,0,255,0.3)', 'rgba(245,135,51,1)',
      'rgba(171,95,36,1)', 'rgba(255,226,205,1)', 'rgba(51,215,245,1)', 'rgba(36,150,171,1)',
      'rgba(101,0,36,1)', 'rgba(133,100,2,1)', 'rgba(91,15,205,1)', 'rgba(244,100,171,1)',
      'rgba(211,0,96,1)', 'rgba(3,206,205,1)', 'rgba(151,0,205,1)', 'rgba(96,190,101,1)',
      'rgba(0,4,255,1)', 'rgba(3,0,205,1)', 'rgba(51,200,10,1)', 'rgba(96,2,0,1)',
      'rgba(1,3,105,1)', 'rgba(22,0,5,1)', 'rgba(101,90,200,1)', 'rgba(125,2,10,1)',
      'rgba(2,0,205,1)', 'rgba(22,150,5,1)', 'rgba(10,110,200,1)', 'rgba(175,2,110,1)',
      'rgba(201,190,10,1)', 'rgba(0,191,253)', 'rgba(186,85,211)', 'rgba(255,228,196)', 'rgba(240,248,255)',
      'rgba(0,128,128)', 'rgba(138,43,226)', 'rgba(128,0,128)', 'rgba(255,255,224)', 'rgba(240,255,240)',
      'rgba(224,255,255)', 'rgba(106,90,205)', 'rgba(238,130,238)', 'rgba	(139,69,19)', 'rgba(240,255,255)',

    ]
  }];
  //Grafica de barras
  public pieChartHospitalizationAndSurgeryLabels: Label[] = [];
  public pieChartHospitalizationLabels: Label[] = [];
  public pieChartAmbulatorySurgeryLabels: Label[] = [];
  public pieChartObservationLabels: Label[] = [];
  public pieChartGeneralLabels: Label[] = [];
  public pieChartHospitalizationAndSurgeryLegend = true;
  public pieChartHospitalizationLegend = true;
  public pieChartAmbulatorySurgeryLegend = true;
  public pieChartObservationLegend = true;
  public pieChartGeneralLegend = true;
  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      position: 'left',
      display:false
    },
    plugins: {
      labels: {
        render: function (args) {
          let dollarUSLocale = Intl.NumberFormat('en-US');
          // args will be something like:
          // { label: 'Label', value: 123, percentage: 50, index: 0, dataset: {...} }
          return '$' + dollarUSLocale.format(args.value);
       
          // return object if it is image
          // return { src: 'image.png', width: 16, height: 16 };
        },
        fontColor: [],
        fontSize: 9,
      }
    }
  };

  public pieChartHospitalizationAndSurgeryData: SingleDataSet = [];
  public pieChartHospitalizationData: SingleDataSet = [];
  public pieChartAmbulatorySurgeryData: SingleDataSet = [];
  public pieChartObservationData: SingleDataSet = [];
  public pieChartGeneralData: SingleDataSet = [];

  public pieChartGeneralOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      position: 'left',
      display:false
    },
    plugins:{
      datalabels: {
        formatter: function(value, context) {
          return context.chart.data.labels[context.dataIndex];
        }
      }
    }
  };

  years = [];
  // Objeto para almacenar la linea del tiempo de ingresos de pacientes en el sistema
  patientChart = {
    chartType:'line',
    chartData: [
      { data: [], label: 'Observación' },
      { data: [], label: 'Cirugia ambulatoria' },
      { data: [], label: 'Hospitalizacion y cirugia' },
      { data: [], label: 'Hospitalizacion' },
      { data: [], label: 'Total pacientes' },
    ],
    chartLabels:[],
    chartLegend:true,
    year:moment().format('YYYY'),
    chartPlugins:[DataLabelsPlugin],
    chartColors:[
      { backgroundColor: '#C0D8C0' },
      { backgroundColor: '#F5EEDC' },
      { backgroundColor: '#DD4A48' },
      { backgroundColor: '#ECB390' },
      { backgroundColor: '#61764B' },
    ],
    chartOptions:{
      responsive: true,
      plugins: {
        labels: {
          render: 'value',
          fontSize: 1,
          fontColor: []
        },
        datalabels: {
          backgroundColor:function(context) {
            let color:any= context.dataset.backgroundColor;
            return color;
          },
          borderRadius: 20,
          color: '#fff',
          font: {
            weight: 'bold',
          },
          formatter: function(value, context) {
              return ''+Math.round(value);
            },
          padding: 6
        }
      }
    },

  }

  // Objeto para almacenar la linea del tiempo de ingresos y egresos del sistema
  balanceChart = {
    chartType:'line',
    chartData: [
      { data: [], label: 'Ganancias', fill: false, borderColor:'#20b2aa'},
      { data: [], label: 'Gastos', fill: false, borderColor:'#d1e0de'},
      { data: [], label: 'Ingresos', fill: false, borderColor:'#404040'}
    ],
    chartLabels:[],
    chartLegend:true,
    year:moment().format('YYYY'),
    chartPlugins:[DataLabelsPlugin],
    chartColors:[
      { backgroundColor: '#20b2aa' },
      { backgroundColor: '#d1e0de' },
      { backgroundColor: '#404040' },
      { backgroundColor: '#e0e4e7' },
      { backgroundColor: '#61764B' },
    ],
    chartOptions:{
      responsive: true,
      datasetFill:false,
      fill:false,
      plugins: {
        labels: {
          render: 'value',
          fontSize: 1,
          fontColor: []
        },
        datalabels: {
          backgroundColor:function(context) {
            let color:any= context.dataset.backgroundColor;
            return color;
          },
          borderRadius: 20,
          color: '#fff',
          font: {
            weight: 'bold',
          },
          formatter: function(value, context) {
              return '$ '+Math.round(value);
            },
          padding: 6
        }
      }
    },

  }

  constructor(private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit() {
    this.createYears();
    this.creatingChartPatient();
    this.creatingChartBalance();

    this.pieChartPlugins = [pluginLabels];
    // Cargamos los tipos de ocupación
    this.findAllByTypeOccupationAndDateBetween('Hospitalizacion y cirugia');
    this.findAllByTypeOccupationAndDateBetween('Hospitalizacion');
    this.findAllByTypeOccupationAndDateBetween('Cirugia ambulatoria');
    this.findAllByTypeOccupationAndDateBetween('Observacion');

    // Cargamos los datos de la gráfica de pastel
    this.findAllByCompanyAndRoleAndStatus();

    // Cargamos los datos de la gráfica de barras
    this.findAllByCompanyAndRoleAndDateBetween();

    // Cargamos los datos de la tabla de ingresos
    this.findAllMoneyByCompanyAndRoleAndDateBetween();

    // Total de ventas
    this.totalSalesByCompanyAndRoleAndDateBetween();

    // Total de otros ingresos
    this.totalOtherIncomeByCompanyAndDateBetween();

    // Total de gastos de caja chica
    this.spending();

    // Total de gastos de caja chica
    this.spendingGeneral();

    // Total de guardias pagadas
    this.honoraryGuards();
  }

  honoraryGuards() {
    // Obtenemos el total de gastos en caja chica
    this.session.getRequest('roomOccupationDoctor:moneyByDateBetweenAndPay', { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } }, main: { startDate: this.metadata.system.roomOccupationDetail.main.startDate, endDate: this.metadata.system.roomOccupationDetail.main.endDate } }).subscribe((data: any) => {
      this.metadata.honoraryGuards = data.object;
    }, error => {
      this.loadingService.hide();
      console.log("Error: roomOccupationDoctor:moneyByDateBetweenAndPay", error);
    });
  }

  spending() {
    // Obtenemos el total de gastos en caja chica
    this.session.getRequest('spending:moneyByCompanyAndBetweenDate', { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } }, main: { startDate: this.metadata.system.roomOccupationDetail.main.startDate, endDate: this.metadata.system.roomOccupationDetail.main.endDate } }).subscribe((data: any) => {
      this.metadata.totalSpending = data.object;
    }, error => {
      this.loadingService.hide();
      console.log("Error: spending:moneyByCompanyAndBetweenDate", error);
    });
  }

  spendingGeneral() {
    // Obtenemos el total de gastos en caja chica
    this.session.getRequest('spendingGeneral:moneyByCompanyAndBetweenDate', { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } }, main: { startDate: this.metadata.system.roomOccupationDetail.main.startDate, endDate: this.metadata.system.roomOccupationDetail.main.endDate } }).subscribe((data: any) => {
      this.metadata.totalSpendingGeneral = data.object;
    }, error => {
      this.loadingService.hide();
      console.log("Error: spendingGeneral:moneyByCompanyAndBetweenDate", error);
    });
  }

  // Obtenemos los colaboradores doctores especialistas
  findAllByCompanyAndRoleAndStatus() {
    this.loadingService.wait();
    this.session.getRequest("collaborator:findAllByCompanyAndRoleAndStatus", {
      system: {
        role: 'Specialist doctor',
        company: {
          main: {
            uuid: this.session.getSessionObject().main.uuid
          }
        },
        status: true
      }
    }).subscribe((data: any) => {
      this.pieChartLabels = [];
      this.pieChartData = [];
      for (let x = 0; x < data.object.instanceList.length; x++) {
        // Obtenemos el número de pacientes por doctor especialista
        this.getPatientByCollaborator(data.object.instanceList[x]).then((object: any) => {
          if (object.object.value > 0) {
            this.pieChartLabels.push(object.object.value + ' ' + data.object.instanceList[x].system.physicalPerson.main.name + ' ' + data.object.instanceList[x].system.physicalPerson.main.lastname + ' ' + data.object.instanceList[x].system.physicalPerson.main.surname);
            this.pieChartData.push(object.object.value);
          }
        });
      }
      this.loadingService.hide();
    }, error => {
      console.log("error", error);
      this.loadingService.hide();
    })
  }

  // Obtenemos los tipos de ocupación por doctor especialista
  findAllByCompanyAndRoleAndDateBetween() {
    this.loadingService.wait();
    this.pieChartGeneralData = [];
    this.pieChartGeneralLabels = [];
    this.session.getRequest("dashboard:findAllMoneyByCompanyAndRoleAndDateBetween", {
      system: {
        roomOccupationDetail: {
          main: {
            endDate: this.metadata.system.roomOccupationDetail.main.endDate,
            startDate: this.metadata.system.roomOccupationDetail.main.startDate,
          }
        },
        role: 'Specialist doctor',
        company: {
          main: {
            uuid: this.session.getSessionObject().main.uuid
          }
        },
        status: true
      }
    }).subscribe((data: any) => {
      this.pieChartHospitalizationAndSurgeryData = [];
      this.pieChartHospitalizationData = [];
      this.pieChartAmbulatorySurgeryData = [];
      this.pieChartObservationData = [];
      this.pieChartHospitalizationAndSurgeryLabels = [];
      this.pieChartHospitalizationLabels = [];
      this.pieChartAmbulatorySurgeryLabels = [];
      this.pieChartObservationLabels = [];
      this.pieChartGeneralData = [];
      this.pieChartGeneralLabels = [];
      for (let x=0; x<data.object.instanceList.length; x++) {
        if (data.object.instanceList[x].hospitalYCirugia > 0) {
          this.pieChartHospitalizationAndSurgeryLabels.push(data.object.instanceList[x].specialistDoctor + ' (' + data.object.instanceList[x].hospitalYCirugia + ')');
          this.pieChartHospitalizationAndSurgeryData.push(data.object.instanceList[x].hospitalYCirugia);
        }
        if (data.object.instanceList[x].hospital > 0) {
          this.pieChartHospitalizationLabels.push(data.object.instanceList[x].specialistDoctor + ' (' + data.object.instanceList[x].hospital + ')');
          this.pieChartHospitalizationData.push(data.object.instanceList[x].hospital);
        }
        if (data.object.instanceList[x].cirugia > 0) {
          this.pieChartAmbulatorySurgeryLabels.push(data.object.instanceList[x].specialistDoctor + ' (' + data.object.instanceList[x].cirugia + ')');
          this.pieChartAmbulatorySurgeryData.push(data.object.instanceList[x].cirugia);
        }
        if (data.object.instanceList[x].observacion > 0) {
          this.pieChartObservationLabels.push(data.object.instanceList[x].specialistDoctor + ' (' + data.object.instanceList[x].observacion + ')');
          this.pieChartObservationData.push(data.object.instanceList[x].observacion);
        }
        let dollarUSLocale = Intl.NumberFormat('en-US');

        this.pieChartGeneralLabels.push(data.object.instanceList[x].specialistDoctor + ' ( $' + dollarUSLocale.format(data.object.instanceList[x].hospitalYCirugia + data.object.instanceList[x].hospital + data.object.instanceList[x].cirugia + data.object.instanceList[x].observacion) + ')');
        this.pieChartGeneralData.push(data.object.instanceList[x].hospitalYCirugia + data.object.instanceList[x].hospital + data.object.instanceList[x].cirugia + data.object.instanceList[x].observacion);
      }
      this.loadingService.hide();
    }, error => {
      console.log("Error: dashboard:findAllMoneyByCompanyAndRoleAndDateBetween", error);
      this.loadingService.hide();
    });
  }

  // Obtenemos los ingreso por el rol del doctor y el periodo especificado
  findAllMoneyByCompanyAndRoleAndDateBetween() {
    this.loadingService.wait();
    this.session.getRequest("dashboard:findAllMoneyByCompanyAndRoleAndDateBetween", {
      system: {
        roomOccupationDetail: {
          main: {
            endDate: this.metadata.system.roomOccupationDetail.main.endDate,
            startDate: this.metadata.system.roomOccupationDetail.main.startDate,
          }
        },
        role: 'Specialist doctor',
        company: {
          main: {
            uuid: this.session.getSessionObject().main.uuid
          }
        },
        status: true
      }
    }).subscribe((data: any) => {
      this.collaboratorModeyList = data.object.instanceList;
      this.loadingService.hide();
    }, error => {
      console.log("Error: dashboard:findAllMoneyByCompanyAndRoleAndDateBetween", error);
      this.loadingService.hide();
    });
  }

  // Obtenemos el total de ingreso por el rol del doctor y el periodo especificado
  totalSalesByCompanyAndRoleAndDateBetween() {
    this.loadingService.wait();
    this.session.getRequest("dashboard:totalSalesByCompanyAndRoleAndDateBetween", {
      system: {
        roomOccupationDetail: {
          main: {
            endDate: this.metadata.system.roomOccupationDetail.main.endDate,
            startDate: this.metadata.system.roomOccupationDetail.main.startDate,
          }
        },
        role: 'Specialist doctor',
        company: {
          main: {
            uuid: this.session.getSessionObject().main.uuid
          }
        },
        status: true
      }
    }).subscribe((data: any) => {
      this.totalSales = data.object;
      this.loadingService.hide();
    }, error => {
      console.log("Error: dashboard:totalSalesByCompanyAndRoleAndDateBetween", error);
      this.loadingService.hide();
    });
  }

  // Obtenemos el total de otros ingresos por el periodo especificado
  totalOtherIncomeByCompanyAndDateBetween() {
    this.loadingService.wait();
    this.session.getRequest("dashboard:totalOtherIncomeByCompanyAndDateBetween", {
      system: {
        roomOccupationDetail: {
          main: {
            endDate: this.metadata.system.roomOccupationDetail.main.endDate,
            startDate: this.metadata.system.roomOccupationDetail.main.startDate,
          }
        },
        company: {
          main: {
            uuid: this.session.getSessionObject().main.uuid
          }
        },
      }
    }).subscribe((data: any) => {
      this.totalOtherIncome = data.object;
      this.loadingService.hide();
    }, error => {
      console.log("Error: dashboard:totalOtherIncomeByCompanyAndDateBetween", error);
      this.loadingService.hide();
    });
  }

  // Obtenemos todos las ocupaciones por tipo de ocupación
  findAllByTypeOccupationAndDateBetween(typeOccupation) {
    this.loadingService.wait();
    this.patientTypeOccupationList = [];
    this.metadata.totalPatient = 0;
    this.session.getRequest("dashboard:findAllByTypeOccupationAndDateBetween", { complement: { typeOccupation: typeOccupation }, system: this.metadata.system }).subscribe((data: any) => {
      this.patientTypeOccupationList.push(data.object);
      this.metadata.totalPatient = this.metadata.totalPatient + data.object.value;
      this.loadingService.hide();
    }, error => {
      console.log("Error: dashboard:findAllByTypeOccupationAndDateBetween", error);
      this.loadingService.hide();
    })
  }

  // Obtenemos el número de pacientes atendidos por cada dr. especialista indicado
  getPatientByCollaborator(collaborator) {
    return new Promise((resolve, reject) => {
      this.loadingService.wait();
      this.session.getRequest("dashboard:findAllByCollaboratorSpecialistAndDateBetween", { system: { collaborator: { main: { uuid: collaborator.main.uuid } }, roomOccupationDetail: this.metadata.system.roomOccupationDetail } }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object });
          this.loadingService.hide();
        }, error => {
          console.log("Error: dashboard:findAllByCollaboratorSpecialistAndDateBetween", error);
          this.loadingService.hide();
        });
    });
  }

  // Obtenemos todas las ocupaciones por tipo de ocupación y doctor indicado
  getPatientByCollaboratorAndTypeOccupationAndDateBetween(typeOccupation, collaborator) {
    return new Promise((resolve, reject) => {
      this.loadingService.wait();
      this.session.getRequest("dashboard:findAllByTypeOccupationAndCollaboratorSpecialistAndDateBetween", {
        complement: {
          typeOccupation: typeOccupation
        },
        system: {
          collaborator: {
            main: {
              uuid: collaborator.main.uuid
            }
          },
          roomOccupationDetail: this.metadata.system.roomOccupationDetail
        }
      }).subscribe((data: any) => {
        resolve({ transaction: 'ok', object: data.object });
        this.loadingService.hide();
      }, error => {
        console.log("Error: dashboard:findAllByTypeOccupationAndCollaboratorSpecialistAndDateBetween", error);
        this.loadingService.hide();
      })
    });
  }

  selectPeriond(event, period) {
    if (period === 'Inicial') {
      this.metadata.system.roomOccupationDetail.main.startDate = moment(event).format('DD/MM/YYYY hh:mm:ss');
    } else {
      this.metadata.system.roomOccupationDetail.main.endDate = moment(event).format('DD/MM/YYYY hh:mm:ss');
    }
    if (this.metadata.system.roomOccupationDetail.main.startDate <= this.metadata.system.roomOccupationDetail.main.endDate) {
      // Obtenemos el total de pacientes por tipo de ocupación
      this.findAllByTypeOccupationAndDateBetween('Hospitalizacion y cirugia');
      this.findAllByTypeOccupationAndDateBetween('Hospitalizacion');
      this.findAllByTypeOccupationAndDateBetween('Cirugia ambulatoria');
      this.findAllByTypeOccupationAndDateBetween('Observacion');

      // Cargamos los datos de las gráficas por doctor especialista de acuerdo al rol
      this.findAllByCompanyAndRoleAndStatus();

      // Cargamos los datos de la gráfica de barras
      this.findAllByCompanyAndRoleAndDateBetween();

      // Cargamos los datos de la tabla de ingresos
      this.findAllMoneyByCompanyAndRoleAndDateBetween();

      // Total de ventas
      this.totalSalesByCompanyAndRoleAndDateBetween();

      // Total de ventas
      this.totalOtherIncomeByCompanyAndDateBetween();

      // Total de gastos de caja chica
      this.spending();

      // Total de gastos de caja chica
      this.spendingGeneral();

      // Total de guardias pagadas
      this.honoraryGuards();
    }
  }

  /**funcionalidad para la grafica de linea de paciente de ingresos al hospital
  */
  async creatingChartPatient(){
    try{
      this.patientChart.chartLabels = [];
      for(let i = 0; i < this.patientChart.chartData.length; i++){
        this.patientChart.chartData[i].data = [];
      }

      let index = 0;
      let end = moment().locale('es-mx').format('M');
      // let endNumber = 11;
      //creamos los labels del mes
      while (index < 12) {
        let month = moment().locale('es-mx').set('month',index).format('MMMM');
        console.log(month);
        this.patientChart.chartLabels.push(month);
        index++;
      }

      // Generamos las consultas para lo contadores
      index = 0;
      while(index < 12){
        let year:any = this.patientChart.year;
        // let startMonth = moment().set('day',1).set('months',index).set('year',year).toISOString();
        // let endMonth = moment().set('day',1).set('months',index+1).subtract(1,'day').set('year',year).toISOString();
        let startMonth  = new Date(year, index, 1).toISOString();
        let endMonth =  new Date((new Date(year, index+1, 1)).setDate((new Date(year, index+1, 1)).getDate() - 1)).toISOString();

        let observacionTotal = await this.findAllByTypeOccupationAndDateBetweenDate('Observacion',startMonth,endMonth);
        let cirugiaAmbulatoriaTotal = await this.findAllByTypeOccupationAndDateBetweenDate('Cirugia ambulatoria',startMonth,endMonth);
        let hospitalYCirugiaTotal = await this.findAllByTypeOccupationAndDateBetweenDate('Hospitalizacion y cirugia',startMonth,endMonth);
        let hospitalTotal = await this.findAllByTypeOccupationAndDateBetweenDate('Hospitalizacion',startMonth,endMonth);
        let totalPacientes = observacionTotal + cirugiaAmbulatoriaTotal + hospitalYCirugiaTotal + hospitalTotal;

        this.patientChart.chartData[0].data.push(observacionTotal);
        this.patientChart.chartData[1].data.push(cirugiaAmbulatoriaTotal);
        this.patientChart.chartData[2].data.push(hospitalYCirugiaTotal);
        this.patientChart.chartData[3].data.push(hospitalTotal);
        this.patientChart.chartData[4].data.push(totalPacientes);
        index++;
      }
      // console.log(this.consultinChart)
    }catch(e){

    }
  }

  /**funcionalidad para la grafica de linea de ingresos al hospital
  */
  async creatingChartBalance(){
    try{
      this.balanceChart.chartLabels = [];
      for(let i = 0; i < this.balanceChart.chartData.length; i++){
        this.balanceChart.chartData[i].data = [];
      }

      let index = 0;
      let end = moment().locale('es-mx').format('M');
      // let endNumber = 11;
      //creamos los labels del mes
      while (index < 12) {
        let month = moment().locale('es-mx').set('month',index).format('MMMM');
        console.log(month);
        this.balanceChart.chartLabels.push(month);
        index++;
      }

      // Generamos las consultas para lo contadores
      index = 0;
      while(index < 12){
        let year:any = this.balanceChart.year;
        // let startMonth = moment().set('day',1).set('months',index).set('year',year).toISOString();
        // let endMonth = moment().set('day',1).set('months',index+1).subtract(1,'day').set('year',year).toISOString();
        let startMonth  = new Date(year, index, 1).toISOString();
        let endMonth =  new Date((new Date(year, index+1, 1)).setDate((new Date(year, index+1, 1)).getDate() - 1)).toISOString();

        let incomenBruto = await this.totalSalesByCompanyAndRoleAndDateBetweenDate(startMonth,endMonth);
        let incomenOther = await this.totalOtherIncomeByCompanyAndDateBetweenDate(startMonth,endMonth);
        let expenseCajaChica = await this.spendingBetweenDate(startMonth,endMonth);
        let expenseOther = await this.spendingGeneralBetweenDate(startMonth,endMonth);
        let expenseGuard = await this.honoraryGuardsBetweenDate(startMonth,endMonth);

        let income = incomenBruto + incomenOther;
        let expense = expenseCajaChica + expenseOther + expenseGuard;
        let balance = income - expense;

        this.balanceChart.chartData[0].data.push(balance);
        this.balanceChart.chartData[1].data.push(expense);
        this.balanceChart.chartData[2].data.push(income);

        index++;
      }
      // console.log(this.consultinChart)
    }catch(e){

    }
  }

  /***
  UTILIDADES
  **/

  /**funciñonalidad para crear el arreglo de años
  */
  createYears(){
    let year = moment().format('YYYY');
    let initial = 2019;
    this.years = [];

    while(initial <= parseInt(year)){
      this.years.push(initial);
      initial++;
    }
  }

  /**funcionalidad para obtener los totales de ingresos al CHUM
  */
  findAllByTypeOccupationAndDateBetweenDate(typeOccupation,startDate,endDate):Promise<number> {
    return new Promise((resolve,reject)=>{
      this.session.getRequest("dashboard:findAllByTypeOccupationAndDateBetween", { complement: { typeOccupation: typeOccupation }, system: {roomOccupationDetail:{main:{startDate: moment(startDate).format('DD/MM/YYYY hh:mm:ss'),endDate: moment(endDate).format('DD/MM/YYYY hh:mm:ss')}}} }).subscribe((data: any) => {
        resolve(data.object.value)
      }, error => {
        reject(error);
        console.log("Error: dashboard:findAllByTypeOccupationAndDateBetween", error);
      })
    });
  }

  // Obtenemos el total de ingreso por el rol del doctor y el periodo especificado
  totalSalesByCompanyAndRoleAndDateBetweenDate(startDate,endDate):Promise<number> {
    return new Promise((resolve,reject)=>{
      this.session.getRequest("dashboard:totalSalesByCompanyAndRoleAndDateBetween", {
        system: {
          roomOccupationDetail: {
            main: {
              endDate: moment(endDate).format('DD/MM/YYYY hh:mm:ss'),
              startDate: moment(startDate).format('DD/MM/YYYY hh:mm:ss'),
            }
          },
          role: 'Specialist doctor',
          company: {
            main: {
              uuid: this.session.getSessionObject().main.uuid
            }
          },
          status: true
        }
      }).subscribe((data: any) => {
        resolve(data.object)
      }, error => {
        console.log("Error: dashboard:totalSalesByCompanyAndRoleAndDateBetween", error);
        reject(error);
      });
    });
  }

  // Obtenemos el total de otros ingresos por el periodo especificado
  totalOtherIncomeByCompanyAndDateBetweenDate(startDate,endDate):Promise<number> {
    return new Promise((resolve,reject)=>{
      this.session.getRequest("dashboard:totalOtherIncomeByCompanyAndDateBetween", {
        system: {
          roomOccupationDetail: {
            main: {
              endDate: moment(endDate).format('DD/MM/YYYY hh:mm:ss'),
              startDate: moment(startDate).format('DD/MM/YYYY hh:mm:ss'),
            }
          },
          company: {
            main: {
              uuid: this.session.getSessionObject().main.uuid
            }
          },
        }
      }).subscribe((data: any) => {
        resolve(data.object)
      }, error => {
        console.log("Error: dashboard:totalOtherIncomeByCompanyAndDateBetween", error);
        reject(error);
      });
    });
  }

  honoraryGuardsBetweenDate(startDate,endDate):Promise<number> {
    return new Promise((resolve,reject)=>{
      // Obtenemos el total de gastos en caja chica
      this.session.getRequest('roomOccupationDoctor:moneyByDateBetweenAndPay', { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } }, main: { startDate: moment(startDate).format('DD/MM/YYYY hh:mm:ss'), endDate: moment(endDate).format('DD/MM/YYYY hh:mm:ss') } }).subscribe((data: any) => {
        resolve(data.object)
      }, error => {
        reject(error);
        console.log("Error: roomOccupationDoctor:moneyByDateBetweenAndPay", error);
      });
    })
  }

  spendingBetweenDate(startDate,endDate):Promise<number> {
    return new Promise((resolve,reject)=>{
      // Obtenemos el total de gastos en caja chica
      this.session.getRequest('spending:moneyByCompanyAndBetweenDate', { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } }, main: { startDate: moment(startDate).format('DD/MM/YYYY hh:mm:ss'), endDate: moment(endDate).format('DD/MM/YYYY hh:mm:ss') } }).subscribe((data: any) => {
        resolve(data.object)
      }, error => {
        reject(error);
        console.log("Error: spending:moneyByCompanyAndBetweenDate", error);
      });
    });
  }

  spendingGeneralBetweenDate(startDate,endDate):Promise<number> {
    return new Promise((resolve,reject)=>{
      // Obtenemos el total de gastos en caja chica
      this.session.getRequest('spendingGeneral:moneyByCompanyAndBetweenDate', { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } }, main: { startDate: moment(startDate).format('DD/MM/YYYY hh:mm:ss'), endDate: moment(endDate).format('DD/MM/YYYY hh:mm:ss') } }).subscribe((data: any) => {
        resolve(data.object);
      }, error => {
        console.log("Error: spendingGeneral:moneyByCompanyAndBetweenDate", error);
        reject(error);
      });
    });
  }

  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
