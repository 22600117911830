import { Component, OnInit, Inject } from '@angular/core';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-owner-show-annotation',
  templateUrl: './owner-show-annotation.component.html',
  styleUrls: ['./owner-show-annotation.component.css']
})
export class OwnerShowAnnotationComponent implements OnInit {
  feedPaymentAnnotationModel = {
    main: {
      uuid: '',
      annotation: '',
      dateAnnotation: ''
    },
    system: {
      sales:{
        main:{
          uuid:''
        }
      },
      feedPayment: {
        main: {
          uuid:''
        }
      }
    }
  }
  object =  JSON.parse(JSON.stringify(this.feedPaymentAnnotationModel));
  constructor(public loadingService: LoadingService, private session: SessionService, public dialogRef: MatDialogRef<OwnerShowAnnotationComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar) {
    this.object.system.sales.main.uuid = data.main.uuid;
    console.log(this.object)
  }

  ngOnInit() {
    this.object.system.sales.main.uuid = this.data.main.uuid;
    this.session.getRequest("feedPaymentAnnotation:findBySale",this.object).subscribe((data:any)=>{
      this.object = JSON.parse(JSON.stringify(data.object));
    },error=>{
      console.log(error);
    })
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'price_list:001';
    }
    this.dialogRef.close(object);
  }

}
