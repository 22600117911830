import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-treasury-expense-record',
  templateUrl: './admin-treasury-expense-record.component.html',
  styleUrls: ['./admin-treasury-expense-record.component.css']
})
export class AdminTreasuryExpenseRecordComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;
  pettyCashModel = {
    main: {
      uuid: '',
      openAmount: 0.0,
      closeAmount: 0.0,
      openDate: moment().format('DD/MM/YYYY HH:mm:ss'),
      closeDate: moment().format('DD/MM/YYYY HH:mm:ss'),
      open: false,
    },
    system: {
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      },
      openDoctor: {
        main: {
          uuid: ''
        },
        system: {
          user: {
            main: {
              uuid: this.session.getUser().main.uuid
            }
          },
          physicalPerson: {
            main: {
              name: '',
              lastname: '',
              surname: ''
            }
          }
        },
      },
      closeDoctor: {
        main: {
          uuid: ''
        },
        system: {
          user: {
            main: {
              uuid: this.session.getUser().main.uuid
            }
          },
          physicalPerson: {
            main: {
              name: '',
              lastname: '',
              surname: ''
            }
          }
        },
      },
      spendings: [],
    }
  }
  metadata = {
    diference: 0.0
  }

  object = JSON.parse(JSON.stringify(this.pettyCashModel));
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {
    this.loadingService.wait();
    this.session.getRequest('pettyCash:findByCompanyAndOpen', { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } }, main: { open: true } }).subscribe((data: any) => {
      if (data.object != '') {
        // Asignamos el detalle de la apertura y cierre del turno
        this.object = JSON.parse(JSON.stringify(data.object));
        this.object.main.openDate = moment(this.object.main.openDate).format('DD/MM/YYYY HH:mm:SS');
        // Obtenemos los detalles de los gastos
        this.loadingService.wait();
        this.session.getRequest('spending:findAllByPettyCash', { system: { pettyCash: { main: { uuid: this.object.main.uuid } } } }).subscribe((data: any) => {
          this.object.system.spendings = data.object.instanceList;
          this.loadingService.hide();
        }, (error) => {
          console.log('spending:findAllByPettyCash', error);
          this.loadingService.hide();
        });
      }
      // this.metadata.dateBirth = moment(this.object.system.physicalPerson.complement.dateBirth).utc().toISOString();
      this.loadingService.hide();
    }, (error) => {
      console.log('pettyCash:findByCompanyAndOpen', error);
      this.loadingService.hide();
    });
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }
}
