import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { HttpModule } from '@angular/http';
import { TreeModule } from 'angular-tree-component';
import { SessionModule } from './service/session/session.module';
import { LoadingModule } from './directive/loading/loading.module';
import { StiFileComponent } from './directive/sti-file/sti-file.directive';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MenuService } from './menu/menu.service';
import { KeysoftUploadComponent } from './directive/keysoft-upload/keysoft-upload';
import { ChartsModule } from 'ng2-charts';
import localeEs from '@angular/common/locales/es-Mx';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { NgxUploaderModule } from 'ngx-uploader';
import 'hammerjs';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
  MatNativeDateModule,
  MatRippleModule
} from '@angular/material';
import { CKEditorModule } from 'ckeditor4-angular';
import { LoginComponent } from './login/login.component';
import { ToolBarComponent } from './tool-bar/tool-bar.component';
import { MenuComponent } from './menu/menu.component';
import { AdminDoctorComponent } from './admin/admin-doctor/admin-doctor.component';
import { ActivedsessionService } from './login/activedsession.service';
import { AdminDoctorUpdateComponent } from './admin/admin-doctor/admin-doctor-update/admin-doctor-update.component';
import { AdminProductComponent } from './admin/admin-product/admin-product.component';
import { AdminProductUpdateComponent } from './admin/admin-product/admin-product-update/admin-product-update.component';
import { AdminZoneComponent } from './admin/admin-zone/admin-zone.component';
import { AdminZoneUpdateComponent } from './admin/admin-zone/admin-zone-update/admin-zone-update.component';
import { AdminInventoryComponent } from './admin/admin-inventory/admin-inventory.component';
import { AdminRoomComponent } from './admin/admin-room/admin-room.component';
import { AdminRoomUpdateComponent } from './admin/admin-room/admin-room-update/admin-room-update.component';
import { AdminWarehouseUpdateComponent } from './admin/admin-inventory/admin-warehouse-update/admin-warehouse-update.component';
import { AdminInventoryStockComponent } from './admin/admin-inventory/admin-inventory-stock/admin-inventory-stock.component';
import { AdminAdjustmentComponent } from './admin/admin-inventory/admin-adjustment/admin-adjustment.component';
import { AdminAdjustmentUpdateComponent } from './admin/admin-inventory/admin-adjustment/admin-adjustment-update/admin-adjustment-update.component';
import { AdminAjustmentValidateComponent } from './admin/admin-inventory/admin-adjustment/admin-ajustment-validate/admin-ajustment-validate.component';
import { AdminPatientComponent } from './admin/admin-patient/admin-patient.component';
import { AdminPatientUpdateComponent } from './admin/admin-patient/admin-patient-update/admin-patient-update.component';
import { AdminRoleComponent } from './admin/admin-role/admin-role.component';
import { AdminRoleDashboardComponent } from './admin/admin-role-dashboard/admin-role-dashboard.component';
import { AdminAccessComponent } from './admin/admin-access/admin-access.component';
import { AdminAccessUpdateComponent } from './admin/admin-access/admin-access-update/admin-access-update.component';
import { AdminAccessAccessComponent } from './admin/admin-access/admin-access-access/admin-access-access.component';
import { AdminServiceCatalogComponent } from './admin/admin-service-catalog/admin-service-catalog.component';
import { AdminServiceCatalogUpdateComponent } from './admin/admin-service-catalog/admin-service-catalog-update/admin-service-catalog-update.component';
import { AdminRoomServiceCatalogComponent } from './admin/admin-room/admin-room-service-catalog/admin-room-service-catalog.component';
import { ProductPriceImportComponent } from './admin/admin-product/product-price-import/product-price-import.component';
import { DialogAdjusmentIncomplete } from './admin/admin-inventory/admin-adjustment/admin-ajustment-validate/admin-ajustment-validate.component';
import { CategoriesComponent } from './admin/catalogs/categories/categories.component';
import { CategoriesUpdateComponent } from './admin/catalogs/categories/categories-update/categories-update.component';
import { CharacteristicComponent } from './admin/catalogs/characteristic/characteristic.component';
import { CharacteristicUpdateComponent } from './admin/catalogs/characteristic/characteristic-update/characteristic-update.component';
import { MakerComponent } from './admin/catalogs/maker/maker.component';
import { MakerUpdateComponent } from './admin/catalogs/maker/maker-update/maker-update.component';
import { MeasurementComponent } from './admin/catalogs/measurement/measurement.component';
import { MeasurementUpdateComponent } from './admin/catalogs/measurement/measurement-update/measurement-update.component';
import { AdjustmentTypeComponent } from './admin/admin-inventory/adjustment-type/adjustment-type.component';
import { AdjustmentTypeUpdateComponent } from './admin/admin-inventory/adjustment-type/adjustment-type-update/adjustment-type-update.component';
import { ProductImportComponent } from './admin/admin-product/product-import/product-import.component';
import { AssistantRegisterComponent } from './assistant/assistant-register/assistant-register.component';
import { AssistantPatientUpdateComponent } from './assistant/assistant-register/assistant-patient-update/assistant-patient-update.component';
import { AssistantRoomOccupationDetailComponent } from './assistant/assistant-register/assistant-room-occupation-detail/assistant-room-occupation-detail.component';
import { NurseDashboardComponent } from './nurse/nurse-dashboard/nurse-dashboard.component';
import { NurseRoomConsumptionComponent } from './nurse/nurse-room-consumption/nurse-room-consumption.component';
import { NurseProductServiceComponent } from './nurse/nurse-dashboard/nurse-product-service/nurse-product-service.component';
import { NurseRegisterUpdateComponent } from './nurse/nurse-dashboard/nurse-register-update/nurse-register-update.component';
import { NursePatientUpdateComponent } from './nurse/nurse-dashboard/nurse-register-update/nurse-patient-update/nurse-patient-update.component';
import { NurseRoomOcupationDetailComponent } from './nurse/nurse-dashboard/nurse-room-ocupation-detail/nurse-room-ocupation-detail.component';
import { PointSaleComponent } from './admin/point-sale/point-sale.component';
import { PointSaleUpdateComponent } from './admin/point-sale/point-sale-update/point-sale-update.component';
import { ProductPriceComponent } from './admin/admin-product/product-price/product-price.component';
import { ProductImageComponent } from './admin/admin-product/product-image/product-image.component';
import { PaymentMethodComponent } from './admin/point-sale/point-sale-update/payment-method/payment-method.component';
import { NursePatientMoveComponent } from './nurse/nurse-dashboard/nurse-patient-move/nurse-patient-move.component';
import { AdminTransferComponent } from './admin/admin-inventory/admin-transfer/admin-transfer.component';
import { AdminTransferUpdateComponent } from './admin/admin-inventory/admin-transfer/admin-transfer-update/admin-transfer-update.component';
import { AdminTransferValidateComponent } from './admin/admin-inventory/admin-transfer/admin-transfer-validate/admin-transfer-validate.component';
import { AdminTransferApproveComponent } from './admin/admin-inventory/admin-transfer/admin-transfer-approve/admin-transfer-approve.component';
import { DialogTransferIncomplete } from './admin/admin-inventory/admin-transfer/admin-transfer-approve/admin-transfer-approve.component';
import { AdminTransferValidationComponent } from './admin/admin-inventory/admin-transfer/admin-transfer-validation/admin-transfer-validation.component';
import { AdminNurseComponent } from './admin/admin-nurse/admin-nurse.component';
import { AdminNursePatientMoveComponent } from './admin/admin-nurse/admin-nurse-patient-move/admin-nurse-patient-move.component';
import { AdminNurseProductServiceComponent } from './admin/admin-nurse/admin-nurse-product-service/admin-nurse-product-service.component';
import { AdminNurseRegisterUpdateComponent } from './admin/admin-nurse/admin-nurse-register-update/admin-nurse-register-update.component';
import { AdminNurseRoomOcupationDetailComponent } from './admin/admin-nurse/admin-nurse-room-ocupation-detail/admin-nurse-room-ocupation-detail.component';
import { AdminNursePatientUpdateComponent } from './admin/admin-nurse/admin-nurse-register-update/admin-nurse-patient-update/admin-nurse-patient-update.component';
import { OtherChargeUpdateComponent } from './admin/point-sale/point-sale-update/other-charge-update/other-charge-update.component';
import { AdminTransferDetailsComponent } from './admin/admin-inventory/admin-transfer/admin-transfer-details/admin-transfer-details.component';
import { DoctorsGuardsComponent } from './admin/doctors-guards/doctors-guards.component';
import { OwnerDashboardComponent } from './owner/owner-dashboard/owner-dashboard.component';
import { OwnerReportComponent } from './owner/owner-report/owner-report.component';
import { AdminTreasuryExpenseClassificationComponent } from './admin/admin-treasury/admin-treasury-expense-classification/admin-treasury-expense-classification.component';
import { AdminTreasuryExpenseRecordComponent } from './admin/admin-treasury/admin-treasury-expense-record/admin-treasury-expense-record.component';
import { AdminTreasuryExpenseClassificationUpdateComponent } from './admin/admin-treasury/admin-treasury-expense-classification/admin-treasury-expense-classification-update/admin-treasury-expense-classification-update.component';
import { AdminTreasuryExpenseRecordUpdateComponent } from './admin/admin-treasury/admin-treasury-expense-record/admin-treasury-expense-record-update/admin-treasury-expense-record-update.component';
import { OwnerExpenseRecordComponent } from './owner/owner-expense-record/owner-expense-record.component';
import { OwnerExpenseRecordUpdateComponent } from './owner/owner-expense-record/owner-expense-record-update/owner-expense-record-update.component';
import { OwnerFeePaymentComponent } from './owner/owner-point-sale/owner-fee-payment/owner-fee-payment.component';
import { PettyCashOpenComponent } from './owner/owner-expense-record/owner-expense-record-update/petty-cash-open/petty-cash-open.component';
import { SaleReportComponent } from './admin/point-sale/point-sale-update/sale-report/sale-report.component';
import { AssistantNurseRegisterUpdateComponent } from './assistant/assistant-register/assistant-nurse-register-update/assistant-nurse-register-update.component';
import { AssistantNursePatientUpdateComponent } from './assistant/assistant-register/assistant-nurse-register-update/assistant-nurse-patient-update/assistant-nurse-patient-update.component';
import { OwnerPointSaleComponent } from './owner/owner-point-sale/owner-point-sale.component';
import { OwnerPointSaleUpdateComponent } from './owner/owner-point-sale/owner-point-sale-update/owner-point-sale-update.component';
import { OwnerSaleReportComponent } from './owner/owner-point-sale/owner-point-sale-update/owner-sale-report/owner-sale-report.component';
import { OwnerOtherChargeUpdateComponent } from './owner/owner-point-sale/owner-point-sale-update/owner-other-charge-update/owner-other-charge-update.component';
import { OwnerPaymentMethodComponent } from './owner/owner-point-sale/owner-point-sale-update/owner-payment-method/owner-payment-method.component';
import { OwnerExpenseClassificationComponent } from './owner/owner-expense-classification/owner-expense-classification.component';
import { OwnerExpenseClassificationUpdateComponent } from './owner/owner-expense-classification/owner-expense-classification-update/owner-expense-classification-update.component';
import { OwnerExpenseComponent } from './owner/owner-expense/owner-expense.component';
import { OwnerExpenseUpdateComponent } from './owner/owner-expense/owner-expense-update/owner-expense-update.component';
import { AdminTreasuryExpenseComponent } from './admin/admin-treasury/admin-treasury-expense/admin-treasury-expense.component';
import { AdminTreasuryExpenseUpdateComponent } from './admin/admin-treasury/admin-treasury-expense/admin-treasury-expense-update/admin-treasury-expense-update.component';
import { OwnerDoctorsGuardsComponent } from './owner/owner-doctors-guards/owner-doctors-guards.component';
import { CalendarDateFormatter, CalendarModule, CalendarNativeDateFormatter, DateAdapter, DateFormatterParams } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MedicalConsultationComponent } from './owner/medical-consultation/medical-consultation.component';
import { MedicalConsultationHistoryComponent } from './owner/medical-consultation/medical-consultation-history/medical-consultation-history.component';
import { CensusComponent } from './owner/census/census.component';
import { DoctorCensusComponent } from './doctor/doctor-census/doctor-census.component';
import { AssistantCensusComponent } from './assistant/assistant-census/assistant-census.component';
import { DoctorRegisterComponent } from './doctor/doctor-register/doctor-register.component';
import { DoctorNurseRegisterUpdateComponent } from './doctor/doctor-register/doctor-nurse-register-update/doctor-nurse-register-update.component';
import { DoctorPatientUpdateComponent } from './doctor/doctor-register/doctor-patient-update/doctor-patient-update.component';
import { DoctorRoomOccupationDetailComponent } from './doctor/doctor-register/doctor-room-occupation-detail/doctor-room-occupation-detail.component';
import { DoctorNursePatientUpdateComponent } from './doctor/doctor-register/doctor-nurse-register-update/doctor-nurse-patient-update/doctor-nurse-patient-update.component';
import { AssistantMedicalAppointmentComponent } from './assistant/assistant-medical-appointment/assistant-medical-appointment.component';
import { AssistantMedicalAppointmentDetailComponent } from './assistant/assistant-medical-appointment/assistant-medical-appointment-detail/assistant-medical-appointment-detail.component';
import { AssistantMedicalAppointmentUpdateComponent } from './assistant/assistant-medical-appointment/assistant-medical-appointment-update/assistant-medical-appointment-update.component';
import { OwnerMedicalConsultationComponent } from './owner/medical-consultation/owner-medical-consultation/owner-medical-consultation.component';
import { OwnerMedicalAppointmentDetailComponent } from './owner/medical-consultation/owner-medical-consultation/owner-medical-appointment-detail/owner-medical-appointment-detail.component';
import { OwnerMedicalAppointmentUpdateComponent } from './owner/medical-consultation/owner-medical-consultation/owner-medical-appointment-update/owner-medical-appointment-update.component';
import { PatientMedicalRecordsComponent } from './directive/patient-medical-records/patient-medical-records.component';
import { AssistantMedicalAppointmentSpendingComponent } from './assistant/assistant-medical-appointment/assistant-medical-appointment-spending/assistant-medical-appointment-spending.component';
import { OwnerOfficeExpenseDetailsComponent } from './owner/medical-consultation/owner-office-expense-details/owner-office-expense-details.component';
import { MedicalRecordsDetailsComponent } from './directive/medical-records-details/medical-records-details.component';
import { PatientBankDataComponent } from './directive/patient-bank-data/patient-bank-data.component';
import { OwnerConsultationPatientComponent } from './owner/medical-consultation/owner-consultation-patient/owner-consultation-patient.component';
import { AdminTreasuryOtherIncomeClassificationComponent } from './admin/admin-treasury/admin-treasury-other-income-classification/admin-treasury-other-income-classification.component';
import { AdminTreasuryOtherIncomeClassificationUpdateComponent } from './admin/admin-treasury/admin-treasury-other-income-classification/admin-treasury-other-income-classification-update/admin-treasury-other-income-classification-update.component';
import { AdminTreasuryOtherIncomeComponent } from './admin/admin-treasury/admin-treasury-other-income/admin-treasury-other-income.component';
import { AdminTreasuryOtherIncomeUpdateComponent } from './admin/admin-treasury/admin-treasury-other-income/admin-treasury-other-income-update/admin-treasury-other-income-update.component';
import { OwnerOtherIncomeClassificationComponent } from './owner/owner-other-income-classification/owner-other-income-classification.component';
import { OwnerOtherIncomeClassificationUpdateComponent } from './owner/owner-other-income-classification/owner-other-income-classification-update/owner-other-income-classification-update.component';
import { OwnerOtherIncomeComponent } from './owner/owner-other-income/owner-other-income.component';
import { OwnerOtherIncomeUpdateComponent } from './owner/owner-other-income/owner-other-income-update/owner-other-income-update.component';
import { OwnerBalanceConsultingComponent } from './owner/owner-balance-consulting/owner-balance-consulting.component';
import { OwnerSurgeriesComponent } from './owner/owner-surgeries/owner-surgeries.component';
import { OwnerSurgeriesUpdateComponent } from './owner/owner-surgeries/owner-surgeries-update/owner-surgeries-update.component';
import { OwnerSurgeriesAvailabilityComponent } from './owner/owner-surgeries/owner-surgeries-availability/owner-surgeries-availability.component';
import { OwnerColaboratorComponent } from './owner/owner-colaborator/owner-colaborator.component';
import { OwnerColaboratorUpdateComponent } from './owner/owner-colaborator/owner-colaborator-update/owner-colaborator-update.component';
import { AssistantMedicalAppointmentOtherIncomeComponent } from './assistant/assistant-medical-appointment/assistant-medical-appointment-other-income/assistant-medical-appointment-other-income.component';
import { OwnerMedicalConsultationDashboardComponent } from './owner/owner-medical-consultation-dashboard/owner-medical-consultation-dashboard.component';
import { OwnerMedicalNotePatientComponent } from './owner/medical-consultation/owner-medical-consultation/owner-medical-note-patient/owner-medical-note-patient.component';
import { OwnerMedicalConsultationCirugyTableComponent } from './owner/owner-medical-consultation-dashboard/owner-medical-consultation-cirugy-table/owner-medical-consultation-cirugy-table.component';
import { OwnerMedicalConsultationConsultingTableComponent } from './owner/owner-medical-consultation-dashboard/owner-medical-consultation-consulting-table/owner-medical-consultation-consulting-table.component';
import { OwnerMedicalConsultationSpendigTableComponent } from './owner/owner-medical-consultation-dashboard/owner-medical-consultation-spendig-table/owner-medical-consultation-spendig-table.component';
import { OwnerMedicalConsultationOtherIncomeTableComponent } from './owner/owner-medical-consultation-dashboard/owner-medical-consultation-other-income-table/owner-medical-consultation-other-income-table.component';
import { OwnerMedicalConsultationConsultingIncomeTableComponent } from './owner/owner-medical-consultation-dashboard/owner-medical-consultation-consulting-income-table/owner-medical-consultation-consulting-income-table.component';
import { OwnerFeePaymentUpdateComponent } from './owner/owner-point-sale/owner-fee-payment/owner-fee-payment-update/owner-fee-payment-update.component';
import { AdminAccessChangePasswordComponent } from './admin/admin-access/admin-access-change-password/admin-access-change-password.component';
import { ChangeMyPasswordComponent } from './directive/change-my-password/change-my-password.component';
import { RestorePasswordComponent } from './login/restore-password/restore-password.component';
import { SpecialistCensusComponent } from './specialist/specialist-census/specialist-census.component';
import { SpecialistDashboardComponent } from './specialist/specialist-dashboard/specialist-dashboard.component';
import { SpecialistDashboardConsultingIncomeTableComponent } from './specialist/specialist-dashboard/specialist-dashboard-consulting-income-table/specialist-dashboard-consulting-income-table.component';
import { SpecialistDashboardSpendigTableComponent } from './specialist/specialist-dashboard/specialist-dashboard-spendig-table/specialist-dashboard-spendig-table.component';
import { SpecialistDashboardConsultingTableComponent } from './specialist/specialist-dashboard/specialist-dashboard-consulting-table/specialist-dashboard-consulting-table.component';
import { SpecialistBalanceConsultingComponent } from './specialist/specialist-balance-consulting/specialist-balance-consulting.component';
import { SpecialistMedicalConsultationComponent } from './specialist/specialist-medical-consultation/specialist-medical-consultation.component';
import { SpecialistMedicalConsultationHistoryComponent } from './specialist/specialist-medical-consultation/specialist-medical-consultation-history/specialist-medical-consultation-history.component';
import { SpecialistOfficeExpenseDetailsComponent } from './specialist/specialist-medical-consultation/specialist-office-expense-details/specialist-office-expense-details.component';
import { SpecialistConsultationPatientComponent } from './specialist/specialist-medical-consultation/specialist-consultation-patient/specialist-consultation-patient.component';
import { SpecialistMedicalConsultationUpdateComponent } from './specialist/specialist-medical-consultation/specialist-medical-consultation-update/specialist-medical-consultation-update.component';
import { SpecialistMedicalAppointmentDetailComponent } from './specialist/specialist-medical-consultation/specialist-medical-consultation-update/specialist-medical-appointment-detail/specialist-medical-appointment-detail.component';
import { SpecialistMedicalAppointmentUpdateComponent } from './specialist/specialist-medical-consultation/specialist-medical-consultation-update/specialist-medical-appointment-update/specialist-medical-appointment-update.component';
import { SpecialistMedicalNotePatientComponent } from './specialist/specialist-medical-consultation/specialist-medical-consultation-update/specialist-medical-note-patient/specialist-medical-note-patient.component';
import { SpecialistSurgeriesComponent } from './specialist/specialist-surgeries/specialist-surgeries.component';
import { SpecialistSurgeriesUpdateComponent } from './specialist/specialist-surgeries/specialist-surgeries-update/specialist-surgeries-update.component';
import { SpecialistSurgeriesAvailabilityComponent } from './specialist/specialist-surgeries/specialist-surgeries-availability/specialist-surgeries-availability.component';
import { OwnerFeedPaymentAnnotationComponent } from './owner/owner-point-sale/owner-fee-payment/owner-feed-payment-annotation/owner-feed-payment-annotation.component';
import { AdminNurseEditComponent } from './admin/admin-nurse/admin-nurse-edit/admin-nurse-edit.component';
import { OwnerSlopeTrackingComponent } from './owner/owner-slope-tracking/owner-slope-tracking.component';
import { OwnerSlopeTrackingUpdateComponent } from './owner/owner-slope-tracking/owner-slope-tracking-update/owner-slope-tracking-update.component';
import { OwnerSlopeTrackingDetailsComponent } from './owner/owner-slope-tracking/owner-slope-tracking-details/owner-slope-tracking-details.component';
import { AdminTasksComponent } from './admin/admin-tasks/admin-tasks.component';
import { AdminSurveyComponent } from './admin/admin-survey/admin-survey.component';
import { AdminSurveyUpdateComponent } from './admin/admin-survey/admin-survey-update/admin-survey-update.component';
import { SurveyToAnswerComponent } from './anonymous/survey-to-answer/survey-to-answer.component';
import { OwnerShowAnnotationComponent } from './owner/owner-point-sale/owner-show-annotation/owner-show-annotation.component';
import { OwnerReportCie10Component } from './owner/owner-report-cie10/owner-report-cie10.component';
import { AdminReportDashboardComponent } from './admin/admin-report-dashboard/admin-report-dashboard.component';
import { AdminCensusComponent } from './admin/admin-census/admin-census.component';
import { AdminCie10Component } from './admin/admin-cie10/admin-cie10.component';
import { AdminWorkingHoursComponent } from './admin/admin-working-hours/admin-working-hours.component';
import { AdminWorkingHoursUpdateComponent } from './admin/admin-working-hours/admin-working-hours-update/admin-working-hours-update.component';
import { AdminTypePunishmentComponent } from './admin/admin-type-punishment/admin-type-punishment.component';
import { AdminTypePunishmentUpdateComponent } from './admin/admin-type-punishment/admin-type-punishment-update/admin-type-punishment-update.component';
import { AdminAttendanceRuleComponent } from './admin/admin-attendance-rule/admin-attendance-rule.component';
import { AdminAttendanceRuleUpdateComponent } from './admin/admin-attendance-rule/admin-attendance-rule-update/admin-attendance-rule-update.component';
import { AdminCalendarIncidenceComponent } from './admin/admin-calendar-incidence/admin-calendar-incidence.component';
import { AdminCalendarIncidenceDayComponent } from './admin/admin-calendar-incidence/admin-calendar-incidence-day/admin-calendar-incidence-day.component';
import { AdminTimeClockComponent } from './admin/admin-time-clock/admin-time-clock.component';
import { AdminSurveyAnswerComponent } from './admin/admin-survey/admin-survey-answer/admin-survey-answer.component';
import { SurveyThanksComponent } from './anonymous/survey-thanks/survey-thanks.component';
import { AdminSurveyListComponent } from './admin/admin-survey/admin-survey-list/admin-survey-list.component';
import { AdminSurveyLisUpdateComponent } from './admin/admin-survey/admin-survey-list/admin-survey-lis-update/admin-survey-lis-update.component';
import { AssistantSurgeriesComponent } from './assistant/assistant-surgeries/assistant-surgeries.component';
import { AssistantSurgeriesAvailabilityComponent } from './assistant/assistant-surgeries/assistant-surgeries-availability/assistant-surgeries-availability.component';
import { AssistantSurgeriesUpdateComponent } from './assistant/assistant-surgeries/assistant-surgeries-update/assistant-surgeries-update.component';
import { NurseSurgeriesComponent } from './nurse/nurse-surgeries/nurse-surgeries.component';
import { NurseSurgeriesUpdateComponent } from './nurse/nurse-surgeries/nurse-surgeries-update/nurse-surgeries-update.component';
import { NurseSurgeriesAvailabilityComponent } from './nurse/nurse-surgeries/nurse-surgeries-availability/nurse-surgeries-availability.component';
import { NurseCensusComponent } from './nurse/nurse-census/nurse-census.component';
import { AdminSurgeriesComponent } from './admin/admin-surgeries/admin-surgeries.component';
import { AdminSurgeriesUpdateComponent } from './admin/admin-surgeries/admin-surgeries-update/admin-surgeries-update.component';
import { AdminSurgeriesAvailabilityComponent } from './admin/admin-surgeries/admin-surgeries-availability/admin-surgeries-availability.component';
import { SelectCompanyComponent } from './login/select-company/select-company.component';
import { DemoModule } from './classDate';
import { AssistantPointSaleComponent } from './assistant/assistant-point-sale/assistant-point-sale.component';
import { AssistantPointSaleOtherChargeUpdateComponent } from './assistant/assistant-point-sale/assistant-point-sale-other-charge-update/assistant-point-sale-other-charge-update.component';
import { AssistantPointSaleProductServiceComponent } from './assistant/assistant-point-sale/assistant-point-sale-product-service/assistant-point-sale-product-service.component';
import { AssistantPointSalePaymentMethodComponent } from './assistant/assistant-point-sale/assistant-point-sale-payment-method/assistant-point-sale-payment-method.component';
import { OwnerSurveyComponent } from './owner/owner-survey/owner-survey.component';
import { OwnerSurveyListComponent } from './owner/owner-survey/owner-survey-list/owner-survey-list.component';
import { AssistantPointSaleRegisterPatientUpdateComponent } from './assistant/assistant-point-sale/assistant-point-sale-register-patient-update/assistant-point-sale-register-patient-update.component';
import { AssistantPointSaleOpenCashComponent } from './assistant/assistant-point-sale/assistant-point-sale-open-cash/assistant-point-sale-open-cash.component';
import { AssistantPointSaleCloseCashComponent } from './assistant/assistant-point-sale/assistant-point-sale-close-cash/assistant-point-sale-close-cash.component';
import { AdminCashingByEmergenciesComponent } from './admin/admin-cashing-by-emergencies/admin-cashing-by-emergencies.component';
import { AdminCashingByEmergenciesValidateComponent } from './admin/admin-cashing-by-emergencies/admin-cashing-by-emergencies-validate/admin-cashing-by-emergencies-validate.component';
import { OwnerCashingByEmergenciesComponent } from './owner/owner-cashing-by-emergencies/owner-cashing-by-emergencies.component';
import { OwnerCashingByEmergenciesValidateComponent } from './owner/owner-cashing-by-emergencies/owner-cashing-by-emergencies-validate/owner-cashing-by-emergencies-validate.component';
import { OwnerCashingByEmergenciesSettleComponent } from './owner/owner-cashing-by-emergencies/owner-cashing-by-emergencies-settle/owner-cashing-by-emergencies-settle.component';
import { OwnerColaboratorScheduleComponent } from './owner/owner-colaborator/owner-colaborator-schedule/owner-colaborator-schedule.component';
import { AdminDoctorScheduleComponent } from './admin/admin-doctor/admin-doctor-schedule/admin-doctor-schedule.component';
import { OwnerClockComponent } from './owner/owner-clock/owner-clock.component';
import { OwnerClockMapComponent } from './owner/owner-clock/owner-clock-map/owner-clock-map.component';
import { OwnerClockCheckEntryExitWorkComponent } from './owner/owner-clock/owner-clock-check-entry-exit-work/owner-clock-check-entry-exit-work.component';
import { OwnerClockCheckEntryExitWorkConfirmDeleteChecksComponent } from './owner/owner-clock/owner-clock-check-entry-exit-work/owner-clock-check-entry-exit-work-confirm-delete-checks/owner-clock-check-entry-exit-work-confirm-delete-checks.component';
import { NursePointSaleComponent } from './nurse/nurse-point-sale/nurse-point-sale.component';
import { NursePointSaleCloseCashComponent } from './nurse/nurse-point-sale/nurse-point-sale-close-cash/nurse-point-sale-close-cash.component';
import { NursePointSaleOpenCashComponent } from './nurse/nurse-point-sale/nurse-point-sale-open-cash/nurse-point-sale-open-cash.component';
import { NursePointSaleOtherChargeUpdateComponent } from './nurse/nurse-point-sale/nurse-point-sale-other-charge-update/nurse-point-sale-other-charge-update.component';
import { NursePointSalePaymentMethodComponent } from './nurse/nurse-point-sale/nurse-point-sale-payment-method/nurse-point-sale-payment-method.component';
import { NursePointSaleProductServiceComponent } from './nurse/nurse-point-sale/nurse-point-sale-product-service/nurse-point-sale-product-service.component';
import { NursePointSaleRegisterPatientUpdateComponent } from './nurse/nurse-point-sale/nurse-point-sale-register-patient-update/nurse-point-sale-register-patient-update.component';
import { OwnerMotionRequestComponent } from './owner/owner-motion-request/owner-motion-request.component';
import { AdminMotionRequestComponent } from './admin/point-sale/admin-motion-request/admin-motion-request.component';

registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ToolBarComponent,
    MenuComponent,
    KeysoftUploadComponent,
    AdminDoctorComponent,
    AdminDoctorUpdateComponent,
    AdminProductComponent,
    AdminProductUpdateComponent,
    AdminZoneComponent,
    AdminZoneUpdateComponent,
    AdminInventoryComponent,
    CategoriesComponent,
    CategoriesUpdateComponent,
    CharacteristicComponent,
    CharacteristicUpdateComponent,
    MakerComponent,
    MakerUpdateComponent,
    MeasurementComponent,
    MeasurementUpdateComponent,
    AdjustmentTypeComponent,
    AdjustmentTypeUpdateComponent,
    StiFileComponent,
    ProductImportComponent,
    AdminRoomComponent,
    AdminRoomUpdateComponent,
    AdminWarehouseUpdateComponent,
    AdminInventoryStockComponent,
    AdminAdjustmentComponent,
    AdminAdjustmentUpdateComponent,
    AdminAjustmentValidateComponent,
    DialogAdjusmentIncomplete,
    AdminPatientComponent,
    AdminPatientUpdateComponent,
    AssistantRegisterComponent,
    AdminRoleComponent,
    AdminRoleDashboardComponent,
    AdminAccessComponent,
    AdminAccessUpdateComponent,
    AdminAccessAccessComponent,
    AssistantPatientUpdateComponent,
    AssistantRoomOccupationDetailComponent,
    ProductPriceImportComponent,
    NurseDashboardComponent,
    NurseRoomConsumptionComponent,
    NurseProductServiceComponent,
    NurseRegisterUpdateComponent,
    NursePatientUpdateComponent,
    NurseRoomOcupationDetailComponent,
    AdminServiceCatalogComponent,
    AdminServiceCatalogUpdateComponent,
    AdminRoomServiceCatalogComponent,
    PointSaleComponent,
    PointSaleUpdateComponent,
    ProductPriceComponent,
    ProductImageComponent,
    PaymentMethodComponent,
    NursePatientMoveComponent,
    AdminTransferComponent,
    AdminTransferUpdateComponent,
    AdminTransferValidateComponent,
    AdminTransferApproveComponent,
    DialogTransferIncomplete,
    AdminTransferValidationComponent,
    AdminNurseComponent,
    AdminNursePatientMoveComponent,
    AdminNurseProductServiceComponent,
    AdminNurseRegisterUpdateComponent,
    AdminNurseRoomOcupationDetailComponent,
    AdminNursePatientUpdateComponent,
    OtherChargeUpdateComponent,
    AdminTransferDetailsComponent,
    DoctorsGuardsComponent,
    OwnerDashboardComponent,
    OwnerReportComponent,
    AdminTreasuryExpenseClassificationComponent,
    AdminTreasuryExpenseRecordComponent,
    AdminTreasuryExpenseClassificationUpdateComponent,
    AdminTreasuryExpenseRecordUpdateComponent,
    OwnerExpenseRecordComponent,
    OwnerExpenseRecordUpdateComponent,
    OwnerFeePaymentComponent,
    PettyCashOpenComponent,
    SaleReportComponent,
    AssistantNurseRegisterUpdateComponent,
    AssistantNursePatientUpdateComponent,
    OwnerPointSaleComponent,
    OwnerPointSaleUpdateComponent,
    OwnerSaleReportComponent,
    OwnerOtherChargeUpdateComponent,
    OwnerPaymentMethodComponent,
    OwnerExpenseClassificationComponent,
    OwnerExpenseClassificationUpdateComponent,
    OwnerExpenseComponent,
    OwnerExpenseUpdateComponent,
    AdminTreasuryExpenseComponent,
    AdminTreasuryExpenseUpdateComponent,
    OwnerDoctorsGuardsComponent,
    MedicalConsultationComponent,
    MedicalConsultationHistoryComponent,
    CensusComponent,
    DoctorCensusComponent,
    AssistantCensusComponent,
    DoctorRegisterComponent,
    DoctorNurseRegisterUpdateComponent,
    DoctorPatientUpdateComponent,
    DoctorRoomOccupationDetailComponent,
    DoctorNursePatientUpdateComponent,
    AssistantMedicalAppointmentComponent,
    AssistantMedicalAppointmentDetailComponent,
    AssistantMedicalAppointmentUpdateComponent,
    OwnerMedicalConsultationComponent,
    OwnerMedicalAppointmentDetailComponent,
    OwnerMedicalAppointmentUpdateComponent,
    PatientMedicalRecordsComponent,
    AssistantMedicalAppointmentSpendingComponent,
    OwnerOfficeExpenseDetailsComponent,
    MedicalRecordsDetailsComponent,
    PatientBankDataComponent,
    OwnerConsultationPatientComponent,
    AdminTreasuryOtherIncomeClassificationComponent,
    AdminTreasuryOtherIncomeClassificationUpdateComponent,
    AdminTreasuryOtherIncomeComponent,
    AdminTreasuryOtherIncomeUpdateComponent,
    OwnerOtherIncomeClassificationComponent,
    OwnerOtherIncomeClassificationUpdateComponent,
    OwnerOtherIncomeComponent,
    OwnerOtherIncomeUpdateComponent,
    OwnerBalanceConsultingComponent,
    OwnerSurgeriesComponent,
    OwnerSurgeriesUpdateComponent,
    OwnerSurgeriesAvailabilityComponent,
    OwnerColaboratorComponent,
    OwnerColaboratorUpdateComponent,
    AssistantMedicalAppointmentOtherIncomeComponent,
    OwnerMedicalConsultationDashboardComponent,
    OwnerMedicalNotePatientComponent,
    OwnerMedicalConsultationCirugyTableComponent,
    OwnerMedicalConsultationConsultingTableComponent,
    OwnerMedicalConsultationSpendigTableComponent,
    OwnerMedicalConsultationOtherIncomeTableComponent,
    OwnerMedicalConsultationConsultingIncomeTableComponent,
    OwnerFeePaymentUpdateComponent,
    AdminAccessChangePasswordComponent,
    ChangeMyPasswordComponent,
    RestorePasswordComponent,
    SpecialistCensusComponent,
    SpecialistDashboardComponent,
    SpecialistDashboardConsultingIncomeTableComponent,
    SpecialistDashboardSpendigTableComponent,
    SpecialistDashboardConsultingTableComponent,
    SpecialistBalanceConsultingComponent,
    SpecialistMedicalConsultationComponent,
    SpecialistMedicalConsultationHistoryComponent,
    SpecialistOfficeExpenseDetailsComponent,
    SpecialistConsultationPatientComponent,
    SpecialistMedicalConsultationUpdateComponent,
    SpecialistMedicalAppointmentDetailComponent,
    SpecialistMedicalAppointmentUpdateComponent,
    SpecialistMedicalNotePatientComponent,
    SpecialistSurgeriesComponent,
    SpecialistSurgeriesUpdateComponent,
    SpecialistSurgeriesAvailabilityComponent,
    OwnerFeedPaymentAnnotationComponent,
    AdminNurseEditComponent,
    OwnerSlopeTrackingComponent,
    OwnerSlopeTrackingUpdateComponent,
    OwnerSlopeTrackingDetailsComponent,
    AdminTasksComponent,
    AdminSurveyComponent,
    AdminSurveyUpdateComponent,
    SurveyToAnswerComponent,
    OwnerShowAnnotationComponent,
    OwnerReportCie10Component,
    AdminReportDashboardComponent,
    AdminCensusComponent,
    AdminCie10Component,
    AdminWorkingHoursComponent,
    AdminWorkingHoursUpdateComponent,
    AdminTypePunishmentComponent,
    AdminTypePunishmentUpdateComponent,
    AdminAttendanceRuleComponent,
    AdminAttendanceRuleUpdateComponent,
    AdminCalendarIncidenceComponent,
    AdminCalendarIncidenceDayComponent,
    AdminTimeClockComponent,
    AdminSurveyAnswerComponent,
    SurveyThanksComponent,
    AdminSurveyListComponent,
    AdminSurveyLisUpdateComponent,
    AssistantSurgeriesComponent,
    AssistantSurgeriesAvailabilityComponent,
    AssistantSurgeriesUpdateComponent,
    NurseSurgeriesComponent,
    NurseSurgeriesUpdateComponent,
    NurseSurgeriesAvailabilityComponent,
    NurseCensusComponent,
    AdminSurgeriesComponent,
    AdminSurgeriesUpdateComponent,
    AdminSurgeriesAvailabilityComponent,
    SelectCompanyComponent,
    AssistantPointSaleComponent,
    AssistantPointSaleOtherChargeUpdateComponent,
    AssistantPointSaleProductServiceComponent,
    AssistantPointSalePaymentMethodComponent,
    OwnerSurveyListComponent,
    OwnerSurveyComponent,
    AssistantPointSalePaymentMethodComponent,
    AssistantPointSaleRegisterPatientUpdateComponent,
    AssistantPointSaleOpenCashComponent,
    AssistantPointSaleCloseCashComponent,
    AdminCashingByEmergenciesComponent,
    AdminCashingByEmergenciesValidateComponent,
    OwnerCashingByEmergenciesComponent,
    OwnerCashingByEmergenciesValidateComponent,
    OwnerCashingByEmergenciesSettleComponent,
    OwnerColaboratorScheduleComponent,
    AdminDoctorScheduleComponent,
    OwnerClockComponent,
    OwnerClockMapComponent,
    OwnerClockCheckEntryExitWorkComponent,
    OwnerClockCheckEntryExitWorkConfirmDeleteChecksComponent,
    NursePointSaleComponent,
    NursePointSaleCloseCashComponent,
    NursePointSaleOpenCashComponent,
    NursePointSaleOtherChargeUpdateComponent,
    NursePointSalePaymentMethodComponent,
    NursePointSaleProductServiceComponent,
    NursePointSaleRegisterPatientUpdateComponent,
    OwnerMotionRequestComponent,
    AdminMotionRequestComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    FormsModule,
    FilterPipeModule,
    ReactiveFormsModule,
    NgxUploaderModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatNativeDateModule,
    MatRippleModule,
    HttpModule,
    TreeModule.forRoot(),
    LoadingModule.forRoot(),
    ChartsModule,
    SessionModule,
    CKEditorModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    },
    {
      dateFormatter: {
        provide: CalendarDateFormatter,
        useClass: DemoModule,
      },
    })
  ],
  providers: [
    MenuService,
    ActivedsessionService,
    { provide: LOCALE_ID, useValue: 'es-MX' }
  ],
  entryComponents:[
    StiFileComponent,
    KeysoftUploadComponent,
    ProductImportComponent,
    DialogAdjusmentIncomplete,
    AdminAccessUpdateComponent,
    AdminAccessAccessComponent,
    AdminRoomServiceCatalogComponent,
    AssistantPatientUpdateComponent,
    NursePatientUpdateComponent,
    NurseProductServiceComponent,
    NurseRoomOcupationDetailComponent,
    AssistantRoomOccupationDetailComponent,
    ProductImageComponent,
    PaymentMethodComponent,
    NursePatientMoveComponent,
    DialogTransferIncomplete,
    AdminNurseProductServiceComponent,
    AdminNurseRoomOcupationDetailComponent,
    AdminNursePatientMoveComponent,
    AdminNursePatientUpdateComponent,
    OtherChargeUpdateComponent,
    PettyCashOpenComponent,
    SaleReportComponent,
    AssistantNursePatientUpdateComponent,
    DoctorNursePatientUpdateComponent,
    DoctorPatientUpdateComponent,
    OwnerSaleReportComponent,
    MedicalConsultationHistoryComponent,
    AssistantMedicalAppointmentUpdateComponent,
    AssistantMedicalAppointmentDetailComponent,
    OwnerMedicalAppointmentUpdateComponent,
    OwnerMedicalAppointmentDetailComponent,
    PatientMedicalRecordsComponent,
    AssistantMedicalAppointmentSpendingComponent,
    AssistantMedicalAppointmentOtherIncomeComponent,
    OwnerOfficeExpenseDetailsComponent,
    MedicalRecordsDetailsComponent,
    OwnerConsultationPatientComponent,
    OwnerSurgeriesUpdateComponent,
    OwnerSurgeriesAvailabilityComponent,
    OwnerMedicalNotePatientComponent,
    OwnerMedicalConsultationConsultingIncomeTableComponent,
    OwnerMedicalConsultationOtherIncomeTableComponent,
    OwnerMedicalConsultationSpendigTableComponent,
    OwnerMedicalConsultationConsultingTableComponent,
    OwnerFeePaymentUpdateComponent,
    OwnerMotionRequestComponent,
    AdminMotionRequestComponent,
    SpecialistDashboardConsultingIncomeTableComponent,
    SpecialistDashboardSpendigTableComponent,
    SpecialistDashboardConsultingTableComponent,
    SpecialistMedicalConsultationHistoryComponent,
    SpecialistOfficeExpenseDetailsComponent,
    SpecialistConsultationPatientComponent,
    SpecialistMedicalAppointmentUpdateComponent,
    SpecialistSurgeriesUpdateComponent,
    AdminAccessChangePasswordComponent,
    ChangeMyPasswordComponent,
    OwnerFeedPaymentAnnotationComponent,
    AdminNurseEditComponent,
    OwnerSlopeTrackingUpdateComponent,
    OwnerSlopeTrackingDetailsComponent,
    OwnerShowAnnotationComponent,
    AdminCalendarIncidenceDayComponent,
    AssistantSurgeriesAvailabilityComponent,
    AssistantSurgeriesUpdateComponent,
    NurseSurgeriesUpdateComponent,
    NurseSurgeriesAvailabilityComponent,
    AdminSurgeriesUpdateComponent,
    AdminSurgeriesAvailabilityComponent,
    SelectCompanyComponent,
    AssistantPointSaleOtherChargeUpdateComponent,
    AssistantPointSaleProductServiceComponent,
    AssistantPointSalePaymentMethodComponent,
    AssistantPointSaleRegisterPatientUpdateComponent,
    AssistantPointSaleOpenCashComponent,
    AssistantPointSaleCloseCashComponent,
    NursePointSaleCloseCashComponent,
    NursePointSaleOpenCashComponent,
    AdminCashingByEmergenciesValidateComponent,
    OwnerCashingByEmergenciesValidateComponent,
    OwnerCashingByEmergenciesSettleComponent,
    OwnerColaboratorScheduleComponent,
    AdminDoctorScheduleComponent,
    OwnerClockMapComponent,
    OwnerClockCheckEntryExitWorkComponent,
    OwnerClockCheckEntryExitWorkConfirmDeleteChecksComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
