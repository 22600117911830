import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatSnackBar,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SessionService } from './../../service/session/session.module';
import { LoadingService } from './../../directive/loading/loading.module';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-change-my-password',
  templateUrl: './change-my-password.component.html',
  styleUrls: ['./change-my-password.component.css']
})
export class ChangeMyPasswordComponent implements OnInit {
  complexForm: FormGroup;

  userObject = {
    main: {
      uuid: '',
      confirmPassword:'',
      password:''
    },
    user: {
      main: {
        uuid: '',
        username: ''
      }
    },
    role: {
      main: {
        uuid: ''
      }
    },
    system: {
      objectIdTemp: '',
      objectId: '',
      objectType: 'Company',
      listObjects:[]
    }
  };
  object = JSON.parse(JSON.stringify(this.userObject));
  constructor(
    protected session: SessionService,
    public loadingService: LoadingService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ChangeMyPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder
  ) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'confirmPassword': [null, Validators.required],
      'password': [null, Validators.compose([Validators.required])],
      'oldPassword': [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit() {
    this.object.system.objectUuid = this.session.getSessionObject().main.uuid;
    console.log("data",this.data);
    if(this.data.object.main.uuid != ''){
      this.object.main.uuid = this.data.object.main.uuid;
      this.object = JSON.parse(JSON.stringify(this.data.object));
      this.object.main.password = '';
    }
  }


    send(){
        this.loadingService.show(true,"Espere un momento...");
        if(this.object.main.confirmPassword == this.object.main.password ){
          this.object.system.objectType = this.object.system.type;
          this.session.getRequest("validate:userPasswdUpdate",this.object).subscribe((data)=>{
            this.loadingService.hide();
            this.snackBar.open('Datos de usuario, actualizados.', 'Información', {duration: 5000});
            this.complexForm.reset();
            this.close(true);
          },
           error=>{
             console.log("validate:userPasswdUpdate", error);
             this.snackBar.open(error.message, 'Información', {duration: 5000});
             this.loadingService.hide();
           })
        }else{
          this.snackBar.open('La contraseña debe coincidir..', 'Información', {duration: 5000});
          this.loadingService.hide();
        }
      }



    close(status){
      let object = {
        transaction:'',
        code:'',
        object:{}
      };
      if(status){
        object.transaction = 'ok';
        object.object = this.object;
      }else{
        object.transaction = 'bad';
        object.code = 'passwordchange:001';
      }
      this.dialogRef.close(object);
    }

}
