import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import {MatSnackBar } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { TreeNode, TreeModel, TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions } from 'angular-tree-component';

@Component({
  selector: 'app-admin-warehouse-update',
  templateUrl: './admin-warehouse-update.component.html',
  styleUrls: ['./admin-warehouse-update.component.css']
})
export class AdminWarehouseUpdateComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  complexForm: FormGroup;
  zoneList = [];
  roomList=[];
  collaboratorList=[];
  customTemplateStringOptionss: ITreeOptions = {
    isExpandedField: 'expanded',
    idField: 'uuid',
    animateExpand: true,
    animateSpeed: 30,
    animateAcceleration: 1.2
  };

  zoneMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 100,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  };

  roomMin = {
    system:{
      floor:{
        main:{
          uuid: ''
        }
      }
    },
    max: 100,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  };

  collaboratorMin = {
    system:{
      role:'',
      company:{
        main:{
          uuid:''
        }
      },
      status:true,
    },
    max: 100,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  };

  warehouseModel = {
    main: {
      uuid: '',
      code: '',
      name: ''
    },
    complement: {
      description: '-',
    },
    system: {
      floor: {
        main:{
          uuid: ''
        }
      },
      company: {
        main:{
          uuid: ''
        }
      },
      collaborator: {
        main:{
          uuid: '',
          code: '',
          fullName: '',
        }
      },
      room: {
        main:{
          uuid: '',
          name: ''
        }
      },
      status: true
    }
  };
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  object = JSON.parse(JSON.stringify(this.warehouseModel));
  constructor(public fb: FormBuilder, public session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'code': [null, Validators.required],
      'floor': [null, Validators.required],
      'room': [null, Validators.required],
      'name': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
    });
      this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if(val != ''){
          this.collaboratorList = [];
          this.collaboratorMin.filter.name = 'searchElement';
        }else{
          this.collaboratorList = [];
          this.collaboratorMin.filter.name = '';
          this.collaboratorMin.filter.value = '';
          val = 'false'
        }
        this.findAllByCompanyAndRoleAndStatus();
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
      console.log('error logged:');
      this.snackBar.open(error.message, 'Error', {duration: 5000});
      console.log(error);
    });
   }

   ngOnInit() {
     this.getFloors();
         this.activatedRoute.params.subscribe((params: Params) => {
           if(params['uuid'] == 'new'){

           }else{
             this.loadingService.wait();
             this.object.main.uuid = params['uuid'];
             // cargamos los datos de la BD
             this.session.getRequest('warehouse:get',this.object).subscribe((data:any)=>{
             this.object = JSON.parse(JSON.stringify(data.object));
             this.object.system.floor = this.object.system.room.system.floor;
             this.collaboratorMin.filter.value = this.object.system.collaborator.system.physicalPerson.main.name + ' ' + this.object.system.collaborator.system.physicalPerson.main.lastname + ' ' + this.object.system.collaborator.system.physicalPerson.main.surname;
             this.loadingService.hide();
           },
           (error)=>{
             this.loadingService.hide();
             console.log('Error:warehouse:get',error)
           })
         }
       });
     }

     /** funcionalidad para obtener los pisos
     */
     getFloors(){
       this.loadingService.wait();
       this.zoneMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
       this.session.getRequest("floor:findAllByCompany",this.zoneMin).subscribe((data:any)=>{
         this.zoneList = data.object.instanceList;
         this.loadingService.hide();
       },error=>{
         console.log("Error:floor:findAllByCompany",error);
         this.loadingService.hide();
       });
     }

     /** funcionalidad para obtener los los cuarto de un piso
     */

     getRoomsByFloor(floor){
       if(floor.main.uuid != ''){
         this.loadingService.wait();
         this.roomMin.system.floor.main.uuid = floor.main.uuid;
         this.session.getRequest("room:findAllByFloor",this.roomMin).subscribe((data:any)=>{
           this.roomList = data.object.instanceList;
           this.loadingService.hide();
         },error=>{
           console.log("Error:room:findAllByFloor",error);
           this.loadingService.hide();
         });
       }

     }

     /** funcionalidad para obtener al encargado
     */

     findAllByCompanyAndRoleAndStatus(){
       this.loadingService.wait();
       this.collaboratorMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
       this.session.getRequest("collaborator:findAllByCompany",this.collaboratorMin).subscribe((data:any)=>{
         this.collaboratorList = data.object.instanceList;
         this.loadingService.hide();
       },error=>{
         console.log("error:collaborator:findAllByCompany",error);
         this.loadingService.hide();
       })
     };

     /*
       *Funcion para buscar
     */
     search(){
       if(this.collaboratorMin.filter.value != ''){
         this.metadata.searchBoxInput.next(this.collaboratorMin.filter.value);
       }else{
         this.collaboratorList = [];
       }
     }

     /** funcionalidad para seleccionar un collaborator seleccionado
     */
     collaboratorSeleted(object){
       this.object.system.collaborator.main.uuid = object.main.uuid;
       this.collaboratorList = [];
       this.collaboratorMin.filter.value = object.system.physicalPerson.main.name + ' ' + object.system.physicalPerson.main.lastname + ' ' + object.system.physicalPerson.main.surname;
     }

     /** funcionalidad para enviar a la bd y crear un almacen.
     */

     send(object){
       object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
       this.loadingService.show(true,'Guardando registro');
       this.session.getRequest("warehouse:update", object).subscribe(
         (data:any) => {
           // evento a emitir cuando se crea o actaliza una categoria
           this.snackBar.open('El registro se guardo correctamente', '', {
             duration: 8000
           });
           this.loadingService.hide();
           this.router.navigate(['/admin/inventory/list']);
        },
        error => {
          this.loadingService.hide();
          this.snackBar.open('Hubo un error, verifique la información.', '', {
            duration: 5000
          });
          console.log("warehouse:update",error);
        }
       );
     }

     /*
     funcion para controlar si se abre o cierra el menu
     */
     toogleMenu(ev){
       this.drawerCtrl.opened = ev;
     }


}
