import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { SingleDataSet, Label, Color, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import * as moment from 'moment';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';
import * as pluginLabels from 'chartjs-plugin-labels';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-owner-report-cie10',
  templateUrl: './owner-report-cie10.component.html',
  styleUrls: ['./owner-report-cie10.component.css']
})
export class OwnerReportCie10Component implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;

  metadata = {
    dates: {
      startDate: moment().startOf('month').toISOString(),
      endDate: moment().endOf('month').toISOString(),
    },
    system: {
      roomOccupationDetail: {
        main: {
          startDate: moment().clone().startOf('month').format('DD/MM/YYYY HH:mm:ss'),
          endDate: moment().clone().endOf('month').format('DD/MM/YYYY HH:mm:ss'),
        }
      }
    },
    startDate: '',
    endDate: '',
  }

  // Objeto para almacenar la linea del tiempo de ingresos y egresos del sistema
  cieBalanceChart = {
    chartType:'line',
    chartData: [
      { data: [], label: 'Ganancias', fill: false, borderColor:'#20b2aa'},
      { data: [], label: 'Gastos', fill: false, borderColor:'#d1e0de'},
      { data: [], label: 'Ingresos', fill: false, borderColor:'#404040'}
    ],
    chartLabels:[],
    chartLegend:true,
    year:moment().format('YYYY'),
    chartPlugins:[DataLabelsPlugin],
    chartColors:[
      { backgroundColor: '#20b2aa' },
      { backgroundColor: '#d1e0de' },
      { backgroundColor: '#404040' },
      { backgroundColor: '#e0e4e7' },
      { backgroundColor: '#61764B' },
    ],
    chartOptions:{
      responsive: true,
      datasetFill:false,
      fill:false,
      plugins: {
        labels: {
          render: 'value',
          fontSize: 1,
          fontColor: []
        },
        datalabels: {
          backgroundColor:function(context) {
            let color:any= context.dataset.backgroundColor;
            return color;
          },
          borderRadius: 20,
          color: '#fff',
          font: {
            weight: 'bold',
          },
          formatter: function(value, context) {
              return Math.round(value);
            },
          padding: 6
        }
      }
    },

  }

  years = [];
  cie10List:any = [];

  constructor(private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService) {

  }

  async ngOnInit() {
    this.createYears();

    // Cargamos grafica de diagnosticos
    this.creatingChartCie10();
    this.cie10List = await this.diagnosisByCompanyAndRoleAndBetweenDate(this.metadata.dates.startDate,this.metadata.dates.endDate);
    console.log(this.cie10List);
  }

  // Obtenemos el total de ingreso por el rol del doctor y el periodo especificado
  diagnosisByCompanyAndRoleAndDateBetween() {
    // this.loadingService.wait();
    // this.session.getRequest("dashboard:diagnosisByCompanyAndRoleAndDateBetween", {
    //   system: {
    //     roomOccupationDetail: {
    //       main: {
    //         endDate: this.metadata.system.roomOccupationDetail.main.endDate,
    //         startDate: this.metadata.system.roomOccupationDetail.main.startDate,
    //       }
    //     },
    //     company: {
    //       main: {
    //         uuid: this.session.getSessionObject().main.uuid
    //       }
    //     },
    //   }
    // }).subscribe((data: any) => {
    //   console.log('---',data.object);
    //   for (let x=0; x<data.object.instanceList; x++) {
    //     // this.barChartDataLabels.push()
    //     this.barChartData.push({data: data.object.instanceList[x].value, label: data.object.instanceList[x].name });
    //   }
    //   this.loadingService.hide();
    // }, error => {
    //   console.log("Error: dashboard:diagnosisByCompanyAndRoleAndDateBetween", error);
    //   this.loadingService.hide();
    // });
  }

  async selectPeriond(event, period) {
    if (period === 'Inicial') {
      this.metadata.system.roomOccupationDetail.main.startDate = moment(event).format('DD/MM/YYYY HH:mm:ss');
      this.metadata.dates.startDate = moment(event).toISOString();
    } else {
      this.metadata.system.roomOccupationDetail.main.endDate = moment(event).format('DD/MM/YYYY HH:mm:ss');
      this.metadata.dates.endDate = moment(event).toISOString();
    }
    if (this.metadata.system.roomOccupationDetail.main.startDate <= this.metadata.system.roomOccupationDetail.main.endDate) {

      let diagnosticos = await this.diagnosisByCompanyAndRoleAndBetweenDate(this.metadata.dates.startDate,this.metadata.dates.endDate);
      this.cie10List = diagnosticos;
      console.log("::::",diagnosticos);
    }
  }
  /**funcionalidad para la grafica de linea de ingresos al hospital
  */
  async creatingChartCie10(){
    try{
      this.cieBalanceChart.chartLabels = [];
      for(let i = 0; i < this.cieBalanceChart.chartData.length; i++){
        this.cieBalanceChart.chartData[i].data = [];
      }

      let index = 0;
      let end = moment().locale('es-mx').format('M');
      let endNumber = moment().get('months');
      //creamos los labels del mes
      while (index <= endNumber) {
        let month = moment().locale('es-mx').set('month',index).format('MMMM');
        console.log(month);
        this.cieBalanceChart.chartLabels.push(month);
        index++;
      }

      // Generamos las consultas para lo contadores
      index = 0;
      while(index <= endNumber){
        let year:any = this.cieBalanceChart.year;
        // let startMonth = moment().set('day',1).set('months',index).set('year',year).toISOString();
        // let endMonth = moment().set('day',1).set('months',index+1).subtract(1,'day').set('year',year).toISOString();
        let startMonth  = new Date(year, index, 1).toISOString();
        let endMonth =  new Date((new Date(year, index+1, 1)).setDate((new Date(year, index+1, 1)).getDate() - 1)).toISOString();

        let diagnosticos = await this.diagnosisByCompanyAndRoleAndBetweenDate(startMonth,endMonth);
        console.log("::::",diagnosticos);
        // let incomenOther = await this.totalOtherIncomeByCompanyAndDateBetweenDate(startMonth,endMonth);
        // let expenseCajaChica = await this.spendingBetweenDate(startMonth,endMonth);
        // let expenseOther = await this.spendingGeneralBetweenDate(startMonth,endMonth);
        // let expenseGuard = await this.honoraryGuardsBetweenDate(startMonth,endMonth);
        //
        // let income = incomenBruto + incomenOther;
        // let expense = expenseCajaChica + expenseOther + expenseGuard;
        // let balance = income - expense;
        //
        // this.cieBalanceChart.chartData[0].data.push(balance);
        // this.cieBalanceChart.chartData[1].data.push(expense);
        // this.cieBalanceChart.chartData[2].data.push(income);

        index++;
      }
      // console.log(this.consultinChart)
    }catch(e){

    }
  }
  /***
  UTILIDADES
  **/

  /**funcionalidad para obtener los registros de cie 10
  */
  diagnosisByCompanyAndRoleAndBetweenDate(startDate,endDate):Promise<number> {
    return new Promise((resolve,reject)=>{
      this.session.getRequest("dashboard:diagnosisByCompanyAndRoleAndDateBetween", { system: {company:{main:{uuid:this.session.getSessionObject().main.uuid}},roomOccupationDetail:{main:{startDate: moment(startDate).format('DD/MM/YYYY hh:mm:ss'),endDate: moment(endDate).format('DD/MM/YYYY hh:mm:ss')}}} }).subscribe((data: any) => {
        let list = data.object.instanceList.sort(function (a, b) {
          if (a.value < b.value) {
            return 1;
          }
          if (a.value > b.value) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });


        resolve(list)
      }, error => {
        reject(error);
        console.log("Error: dashboard:findAllByTypeOccupationAndDateBetween", error);
      })
    });
  }

  /**funciñonalidad para crear el arreglo de años
  */
  createYears(){
    let year = moment().format('YYYY');
    let initial = 2019;
    this.years = [];

    while(initial <= parseInt(year)){
      this.years.push(initial);
      initial++;
    }
  }
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
