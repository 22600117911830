import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar,MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-motion-request',
  templateUrl: './admin-motion-request.component.html',
  styleUrls: ['./admin-motion-request.component.css']
})
export class AdminMotionRequestComponent implements OnInit {
  motionRequestModel = {
    main: {
      uuid: '',
      reason: '',
      dateRequest: moment(),
      type: '',
      status: 'request'
    },
    system: {
      object: 'Sales',
      objectUuid: '',
      user: {
        main: {
          uuid: ''
        }
      },
      owner: {
        main: {
          uuid: ''
        }
      }
    }
  }
  collaboratorMin = {
    system:{
      role:'',
      company:{
        main:{
          uuid:''
        }
      },
      status:true,
      max: 10,
      offset: 0,
      filter:{
        name:'',
        value: ''
      }
    }
  }
  doctorList = [];
  url = '';
  object = JSON.parse(JSON.stringify(this.motionRequestModel));
  constructor(protected session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar,public dialogRef: MatDialogRef<AdminMotionRequestComponent>, @Inject(MAT_DIALOG_DATA) public data: any,) {

  }

  ngOnInit() {
    console.log("data", this.data);
    this.object.main.type = this.data.type;
    this.object.system.object = 'Sales';
    this.object.system.objectUuid = this.data.sales.main.uuid;
    this.findAllByCompanyAndRoleAndStatus('Doctor');
  }

  closeDialog(status): void {
    this.dialogRef.close(status);
  }

  findAllByCompanyAndRoleAndStatus(role){
    this.loadingService.wait();
    this.collaboratorMin.system.role = role;
    this.collaboratorMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("collaborator:findAllByCompanyAndRoleAndStatus",this.collaboratorMin).subscribe((data:any)=>{
      this.doctorList = data.object.instanceList;
      this.loadingService.hide();
    },error=>{
      console.log("error",error);
      this.loadingService.hide();
    })
  }

  send(object) {
    // cargamos los datos de la BD
    this.loadingService.show(true,'Generando la solicitud');
    object.main.dateRequest = moment().format('DD/MM/yyyy hh:mm:ss');
    object.system.user = this.session.getUser();
    this.session.getRequest('motionRequest:update', object).subscribe(async(data:any)=>{
        this.object = JSON.parse(JSON.stringify(data.object));
        //verificamos que el usuario  ya este asignado a un colaborador
        try {
          this.snackBar.open('La solicitud se genero correctante', 'Información', {duration: 5000});
          this.url = 'https://plataforma.chum.mx/#/owner/motionRequest/'+this.object.main.uuid;
          this.loadingService.hide();
        }catch(e){
          console.log(e);
          console.log('Error: motionRequest:update',e)
          this.snackBar.open(e.message, 'Error', {duration: 5000});
          this.loadingService.hide();
        }

      },
      (error)=>{
        console.log('Error:user:update',error)
        this.snackBar.open(error.message, 'Error', {duration: 5000});
        this.loadingService.hide();
      })
  }
}
