import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import * as moment from 'moment';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.css']
})
export class PaymentMethodComponent implements OnInit {

  salesPayment = {
    main: {
      uuid: '',
      amount: 0.0,
      paymentMethod: '',
    },
    system: {
      sales:{
        main: {
          uuid: '',
          saleNumber: '',
          typeDiscount: '',
          discountValue: 0.0,
          discountAmount: 0.0,
          subtotal: 0.0,
          total: 0.0,
          tax: 0.0,
          status: '',
          expeditionDate: '',
          enableTax: false
        }
      },
      salesTerminal: {
        main: {
          authorizationNumber: ''
        },
        system: {
          salesPayment: {
            main: {
              uuid:''
            }
          }
        }
      }
    }
  }

  object:any={}
  constructor(protected session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<PaymentMethodComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data:any) {
    this.object = JSON.parse(JSON.stringify(this.salesPayment));
    this.object.system.sales = JSON.parse(JSON.stringify(data.object));
  }

  ngOnInit() {
  }

  pay(payment) {
    console.log('object', payment);
    payment.main.paymentDate = moment().format('DD/MM/YYYY hh:mm:ss');
    this.loadingService.show(true, "Espere un momento... Obteniendo el detalle.")
    this.session.getRequest('salesPayment:update', payment).subscribe((data: any) => {
      this.snackBar.open('Su pago se a realizado con exito, pago realizado.', 'Success', {
        duration: 5000
      });
      this.close(true);
      this.loadingService.hide();
    }, (error) => {
      console.log('Error: salesPayment:update', error)
      this.loadingService.hide();
    });
  }

  close(status){
    this.dialogRef.close(status);
  }

}
