import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { TreeNode, TreeModel, TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions } from 'angular-tree-component';
import * as Rx from 'rxjs';
// import * as moment from 'moment';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-admin-product-update',
  templateUrl: './admin-product-update.component.html',
  styleUrls: ['./admin-product-update.component.css']
})
export class AdminProductUpdateComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  complexForm: FormGroup;
  characteristicModel = {
    main: {
      uuid: '',
      name: ''
    }
  }
  productCharacteristicModel = {
    system: {
      product: {
        main: {
          uuid: ''
        }
      },
      characteristic: {
        main: {
          uuid: '',
        }
      }
    }
  }
  productCharacteristicValueModel = {
    main: {
      value: ''
    },
    system: {
      product: {
        main: {
          uuid: ''
        }
      },
      characteristic: {
        main: {
          uuid: ''
        }
      }
    }
  }
  productTransientDataModel = {
    system: {
      product: {
        main: {
          uuid: ''
        }
      },
      transientData: {
        main: {
          uuid: ''
        }
      }
    }
  }
  productTransientDataValueModel = {
    main: {
      value: ''
    },
    system: {
      product: {
        main: {
          uuid: ''
        }
      },
      transientData: {
        main: {
          uuid: ''
        }
      }
    }
  }
  productModel = {
    main: {
      uuid: '',
      code: '',
      name: ''
    },
    complement: {
      description: '',
      keySat:''
    },
    system: {
      company: {
        main: {
          uuid: ''
        }
      },
      kindProduct: {
        main: {
          uuid: '',
          code: '',
          name: ''
        }
      },
      productFamily: {
        main: {
          uuid: '',
          code: '',
          name: ''
        }
      },
      maker: {
        main: {
          uuid: '',
          name: ''
        }
      },
      unitMeasure: {
        main: {
          uuid: '',
          name: ''
        }
      },
      status: true
    }
  }
  kindProductList = [];
  kindProductListFilter = [];
  productFamilyList = [];
  makerList = [];
  makerListFilter = [];
  unitMeasureList = [];
  characteristicList = [];
  characteristicListFilter = [];
  productCharacteristicValueList = [];
  transientDataList = [];
  productTransientDataList = [];
  productOrServiceList = [];
  selectedIndex = 0;
  metadata = {
    productOrService: {
      filter: '',
      searchBoxInput: new Rx.Subject<string>(),
      searching: false
    },
  }
  customTemplateStringOptions:any = {}
  object = JSON.parse(JSON.stringify(this.productModel));
  constructor(public fb: FormBuilder, public session: SessionService, public snackBar: MatSnackBar, private router: Router, private activatedRoute: ActivatedRoute, public loadingService: LoadingService) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'code': [null, Validators.required],
      'name': [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(100)])],
    });
    this.complexForm.controls['name'].enable();
  }

  ngOnInit() {
    // obtenemos la lista de categorias.
    this.loadingService.show(true,'Cargando Recursos para crear productos...')
    this.session.getRequest("productFamily:treeviewByCompanyAndStatus", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } }, status: true } }).subscribe(
      (data: any) => {
        this.productFamilyList = data.object;
        // obtenemos la lista de kit de productos.
        this.session.getRequest("kindproduct:findAllByCompanyAndStatus", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } }, status: true } }).subscribe(
          (data: any) => {
            this.kindProductList = data.object.instanceList;
            if(this.kindProductList.length > 0){
              this.object.system.kindProduct.main.uuid = this.kindProductList[0].main.uuid;
            }
            // obtenemos la lista de marcas.
            this.session.getRequest("maker:getAll", {}).subscribe(
              (data: any) => {
                this.makerList = data.object.instanceList;
                // obtenemos la lista de unidades de medida.
                this.session.getRequest("unitMeasure:findAllByStatus", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } } }).subscribe(
                  (data: any) => {
                    this.unitMeasureList = data.object.instanceList;
                    // obtenemos la lista de productos que se puede aceptar como composicion
                    this.session.getRequest("kindProduct:findAllByCompanyAndCompositionAndStatus", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } } }, composition: true, status: true }).subscribe(
                      (data: any) => {
                        this.kindProductListFilter = data.object.instanceList;
                        for (let item of this.kindProductListFilter) {
                          item.system.selected = false;
                        }
                        // obtenemos la lista de caracteristicas.
                        this.session.getRequest("characteristic:findAllByCompanyAndStatus", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } }, status: true } }).subscribe(
                          (data: any) => {
                            this.characteristicList = data.object.instanceList;
                            this.loadingService.hide();
                          },
                          error => {
                            console.log("characteristic:findAllByCompanyAndStatus", error);
                            this.loadingService.hide();
                          });
                      },
                      error => {
                        console.log("kindProduct:findAllByCompanyAndCompositionAndStatus", error);
                        this.loadingService.hide();
                      });
                  },
                  error => {
                    console.log("unitMeasure:findAllByStatus", error);
                    this.loadingService.hide();
                  });
              },
              error => {
                console.log("maker:list", error);
                this.loadingService.hide();
              });
          },
          error => {
            console.log("kindproduct:findAllByCompany", error);
            this.loadingService.hide();
          });
      },
      error => {
        console.log("productFamily:findAllByCompany", error);
        this.loadingService.hide();
      });

      this.activatedRoute.params.subscribe((params: Params) => {
        if(params['uuid'] == 'new'){

        }else{
          // this.loadingService.wait();
           this.object.main.uuid = params['uuid'];
           // cargamos los datos de la BD
           this.session.getRequest('product:get',this.object).subscribe((data:any)=>{
             this.object = JSON.parse(JSON.stringify(data.object));
             this.metadata.productOrService.filter = this.object.complement.keySat;
             this.loadingService.hide();
           },
           (error)=>{
             this.loadingService.hide();
             console.log('Error:product:get',error)
           })

        }
      });
  }

  /*
   * Función para guardar un producto
   */
  send(object, goOut) {
    this.loadingService.wait();
    if (object.main.name != '' && object.main.code != '' && object.system.kindProduct.main.uuid != '' && object.system.productFamily.main.uuid != '' && object.system.maker.main.uuid != '' && object.system.unitMeasure.main.uuid != '') {
      object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
      this.session.getRequest("product:update", object).subscribe(
        (data: any) => {
          this.loadingService.hide();
          this.object.main.uuid = data.object.main.uuid;
          this.snackBar.open('El producto se guardo correctamente', '', {
            duration: 8000
          });
          if (goOut) {
            this.selectedIndex = 0;
            this.router.navigate(['/admin/product/list']);
          } else {
            this.getProductCharacteristicValue(1);
          }
        },
        error => {
          console.log("product:update", error);
          this.loadingService.hide();
        }
      );
    }
  }

  /**
   * Funcion para obtener el fabricante.
   */
  makerFindAllByNameLike(name) {
    this.object.system.maker.main.name = name.toUpperCase();
    this.makerListFilter = this.makerList.filter((item: any): any => {
      var newStrProperty = 'main.name';
      // var newItem1 = item;
      while (newStrProperty.indexOf(".") != -1) {
        var positionPoint = newStrProperty.indexOf(".");
        var stringProperty = newStrProperty.slice(0, positionPoint);
        newStrProperty = newStrProperty.slice(positionPoint + 1, newStrProperty.length);
        if (typeof (item[stringProperty]) == "object") {
          item = item[stringProperty];
        }
      }
      if (item.name.toUpperCase().indexOf(this.object.system.maker.main.name) != -1)
        return item;
    });
  }

  /**
   * Función para colocar el fabricante seleccionado
   */
  makerSeleted(object) {
    this.object.system.maker = object;
    this.makerListFilter = [];
  }

  /**
   * Función para eliminar la categoria de productos
   */
  deleteProductFamily(object) {
    this.object.system.productFamily.main.uuid = '';
    this.object.system.productFamily.main.code = '';
    this.object.system.productFamily.main.name = '';
  }

  /**
   * Función que obtiene el objecto seleccionado de la categoria de producto
   */
  onEvent(event) {
    this.object.system.productFamily.main.uuid = event.node.data.uuid;
    this.object.system.productFamily.main.name = event.node.data.name;
  }

  /*
  #########################
  CARACTERISTICAS
  #########################
  */
  /**
   * Funcion para obtener la caracteristica
   */
  characteristicFindAllByNameLike(name) {
    this.characteristicModel.main.name = name.toUpperCase();
    this.characteristicListFilter = this.characteristicList.filter((item: any): any => {
      var newStrProperty = 'main.name';
      while (newStrProperty.indexOf(".") != -1) {
        var positionPoint = newStrProperty.indexOf(".");
        var stringProperty = newStrProperty.slice(0, positionPoint);
        newStrProperty = newStrProperty.slice(positionPoint + 1, newStrProperty.length);
        if (typeof (item[stringProperty]) == "object") {
          item = item[stringProperty];
        }
      }
      if (item.name.toUpperCase().indexOf(this.characteristicModel.main.name) != -1)
        return item;
    });
  }

  /**
   * Función para colocar el fabricante seleccionado
   */
  characteristicSeleted(object) {
    // Limpiamos el modelo para otra caracteristica
    this.characteristicModel.main.name = '';
    // Asignamos la caracteristica al producto
    let productCharacteristic = Object.assign({}, this.productCharacteristicModel);
    productCharacteristic.system.product.main.uuid = this.object.main.uuid;
    productCharacteristic.system.characteristic.main.uuid = object.main.uuid;
    // Eliminamos la caracteristica del listado de caracteristicas del producto, porque solo puede tener una asignada el producto
    for (var i = 0; i < this.characteristicList.length; i++) {
      if (object.main.uuid === this.characteristicList[i].main.uuid) {
        this.characteristicList.splice(i, 1);
        break;
      }
    }
    this.loadingService.wait();
    this.session.getRequest("productCharacteristic:update", productCharacteristic).subscribe(
      (data: any) => {
        // creamos la relación producto-caracteristica y valor.
        let productCharacteristicValue = Object.assign({}, this.productCharacteristicValueModel);
        productCharacteristicValue.system.product = productCharacteristic.system.product;
        productCharacteristicValue.system.characteristic = productCharacteristic.system.characteristic;
        this.session.getRequest("productCharacteristicValue:create", productCharacteristicValue).subscribe(
          (data: any) => {
            this.loadingService.hide();
            this.productCharacteristicValueList.push(data.object);
            this.snackBar.open('Se asigno correctamente la caracteristica al producto', '', {
              duration: 8000
            });
          },
          error => {
            this.loadingService.hide();
            console.log("productCharacteristic:update", error);
          }
        );
      },
      error => {
        this.loadingService.hide();
        console.log("productCharacteristic:update", error);
      }
    );

    this.characteristicListFilter = [];
  }

  // Función para obtener las caracteristicas de un productos y su valor
  getProductCharacteristicValue(val: number) {
    if (this.object.main.uuid != '') {
      this.loadingService.wait();
      this.session.getRequest("productCharacteristicValue:findAllByProduct", { system: { product: { main: { uuid: this.object.main.uuid } } } }).subscribe(
        (data: any) => {
          this.loadingService.hide();
          this.productCharacteristicValueList = data.object.instanceList;
          // Eliminamos la caracteristica del listado de caracteristicas del producto, porque solo puede tener una asignada el producto
          for (var i = 0; i < this.characteristicList.length; i++) {
            for (var j = 0; j < data.object.instanceList.length; j++) {
              if (this.characteristicList[i].main.uuid === data.object.instanceList[j].system.characteristic.main.uuid) {
                this.characteristicList.splice(i, 1);
                break;
              }
            }
          }
          // Obtenemos los datos transitorios
          this.getProductTransientData();
        },
        error => {
          console.log("productCharacteristicValue:findAllByProduct", error);
          this.loadingService.hide();
        }
      );
    }
  }

  // Función para guadar caracteristicas de un productos y su valor
  updateProductCharacteristicValue(object) {
  this.loadingService.wait()
    this.session.getRequest("productCharacteristicValue:update", object).subscribe(
      (data: any) => {
        this.loadingService.hide();
        this.snackBar.open('Se asigno el valor de caracteristica al producto correctamente', '', {
          duration: 8000
        });
      },
      error => {
        this.loadingService.hide();
        console.log("productCharacteristicValue:update", error);
      });
  }

  // Función para eliminar caracteristicas de un productos y su valor
  deleteProductCharacteristicValue(object) {
  this.loadingService.wait();
    // Eliminamos el valor del producto caracteristica
    this.session.getRequest("productCharacteristicValue:delete", object).subscribe(
      (data: any) => {
        // Eliminamos la asociacion de producto con caracteristica
        this.session.getRequest("productCharacteristic:delete", object).subscribe(
          (data: any) => {
            this.loadingService.hide();
            this.snackBar.open('Se elimino la caracteristica del producto', '', {
              duration: 8000
            });
            for (var _i = 0; _i < this.productCharacteristicValueList.length; _i++) {
              if (object.system.characteristic.main.uuid == this.productCharacteristicValueList[_i].system.characteristic.main.uuid && object.main.value == this.productCharacteristicValueList[_i].main.value) {
                // Ingresamos la caracteristica al listado
                this.characteristicList.push(this.productCharacteristicValueList[_i].system.characteristic)
                this.productCharacteristicValueList.splice(_i, 1);
                break;
              }
            }
          },
          error => {
            this.loadingService.hide();
            console.log("productCharacteristic:update", error);
          }
        );
      },
      error => {
        this.loadingService.hide();
        console.log("productCharacteristicValue:delete", error);
      });
  }

  /*
  ##########################
  DATOS TRANSITORIOS
  ##########################
  */
  // funcion para asignar datos transitorios a  productos
  addTransientDataToProduct(itemTransientData) {
  this.loadingService.wait();
    let item = Object.assign({}, this.productTransientDataModel);
    item.system.product = this.object;
    item.system.transientData = itemTransientData;
    if (!itemTransientData.system.selected) {
      this.session.getRequest("productTransientData:update", item).subscribe(
        (data: any) => {
          this.loadingService.hide();
          // creamos la relación producto-transientData y valor.
          let productTransientDataValueTemp = Object.assign({}, this.productTransientDataValueModel);
          productTransientDataValueTemp.system.product = this.object;
          productTransientDataValueTemp.system.transientData = itemTransientData;
          itemTransientData.system.selected = true;
        },
        error => {
          console.log("productTransientData:update", error);
          this.loadingService.hide();
        }
      );
    } else {
      this.session.getRequest("productTransientData:delete", item).subscribe(
        (data: any) => {
          this.loadingService.hide();
          itemTransientData.system.selected = false;
        },
        error => {
          console.log("productTransientData:update", error);
          this.loadingService.hide();
        }
      );
    }
  }

  // funcion para obtener los datos transitorios de productos
  getProductTransientData() {
    // obtenemos la lista de datos transitorios.
    this.session.getRequest("transientdata:findAllByCompanyAndStatus", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } }, status: true } }).subscribe(
      (data: any) => {
        this.transientDataList = data.object.instanceList;
        for (let item of this.transientDataList) {
          item.system.selected = false;
        }
        // obtenemos la lista de datos transitorios asociados al producto.
        if (this.object.main.uuid != '') {
          this.session.getRequest("productTransientData:findAllByProduct", { system: { product: { main: { uuid: this.object.main.uuid } } } }).subscribe(
            (data: any) => {
              this.productTransientDataList = data.object.instanceList;
              //  colocamos el true los datos transitorios que ya tiene asignado el producto
              for (let item of this.productTransientDataList) {
                for (let item2 of this.transientDataList) {
                  if (item.system.transientData.main.uuid == item2.main.uuid) {
                    item2.system.selected = true;
                  }
                }
              }

            },
            error => {
              console.log("productTransientData:findAllByProduct", error);
            }
          );
        }
      },
      error => {
        console.log("transientdata:findAllByCompany", error);
      });
  }

  searchProductOrService() {
    if (this.metadata.productOrService.filter.length > 3) {
      this.metadata.productOrService.searchBoxInput.next(this.metadata.productOrService.filter);
    } else {
      this.productOrServiceList = [];
    }
  }

    /*
  function to get a product or service
  */
 getproductOrServiceList(val){
  this.metadata.productOrService.searching = true;
  this.session.getRequest('productOrService:list', { filter: { name: 'searchElement', value: val } }).subscribe(
    (data: any) => {
      this.productOrServiceList = data.object.instanceList;
      this.metadata.productOrService.searching = false;
    }, error => {
      console.log("productOrService:list", error);
      this.snackBar.open(error.message, 'Error', { duration: 5000 });
    })
}
productOrServiceSelected(pOS){
  // console.log(pOS);
  this.object.complement.keySat = pOS.main.code;
  this.productOrServiceList = [];
  this.metadata.productOrService.filter = pOS.main.code;
}

  /*
  funcion para controlar si se abre o cierra el menu
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
