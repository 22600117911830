import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-nurse-edit',
  templateUrl: './admin-nurse-edit.component.html',
  styleUrls: ['./admin-nurse-edit.component.css']
})
export class AdminNurseEditComponent implements OnInit {
  complexForm: FormGroup;
  roomOccupationModel = {
    main:{
      uuid:'',
      observation:'',
      status:'',
      subTotal:0.0,
      tax:0.0,
      total:0.0
    },
    complement:{
      cie10:'',
      typeOccupation:''
    },
    system:{
      specialistDoctor:{
        main:{
          uuid:''
        }
      },
      room:{
        main:{
          uuid:''
        }
      },
      patient:{
        main:{
          uuid:''
        }
      },
      detail:{
        main:{
          uuid:''
        }
      },
      doctor:{
        main:{
          uuid:''
        }
      }
    }
  }
  object = JSON.parse(JSON.stringify(this.roomOccupationModel));
  metadata = {
    doctor: {
      filter: {
        name: 'searchElement',
        value: '',
      },
      searchBoxInput: new Rx.Subject<string>(),
    },
    specialistDoctor: {
      filter: {
        name: 'searchElement',
        value: '',
      },
      searchBoxInput: new Rx.Subject<string>(),
    },
    doctorList: [],
    specialistDoctorList: [],
    date:''
  };
  constructor(
    private session: SessionService,
    public loadingService: LoadingService,
    public snackBar: MatSnackBar,
    private router: Router,
    public dialogRef: MatDialogRef<AdminNurseEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,public fb: FormBuilder) {
      this.metadata.doctor.searchBoxInput.debounceTime(700).switchMap(val => {
        this.metadata.doctorList = [];
        if (val == '') {
          this.metadata.doctor.filter.value = '';
          val = 'false';
        } else {
          this.getDoctors('Doctor');
        }
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged:');
        this.snackBar.open(error.message, 'Error', {duration: 5000});
        console.log(error);
      });

      this.metadata.specialistDoctor.searchBoxInput.debounceTime(700).switchMap(val => {
        this.metadata.specialistDoctorList = [];
        if (val == '') {
          this.metadata.specialistDoctor.filter.value = '';
          val = 'false';
        } else {
          this.getDoctors('Specialist doctor');
        }
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged:');
        this.snackBar.open(error.message, 'Error', {duration: 5000});
        console.log(error);
      });

      this.complexForm = fb.group({
        // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
        'typeOccupation': [null, Validators.required],
        'cie10': [null, Validators.required],
        'doctor': [null, Validators.required],
        'specialistDoctor': [null, Validators.required],
      })
     }

  ngOnInit() {
    this.object = JSON.parse(JSON.stringify(this.data.object));
    console.log('-----', this.object);
    // validamos si hay un dr de guardia asignado
    if (this.object.system.doctor.main.uuid == '' ) {
      this.metadata.doctor.filter.value = "SIN MEDICO DE GUARDIA";
    } else {
      this.metadata.doctor.filter.value = this.object.system.doctor.system.physicalPerson.main.name + ' ' + this.object.system.doctor.system.physicalPerson.main.surname + ' ' + this.object.system.doctor.system.physicalPerson.main.lastname;
    }
    // validamos si hay un dr especialista asignado
    if (this.object.system.specialistDoctor.main.uuid == '' ) {
      this.metadata.specialistDoctor.filter.value = "SIN MEDICO ESPECIALISTA";
    } else {
      this.metadata.specialistDoctor.filter.value = this.object.system.specialistDoctor.system.physicalPerson.main.name + ' ' + this.object.system.specialistDoctor.system.physicalPerson.main.surname + ' ' + this.object.system.specialistDoctor.system.physicalPerson.main.lastname;
    }
  }

  /**funcionalidad para obtener el roomOccupation
  */
  getRoomOccupation(uuid){
    this.loadingService.show(true,"Espere un momento...");
    this.object.main.uuid = uuid;
    this.session.getRequest("roomOccupation:get",this.object).subscribe((data:any)=>{
      this.object = JSON.parse(JSON.stringify(data.object));
      this.metadata.date = moment(this.object.system.detail.main.dateRegister).format('YYYY-MM-DDTHH:mm');
      this.object.system.detail.main.dateRegister = moment(this.object.system.detail.main.dateRegister).format('DD/MM/YYYY HH:mm:ss');
      if(this.object.system.doctor.main.uuid != ''){
        this.metadata.doctor.filter.value = this.object.system.doctor.system.physicalPerson.main.name + ' ' + this.object.system.doctor.system.physicalPerson.main.lastname + ' ' + this.object.system.doctor.system.physicalPerson.main.surname;
      }else{
        this.metadata.doctor.filter.value = 'SIN MEDICO DE GUARDIA';
      }

      if(this.object.system.specialistDoctor.main.uuid != ''){
        this.metadata.specialistDoctor.filter.value = this.object.system.specialistDoctor.system.physicalPerson.main.name + ' ' + this.object.system.specialistDoctor.system.physicalPerson.main.lastname + ' ' + this.object.system.specialistDoctor.system.physicalPerson.main.surname;
      }else{
        this.metadata.specialistDoctor.filter.value = 'SIN MEDICO DE GUARDIA';
      }


      this.loadingService.hide();
      console.log("roomOccupation:::::",this.object)
    },error=>{
      this.loadingService.hide();
      console.log("error:roomOccupation:get",error);
    })
  }

  /**
   * Obtiene los doctores de acuerdo a la palabra escrita por el usuario
   */
  getDoctors(role) {
    this.loadingService.wait();
    var filtering = {};
    if (role == 'Specialist doctor') {
      this.metadata.specialistDoctorList = [];
      filtering = this.metadata.specialistDoctor.filter;
    } else {
      this.metadata.doctorList = [];
      filtering = this.metadata.doctor.filter;
    }
    this.session.getRequest("collaborator:findAllByCompanyAndRole", {system: {role: role, company: {main: {uuid: this.session.getSessionObject().main.uuid} } }, filter: filtering }).subscribe(
      (data: any) => {
        if (role == 'Specialist doctor') {
          this.metadata.specialistDoctorList = data.object.instanceList;
        } else {
          this.metadata.doctorList = data.object.instanceList;
          if(data.object.instanceList.length > 0)
            this.metadata.doctorList.push({
                "main": {
                    "uuid": "-",
                    "specialty": ""
                },
                "system": {
                    "physicalPerson": {
                        "main": {
                            "uuid": "",
                            "name": "SIN",
                            "lastname": "MEDICO",
                            "surname": "DE GUARDIA"
                        },
                        "complement": {
                            "dateBirth": "1994-03-08T06:00:00Z",
                            "curp": null,
                            "rfc": null,
                            "gender": "Masculino"
                        },
                        "system": {
                            "status": false,
                            "address": null,
                            "contacts": []
                        }
                    },
                    "user": {
                        "main": {
                            "uuid": ""
                        }
                    },
                    "role": "Doctor",
                    "company": {
                        "main": {
                            "uuid": this.session.getSessionObject().main.uuid
                        }
                    },
                    "status": true
                }
            });
        }
        this.loadingService.hide();
      },
      error => {
        console.log("Error: collaborator:findAllByCompanyAndRole", error);
        this.loadingService.hide();
      });
  }

  /**
   * Funcion para buscar doctor
   */
  searchDoctor(value, role) {
    if (role == 'Specialist doctor') {
      this.metadata.specialistDoctor.searchBoxInput.next(value);
    } else {
      this.metadata.doctor.searchBoxInput.next(value);
    }
  }

  /**
   * Selecciona al doctor seleccionado
   */
  doctorSeleted(object, role) {
    console.log('que pdo', object);
    if (role == 'Doctor') {
      this.object.system.doctor = object;
      // this.metadata.doctor.filter.value = object.system.physicalPerson.main.name + ' ' + object.system.physicalPerson.main.lastname + ' ' + object.system.physicalPerson.main.surname;
      this.metadata.doctorList = [];
    } else {
      this.object.system.specialistDoctor = object;
      // this.metadata.specialistDoctor.filter.value = object.system.physicalPerson.main.name + ' ' + object.system.physicalPerson.main.lastname + ' ' + object.system.physicalPerson.main.surname;
      this.metadata.specialistDoctorList = [];
    }
  }

  send(){
    this.object.system.detail.main.date = moment().format('DD/MM/YYYY HH:mm:ss');
    this.object.system.detail.main.hour = moment().format('hh');
    this.object.system.detail.main.minute = moment().format('mm');
    this.object.system.detail.main.typeMovement = 'Entrada';
    this.object.system.detail.main.dateRegister = moment().format('DD/MM/YYYY HH:mm:ss');
    this.session.getRequest("roomOccupation:update", this.object).subscribe(
      (data: any) => {
        this.snackBar.open('Ok', 'El registro se guardo correctamente', {
          duration: 8000
        });
        this.loadingService.hide();
        this.close(true);
      }, error => {
        console.log("Error: roomOccupationDoctor:update", error);
        this.loadingService.hide();
      });

  }

  changeDate(){
    this.object.system.detail.main.date = moment(this.metadata.date).format('DD/MM/YYYY HH:mm:ss');
    this.object.system.detail.main.hour = moment(this.metadata.date).format('hh');
    this.object.system.detail.main.minute = moment(this.metadata.date).format('mm');
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'roomOccupation:001';
    }
    this.dialogRef.close(object);
  }

}
