import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';

@Component({
  selector: 'app-owner-expense',
  templateUrl: './owner-expense.component.html',
  styleUrls: ['./owner-expense.component.css']
})
export class OwnerExpenseComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;
  expenseClassificationModel = {
    main: {
      uuid: '',
      code: '',
      name: ''
    },
    complement: {
      description: '',
    },
    system: {
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      }
    }
  };
  expenseClassificationList = [];
  expenseMin = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      }
    },
    sort: 'mDateExpenditure',
    order: 'desc',
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }
  expenseList = [];
  expenseList2 = [];
  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100, 200, 500, 1000],
  };
  // MatPaginator Output
  pageEvent: PageEvent;
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object: {
      main: {
        uuid: ''
      },
      system: {
        startDate: moment().startOf('month').format('DD/MM/YYYY HH:mm:ss'),
        endDate: moment().endOf('month').format('DD/MM/YYYY HH:mm:ss'),
      }
    },
    dates: {
      startDate: moment().startOf('month').toISOString(),
      endDate: moment().endOf('month').toISOString(),
    },
    expenseClassification: {
      main: {
        uuid: '',
        code: '',
        name: ''
      },
    },
    totalExpenses:0
  }
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, private router: Router) {
    this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.expenseMin.filter.name = 'findAllByCompanyAndLikeName';
          this.getExpenseList();
        } else {
          this.expenseMin.filter.name = '';
          this.expenseMin.filter.value = '';
          val = 'false'
          this.getExpenseList();
        }
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged: ', error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
      });
   }

  ngOnInit() {
    this.changeTab(0);
  }

  /**Obtenemos la lista de gastos***/
  getExpenseList() {
    this.loadingService.wait();
    this.expenseList = [];
    // verificamos en que pagina verificamos
    this.expenseMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    // Vamos a traer la mitad de gastos generales y la otra mitad de gastos de caja chica
    this.expenseMin.max=this.expenseMin.max/2;
    this.expenseMin.sort = 'mDateExpenditure';
    this.expenseMin.order = 'desc';
    this.session.getRequest("spendingGeneral:list", this.expenseMin).subscribe(
      (data: any) => {
        this.expenseList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        this.expenseMin.sort = 'mDateExpenditure';
        this.expenseMin.order = 'desc';
        this.session.getRequest("spending:list", this.expenseMin).subscribe(
          (data: any) => {
            for (let x=0; x<data.object.instanceList.length; x++) {
              this.expenseList.push(data.object.instanceList[x]);
            }
            this.paginatorModel.total = this.paginatorModel.total + data.object.total;
            this.loadingService.hide();
          },
          error => {
            console.log("spending:list", error);
            this.loadingService.hide();
          });
      },
      error => {
        console.log("spendingGeneral:list", error);
        this.loadingService.hide();
      });
  }

  getExpenseClassificationList() {
    return new Promise((resolve, reject) => {
      // Se obtienen las clasificaciones de gastos
      this.session.getRequest("expenseClassification:findAllByCompany", {system: {company: {main: {uuid: this.session.getSessionObject().main.uuid} } } }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: { instanceList: data.object.instanceList } });
        },
        error => {
          console.log("expenseClassification:findAllByCompany", error);
      });
    });
  }

  /**
   * Indica el cambio de familia de producto
   */
  changeTab(tabSelect) {
    if (tabSelect == 0) {
      this.expenseClassificationList = [];
      this.getExpenseClassificationList().then((data: any) => {
        this.expenseClassificationList = data.object.instanceList;
        let expenseClassification = JSON.parse(JSON.stringify(this.expenseClassificationModel));
        expenseClassification.main.name = 'TODOS';
        this.metadata.expenseClassification.main.name = 'TODOS';
        this.expenseClassificationList.push(expenseClassification);
        this.selectedExpenseClassification('');
        if (this.expenseClassificationList.length == 0) {
          this.router.navigate(['/owner/expenseClassification/update/new']);
        }
      });
    } else {
      this.getExpenseList();
    }
  }

  selectedExpenseClassification(expenseClassificationUuid) {
    this.metadata.totalExpenses = 0;
    this.metadata.expenseClassification.main.uuid = expenseClassificationUuid;
    // obtenemos todos los gastos por el periodo y la nueva clasificación
    if (this.metadata.expenseClassification.main.uuid == '') {
      this.expenseList2 = [];
      for (let x=0; x<this.expenseClassificationList.length; x++) {
        if (this.expenseClassificationList[x].main.uuid != '') {
          this.findAllByExpenseClassificationAndDateBetween(this.expenseClassificationList[x], this.metadata.object.system.startDate, this.metadata.object.system.endDate).then((data: any) => {
            if (data.transaction == 'ok') {
              if (data.object.amount > 0) {
                this.metadata.totalExpenses = this.metadata.totalExpenses + data.object.amount;
                this.expenseList2.push(data.object);
              }
            }
          });
        }
      }
      // obtenemos los gastos de caja chica
      this.spendingFindAllByExpenseClassificationAndDateBetween('2c9fa81f7561194001756122af7d0020', this.metadata.object.system.startDate, this.metadata.object.system.endDate).then((data: any) => {
        if (data.transaction == 'ok') {
          if (data.object.amount > 0) {
            this.metadata.totalExpenses = this.metadata.totalExpenses + data.object.amount;
            this.expenseList2.push(data.object);
          }
        }
      });
    } else {
      this.expenseList2 = [];
      if (expenseClassificationUuid == '2c9fa81f7561194001756122af7d0020') {
        // obtenemos los gastos de caja chica
        this.spendingFindAllByExpenseClassificationAndDateBetween('2c9fa81f7561194001756122af7d0020', this.metadata.object.system.startDate, this.metadata.object.system.endDate).then((data: any) => {
          if (data.transaction == 'ok') {
            if (data.object.amount > 0) {
              this.metadata.totalExpenses = this.metadata.totalExpenses + data.object.amount;
              this.expenseList2.push(data.object);
            }
          }
        });
      } else {
        this.findAllByExpenseClassificationAndDateBetween({main: {uuid: expenseClassificationUuid}}, this.metadata.object.system.startDate, this.metadata.object.system.endDate).then((data: any) => {
          if (data.transaction == 'ok') {
            if (data.object.amount > 0) {
              this.metadata.totalExpenses = this.metadata.totalExpenses + data.object.amount;
              this.expenseList2.push(data.object);
            }
          }
        });
      }
    }
  }

  selectPeriond(event, period) {
    if (period === 'Inicial') {
      this.metadata.object.system.startDate = moment(event).format('DD/MM/YYYY HH:mm:ss');
    } else {
      this.metadata.object.system.endDate = moment(event).endOf('date').format('DD/MM/YYYY HH:mm:ss');
    }
    if (this.metadata.object.system.startDate <= this.metadata.object.system.endDate) {
      this.selectedExpenseClassification(this.metadata.expenseClassification.main.uuid);
    }
  }

  findAllByExpenseClassificationAndDateBetween(expenseClassification, startDate, endDate) {
    return new Promise((resolve, reject) => {
      // Se obtienen las clasificaciones de gastos
      this.session.getRequest("spendingGeneral:findByExpenseClassificationAndDateBetween", {system: {expenseClassification: expenseClassification}, main: {dateExpenditureStart: startDate, dateExpenditureEnd: endDate} }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object});
        },
        error => {
          console.log("Error: spendingGeneral:findByExpenseClassificationAndDateBetween", error);
      });
    });
  }

  spendingFindAllByExpenseClassificationAndDateBetween(expenseClassification, startDate, endDate) {
    return new Promise((resolve, reject) => {
      // Se obtienen las clasificaciones de gastos
      this.session.getRequest("spending:findByExpenseClassificationAndDateBetween", {system: {expenseClassification: {main: {uuid: expenseClassification}}}, main: {dateExpenditureStart: startDate, dateExpenditureEnd: endDate} }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object});
        },
        error => {
          console.log("Error: spending:findByExpenseClassificationAndDateBetween", error);
      });
    });
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event) {
    this.expenseMin.offset = (event.pageIndex * event.pageSize);
    this.expenseMin.max = event.pageSize;
    this.getExpenseList();
  }

  handleUpdated(event) {
    this.expenseMin.offset = (this.pageEvent ? this.pageEvent.pageIndex * this.pageEvent.pageSize : this.expenseMin.offset);
    this.getExpenseList();
  }

  /**
   * Funcion para buscar
   */
  search() {
    this.metadata.searchBoxInput.next(this.expenseMin.filter.value);
  }

  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
