import { Component, OnInit, Inject } from '@angular/core';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-nurse-point-sale-close-cash',
  templateUrl: './nurse-point-sale-close-cash.component.html',
  styleUrls: ['./nurse-point-sale-close-cash.component.css']
})
export class NursePointSaleCloseCashComponent implements OnInit {
  saleClinicModel={
    main:{
      uuid:'',
      total:0.0,
      cashFund:0.0,
      status:'Abierto',
      starDate:'',
      endDate:'',
      totalAttended: 0,
      totalReceived:0.0,
    },
    system:{
      collaborator:{
        main:{
          uuid:''
        }
      },
      otherIncome:{
        main:{
          uuid:''
        }
      }
    }
  }

  object = JSON.parse(JSON.stringify(this.saleClinicModel));

  constructor(public loadingService: LoadingService, private session: SessionService, public dialogRef: MatDialogRef<NursePointSaleCloseCashComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar) { }

  ngOnInit() {
    if(this.data != 'new') {
      // this.loadingService.wait();
      console.log('aqui', this.data)
      this.object.main.uuid = this.data;
      this.session.getRequest('saleClinic:get', {main:{uuid: this.object.main.uuid }}).subscribe(
        (data: any) => {
          this.object = data.object;
          this.loadingService.hide();
        }, error => {
          console.log('Error: saleClinic:get', error);
          this.loadingService.hide();
        });
    }
  }


  send(object) {
    this.loadingService.wait();
    this.session.getRequest('validate:findByUsername',  {system: {
        user: this.session.getUser()
      }}).subscribe(
      (data: any) => {
        object.system.collaborator.main.uuid = data.object.main.uuid;
        this.loadingService.wait();
        this.object.main.status = 'Cerrado';
        this.object.main.starDate = moment(this.object.main.starDate).format("DD-MM-YYYY hh:mm:ss");
        this.object.main.endDate = moment().format('DD-MM-YYYY hh:mm:ss');
        this.session.getRequest('saleClinic:update', this.object).subscribe(
          (data: any) => {
            this.object = data.object;
            this.loadingService.hide();
            this.close(true);
          }, error => {
            console.log('Error: saleClinic:update', error);
            this.loadingService.hide();
          });
        this.loadingService.hide();
      }, error => {
        console.log('Error: validate:findByUsername', error);
        this.loadingService.hide();
      });
  }

  /*
  * Cierra el modal
  */
  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'saleClinic:001';
    }
    this.dialogRef.close(object);
  }
}
