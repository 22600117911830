import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { SessionService } from '../../../service/session/session.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { LoadingService } from '../../../directive/loading/loading.module';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-admin-type-punishment-update',
  templateUrl: './admin-type-punishment-update.component.html',
  styleUrls: ['./admin-type-punishment-update.component.css']
})
export class AdminTypePunishmentUpdateComponent implements OnInit {

  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  complexForm: FormGroup;
  typePunishmentModel = {
    main: {
      uuid: '',
      name: '',
      daysSalary: '',
      incidenceRequestedWorker: false,
      colorGroup: ''
    },
    system: {
      status: true,
      company: {
        main: {
          uuid: ''
        }
      }
    }
  }
  object = JSON.parse(JSON.stringify(this.typePunishmentModel));
    constructor(public fb: FormBuilder, protected sessionService: SessionService, private router: Router, private activatedRoute: ActivatedRoute, public loadingService: LoadingService, public snackBar: MatSnackBar) {
      this.complexForm = fb.group({
        'name': [null, Validators.compose([Validators.required])],
        'daysSalary': [null, Validators.compose([Validators.required])],
        'colorGroup': [null, Validators.compose([Validators.required])],
      });
     }
  
    ngOnInit() {
      this.activatedRoute.params.subscribe((params: Params) => {
        if(params['uuid'] == 'new'){
          // no hacemos nada por el momento
        }else{
         this.loadingService.wait();
          this.object.main.uuid = params['uuid'];
          // cargamos los datos de la BD
          this.sessionService.getRequest('typePunishment:get',this.object).subscribe((data:any)=>{
            this.object = JSON.parse(JSON.stringify(data.object));
            this.loadingService.hide();
          },
          (error)=>{
            this.loadingService.hide();
            console.log('Error:typePunishment:get',error)
          })
  
        }
      });
    }
  
    send(object){
      object.system.company.main.uuid = this.sessionService.getSessionObject().main.uuid;
      this.loadingService.show(true,'Guardando registro');
      this.sessionService.getRequest('typePunishment:update',object).subscribe((data:any)=>{
        console.log("Registro Exitoso");
        this.loadingService.hide();
        this.snackBar.open('Registro guardado exitosamente', 'Información', {duration: 5000});
        this.router.navigate(['/admin/schedule/typePunishment']);
      },
      (error)=>{
        this.loadingService.hide();
        console.log('Error:typePunishment:update',error)
        this.snackBar.open('No se guardó el registro', 'Error', {duration: 5000});
      })
    }
  
    /*
    funcion para controlar si se abre o cierra el menu
    */
    toogleMenu(ev){
      this.drawerCtrl.opened = ev;
    }

}
