import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import * as moment from 'moment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';

@Component({
  selector: 'app-admin-treasury-other-income',
  templateUrl: './admin-treasury-other-income.component.html',
  styleUrls: ['./admin-treasury-other-income.component.css']
})
export class AdminTreasuryOtherIncomeComponent implements OnInit {

  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;
  otherIncomeClassificationModel = {
    main: {
      uuid: '',
      code: '',
      name: ''
    },
    complement: {
      description: '',
    },
    system: {
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      }
    }
  };
  otherIncomeClassificationList = [];
  otherIncomeMin = {
    system: {
      company: {
        main: {
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }
  otherIncomeList = [];
  otherIncomeList2 = [];
  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100, 200, 500, 1000],
  };
  // MatPaginator Output
  pageEvent: PageEvent;
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object: {
      main: {
        uuid: ''
      },
      system: {
        startDate: moment().clone().startOf('month').format('DD/MM/YYYY hh:mm:ss'),
        endDate: moment().clone().endOf('month').format('DD/MM/YYYY hh:mm:ss'),
      }
    },
    dates: {
      startDate: moment().clone().startOf('month').toISOString(),
      endDate: moment().clone().endOf('month').toISOString(),
    },
    otherIncomeClassification: {
      main: {
        uuid: '',
        code: '',
        name: ''
      },
    },
  }
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, private router: Router) {
    this.metadata.searchBoxInput.debounceTime(700)
      .switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          this.otherIncomeMin.filter.name = 'findAllByCompanyAndLikeName';
          this.getOtherIncomeList();
        } else {
          this.otherIncomeMin.filter.name = '';
          this.otherIncomeMin.filter.value = '';
          val = 'false'
          this.getOtherIncomeList();
        }
        return val;
      }).subscribe(results => {
        // Modificaciones sobre cada letra si se requiere
      }, error => {
        console.log('error logged: ', error);
        this.snackBar.open(error.message, 'Error', { duration: 5000 });
      });
   }

  ngOnInit() {
    this.changeTab(0);
  }

  /**Obtenemos la lista de gastos***/
  getOtherIncomeList() {
    this.loadingService.wait();
    this.otherIncomeList = [];
    // verificamos en que pagina verificamos
    this.otherIncomeMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("otherIncome:list", this.otherIncomeMin).subscribe(
      (data: any) => {
        console.log('----', data);
        this.otherIncomeList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
      },
      error => {
        console.log("otherIncome:list", error);
        this.loadingService.hide();
      });
  }

  getOtherIncomeClassificationList() {
    return new Promise((resolve, reject) => {
      // Se obtienen las clasificaciones de gastos
      this.session.getRequest("otherIncomeClassification:findAllByCompany", {system: {company: {main: {uuid: this.session.getSessionObject().main.uuid} } } }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: { instanceList: data.object.instanceList } });
        },
        error => {
          console.log("otherIncomeClassification:findAllByCompany", error);
      });
    });
  }

  /**
   * Indica el cambio de familia de producto
   */
  changeTab(tabSelect) {
    if (tabSelect.index == 1) {
      this.getOtherIncomeList();
    } else {
      this.otherIncomeClassificationList = [];
      this.getOtherIncomeClassificationList().then((data: any) => {
        this.otherIncomeClassificationList = data.object.instanceList;
        let otherIncomeClassification = JSON.parse(JSON.stringify(this.otherIncomeClassificationModel));
        otherIncomeClassification.main.name = 'TODOS';
        this.metadata.otherIncomeClassification.main.name = 'TODOS';
        this.otherIncomeClassificationList.push(otherIncomeClassification);
        this.selectedOtherIncomeClassification('');
        if (this.otherIncomeClassificationList.length == 0) {
          this.router.navigate(['/owner/otherIncomeClassification/update/new']);
        }
      });
    }
  }

  selectedOtherIncomeClassification(otherIncomeClassificationUuid) {
    this.metadata.otherIncomeClassification.main.uuid = otherIncomeClassificationUuid;
    // obtenemos todos los gastos por el periodo y la nueva clasificación
    if (this.metadata.otherIncomeClassification.main.uuid == '') {
      this.otherIncomeList2 = [];
      for (let x=0; x<this.otherIncomeClassificationList.length; x++) {
        if (this.otherIncomeClassificationList[x].main.uuid != '') {
          this.findAllByOtherIncomeClassificationAndDateBetween(this.otherIncomeClassificationList[x], this.metadata.object.system.startDate, this.metadata.object.system.endDate).then((data: any) => {
            if (data.transaction == 'ok') {
              if (data.object.amount > 0) {
                this.otherIncomeList2.push(data.object);
              }
            }
          });
        }
      }
    } else {
      this.otherIncomeList2 = [];
      this.findAllByOtherIncomeClassificationAndDateBetween({main: {uuid: otherIncomeClassificationUuid}}, this.metadata.object.system.startDate, this.metadata.object.system.endDate).then((data: any) => {
        if (data.transaction == 'ok') {
          if (data.object.amount > 0) {
            this.otherIncomeList2.push(data.object);
          }
        }
      });
    }
  }

  selectPeriond(event, period) {
    if (period === 'Inicial') {
      this.metadata.object.system.startDate = moment(event).format('DD/MM/YYYY hh:mm:ss');
    } else {
      this.metadata.object.system.endDate = moment(event).format('DD/MM/YYYY hh:mm:ss');
    }
    if (this.metadata.object.system.startDate <= this.metadata.object.system.endDate) {
      this.selectedOtherIncomeClassification(this.metadata.otherIncomeClassification.main.uuid);
    }
  }

  findAllByOtherIncomeClassificationAndDateBetween(otherIncomeClassification, startDate, endDate) {
    return new Promise((resolve, reject) => {
      // Se obtienen las clasificaciones de gastos
      this.session.getRequest("otherIncome:findByOtherIncomeClassificationAndDateBetween", {system: {otherIncomeClassification: otherIncomeClassification}, main: {dateExpenditureStart: startDate, dateExpenditureEnd: endDate} }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: data.object});
        },
        error => {
          console.log("Error: otherIncome:findByOtherIncomeClassificationAndDateBetween", error);
      });
    });
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event) {
    this.otherIncomeMin.offset = (event.pageIndex * event.pageSize);
    this.otherIncomeMin.max = event.pageSize;
    this.getOtherIncomeList();
  }

  handleUpdated(event) {
    this.otherIncomeMin.offset = (this.pageEvent ? this.pageEvent.pageIndex * this.pageEvent.pageSize : this.otherIncomeMin.offset);
    this.getOtherIncomeList();
  }

  /**
   * Funcion para buscar
   */
  search() {
    this.metadata.searchBoxInput.next(this.otherIncomeMin.filter.value);
  }
  
  /* funcionalidad para cambiar el menú
  */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
