import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import * as moment from 'moment';

@Component({
  selector: 'app-nurse-patient-move',
  templateUrl: './nurse-patient-move.component.html',
  styleUrls: ['./nurse-patient-move.component.css']
})
export class NursePatientMoveComponent implements OnInit {
  roomOccupation = {
    main: {},
    system: {
      room: {
        main:{
          uuid: ''
        }
      }
    }
  };
  floors = [];
  rooms = [];
  metadata = {
    floor: {
      main: {
        uuid: '',
        name: '',
        order: 0
      }
    },
    room: {
      main: {
        uuid: '',
        nameOrNumber: '',
        order: 0
      }
    }
  }
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<NursePatientMoveComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.roomOccupation = JSON.parse(JSON.stringify(data.object));
  }

  ngOnInit() {
    this.getFloor();
  }

  /**
   * Obtenemos la lista de pisos de la clinica
   */
  getFloor() {
    this.floors = [];
    this.findAllByCompany().then((data: any) => {
      this.floors = data.object.instanceList;
      this.metadata.floor = JSON.parse(JSON.stringify(data.object.instanceList[0]));
      this.findAllByFloor(this.metadata.floor.main.uuid);
    });
  }

  /**
   * Encuentra todos los pisos asociados a la empresa indicada
   */
  findAllByCompany() {
    return new Promise((resolve, reject) => {
      this.loadingService.show(true, "Obteniendo pisos, espere un momento...");
      this.session.getRequest("floor:findAllByCompany", { system: { company: { main: { uuid: this.session.getSessionObject().main.uuid } }, status: true } }).subscribe(
        (data: any) => {
          resolve({ transaction: 'ok', object: { instanceList: data.object.instanceList } });
          this.loadingService.hide();
        },
        error => {
          console.log("Error: floor:findAllByCompany", error);
          this.loadingService.hide();
        });
    });
  }

  /**
   * Selecciona el piso
   */
  findAllByFloor(uuid) {
    this.loadingService.show(true, "Obteniendo cuartos del piso, espere un momento...");
    this.session.getRequest("roomOccupation:findAllByFloorAndNotRoomOccupationAndNotWarehouse", { system: { floor: { main: { uuid: uuid } }, status: true } }).subscribe(
      (data: any) => {
        this.rooms = [];
        // Obtenemos la ocupación del cuarto
        for (let y = 0; y < data.object.instanceList.length; y++) {
          if (data.object.instanceList[y].main.uuid != this.roomOccupation.system.room.main.uuid) {
            this.rooms.push(data.object.instanceList[y]);
          }
        }
        this.loadingService.hide();
      },
      error => {
        console.log("Erro: roomOccupation:findAllByFloorAndNotRoomOccupationAndNotWarehouse", error);
        this.loadingService.hide();
      });
  }

  send(roomOccupation) {
    this.loadingService.show(true, "Espere un momento... se esta guardando el registro.");
    let newRoomOccupation = JSON.parse(JSON.stringify(roomOccupation));
    roomOccupation.main.status = 'Terminado';
    roomOccupation.system.detail.main.date = moment().format('DD/MM/YYYY HH:mm:ss');
    roomOccupation.system.detail.main.hour = moment().format('hh');
    roomOccupation.system.detail.main.minute = moment().format('mm');
    roomOccupation.system.detail.main.typeMovement = 'Salida';
    roomOccupation.system.detail.main.dateDeparture = moment().format('DD/MM/YYYY HH:mm:ss');
    roomOccupation.system.detail.main.dateRegister = moment(roomOccupation.system.detail.main.dateRegister).format('DD/MM/YYYY HH:mm:ss');
    roomOccupation.system.detail.system.user.main.uuid = this.session.getUser().main.uuid;
    roomOccupation.system.detail.system.user.main.objectType = 'Nurse';
    this.session.getRequest("roomOccupation:update", roomOccupation).subscribe(
      (data: any) => {
        this.loadingService.show(true, "Espere un momento... se esta guardando el registro.");
        newRoomOccupation.main.uuid = '';
        newRoomOccupation.main.status = 'Ocupado';
        newRoomOccupation.system.room = this.metadata.room;
        newRoomOccupation.system.detail.main.date = moment().format('DD/MM/YYYY HH:mm:ss');
        newRoomOccupation.system.detail.main.hour = moment().format('hh');
        newRoomOccupation.system.detail.main.minute = moment().format('mm');
        newRoomOccupation.system.detail.main.typeMovement = 'Entrada';
        newRoomOccupation.system.detail.main.dateRegister = moment().format('DD/MM/YYYY HH:mm:ss');
        newRoomOccupation.system.detail.system.user.main.uuid = this.session.getUser().main.uuid;
        newRoomOccupation.system.detail.system.user.main.objectType = 'Nurse';
        this.session.getRequest("roomOccupation:update", newRoomOccupation).subscribe(
          (data: any) => {
            this.roomOccupation = data.object;
            this.snackBar.open('Ok', 'El paciente se movió correctamente', {
              duration: 8000
            });
            this.loadingService.hide();
            this.dialogRef.close(this.roomOccupation);
          },
          error => {
            console.log("roomOccupation:update", error);
            this.loadingService.hide();
          });
      },
      error => {
        console.log("roomOccupation:update", error);
        this.loadingService.hide();
      });
  }
}
