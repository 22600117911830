import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { MatSnackBar } from '@angular/material';
import * as Rx from 'rxjs/Rx';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-service-catalog-update',
  templateUrl: './admin-service-catalog-update.component.html',
  styleUrls: ['./admin-service-catalog-update.component.css']
})
export class AdminServiceCatalogUpdateComponent implements OnInit {
  complexForm: FormGroup;
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  serviceCatalogModel = {
    main: {
      uuid: '',
      code: '',
      name: '',
      description: '',
      price: 0,
      tax: true
    },
    system: {
      status: true,
      company: {
        main: {
          uuid: ''
        }
      }
    }
  }
  object = JSON.parse(JSON.stringify(this.serviceCatalogModel));

  constructor(public fb: FormBuilder, protected session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, private router: Router, private activatedRoute: ActivatedRoute) {
    this.complexForm = fb.group({
      'code': [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(100)])],
      'name': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      'price': [null, Validators.compose([Validators.required])],
    })
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params['uuid'] == 'new'){
        // no hacemos nada por el momento
      }else{
        this.object.main.uuid = params['uuid'];
        // cargamos los datos de la BD
        this.loadingService.wait();
        this.session.getRequest('serviceCatalog:get',this.object).subscribe((data:any)=>{
          this.object = JSON.parse(JSON.stringify(data.object));
          this.loadingService.hide();
        },
        (error)=>{
          console.log('Error: serviceCatalog:get',error)
          this.loadingService.hide();
        })
      }
    });
  }

  send(object) {
    object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.loadingService.show(true, "Espere un momento... Se esta guardando el registro.")
    this.session.getRequest("serviceCatalog:update", object).subscribe(
      (data:any) => {
        object.main.uuid = data.object.main.uuid;
        this.snackBar.open('El registro se guardo correctamente', '', {
          duration: 8000
        });
        this.loadingService.hide();
        this.router.navigate(['/admin/serviceCatalog/list']);

      },
      error => {
        console.log("Error: serviceCatalog:update",error);
        this.loadingService.hide();
      }
    );
  }

  taxCharge(ev){
    this.object.main.tax = ev.checked;
  }

  statusCharge(ev){
    this.object.system.status = ev.checked;
  }

}
