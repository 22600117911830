import { Component, OnInit, Inject} from '@angular/core';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-owner-slope-tracking-details',
  templateUrl: './owner-slope-tracking-details.component.html',
  styleUrls: ['./owner-slope-tracking-details.component.css']
})
export class OwnerSlopeTrackingDetailsComponent implements OnInit {
  complexForm: FormGroup;
  slopeTracking = {
    main:{
      uuid:'',
      note:''
    },
    system:{
      doctor:{
        main:{
          uuid:''
        }
      },
      collaborator:{
        main:{
          uuid:''
        }
      },
      status:'Iniciada',
      createDate:''//dd/MM/yyyy hh:mm:ss
    }
  };
  metadata = {
    doctor:{
      main:{
        uuid:''
      }
    }
  }
  earringAnnotationModel = {
    main:{
      uuid:'',
      note:''
    },
    system:{
      collaborator:{
        main:{
          uuid:''
        }
      },
      slopeTracking:{
        main:{
          uuid:''
        }
      }
    }
  }

  feedbackModel = {
    main:{
      uuid:'',
      note:''
    },
    system:{
      doctor:{
        main:{
          uuid:''
        }
      },
      slopeTracking:{
        main:{
          uuid:''
        }
      }
    }
  }
  object = JSON.parse(JSON.stringify(this.feedbackModel));
  historyList = [];
  historyTemp = [];

  constructor(public fb: FormBuilder,
    private session: SessionService,
    public loadingService: LoadingService,
    public dialogRef: MatDialogRef<OwnerSlopeTrackingDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  async ngOnInit() {
    await this.getDoctorUUid();
    if(this.data != 'new') {
      this.loadingService.show(true,"Espere un momento..");
       // cargamos los datos de la BD
       this.session.getRequest('slopeTracking:get',{main:{uuid:this.data}}).subscribe((data:any)=>{
         this.slopeTracking = JSON.parse(JSON.stringify(data.object));
         this.object.system.slopeTracking.main = this.slopeTracking.main;
         this.object.system.doctor.main = this.slopeTracking.system.doctor.main;
         this.loadingService.hide();
         this.getEarringAnnotationAndFeedBack();
       },(error)=>{
         this.loadingService.hide();
         console.log('Error: slopeTracking:get',error)
       })
    }
  }


  getDoctorUUid(){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("validate:findByUsername", {
        system: {
          user: this.session.getUser()
        }
      }).subscribe(async(data: any) => {
        this.metadata.doctor.main.uuid = data.object.main.uuid;
        resolve(true)
      }, error=>{
        this.loadingService.hide();
        reject(error);
        console.log(error);
      });
    });
  }



  send(){
    this.loadingService.show(true,'Espere un momento...');
    console.log(this.object);
    this.session.getRequest('feedback:update', this.object).subscribe(
      (data:any)=>{
        this.close(true);
        this.loadingService.hide();
      },
      error => {
        console.log("feedback:update",error);
        this.loadingService.hide();
      })
  }
  collaboratorSelected(object){
    this.object.system.collaborator.main = object.main;
  }

  /**funcionalidad para obtener el historial de notas dejadas por el doctor y el colaborador.
  */
  async getEarringAnnotationAndFeedBack(){
    try{
      await this.getEarringAnnotation();
      // await this.getFeedback();
      this.historyList = this.historyTemp;
      console.log("historyList",this.historyList);
    }catch(e){
      console.log(e);
      this.loadingService.hide();
    }
  }

  /**funcionalidad para  obetner todas las anotaciones del
  */
  getEarringAnnotation(){
    return new Promise((resolve,reject)=>{
      this.earringAnnotationModel.system.slopeTracking.main = this.slopeTracking.main;
      this.session.getRequest("earringAnnotation:findAllBySlopeTracking",this.earringAnnotationModel).subscribe((data:any)=>{
        for(let item of data.object.instanceList){
          this.historyTemp.push(item);
        }
        resolve(true);
      },error=>{
        console.log("error:earringAnnotation:findAllBySlopeTracking",error)
        reject(error);
      })
    })
  }

  /**funcionalidad para  obetner todas las anotaciones del
  */
  getFeedback(){
    return new Promise((resolve,reject)=>{
      this.feedbackModel.system.slopeTracking.main = this.slopeTracking.main;
      this.session.getRequest("feedback:findAllBySlopeTracking",this.feedbackModel).subscribe((data:any)=>{
        for(let item of data.object.instanceList){
          this.historyTemp.push(item);
        }
        resolve(true);
      },error=>{
        console.log("error:earringAnnotation:findAllBySlopeTracking",error)
        reject(error);
      })
    })
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'customer:001';
    }
    this.dialogRef.close(object);
  }

}
