import { Component, OnInit, Inject } from '@angular/core';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { SessionService } from '../../../../service/session/session.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-petty-cash-open',
  templateUrl: './petty-cash-open.component.html',
  styleUrls: ['./petty-cash-open.component.css']
})
export class PettyCashOpenComponent implements OnInit {
  complexForm: FormGroup;
  pettyCashModel = {
    main: {
      uuid: '',
      openAmount: 0.0,
      closeAmount: 0.0,
      openDate: moment().format('DD/MM/YYYY HH:mm:ss'),
      closeDate: moment().format('DD/MM/YYYY HH:mm:ss'),
      open: false,
    },
    system: {
      company: {
        main: {
          uuid: this.session.getSessionObject().main.uuid
        }
      },
      openDoctor: {
        main: {
          uuid: ''
        },
        system: {
          user: {
            main: {
              uuid: this.session.getUser().main.uuid
            }
          }
        },
        spendings: [],
      },
      closeDoctor: {
        main: {
          uuid: ''
        },
        system: {
          user: {
            main: {
              uuid: this.session.getUser().main.uuid
            }
          }
        },
        spendings: [],
      },
    }
  }

  object=JSON.parse(JSON.stringify(this.pettyCashModel));
  constructor(public fb: FormBuilder, public dialogRef: MatDialogRef<PettyCashOpenComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) {
    this.complexForm = fb.group({
      'openAmount': [null, Validators.compose([Validators.required])]
    })
  }

  ngOnInit() {
  }

  send(object) {
    // pasamos la fecha de compleaño al formato que recibe al BD
    object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    object.main.openDate = moment().format('DD/MM/YYYY HH:mm:ss');

    this.session.getRequest("collaborator:findByUser", {system: {user: {main: {uuid: this.session.getUser().main.uuid}}}}).subscribe(
      (data:any) => {
        object.system.openDoctor = data.object;
        object.main.open = true;
        this.loadingService.show(true, "Espere un momento... Se esta guardando el registro.");
        object.main.closeAmount = object.main.openAmount;
        this.session.getRequest("pettyCash:update", object).subscribe(
          (data:any) => {
            object.main.uuid = data.object.main.uuid;
            this.snackBar.open('El registro se guardo correctamente', '', {
              duration: 8000
            });
            this.loadingService.hide();
            this.closeDialog(data.object);
          }, error => {
            console.log("Error: pettyCash:update",error);
            this.loadingService.hide();
          });
        }, error => {
          console.log("Error: collaborator:findByUser",error);
          this.loadingService.hide();
        });
  }

  /*
   * Cierra el modal
   */
  closeDialog(object) {
    this.dialogRef.close(object);
  }

}
