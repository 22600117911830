import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import * as Rx from 'rxjs/Rx';
import { LoadingService } from '../../directive/loading/loading.module';
import { AdminRoomServiceCatalogComponent } from '../admin-room/admin-room-service-catalog/admin-room-service-catalog.component';

@Component({
  selector: 'app-admin-zone',
  templateUrl: './admin-zone.component.html',
  styleUrls: ['./admin-zone.component.css']
})
export class AdminZoneComponent implements OnInit {

  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  zoneMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 1000,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  };
  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  // MatPaginator Output
  pageEvent: PageEvent;
  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  list = [];

  constructor(private session: SessionService, public dialog: MatDialog, public snackBar: MatSnackBar, public loadingService: LoadingService) {

  }

  ngOnInit() {
    // this.drawerCtrl.opened = true;
    this.getlist();
  }

  getlist(){
    this.loadingService.wait();
    this.list = [];
    // verificamos en que pagina verificamos
    this.zoneMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("floor:findAllByCompany", this.zoneMin).subscribe(
      (data: any) => {
        for(let item of data.object.instanceList){
          item.toggleList = false;
          item.system.rooms = [];
          this.list.push(item);
        }

        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
      },
      error => {
        console.log("floor:findAllByCompany", error);
        this.loadingService.hide();
      }
    );
  }

  /**
   * funcionalidad para obtener todos los cuartos de una zona
   */
  findRooms(object){
    if(object.toggleList){
      object.toggleList = false;
      object.system.rooms = [];
    }else{
      object.toggleList = true;
      object.system.rooms = [];
      this.loadingService.wait();
        // verificamos en que pagina verificamos
      let roomMin = {
        system:{
          floor:{
            main:{
              uuid: object.main.uuid
            }
          }
        },
        max: 10000,
        offset: 0,
        filter:{
          name:'',
          value: ''
        }
      };
        this.session.getRequest("room:findAllByFloor", roomMin).subscribe(
          (data: any) => {
            object.system.rooms = data.object.instanceList;
            this.loadingService.hide();
          },
          error => {
            console.log("room:findAllByCompany", error);
            this.loadingService.hide();
          }
        );
    }
  }

  /**
   * función para asignar cargo de servicios automaticos en las habitaciones
   */
  customService(object) {
    this.dialog.open(AdminRoomServiceCatalogComponent, {
      width: '70%',
      data: object,
    }).afterClosed().subscribe(result => {

    });
  }

  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
