import { Component, OnInit, Inject} from '@angular/core';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-owner-slope-tracking-update',
  templateUrl: './owner-slope-tracking-update.component.html',
  styleUrls: ['./owner-slope-tracking-update.component.css']
})
export class OwnerSlopeTrackingUpdateComponent implements OnInit {
  complexForm: FormGroup;
  slopeTrackingModel = {
    main:{
      uuid:'',
      note:''
    },
    system:{
      doctor:{
        main:{
          uuid:''
        }
      },
      collaborator:{
        main:{
          uuid:''
        }
      },
      status:'Iniciada',
      createDate:''//dd/MM/yyyy hh:mm:ss
    }
  };
  metadata = {
    searchBoxColaborator: new Rx.Subject<string>(),
    doctor:{
      main:{
        uuid:''
      }
    }
  }
  object = JSON.parse(JSON.stringify(this.slopeTrackingModel));
  collaboratorMin = {
    system:{
      role:'',
      company:{
        main:{
          uuid:''
        }
      },
      status:true,
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  }
  collaboratorList = [];
  constructor(public fb: FormBuilder,
    private session: SessionService,
    public loadingService: LoadingService,
    public dialogRef: MatDialogRef<OwnerSlopeTrackingUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.metadata.searchBoxColaborator.debounceTime(700)
        .switchMap(val => {
          // console.log('called once',val)
          if (val != '') {
            this.collaboratorList = [];
            this.collaboratorMin.filter.name = 'searchElement';
          } else {
            this.collaboratorList = [];
            this.collaboratorMin.filter.name = '';
            this.collaboratorMin.filter.value = '';
            val = 'false'
          }
          this.getColaborator();
          return val;
        }).subscribe(results => {
          // Modificaciones sobre cada letra si se requiere
        }, error => {
          console.log('error logged:');
          console.log(error);
        });
        this.complexForm = fb.group({
          'note': [null, Validators.compose([Validators.required])],
          'collaborator': [null, Validators.compose([Validators.required])],
        });
    }

  async ngOnInit() {
    await this.getDoctorUUid();
    if(this.data != 'new') {
      this.loadingService.show(true,"Espere un momento..");
       // cargamos los datos de la BD
       this.session.getRequest('slopeTracking:get',{main:{uuid:this.data}}).subscribe((data:any)=>{
         this.object = JSON.parse(JSON.stringify(data.object));
         this.collaboratorMin.filter.value = this.object.system.collaborator.system.physicalPerson.main.name + ' ' + this.object.system.collaborator.system.physicalPerson.main.lastname + ' ' + this.object.system.collaborator.system.physicalPerson.main.surname;
         this.loadingService.hide();
       },(error)=>{
         this.loadingService.hide();
         console.log('Error: slopeTracking:get',error)
       })
    }
  }

  getDoctorUUid(){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("validate:findByUsername", {
        system: {
          user: this.session.getUser()
        }
      }).subscribe(async(data: any) => {
        this.metadata.doctor.main.uuid = data.object.main.uuid;
        resolve(true)
      }, error=>{
        this.loadingService.hide();
        reject(error);
        console.log(error);
      });
    });
  }

  /**funcionalidad para buscar un colaboradores
  */
  searchColaborator(){
    this.metadata.searchBoxColaborator.next(this.collaboratorMin.filter.value);
  }

  getColaborator(){
    this.loadingService.show(true,'Espere un momento...');
    this.collaboratorMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest('collaborator:findAllByCompany', this.collaboratorMin).subscribe(
      (data:any)=>{
        this.collaboratorList = data.object.instanceList;
        this.loadingService.hide();
      },
      error => {
        console.log("collaborator:findAllByCompany",error);
        this.loadingService.hide();
      })
  }

  send(){
    this.loadingService.show(true,'Espere un momento...');
    console.log(this.object);
    this.object.system.createDate = moment().format('DD/MM/yyyy hh:mm:ss');
    this.object.system.doctor.main = this.metadata.doctor.main;
    this.session.getRequest('slopeTracking:update', this.object).subscribe(
      (data:any)=>{
        this.close(true);
        this.loadingService.hide();
      },
      error => {
        console.log("slopeTracking:update",error);
        this.loadingService.hide();
      })
  }
  collaboratorSelected(object){
    this.object.system.collaborator.main = object.main;
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'customer:001';
    }
    this.dialogRef.close(object);
  }
}
