import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { PageEvent, MatSnackBar } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import * as Rx from 'rxjs';
// import { LoadingService } from '../../../directive/loading/loading.module';
// import * as moment from 'moment';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;

  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };
  metadata = {
    active:true,
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }
  // MatPaginator Output
  pageEvent: PageEvent;
  productFamilyList = [];
  productFamilyMin = {
    system: {
      company:{
        main:{
          uuid:''
        }
      }
    },
    max: 10,
    offset: 0,
    filter: {
      name: '',
      value: ''
    }
  }
  productFamilyListActive = [];
  productFamilyListInactive = [];
  constructor(private session: SessionService, public snackBar: MatSnackBar) {
    this.productFamilyMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
  //   this.metadata.searchBoxInput.debounceTime(700)
  //   .switchMap(val => {
  //     // console.log('called once',val)
  //     if(val != ''){
  //       this.productFamilyList = [];
  //       this.productFamilyMin.filter.name = 'searchElement';
  //     }else{
  //       this.productFamilyList = [];
  //       this.productFamilyMin.filter.name = '';
  //       this.productFamilyMin.filter.value = '';
  //       val = 'false'
  //     }
  //     this.getProductFamilyList();
  //     return val;
  //   }).subscribe(results => {
  //     // Modificaciones sobre cada letra si se requiere
  //   }, error => {
  //   console.log('error logged:');
  //   this.snackBar.open(error.message, 'Error', {duration: 5000});
  //   console.log(error);
  // });
   }

  ngOnInit() {
    this.getProductFamilyList();
    // this.drawerCtrl.opened = true;
  }

  // funcion para obtener la lista de kit de productos
  getProductFamilyList() {
  // this.loadingService.wait();
    this.productFamilyList = [];
    // verificamos en que pagina verificamos
    this.productFamilyMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("productFamily:listByCompany", this.productFamilyMin).subscribe(
      (data: any) => {
        // this.productFamilyList = data.object.instanceList;
        for(let item of data.object.instanceList){
          if(item.system.status){
            this.productFamilyListActive.push(item);
          }else{
            this.productFamilyListInactive.push(item);
          }
        }
        if(this.metadata.active){
          this.productFamilyList = this.productFamilyListActive;
        }else{
          this.productFamilyList = this.productFamilyListInactive;
        }
        this.paginatorModel.total = data.object.total;
        // this.loadingService.hide();
      },
      error => {
        console.log("productFamily:listByCompany", error);
        // this.loadingService.hide();
      }
    );
  }

  /*
  Funcion para inactivar una kit de producto
  */
  disableACategory(object) {
    object.system.status = false;
    // this.loadingService.wait();
    this.session.getRequest("productFamily:update", object).subscribe(
      data => {
        this.snackBar.open('La categoria se desactivo correctamente', '', {
          duration: 8000
        });
        // this.loadingService.hide();
        for (var _i = 0; _i < this.productFamilyList.length; _i++) {
          if (object.main.uuid == this.productFamilyList[_i].main.uuid) {
            this.productFamilyList.splice(_i, 1);
            break;
          }
        }
      },
      error => {
        console.log("Erro: productFamily:update", error);
        // this.loadingService.hide();
      }
    );
  }

  /*
  Funcionalidad para colocar el objeto en el menu de opciones
  */
  objectSelected(object){
    for(let i=0; i < this.productFamilyList.length; i++){
      this.productFamilyList[i].system.selected = false;
    }
    if(object.main.uuid == this.metadata.object.main.uuid){
      object.system.selected = false;
      this.metadata.object.main.uuid = '';
    }else{
      object.system.selected = true;
      this.metadata.object = JSON.parse(JSON.stringify(object));;
    }
  }

  /*
    *Funcion para buscar
  */
  search(){
    this.metadata.searchBoxInput.next(this.productFamilyMin.filter.value);
  }
  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.productFamilyMin.offset = (event.pageIndex * event.pageSize);
    this.productFamilyMin.max = event.pageSize;
    this.getProductFamilyList();
  }

  handleUpdated(event) {
    this.productFamilyMin.offset = (this.pageEvent ? this.pageEvent.pageIndex * this.pageEvent.pageSize  : this.productFamilyMin.offset);
    this.getProductFamilyList();
  }
  changeTab(ev){
    switch(ev.tab.textLabel) {
       case "Activos": {
          //statements;
          this.metadata.active = true;
          this.productFamilyList = this.productFamilyListActive;
          break;
       }
       case "Inactivos": {
          //statements;
          this.metadata.active = false;
          this.productFamilyList = this.productFamilyListInactive;
          break;
       }
       default: {
          //statements;
          break;
       }
    }
  }
 /*
 funcion para controlar si se abre o cierra el menu
 */
 toogleMenu(ev){
   this.drawerCtrl.opened = ev;
 }

}
