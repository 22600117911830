import { Component, OnInit, Inject } from '@angular/core';
import { LoadingService } from '../../../../directive/loading/loading.module';
import { SessionService } from '../../../../service/session/session.module';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-doctor-nurse-patient-update',
  templateUrl: './doctor-nurse-patient-update.component.html',
  styleUrls: ['./doctor-nurse-patient-update.component.css']
})
export class DoctorNursePatientUpdateComponent implements OnInit {
  complexForm: FormGroup;
  physicalPerson = {
    main: {
      uuid: '',
      name: '',
      lastname: '',
      surname: ''
    },
    complement: {
      dateBirth: '',
      curp: '',
      rfc: '',
      gender: ''
    },
    system: {
      status:true,
    }
  };

  patientModel = {
    main: {
      uuid:''
    },
    system: {
      physicalPerson:this.physicalPerson,
      company: {
        main: {
          uuid:''
        }
      },
      status:true
    }
  };

  metadata = {
    dateBirth : null
  }

  object = JSON.parse(JSON.stringify(this.patientModel));
  constructor(public fb: FormBuilder, public dialogRef: MatDialogRef<DoctorNursePatientUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) {
    this.complexForm = fb.group({
      'name': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      'lastname': [null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(100)])],
      'surname': [null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(100)])],
      'dateBirth': [null, Validators.compose([Validators.required])],
      'gender': [null, Validators.compose([Validators.required])],
      'rfcP': [null, Validators.compose([Validators.pattern('[A-Za-z]{4}[0-9]{6}[A-Za-z0-9]{3}')])],
    })
  }

  ngOnInit() {
  }

  send(object) {
     // parseamos la fecha de compleaño al formato que recibe la BD
     object.system.physicalPerson.complement.dateBirth = moment(this.metadata.dateBirth).format("DD-MM-YYYY");
     object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
     this.loadingService.show(true, "Espere un momento... Estamos validando sui registro.");
     //se busca el paciente a registrar por su nombre, apellidos y fecha de nacimiento para validar si se encuentra registrado en el sistema.
     this.session.getRequest("physicalPerson:findByNameAndSurnameAndLastnameAndDateBirth", object.system.physicalPerson).subscribe(
       (data:any) => {
         if(data.transaction == 'ok'){
           this.snackBar.open('El paciente ya se encuentra registrado', '', {
             duration: 10000
           });
         }
         this.loadingService.hide();
         this.closeDialog(data.object);
       },
       error => {
         console.log("Error: physicalPerson:findByNameAndSurnameAndLastnameAndDateBirth",error);
         this.loadingService.show(true, "Espere un momento... Se esta guardando el registro.");
         this.session.getRequest("patient:update", object).subscribe(
           (data:any) => {
             object.main.uuid = data.object.main.uuid;
             this.snackBar.open('El registro se guardo correctamente', '', {
               duration: 8000
             });
             this.loadingService.hide();
             this.closeDialog(data.object);
           },
           error => {
             console.log("Error: patient:update",error);
             this.loadingService.hide();
           }
         );
         this.loadingService.hide();
       }
     );
  }

  /*
   * Cierra el modal
   */
  closeDialog(object) {
    this.dialogRef.close(object);
  }

}
