import { Component, OnInit, Inject } from '@angular/core';
// import { LoadingService } from '../../../../directive/loading/loading.module';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
// import { SessionService } from '../../../../service/session/session.module';
import * as moment from 'moment';

@Component({
  selector: 'app-assistant-room-occupation-detail',
  templateUrl: './assistant-room-occupation-detail.component.html',
  styleUrls: ['./assistant-room-occupation-detail.component.css']
})
export class AssistantRoomOccupationDetailComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AssistantRoomOccupationDetailComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar) { }

  ngOnInit() {
  }

}
