import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import * as moment from 'moment';

@Component({
  selector: 'app-specialist-dashboard-spendig-table',
  templateUrl: './specialist-dashboard-spendig-table.component.html',
  styleUrls: ['./specialist-dashboard-spendig-table.component.css']
})
export class SpecialistDashboardSpendigTableComponent implements OnInit {
  metadata = {
    totals:{
      expenses:0,
    },
    dates:{
      startDate:'',
      endDate:''
    }
  }
  officeExpenseMin = {
    main:{
      dateExpense:''
    },
    system:{
      doctor:{
        main:{
          uuid:''
        }
      }
    }
  }


  doctor = {
    main:{
      uuid:''
    }
  }
  officeExpenseList = [];

  constructor(
    private session: SessionService,
    public loadingService: LoadingService,
    public dialogRef: MatDialogRef<SpecialistDashboardSpendigTableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(this.data);
  }

  ngOnInit() {
    this.metadata.dates.startDate = this.data.startDate;
    this.metadata.dates.endDate = this.data.endDate;
    this.getDataDoctor().then((data)=>{
      this.doctor = JSON.parse(JSON.stringify(data));
      this.officeExpenseMin.system.doctor.main.uuid = this.doctor.main.uuid;
      this.getDatas();
    }).catch(e=>{
      console.log(e);
    })
  }

  /**funcionalidad para obtener los datos del doctor
  */
  getDataDoctor(){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("validate:findByUsername", {
        system: {
          user: this.session.getUser()
        }
      }).subscribe(async(data: any) => {
        console.log("validate:findByUsername",data);
        resolve(data.object);
      }, error=>{
        console.log(error);
        reject(error);
      });
    });
  }

  async getDatas(){
    try{
      this.loadingService.show(true,"Bucando resultados...");
      this.officeExpenseList = [];
      //funcionalidad para obtener los dias dentro del rango de fechas dado
      let days = moment(this.metadata.dates.endDate).diff(moment(this.metadata.dates.startDate),'days');
      let datesArrays = [];
      for(let i = 0; i <= days; i++){
        datesArrays.push(moment(this.metadata.dates.startDate).add(i,"days").format('DD/MM/YYYY hh:mm:ss'));
      }
      for(let items of datesArrays){
        await this.getSpendingList(items);
      }
      this.calculatetotals();
      this.loadingService.hide();
    }catch(e){
      this.loadingService.hide(),
      console.log(e);
    }
  };

  /**funcionalidad para obtener los gastos de una fecha
  */
  getSpendingList(date){
    return new Promise((resolve,reject)=>{
      let aux = JSON.parse(JSON.stringify(this.officeExpenseMin));
      aux.main.dateExpense = date;
      this.session.getRequest("officeExpense:findAllByDoctorAndDateExpense",aux).subscribe((data:any)=>{
        this.officeExpenseList = this.officeExpenseList.concat(data.object.instanceList);
        resolve(true);
      },error=>{
        reject(error);
        console.log(error);
      })
    });
  }

  /**función para calcular los totales del balance
  */
  calculatetotals(){
    this.metadata.totals.expenses = 0;
    for(let expense of this.officeExpenseList){
      this.metadata.totals.expenses+=(expense.main.amount*1)
    }
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = {};
    }else{
      object.transaction = 'bad';
      object.code = 'category:001';
    }
    this.dialogRef.close(object);
  }

}
