import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';
import { AdminRoomServiceCatalogComponent } from './admin-room-service-catalog/admin-room-service-catalog.component';
import * as moment from 'moment';
import * as Rx from 'rxjs/Rx';

@Component({
  selector: 'app-admin-room',
  templateUrl: './admin-room.component.html',
  styleUrls: ['./admin-room.component.css']
})
export class AdminRoomComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;

  roomMin = {
    system:{
      floor:{
        main:{
          uuid: ''
        }
      }
    },
    max: 10,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  };

  zoneMin = {
    system:{
      company:{
        main:{
          uuid: ''
        }
      }
    },
    max: 10000,
    offset: 0,
    filter:{
      name:'',
      value: ''
    }
  };

  floorList = [];

  roomList = [];

  pageEvent: PageEvent;

  paginatorModel = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100, 200, 500, 1000],
  };

  metadata = {
    searchBoxInput: new Rx.Subject<string>(),
    object:{
      main:{
        uuid:''
      }
    }
  }

  constructor(private session: SessionService, public snackBar: MatSnackBar, public dialog: MatDialog, public loadingService: LoadingService) {
    this.zoneMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
  }

   ngOnInit() {
     this.getlist();
     // this.drawerCtrl.opened = true;
   }

   /**
    * función para asignar cargo de servicios automaticos en las habitaciones
    */
   customService(object) {
     this.dialog.open(AdminRoomServiceCatalogComponent, {
       width: '70%',
       data: object,
     }).afterClosed().subscribe(result => {

     });
   }

   /**
    * funcion para obtener el listado de pisos
    */
   getlist(){
     this.loadingService.wait();
     this.floorList = [];
     // verificamos en que pagina verificamos
     this.zoneMin.system.company.main.uuid = this.session.getSessionObject().main.uuid;
     this.session.getRequest("floor:findAllByCompany", this.zoneMin).subscribe(
       (data: any) => {
         this.floorList = data.object.instanceList;
         this.loadingService.hide();
       },
       error => {
         console.log("floor:findAllByCompany", error);
         this.loadingService.hide();
       }
     );
   }

   /**
    * funcion para obtener la lista de kit de productos
    */
   getRoomList() {
     this.loadingService.wait();
     this.roomList = [];
     // verificamos en que pagina verificamos
     this.session.getRequest("room:findAllByFloor", this.roomMin).subscribe(
       (data: any) => {
         this.roomList = data.object.instanceList;
         this.paginatorModel.total = data.object.total;
         this.loadingService.hide();
       },
       error => {
         console.log("room:findAllByCompany", error);
         this.loadingService.hide();
       }
     );
   }

   /**
    * Funcionalidad para colocar el objeto en el menu de opciones
    */
   objectSelected(object){
     for(let i=0; i < this.roomList.length; i++){
       this.roomList[i].system.selected = false;
     }
     if(object.main.uuid == this.metadata.object.main.uuid){
       object.system.selected = false;
       this.metadata.object.main.uuid = '';
     }else{
       object.system.selected = true;
       this.metadata.object = JSON.parse(JSON.stringify(object));;
     }
   }

   /**
    * Funcion para buscar
    */
   search(){
     this.metadata.searchBoxInput.next(this.roomMin.filter.value);
   }

   /*
    * Funcion para obtener los datos del paginado.
    */
   onPaginateChange(event){
     this.roomMin.offset = (event.pageIndex * event.pageSize);
     this.roomMin.max = event.pageSize;
     this.getRoomList();
   }

   /**
    * Funcion para paginado
    */
   handleUpdated(event) {
     this.roomMin.offset = (this.pageEvent ? this.pageEvent.pageIndex * this.pageEvent.pageSize  : this.roomMin.offset);
     this.getRoomList();
   }
  /*
  funcion para controlar si se abre o cierra el menu
  */
  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
