import { Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.service';
import * as Rx from 'rxjs/Rx';
import { MatDrawer } from '@angular/material/sidenav';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-admin-survey-update',
  templateUrl: './admin-survey-update.component.html',
  styleUrls: ['./admin-survey-update.component.css']
})
export class AdminSurveyUpdateComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;

  complexForm: FormGroup;
  questionnaireModel = {
    main:{
      uuid:'',
      name:'',
      questionnaireType: '',
      description:'',
      position:''
    },
    complement:{
      classification:''
    },
    system:{
      object:'Company',
      objectUuid:''
    }
  }
  metedata = {
    survey:{
      main:{
        uuid:''
      }
    },
  };
  openQuestionModel = {
  	main: {
  		uuid: '',
  		question: '',
  		dataType: '',// Cadena, Fecha, Número
      position:'',
  	},
    complement:{
      classification:''
    },
  	system: {
  		// Cuestrionario al que pertenece la pregunta múltiple
      questionnaire: {
        main:{
          uuid:''
        }
      },
  	}
  };

  multipleOpenQuestionModel = {
  	main: {
  		uuid: '',
  		question: '',
      position:'',
      dataType: '',// Seleccion, Casilla, Desplegable, Lineal
  	},
    complement:{
      classification:''
    },
  	system: {
  		// Cuestrionario al que pertenece la pregunta múltiple
  		questionnaire: {
        main:{
          uuid:''
        }
      },
      multipleOpenAnswerList:[]
  	}
  };

  multipleOpenAnswerModel={
    main:{
      uuid:'',
      answer:'',
      value:''
    },
    system:{
      multipleOpenQuestion:{
        main:{
          uuid:''
        }
      }
    }
  }
  // arreglo que guarda los cuestionarios
  questionsList = [];
  metadata = {
    question:'',
    type:'',
    classification:'',
    object:JSON.parse(JSON.stringify(this.questionnaireModel)),
    linealStart:{
      answer:'',
      value:'0'
    },
    linealEnd:{
      answer:'',
      value:'2'
    }
  }
  object = JSON.parse(JSON.stringify(this.questionnaireModel));

  constructor(public fb: FormBuilder, private activatedRoute: ActivatedRoute, public snackBar: MatSnackBar, private session:SessionService, private router: Router, public loading: LoadingService) {
    this.complexForm = fb.group({
      'name': [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(100)])],
      'apply_object': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
      'instruction': [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
    })
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['uuid'] != 'new') {
        this.loading.show(true, "Espere un momento.");
        this.object.main.uuid = params['uuid'];
        // cargamos los datos de la BD
        this.session.getRequest('questionnaire:get',this.object).subscribe((data:any)=>{
          this.object = JSON.parse(JSON.stringify(data.object));
          this.findAllByQuestionnaire(this.object).then((data:any)=>{
            this.questionsList = data.instanceList;
          });
          this.loading.hide();
        },
        (error)=>{
          this.loading.hide();
          this.snackBar.open(error.message, 'Error', {duration: 5000});
          console.log('Error:survey:get',error)
        })

      }
    });
  }


  /** funcionalidad para guadar el cuestionario de un survey
  */
  saveQuestionnaire(){
    if(this.object.main.name != '' && this.object.main.description != ''){
      this.loading.show(true,"Guardando registro, espero un momento...");
      this.object.system.object = 'Company';
      this.object.system.objectUuid = this.session.getSessionObject().main.uuid;
      this.session.getRequest("questionnaire:update",this.object).subscribe((data:any)=>{
        this.object.main.uuid = data.object.main.uuid;
        this.snackBar.open('Registro creado correctamente', 'Informativo', {duration: 3000});
        this.loading.hide();
      },error=>{
        this.loading.hide();
        console.log("Error: questionnaire:update",error);
      });
    }
  };

  /** funcionalidad cagar el tipo de pregunta a guardar
  */
  selectedTypeQuestion(){

    switch(this.metadata.type){
      case 'Cadena':
      case 'Número':
      case 'Fecha':
      this.metadata.object = JSON.parse(JSON.stringify(this.openQuestionModel));
      break;
      case 'Seleccion':
      case 'Casilla':
      case 'Desplegable':
      this.metadata.object = JSON.parse(JSON.stringify(this.multipleOpenQuestionModel));
      // agregamos un respuesta inicial
      this.metadata.object.system.multipleOpenAnswerList.push(JSON.parse(JSON.stringify(this.multipleOpenAnswerModel)));
      break;
      case 'Lineal':
      this.metadata.object = JSON.parse(JSON.stringify(this.multipleOpenQuestionModel));
      this.metadata.linealStart.value = '0';
      this.metadata.linealEnd.value = '5';
      break;
      default:
      break;
    }
    // completamos el objeto
    this.metadata.object.system.questionnaire.main.uuid =  this.object.main.uuid;
    this.metadata.object.main.question = this.metadata.question;
    this.metadata.object.main.dataType = this.metadata.type;
    this.metadata.object.complement.classification = this.metadata.classification;
  }

  /** funcionalidad para agregar una pregunta al cuestionario
  */
  addQuestion(){
    let object:any = {};
    this.metadata.object.complement.classification = this.metadata.classification;
    switch(this.metadata.type){
      case 'Cadena':
      case 'Número':
      case 'Fecha':
        this.metadata.object.main.question = this.metadata.question;
        this.saveOpenQuestion(this.metadata.object).then((data:any) => {
          this.metadata.object.main.uuid = data.object.main.uuid;
          this.questionsList.push(this.metadata.object);
          this.cleanMetadata();
        });
      break;
      case 'Seleccion':
      case 'Casilla':
      case 'Desplegable':
        this.metadata.object.main.question = this.metadata.question;
        this.saveMultipleOpenQuestion(this.metadata.object).then((data:any) => {
          this.metadata.object.main.uuid = data.object.main.uuid;
          // recorremos las respuestas para guadarlas
          for(let i=0; i < this.metadata.object.system.multipleOpenAnswerList.length; i++ ){
            this.metadata.object.system.multipleOpenAnswerList[i].system.multipleOpenQuestion.main.uuid = this.metadata.object.main.uuid;
            this.saveMultipleOpenAnswer(this.metadata.object.system.multipleOpenAnswerList[i]).then((data:any) => {
              // this.metadata.object.system.multipleOpenAnswerList[i].main.uuid = data.object.main.uuid;
            });
          };
          this.questionsList.push(this.metadata.object);
          this.cleanMetadata();
        });
      break;
      case 'Lineal':
        this.metadata.object.main.question = this.metadata.question;
        this.saveMultipleOpenQuestion(this.metadata.object).then(async(data:any) => {
          this.metadata.object.main.uuid = data.object.main.uuid;
          // creamos los multipleOpenAnswer de acuerdo a la cantidad de objetos seleccionados
          // si hay algunos multipleOpenAnswerList entonces es un editar
          if(this.metadata.object.multipleOpenAnswerList.length == 0){
            let cont = 0;
            // console.log("Lineal Metadat",this.metadata);
            let linealStartValue = parseInt(this.metadata.linealStart.value);
            let linealEndValue = parseInt(this.metadata.linealEnd.value);

            while(linealStartValue <= linealEndValue){
              let multipleOpenAnswerAux = JSON.parse(JSON.stringify(this.multipleOpenAnswerModel));
              multipleOpenAnswerAux.system.multipleOpenQuestion.main.uuid = this.metadata.object.main.uuid;
              multipleOpenAnswerAux.main.value = linealStartValue;
              if(cont==0){
                multipleOpenAnswerAux.main.answer = this.metadata.linealStart.answer;
              }
              if(cont == linealEndValue){
                multipleOpenAnswerAux.main.answer = this.metadata.linealEnd.answer;
              }
              cont++;

              let itemAux:any = await this.saveMultipleOpenAnswer(multipleOpenAnswerAux);
              multipleOpenAnswerAux.main.uuid = itemAux.main.uuid;
              this.metadata.object.system.multipleOpenAnswerList.push(multipleOpenAnswerAux);
              linealStartValue++;
            }
            this.questionsList.push(this.metadata.object);
            this.cleanMetadata();
          }else{
            // verificamos cuantos objetos nuevos se agregaron
            let diff = parseInt(this.metadata.linealStart.value) - parseInt(this.metadata.linealEnd.value);
            if(diff == this.metadata.object.multipleOpenAnswerList.length){
              // no hacemos nada
            }else{
              if(diff > this.metadata.object.multipleOpenAnswerList.length){
                //creamos nuevo objetos
                let cont = 0;
                // console.log("Lineal Metadat",this.metadata);
                let linealStartValue = this.metadata.object.multipleOpenAnswerList.length;
                let linealEndValue = parseInt(this.metadata.linealEnd.value);

                while(linealStartValue <= linealEndValue){
                  let multipleOpenAnswerAux = JSON.parse(JSON.stringify(this.multipleOpenAnswerModel));
                  multipleOpenAnswerAux.system.multipleOpenQuestion.main.uuid = this.metadata.object.main.uuid;
                  multipleOpenAnswerAux.main.value = linealStartValue;
                  if(cont==0){
                    multipleOpenAnswerAux.main.answer = this.metadata.linealStart.answer;
                  }
                  if(cont == linealEndValue){
                    multipleOpenAnswerAux.main.answer = this.metadata.linealEnd.answer;
                  }
                  cont++;

                  let itemAux:any = await this.saveMultipleOpenAnswer(multipleOpenAnswerAux);
                  multipleOpenAnswerAux.main.uuid = itemAux.main.uuid;
                  this.metadata.object.system.multipleOpenAnswerList.push(multipleOpenAnswerAux);
                  linealStartValue++;
                }
                this.questionsList.push(this.metadata.object);
                this.cleanMetadata();
              }else{
                //eliminamos objetos
              }
            }
          }
        });
      break;
      default:
      break;
    }
  }

  /**funcion para guardar un pregunta abierta
  */
  saveOpenQuestion(object){
    return new Promise((resolve,reject)=>{
      this.loading.show(true,"Guardando registro, espero un momento...");
      // object.brewer.uuid = this.session.getSession().user.objectTypeUuid;
      this.session.getRequest("openQuestion:update",object).subscribe((data:any)=>{
        object.main.uuid = data.object.main.uuid;
        this.snackBar.open('Registro creado correctamente', 'Informativo', {duration: 5000});
        this.loading.hide();
        resolve({transaction: "ok", object: object});
      },error=>{
        this.loading.hide();
        reject(error);
        console.log("Error: openQuestion:update",error);
      });
    });
  }
  /**funcion para guardar un pregunta abierta
  */
  saveMultipleOpenQuestion(object){
    return new Promise((resolve,reject)=>{
      this.loading.show(true,"Guardando registro, espero un momento...");
      // object.brewer.uuid = this.session.getSession().user.objectTypeUuid;
      this.session.getRequest("multipleOpenQuestion:update",object).subscribe((data:any)=>{
        object.main.uuid = data.object.main.uuid;
        this.snackBar.open('Registro creado correctamente', 'Informativo', {duration: 5000});
        this.loading.hide();
        resolve({transaction:"ok", object:object});
      },error=>{
        this.loading.hide();
        reject(error);
        console.log("Error: multipleOpenQuestion:update",error);
      });
    });
  }

  /**funcion para guadar las posibles respuesta de preguntas multiples
  */
  saveMultipleOpenAnswer(object){
    return new Promise((resolve,reject)=>{
      this.loading.show(true,"Guardando registro, espero un momento...");
      // object.brewer.uuid = this.session.getSession().user.objectTypeUuid;
      this.session.getRequest("multipleOpenAnswer:update",object).subscribe((data:any)=>{
        object.main.uuid = data.object.main.uuid;
        this.snackBar.open('Registro creado correctamente', 'Informativo', {duration: 5000});
        this.loading.hide();
        resolve({transaction: "ok", object: object});
      },error=>{
        this.loading.hide();
        reject(error);
        console.log("Error: multipleOpenAnswer:update",error);
      });
    });
  }

  /** función para limpiar metadata
  */
  cleanMetadata() {
    this.metadata.question = '';
    this.metadata.type = '';
    this.metadata.classification = '';
    this.metadata.object = JSON.parse(JSON.stringify(this.questionnaireModel));
    this.metadata.linealStart.answer = '';
    this.metadata.linealStart.value = '0';
    this.metadata.linealEnd.answer = '';
    this.metadata.linealEnd.value = '2';
  }

  /**funcion para agregar una nueva opción
  */
  addOption(){
    this.metadata.object.system.multipleOpenAnswerList.push(JSON.parse(JSON.stringify(this.multipleOpenAnswerModel)));
  }
  /**función para eliminar na opción
  */
  removeOption(object){
    if (object.main.uuid != '') {
      // lo removemos de la bd
      this.loading.wait();
      this.session.getRequest("multipleOpenAnswer:delete",object).subscribe((data:any)=>{
        for(let i=0; i < this.metadata.object.system.multipleOpenAnswerList.length; i++){
          if(this.metadata.object.system.multipleOpenAnswerList[i].main.answer == object.main.answer){
            this.metadata.object.system.multipleOpenAnswerList.splice(i,1);
          }
        }
        this.loading.hide();
      },error=>{
        this.loading.hide();
        this.snackBar.open('Hubo un error al eliminar el regsitro intente nuevamente', 'Informativo', {duration: 5000});
        console.log("Error: multipleOpenAnswer:delete",error);
      })
    }else{
      for(let i=0; i < this.metadata.object.system.multipleOpenAnswerList.length; i++){
        if(this.metadata.object.system.multipleOpenAnswerList[i].main.answer == object.main.answer){
          this.metadata.object.system.multipleOpenAnswerList.splice(i,1);
        }
      }
    }
  }

  /**funcionalidad para obtener todas la preguntas de un cuestionario.
  */
  findAllByQuestionnaire(object) {
    return new Promise((resolve,reject)=>{
      this.session.getRequest("questionnaire:findAllByQuestion",{system:{questionnaire:{main:{uuid:object.main.uuid}}}}).subscribe((data:any)=>{
        this.questionsList = data.object.instanceList;
        // console.log("MMMMM")
        for(let i=0; i < data.object.instanceList.length; i++){
          if(data.object.instanceList[i].main.dataType == 'Seleccion' || data.object.instanceList[i].main.dataType == 'Casilla' || data.object.instanceList[i].main.dataType == 'Desplegable'|| data.object.instanceList[i].main.dataType == 'Lineal'){
            this.getAnswerIfMultipleOpenQuestion(data.object.instanceList[i]).then((data:any) => {
              // data.object.instanceList[i].system.multipleOpenAnswerList = data.instanceList;
            });
          }
        }
        resolve({ "transaction": 'ok', "instanceList": data.object.instanceList});
      },error=>{
        console.log("Error: questionnaire:findAllByQuestion",error);
        reject(error);
      })
    })
  }

  /**funcionalidad para obtener obtener multiple answer question de multipleopenquestion
  */
  getAnswerIfMultipleOpenQuestion(object){
    return new Promise((resolve,reject)=>{
      this.session.getRequest("multipleOpenAnswer:findAllByMultipleOpenQuestion",{system:{multipleOpenQuestion:{main:{uuid:object.main.uuid}}}}).subscribe((data:any)=>{
        object.system.multipleOpenAnswerList  = data.object.instanceList;
        resolve({ transaction: "ok", instanceList: data.object.instanceList});
      },error=>{
        reject(error);
        console.log("error:",error);
      })
    });
  }

  /**funcionalidad para editar una pregunta
  */
  editQuestion(object){
    console.log(object);
    this.metadata.type =  object.main.dataType;
    this.metadata.object = JSON.parse(JSON.stringify(object));
    this.metadata.classification = this.metadata.object.complement.classification;
    switch(this.metadata.type){
        case 'Cadena':
        case 'Número':
        case 'Fecha':
          this.metadata.question = this.metadata.object.main.question;
          for(let i =0; i < this.questionsList.length; i++){
              if(this.questionsList[i].main.uuid == this.metadata.object.main.uuid){
                this.questionsList.splice(i,1);
              }
          }
        break;
        case 'Seleccion':
        case 'Casilla':
        case 'Desplegable':
          this.metadata.question = this.metadata.object.main.question;
          for(let i =0; i < this.questionsList.length; i++){
              if(this.questionsList[i].main.uuid == this.metadata.object.main.uuid){
                this.questionsList.splice(i,1);
              }
          }
        break;
        case 'Lineal':
        this.metadata.question = this.metadata.object.main.question;
        this.metadata.linealStart.value = this.metadata.object.system.multipleOpenAnswerList[0].main.value;
        this.metadata.linealStart.answer = this.metadata.object.system.multipleOpenAnswerList[0].main.answer;

        this.metadata.linealEnd.answer = this.metadata.object.system.multipleOpenAnswerList[this.metadata.object.system.multipleOpenAnswerList.length-1].main.answer;
        this.metadata.linealEnd.value = this.metadata.object.system.multipleOpenAnswerList[this.metadata.object.system.multipleOpenAnswerList.length-1].main.value;
        for(let i =0; i < this.questionsList.length; i++){
            if(this.questionsList[i].main.uuid == this.metadata.object.main.uuid){
              this.questionsList.splice(i,1);
            }
        }
        break;
        default:
        break;
      }

  }

  updateQuestionaire(object){
    console.log(object);
    switch(object.main.dataType){
      case 'Seleccion':
      case 'Casilla':
      case 'Desplegable':
      case 'Lineal':
        this.saveMultipleOpenQuestion(object);
        break;
      case 'Cadena':
      case 'Número':
      case 'Fecha':
        this.saveOpenQuestion(object);
        break;
    }
  }


  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }
}
