import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";
import * as moment from "moment/moment";
import {SessionService} from "../../service/session/session.service";
import {LoadingService} from "../../directive/loading/loading.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-owner-motion-request',
  templateUrl: './owner-motion-request.component.html',
  styleUrls: ['./owner-motion-request.component.css']
})
export class OwnerMotionRequestComponent implements OnInit {

  motionRequestModel = {
    main: {
      uuid: '',
      reason: '',
      dateRequest: moment(),
      type: '',
      status: 'request'
    },
    system: {
      object: 'Sales',
      objectUuid: '',
      user: {
        main: {
          uuid: ''
        }
      },
      owner: {
        main: {
          uuid: ''
        }
      }
    }
  }
  password: '';
  metadata = {
    patient: ''
  }

  object = JSON.parse(JSON.stringify(this.motionRequestModel));
  constructor(protected session: SessionService, public loadingService: LoadingService, private activatedRoute: ActivatedRoute, public snackBar: MatSnackBar) {

  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.object.main.uuid = params['uuid'];
      this.loadingService.show(true, 'Espere un momento obteniendo la solicitud');
      this.session.getRequest("motionRequest:get", {main: {uuid: params['uuid']}}).subscribe((data:any)=>{
        this.object = data.object;
        if (this.object.system.object == 'Sales') {
          this.session.getRequest("sales:getAnonimous", {main: {uuid: this.object.system.objectUuid}}).subscribe((data:any)=>{
            this.metadata.patient = data.object.system.patient.system.physicalPerson.main.name + " " + data.object.system.patient.system.physicalPerson.main.lastname + " " + data.object.system.patient.system.physicalPerson.main.surname;
            this.loadingService.hide();
          },error=>{
            console.log("error", error);
            this.loadingService.hide();
          });
        }
        this.loadingService.hide();
      },error => {
        console.log("error", error);
        this.loadingService.hide();
      });
    });
  }

  send(password) {
    this.loadingService.show(true, 'Espere un momento obteniendo la solicitud');
    this.session.getRequestAnonymous("validate:validateOwner", {main: {password: password}, system: {owner: {main: {uuid: this.object.system.owner.main.uuid}}} }).subscribe((data:any)=>{
      console.log('1 --', data);
      if (data.object == 'true') {
        // Contraseña correcta, procedemos a eliminar la cuenta
        this.session.getRequestAnonymous("motionRequest:updateStatus", {main: {uuid: this.object.main.uuid, status: 'done'} }).subscribe((data:any)=>{
          if (data.object.main.type == 'delete') {
            this.snackBar.open('Se ha eliminado la cuenta correctamente', '', {
              duration: 10000
            });
          } else {
            this.snackBar.open('Se ha abierto la cuenta correctamente', '', {
              duration: 10000
            });
          }
        }, error => {
          console.log("Error: motionRequest:updateStatus", error);
          this.loadingService.hide();
        });
      } else {
        this.snackBar.open('La contreña ingresada no es correcta', '', {
          duration: 10000
        });
      }
      this.loadingService.hide();
    },error=>{
      console.log("Error: validate:validateOwner", error);
      this.loadingService.hide();
    })
  }

  cancel() {
    this.loadingService.show(true, 'Espere un momento obteniendo la solicitud');

        this.session.getRequestAnonymous("motionRequest:updateStatus", {main: {uuid: this.object.main.uuid, status: 'cancel'} }).subscribe((data:any)=>{
          this.snackBar.open('Se cancelo la solicitud correctamente', '', {
            duration: 10000
          });
        }, error => {
          console.log("Error: motionRequest:updateStatus", error);
          this.loadingService.hide();
        });

  }
}
