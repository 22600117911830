import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionService } from '../../../../service/session/session.module';
import { LoadingService } from '../../../../directive/loading/loading.module';
import * as moment from 'moment';
import { MatSnackBar} from '@angular/material/snack-bar';
@Component({
  selector: 'app-owner-clock-check-entry-exit-work-confirm-delete-checks',
  templateUrl: './owner-clock-check-entry-exit-work-confirm-delete-checks.component.html',
  styleUrls: ['./owner-clock-check-entry-exit-work-confirm-delete-checks.component.css']
})
export class OwnerClockCheckEntryExitWorkConfirmDeleteChecksComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<OwnerClockCheckEntryExitWorkConfirmDeleteChecksComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
  }
  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
    //  object.object = this.object;
    }else{
      object.transaction = 'bad';
      //object.code = 'dependentMethods:001';
    }
    this.dialogRef.close(object);
  }

}
