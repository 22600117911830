import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PageEvent, MatSnackBar, MatDialog } from '@angular/material';
import { SessionService } from '../../service/session/session.module';
import * as Rx from 'rxjs/Rx';
import { LoadingService } from '../../directive/loading/loading.module';
import { OwnerCashingByEmergenciesValidateComponent } from './owner-cashing-by-emergencies-validate/owner-cashing-by-emergencies-validate.component';
import { OwnerCashingByEmergenciesSettleComponent } from './owner-cashing-by-emergencies-settle/owner-cashing-by-emergencies-settle.component';

@Component({
  selector: 'app-owner-cashing-by-emergencies',
  templateUrl: './owner-cashing-by-emergencies.component.html',
  styleUrls: ['./owner-cashing-by-emergencies.component.css']
})
export class OwnerCashingByEmergenciesComponent implements OnInit {
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  saleClinicMin = {
    main: {
      status: ''
    },
    max:10,
    offset:0,
  }

  saleClinicList=[];

  paginatorModel = {
    total: 0,
    pageSizeOptions: [2, 5, 10, 25, 100, 200, 500, 1000],
  };
  pageEvent: PageEvent;

  constructor(private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, public dialog: MatDialog) { }

  ngOnInit() {
    this.getSaleClinic('Cerrado');
  }

  /**
   * Obtenemos las ventas por colaborador y estatus
   */
  getSaleClinic(status) {
    this.saleClinicList=[];
    this.saleClinicMin.main.status = status;
    this.session.getRequest('saleClinic:findAllByStatus', this.saleClinicMin).subscribe(
      (data: any) => {
        this.saleClinicList = data.object.instanceList;
        this.paginatorModel.total = data.object.total;
        this.loadingService.hide();
      }, error => {
        console.log('Error: saleClinic:findAllByStatus', error);
        this.loadingService.hide();
      });
  }

  /**
  * Modal para validar la caja
  */
    validateCash(uuid){
      console.log('object', uuid)
      const dialogRef = this.dialog.open(OwnerCashingByEmergenciesValidateComponent, {
        width: '50%',
        height:'30rem',
        data: uuid,
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined ){
          if(result.transaction == 'ok'){
            this.getSaleClinic('Cerrado');
            // El modal se cerro con objeto
           
          }else{
            // El modal se cerro sin objeto
           
          }
        }else{
          // El modal se cerro sin seleccionar algo, dandole click fuera
        }
      });
    }

  /**
  * Modal para settleCash la caja
  */
     settleCash(uuid){
      console.log('object', uuid)
      const dialogRef = this.dialog.open(OwnerCashingByEmergenciesSettleComponent, {
        width: '50%',
        height:'30rem',
        data: uuid,
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result != undefined ){
          if(result.transaction == 'ok'){
            this.getSaleClinic('Entrego');
            // El modal se cerro con objeto
           
          }else{
            // El modal se cerro sin objeto
           
          }
        }else{
          // El modal se cerro sin seleccionar algo, dandole click fuera
        }
      });
    }

    

  tabChange(ev) {
    console.log(ev.tab.textLabel);
    switch(ev.tab.textLabel){
      case 'Cerrado':{
        this.getSaleClinic('Cerrado');
        break;
      }
      case 'Entrego':{
        this.getSaleClinic('Entrego');
        break;
      }
      case 'Liquidado':{
        this.getSaleClinic('Liquidado');
        break;
      }
    }
  }
  
  // funcion para obtener los datos del paginado.
  onPaginateChange(event, status) {
    this.saleClinicMin.offset = (event.pageIndex * event.pageSize);
    this.saleClinicMin.max = event.pageSize;
    this.getSaleClinic(status);
  }

  toogleMenu(ev){
    this.drawerCtrl.opened = ev;
  }

}
