import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav'
import { MatSnackBar } from '@angular/material';
// import * as moment from 'moment'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SessionService } from '../../../../service/session/session.module';
// import { LoadingService } from '../../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-adjustment-type-update',
  templateUrl: './adjustment-type-update.component.html',
  styleUrls: ['./adjustment-type-update.component.css']
})
export class AdjustmentTypeUpdateComponent implements OnInit {
  complexForm: FormGroup;
  adjustmentTypeList = [];
  adjustmentTypeModel = {
    main: {
      uuid: '',
      description: '',
      typeMovement: ''
    },
    system: {
      company: {
        main: {
          uuid: ''
        }
      },
      status: true
    }
  };
  @ViewChild('drawer',{static:true})drawerCtrl: MatDrawer;
  object = JSON.parse(JSON.stringify(this.adjustmentTypeModel));
  constructor(public fb: FormBuilder, private session: SessionService, public snackBar: MatSnackBar, private router: Router, private activatedRoute: ActivatedRoute) {
    this.complexForm = fb.group({
      'description': [null, Validators.required]
    })
  }

  ngOnInit() {
    this.object.system.status = true;
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['uuid'] == 'new') {

      } else {
        // this.loadingService.wait();
        this.object.main.uuid = params['uuid'];
        // cargamos los datos de la BD
        this.session.getRequest('adjustmentType:get', this.object).subscribe((data: any) => {
          this.object = JSON.parse(JSON.stringify(data.object));
          // this.loadingService.hide();
        },
          (error) => {
            // this.loadingService.hide();
            console.log('Error:adjustmentType:get', error)
          })
      }
    });
  }
  send(object) {
    object.system.company.main.uuid = this.session.getSessionObject().main.uuid;
    this.session.getRequest("adjustmentType:update", object).subscribe(
      (data: any) => {
        this.snackBar.open('El registro se guardo correctamente', '', {
          duration: 8000
        });
        // this.loadingService.hide();
        this.router.navigate(['/admin/adjustmentType/list']);
      },
      error => {
        console.log("maker:update", error);
        // this.loadingService.hide();
      }
    );
  }
  /*
  funcion para controlar si se abre o cierra el menu
  */
  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }
}
