import {Component, OnInit, ViewChild} from '@angular/core';
import {PageEvent, MatSnackBar, MatDialog} from '@angular/material';
import {SessionService} from '../../../service/session/session.module';
import {LoadingService} from '../../../directive/loading/loading.service';
import * as Rx from 'rxjs/Rx';
import {MatDrawer} from '@angular/material/sidenav';
import {Router, ActivatedRoute, Params} from '@angular/router';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import {ChartType, ChartOptions, ChartDataSets} from 'chart.js';
import * as moment from "moment/moment";

@Component({
  selector: 'app-admin-survey-answer',
  templateUrl: './admin-survey-answer.component.html',
  styleUrls: ['./admin-survey-answer.component.css']
})
export class AdminSurveyAnswerComponent implements OnInit {
  @ViewChild('drawer', {static: true}) drawerCtrl: MatDrawer;

  questionnaireModel = {
    main: {
      uuid: '',
      name: '',
      questionnaireType: '',
      description: '',
      position: ''
    },
    complement: {
      classification: ''
    },
    system: {
      object: 'Company',
      objectUuid: ''
    }
  }
  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      position: 'left',
    },
    plugins: {
      labels: {
        render: 'percentage',
        fontColor: ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
        precision: 2
      }
    },
  };
  public pieChartColors = [{
    backgroundColor: [
      'rgba(0,128,128)', 'rgba(138,43,226)', 'rgba(128,0,128)', 'rgba(255,255,224)',
      'rgba(255,0,0,1)', 'rgba(0,255,200,1)', 'rgba(0,0,255,0.3)', 'rgba(245,135,51,1)',
      'rgba(171,95,36,1)', 'rgba(255,226,205,1)', 'rgba(51,215,245,1)', 'rgba(36,150,171,1)',
      'rgba(101,0,36,1)', 'rgba(133,100,2,1)', 'rgba(91,15,205,1)', 'rgba(244,100,171,1)',
      'rgba(211,0,96,1)', 'rgba(3,206,205,1)', 'rgba(151,0,205,1)', 'rgba(96,190,101,1)',
      'rgba(0,4,255,1)', 'rgba(3,0,205,1)', 'rgba(51,200,10,1)', 'rgba(96,2,0,1)',
      'rgba(1,3,105,1)', 'rgba(22,0,5,1)', 'rgba(101,90,200,1)', 'rgba(125,2,10,1)',
      'rgba(2,0,205,1)', 'rgba(22,150,5,1)', 'rgba(10,110,200,1)', 'rgba(175,2,110,1)',
      'rgba(201,190,10,1)', 'rgba(0,191,253)', 'rgba(186,85,211)', 'rgba(255,228,196)',
      'rgba(240,048,240)', 'rgba(240,255,240)', 'rgba(240,130,245)', 'rgba(238,130,238)',
      'rgba(224,255,255)', 'rgba(106,90,205)', 'rgba(139,69,19)',
    ]
  }];

  chartModel = {
    total: 0,
    chartType: 'pie',
    chartData: [30, 50, 20],
    chartLabels: ['UNO', 'DOS', 'TRES'],
    chartLegend: true,
    year: '',
    chartPlugins: [DataLabelsPlugin],
    chartColors: JSON.parse(JSON.stringify(this.pieChartColors)),
    chartOptions: JSON.parse(JSON.stringify(this.pieChartOptions))
  }

  questionsList = [];
  metadata = {
    total: 0,
    dates: {
      startDate: moment().clone().startOf('month').toISOString(),
      endDate: moment().clone().endOf('month').toISOString(),
    },
  }
  object = JSON.parse(JSON.stringify(this.questionnaireModel));

  constructor(private activatedRoute: ActivatedRoute, public snackBar: MatSnackBar, private session: SessionService, private router: Router, public loading: LoadingService) {

  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.loading.show(true, "Espere un momento.");
      this.object.main.uuid = params['uuid'];
      // cargamos los datos de la BD
      this.session.getRequest('questionnaire:get', this.object).subscribe((data: any) => {
        this.object = JSON.parse(JSON.stringify(data.object));
        this.findAllByQuestionnaire(this.object).then(async (data: any) => {
          // Ahora mandamos a obtener las respuestas por el periodo y la pregunta
          this.getDataByQuestion();
          this.loading.hide();
        }).catch(e => {
          console.log(e);
          this.loading.hide();
        });
      }, (error) => {
        this.loading.hide();
        this.snackBar.open(error.message, 'Error', {duration: 5000});
        console.log('Error:survey:get', error)
      });
    });
  }

  /**
   * funcionalidad para obtener todas la preguntas de un cuestionario.
   */
  findAllByQuestionnaire(object) {
    return new Promise((resolve, reject) => {
      this.session.getRequest("questionnaire:findAllByQuestion", {system: {questionnaire: {main: {uuid: object.main.uuid}}}}).subscribe(async (data: any) => {
        // Ordenamos por posición las preguntas
        let ordenados = data.object.instanceList.sort((a, b) => {
          if (a.main.position < b.main.position) {
            return -1;
          }
          if (a.main.position > b.main.position) {
            return 1;
          }
          // a debe ser igual b
          return 0;
        });
        // Cargamos el modelo de la grafica a mostrar
        for (let j = 0; j < ordenados.length; j++) {
          ordenados[j].chart = JSON.parse(JSON.stringify(this.chartModel));
        }
        this.questionsList = ordenados;
        resolve({"transaction": 'ok', "instanceList": data.object.instanceList});
      }, error => {
        console.log("Error: questionnaire:findAllByQuestion", error);
        reject(error);
      })
    })
  }

  getDataByQuestion() {
    for (let i= 0; i < this.questionsList.length; i++) {
      this.session.getRequest("survey:dataByQuestionnaireAndAnswerDateBetween", {
        main: {
          answerDateStart: moment(this.metadata.dates.startDate).format('DD/MM/YYYY'),
          answerDateEnd: moment(this.metadata.dates.endDate).format('DD/MM/YYYY'),
        },
        system: {
          questionnaire: {
            main: {
              uuid: this.questionsList[i].main.uuid
            }
          }        }
      }).subscribe((data:any)=>{
        if (this.questionsList[i].main.type == 'Múltiple') {
          this.questionsList[i].chart.chartData = [];
          this.questionsList[i].chart.chartLabels = [];
          for (let j=0; j<data.object.multipleOpenQuestionList.length; j++) {
            this.questionsList[i].chart.total = this.questionsList[i].chart.total + data.object.multipleOpenQuestionList[j].data;
            this.questionsList[i].chart.chartData.push(data.object.multipleOpenQuestionList[j].data);
            this.questionsList[i].chart.chartLabels.push(data.object.multipleOpenQuestionList[j].label);
          }
        }
        if (this.questionsList[i].main.type == 'Abierta') {
          console.log('2,b Resultado Abierta', data.object);
          this.questionsList[i].chart.chartData = [];
          this.questionsList[i].chart.chartLabels = [];
          this.questionsList[i].chart.total = data.object.openQuestionList.length;
          for (let j=0; j<data.object.openQuestionList.length; j++) {
            this.questionsList[i].chart.chartLabels.push(data.object.openQuestionList[j]);
          }
        }

      },error=>{
        console.log("Error: survey:dataByQuestionnaireAndAnswerDateBetween",error);
      })
    }
  }

  selectPeriond(event, period) {
    this.getDataByQuestion();
    if (period === 'Inicial') {
      //this.metadata.system.roomOccupationDetail.main.startDate = moment(event).format('DD/MM/YYYY hh:mm:ss');
    } else {
      //this.metadata.system.roomOccupationDetail.main.endDate = moment(event).format('DD/MM/YYYY hh:mm:ss');
    }
  }

  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }


}
