import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { ChartType, ChartData, ChartOptions, ChartDataSets, ChartConfiguration } from 'chart.js';
import { SingleDataSet, Label, Color, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import * as moment from 'moment';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';
import * as pluginLabels from 'chartjs-plugin-labels';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { OwnerMedicalConsultationConsultingIncomeTableComponent } from './owner-medical-consultation-consulting-income-table/owner-medical-consultation-consulting-income-table.component';
import { OwnerMedicalConsultationConsultingTableComponent } from './owner-medical-consultation-consulting-table/owner-medical-consultation-consulting-table.component';
import { OwnerMedicalConsultationSpendigTableComponent } from './owner-medical-consultation-spendig-table/owner-medical-consultation-spendig-table.component';

@Component({
  selector: 'app-owner-medical-consultation-dashboard',
  templateUrl: './owner-medical-consultation-dashboard.component.html',
  styleUrls: ['./owner-medical-consultation-dashboard.component.css']
})
export class OwnerMedicalConsultationDashboardComponent implements OnInit {
  @ViewChild('drawer', { static: false }) drawerCtrl: MatDrawer;

  metadata = {
    dates: {
      startDate: moment().clone().startOf('week').toISOString(),
      endDate: moment().clone().endOf('week').toISOString(),
    },
    system: {
      doctor: {
        main: {
          uuid: ''
        }
      },
    },
    totalConsulting: 0,
    totalCirugy: 0,
    totalEndoscopia: 0,
    totalIncome: 0,
    totalOtherIncome: 0,
    totalSpending: 0,
    totalPatient: 0
  }

  // Grafica de pastel
  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      position: 'top',
    },
    plugins: {
      labels: {
        render: 'percentage',
        fontColor: ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
        precision: 2
      }
    },
  };
  // Grafica de pastel
  public pieChartMaxOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      display: true,
      position: 'bottom',
    },
    plugins: {
      labels: {
        align: 'start',
        render: 'percentage',
        fontColor: ['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white'],
        precision: 2
      }
    },
  };
  public pieChartLabels: Label[] = [];
  public pieChartData: SingleDataSet = [];
  public pieChartConsultingLabels: Label[] = [];
  public pieChartDataConsulting: SingleDataSet = [];
  public pieChartCirugyLabels: Label[] = [];
  public pieChartDataCirugy: SingleDataSet = [];
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartColors = [{
    backgroundColor: [
      'rgba(0,4,255,1)', 'rgba(3,0,205,1)', 'rgba(51,200,10,1)', 'rgba(96,2,0,1)',
      'rgba(255,0,0,1)', 'rgba(0,255,200,1)', 'rgba(0,0,255,0.3)', 'rgba(245,135,51,1)',
      'rgba(171,95,36,1)', 'rgba(255,226,205,1)', 'rgba(51,215,245,1)', 'rgba(36,150,171,1)',
      'rgba(101,0,36,1)', 'rgba(133,100,2,1)', 'rgba(91,15,205,1)', 'rgba(244,100,171,1)',
      'rgba(211,0,96,1)', 'rgba(3,206,205,1)', 'rgba(151,0,205,1)', 'rgba(96,190,101,1)',
      'rgba(221,0,136,1)', 'rgba(3,206,255,1)', 'rgba(181,30,215,1)', 'rgba(116,201,131,1)',
      'rgba(1,3,105,1)', 'rgba(22,0,5,1)', 'rgba(101,90,200,1)', 'rgba(201,190,10,1)',
      'rgba(1,3,105,1)', 'rgba(202,8,5,1)', 'rgba(71,130,250,1)', 'rgba(241,250,50,1)',
      'rgba(2,0,205,1)', 'rgba(22,150,5,1)', 'rgba(10,110,200,1)', 'rgba(175,2,110,1)'
    ]
  }];
  //Grafica de barras
  public barChartLabels: Label[] = [];
  public barChartConsultingLabels: Label[] = [];
  public barChartCirugyLabels: Label[] = [];
  public barChartLegend = true;
  public barChartPlugins = [DataLabelsPlugin];
  public barChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      labels: {
        render: 'value',
        fontSize: 1,
        fontColor: []
      },
      datalabels: {
        backgroundColor:function(context) {
          let color:any= context.dataset.backgroundColor;
          return color;
        },
        borderRadius: 20,
        color: '#fff',
        font: {
          weight: 'bold',
        },
        formatter: Math.round,
        padding: 6
      }
    }
  };
  public barChartConsultingOptions: ChartOptions = {
    responsive: true,
    plugins: {
      labels: {
        render: 'value',
        fontSize: 2,
        fontColor: []
      }
    }
  };
  public barChartColors: Color[] = [
    { backgroundColor: '#cd7672' },
    { backgroundColor: '#534666' },
    { backgroundColor: '#1F618D' },
    { backgroundColor: '#138086' },
    { backgroundColor: 'rgba(181,30,215,1)' },
  ]
  public barChartData: ChartDataSets[] = [
    { data: [], label: 'Consultas' },
    { data: [], label: 'Canceladas' },
    { data: [], label: 'No asistió' },
    { data: [], label: 'Cirugías' },
  ];
  public barChartDataConsulting: ChartDataSets[] = [
    { data: [], label: 'Número de ocurrencias' }
  ];
  public barChartDataCirugy: ChartDataSets[] = [
    { data: [], label: 'Número de ocurrencias' }
  ];

  //Graficas para linea de tiempo de consultas
  consultinChart = {
    chartType:'line',
    chartData: [
      { data: [], label: 'Cirugías atendidas' },
      { data: [], label: 'Consultas Atendidas' },
      { data: [], label: 'Endoscopia' },
      { data: [], label: 'Total atendidos' }
    ],
    chartLabels:[],
    chartLegend:true,
    year:moment().format('YYYY')
  }

  // Objeto para almacenar la linea del tiempo de grafica balance del doctor
  balanceChart = {
    chartType:'line',
    chartData: [
      { data: [], label: 'Egresos' },
      { data: [], label: 'Utilidad' },
      { data: [], label: 'Ingresos' },
    ],
    chartLabels:[],
    chartLegend:true,
    year:moment().format('YYYY'),
    chartPlugins:[DataLabelsPlugin],
    chartColors:[
      { backgroundColor: '#EA5C2B' },
      { backgroundColor: '#35589A' },
      { backgroundColor: '#370665' },
      { backgroundColor: '#138086' },
      { backgroundColor: 'rgba(181,30,215,1)' },
    ],
    chartOptions:{
      responsive: true,
      plugins: {
        labels: {
          render: 'value',
          fontSize: 1,
          fontColor: []
        },
        datalabels: {
          backgroundColor:function(context) {
            let color:any= context.dataset.backgroundColor;
            return color;
          },
          borderRadius: 20,
          color: '#fff',
          font: {
            weight: 'bold',
          },
          formatter: function(value, context) {
              return '$ '+Math.round(value);
            },
          padding: 6
        }
      }
    },

  }


  years = [];

  constructor(private session: SessionService, public snackBar: MatSnackBar, public loadingService: LoadingService, public dialog: MatDialog) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  async ngOnInit() {
    this.createYears();
    this.pieChartPlugins = [pluginLabels];
    this.creatingChartConsultation();
    this.creatingChartBalancing();

    // Total de pacientes atendidos
    this.metadata.totalPatient = await this.countByDoctorAndStatusAndDateTimeAppointmentBetween('Atendida',this.metadata.dates.startDate,this.metadata.dates.endDate);

    // Total de citas en consultorio atendidas por Primera vez
    this.countByDoctorAndStatusAndTypeAppointmentAndDateTimeAppointmentBetween('Atendida', 'Primera vez');

    // Total de cirugias atendidas
    this.countByDoctorAndStatusAndTypeAppointmentAndDateTimeAppointmentBetween('Atendida', 'Cirugía');

    // Total de Endoscopia
    this.countByDoctorAndStatusAndTypeAppointmentAndDateTimeAppointmentBetween('Atendida', 'Endoscopia');

    // Obtenemos el total de ganancias por consulta, por periodo y doctor
    this.summaryConsultingRoomByDoctorAndStatusAndDateTimeAppointmentBetween('Atendida');

    // Obtenemos el total de otros ingresos por periodo y doctor
    this.summaryOtherIncomeByDoctorAndDateExpenditureBetween();

    // Obtenemos el total de gastos por periodo y doctor
    this.summaryExpenseByDoctorAndDateExpenditureBetween();

    // Obtenemos la grafica de diagnosticos por tipos de citas
    this.countByDoctorAndDateMedicalBetween();

    // Obtenemos la grafica de diagnosticos por tipos de citas
    this.countByDoctorAndDateRegisterBetween();

    // Obtenemos la grafica de barras por ocupación por semana
    this.findAllByStatusOrTypeOccupationAndDateBetween();

    // Obtenemos la grafica de pastel por tipo de cita
    this.findAllByDoctorAndTypeOccupationAndDateBetween();
  }

  findAllByDoctorAndTypeOccupationAndDateBetween() {
    // Obtenemos el número de pacientes por doctor especialista
    this.pieChartLabels = [];
    this.pieChartData = [];
    this.countByDoctorAndTypeOccupationAndDateBetween('Primera vez').then((object: any) => {
      if (object.object > 0) {
        this.pieChartLabels.push('Primera vez');
        this.pieChartData.push(object.object);
      }
    });
    this.countByDoctorAndTypeOccupationAndDateBetween('Endoscopia').then((object: any) => {
      if (object.object > 0) {
        this.pieChartLabels.push('Endoscopia');
        this.pieChartData.push(object.object);
      }
    });
    this.countByDoctorAndTypeOccupationAndDateBetween('Seguimiento').then((object: any) => {
      if (object.object > 0) {
        this.pieChartLabels.push('Seguimiento');
        this.pieChartData.push(object.object);
      }
    });
    this.countByDoctorAndTypeOccupationAndDateBetween('Cirugía').then((object: any) => {
      if (object.object > 0) {
        this.pieChartLabels.push('Cirugía');
        this.pieChartData.push(object.object);
      }
    });
  }

  /**
   * grafica para obtener díagnosticos por el tipo de cita
   */
  countByDoctorAndDateMedicalBetween() {
    this.loadingService.wait();
    this.session.getRequest("medicalNotesCIE10:countByDoctorAndDateMedicalBetweenAndTypeNote", { main: { dateStart: moment(this.metadata.dates.startDate).format('DD/MM/YYYY hh:mm:ss'), dateEnd: moment(this.metadata.dates.endDate).format('DD/MM/YYYY hh:mm:ss') }, system: { user: this.session.getUser() } }).subscribe((data: any) => {
      this.barChartDataConsulting = [];
      this.barChartConsultingLabels = [];
      for (let x = 0; x < data.object.instanceList.length; x++) {
        // Cargamos el diagnostico
        this.barChartConsultingLabels.push(data.object.instanceList[x].labels)
        let typeAppointments = JSON.parse(JSON.stringify(data.object.typeAppointmentList));
        // Recorremos los elemntos del tipo de cita
        for (let y = 0; y < data.object.instanceList[x].typeAppointments.length; y++) {
          // Valido si el tipo de cita ya esta ingresada en el arreglo
          let notExistTypeAppointment = true;
          for (let z = 0; z < this.barChartDataConsulting.length; z++) {
            if (this.barChartDataConsulting[z].label == data.object.instanceList[x].typeAppointments[y].label) {
              notExistTypeAppointment = false;
              // Ingresamos un elemento más al tipo de cita
              this.barChartDataConsulting[z].data.push(data.object.instanceList[x].typeAppointments[y].data);
              for (let k = 0; k < typeAppointments.length; k++) {
                if (typeAppointments[k] == data.object.instanceList[x].typeAppointments[y].label) {
                  typeAppointments.splice(k, 1);
                }
              }
            }
          }
          if (notExistTypeAppointment) {
            this.barChartDataConsulting.push({ data: [data.object.instanceList[x].typeAppointments[y].data], label: data.object.instanceList[x].typeAppointments[y].label });
            for (let k = 0; k < typeAppointments.length; k++) {
              if (typeAppointments[k] == data.object.instanceList[x].typeAppointments[y].label) {
                typeAppointments.splice(k, 1);
              }
            }
          }
        }
        // Ingresamos los datos faltantes en el arreglo
        for (let j = 0; j < typeAppointments.length; j++) {
          for (let k = 0; k < this.barChartDataConsulting.length; k++) {
            if (this.barChartDataConsulting[k].label == typeAppointments[j]) {
              this.barChartDataConsulting[k].data.push(0);
            }
          }
        }
      }
      this.loadingService.hide();
    }, error => {
      console.log("Error: medicalNotesCIE10:countByDoctorAndDateMedicalBetweenAndTypeNote", error);
      this.loadingService.hide();
    });
  }

  countByDoctorAndDateRegisterBetween() {
    this.loadingService.wait();
    this.session.getRequest("roomOccupation:countByDoctorAndDateRegisterBetween", { main: { dateStart: moment(this.metadata.dates.startDate).format('DD/MM/YYYY hh:mm:ss'), dateEnd: moment(this.metadata.dates.endDate).format('DD/MM/YYYY hh:mm:ss') }, system: { user: this.session.getUser() } }).subscribe((data: any) => {
      let values = [];
      let labels = [];
      for (let x = 0; x < data.object.instanceList.length; x++) {
        values.push(data.object.instanceList[x].value);
        labels.push(data.object.instanceList[x].data);
      }
      this.barChartDataCirugy[0].data = values;
      this.barChartCirugyLabels = labels;
      this.loadingService.hide();
    }, error => {
      console.log("Error: roomOccupation:countByDoctorAndDateRegisterBetween", error);
      this.loadingService.hide();
    });
  }

  summaryConsultingRoomByDoctorAndStatusAndDateTimeAppointmentBetween(status) {
    this.loadingService.wait();
    this.session.getRequest("consultingRoom:summaryByDoctorAndStatusAndDateTimeAppointmentBetween", { main: { status: status, dateStart: moment(this.metadata.dates.startDate).format('DD/MM/YYYY hh:mm:ss'), dateEnd: moment(this.metadata.dates.endDate).format('DD/MM/YYYY hh:mm:ss') }, system: { user: this.session.getUser() } }).subscribe((data: any) => {
      this.metadata.totalIncome = data.object;
      this.loadingService.hide();
    }, error => {
      console.log("Error: consultingRoom:summaryByDoctorAndStatusAndDateTimeAppointmentBetween", error);
      this.loadingService.hide();
    })
  }

  summaryOtherIncomeByDoctorAndDateExpenditureBetween() {
    this.loadingService.wait();
    this.session.getRequest("officeOtherIncome:summaryByDoctorAndDateExpenditure", { main: { dateStart: moment(this.metadata.dates.startDate).format('DD/MM/YYYY hh:mm:ss'), dateEnd: moment(this.metadata.dates.endDate).format('DD/MM/YYYY hh:mm:ss') }, system: { user: this.session.getUser() } }).subscribe((data: any) => {
      this.metadata.totalOtherIncome = data.object;
      this.loadingService.hide();
    }, error => {
      console.log("Error: officeOtherIncome:summaryByDoctorAndDateExpenditure", error);
      this.loadingService.hide();
    })
  }

  summaryExpenseByDoctorAndDateExpenditureBetween() {
    this.loadingService.wait();
    this.session.getRequest("officeExpense:summaryByDoctorAndDateExpense", { main: { dateStart: moment(this.metadata.dates.startDate).format('DD/MM/YYYY hh:mm:ss'), dateEnd: moment(this.metadata.dates.endDate).format('DD/MM/YYYY hh:mm:ss') }, system: { user: this.session.getUser() } }).subscribe((data: any) => {
      this.metadata.totalSpending = data.object;
      this.loadingService.hide();
    }, error => {
      console.log("Error: officeExpense:summaryByDoctorAndDateExpense", error);
      this.loadingService.hide();
    });
  }

  countByDoctorAndStatusAndDateTimeAppointmentBetween(status,startDate,endDate):Promise<number> {
    return new Promise((resolve,reject)=>{
      // this.loadingService.wait();
      this.session.getRequest("consultingRoom:countByDoctorAndStatusAndDateTimeAppointmentBetween", { main: { status: status, dateStart: moment(startDate).format('DD/MM/YYYY hh:mm:ss'), dateEnd: moment(endDate).format('DD/MM/YYYY hh:mm:ss') }, system: { user: this.session.getUser() } }).subscribe((data: any) => {
        // this.metadata.totalPatient = data.object;
        // this.loadingService.hide();
        resolve(data.object)
      }, error => {
        console.log("Error: consultingRoom:countByDoctorAndStatusAndDateTimeAppointmentBetween", error);
        reject(error);
        // this.loadingService.hide();
      })
    })

  }

  countByDoctorAndStatusAndTypeAppointmentAndDateTimeAppointmentBetween(status, typeAppointment) {
    this.loadingService.wait();
    this.session.getRequest("consultingRoom:countByDoctorAndStatusAndTypeAppointmentAndDateTimeAppointmentBetween", { main: { typeAppointment: typeAppointment, status: status, dateStart: moment(this.metadata.dates.startDate).format('DD/MM/YYYY hh:mm:ss'), dateEnd: moment(this.metadata.dates.endDate).format('DD/MM/YYYY hh:mm:ss') }, system: { user: this.session.getUser() } }).subscribe((data: any) => {
      if (typeAppointment == 'Cirugía') {
        this.metadata.totalCirugy = data.object;
      }
      if (typeAppointment == 'Primera vez' || typeAppointment == 'Seguimiento') {
        if (typeAppointment == 'Primera vez') {
          this.metadata.totalConsulting = data.object;
          // Total de citas en consultorio atendidas por Seguimiento
          this.countByDoctorAndStatusAndTypeAppointmentAndDateTimeAppointmentBetween('Atendida', 'Seguimiento');
        } else {
          this.metadata.totalConsulting = this.metadata.totalConsulting + data.object;
        }
      }
      if (typeAppointment == 'Endoscopia') {
        this.metadata.totalEndoscopia = data.object;
      }
      this.loadingService.hide();
    }, error => {
      console.log("Error: consultingRoom:countByDoctorAndStatusAndTypeAppointmentAndDateTimeAppointmentBetween", error);
      this.loadingService.hide();
    })
  }

  findAllByStatusOrTypeOccupationAndDateBetween() {
    this.loadingService.wait();
    this.session.getRequest("consultingRoom:findAllByStatusOrTypeOccupationAndDateBetween", { main: { dateStart: moment(this.metadata.dates.startDate).format('DD/MM/YYYY hh:mm:ss'), dateEnd: moment(this.metadata.dates.endDate).format('DD/MM/YYYY hh:mm:ss') }, system: { user: this.session.getUser() } }).subscribe((data: any) => {
      this.barChartData = [];
      this.barChartData.push({ data: data.object.primeraVezYSeguimiento, label: 'Consultos' });
      this.barChartData.push({ data: data.object.cancelado, label: 'Cancelada' });
      this.barChartData.push({ data: data.object.noAsistieron, label: 'No asistió' });
      this.barChartData.push({ data: data.object.cirugiaAtendida, label: 'Cirugía' });

      this.barChartLabels = data.object.patients;
      this.loadingService.hide();
    }, error => {
      console.log("Error: consultingRoom:findAllByStatusOrTypeOccupationAndDateBetween", error);
      this.loadingService.hide();
    })
  }

  countByDoctorAndTypeOccupationAndDateBetween(typeOccupation) {
    return new Promise((resolve, reject) => {
      this.loadingService.wait();
      this.session.getRequest("consultingRoom:countByDoctorAndTypeOccupationAndDateBetween", { main: { typeOccupation: typeOccupation, dateStart: moment(this.metadata.dates.startDate).format('DD/MM/YYYY hh:mm:ss'), dateEnd: moment(this.metadata.dates.endDate).format('DD/MM/YYYY hh:mm:ss') }, system: { user: this.session.getUser() } }).subscribe((data: any) => {
        resolve({ transaction: 'ok', object: data.object });
        this.loadingService.hide();
      }, error => {
        console.log("Error: consultingRoom:countByDoctorAndTypeOccupationAndDateBetween", error);
        this.loadingService.hide();
      });
    });
  }

  async selectPeriond(event, period) {
    if (period === 'Inicial') {
      this.metadata.dates.startDate = moment(event).toISOString();
    } else {
      this.metadata.dates.endDate = moment(event).toISOString();
    }

    if (this.metadata.dates.startDate <= this.metadata.dates.endDate) {
      // Total de pacientes atendidos
      this.metadata.totalPatient = await this.countByDoctorAndStatusAndDateTimeAppointmentBetween('Atendida',this.metadata.dates.startDate,this.metadata.dates.endDate);

      // Total de citas en consultorio atendidas por Primera vez
      this.countByDoctorAndStatusAndTypeAppointmentAndDateTimeAppointmentBetween('Atendida', 'Primera vez');

      // Total de cirugias atendidas
      this.countByDoctorAndStatusAndTypeAppointmentAndDateTimeAppointmentBetween('Atendida', 'Cirugía');

      // Total de Endoscopia
      this.countByDoctorAndStatusAndTypeAppointmentAndDateTimeAppointmentBetween('Atendida', 'Endoscopia');

      // Obtenemos el total de ganancias por consulta, por periodo y doctor
      this.summaryConsultingRoomByDoctorAndStatusAndDateTimeAppointmentBetween('Atendida');

      // Obtenemos la grafica de diagnosticos por tipos de citas
      this.countByDoctorAndDateMedicalBetween();

      // Obtenemos la grafica de diagnosticos por cirugias
      this.countByDoctorAndDateRegisterBetween();

      // Obtenemos el total de otros ingresos por periodo y doctor
      this.summaryOtherIncomeByDoctorAndDateExpenditureBetween();

      // Obtenemos el total de gastos por periodo y doctor
      this.summaryExpenseByDoctorAndDateExpenditureBetween();

      // Obtenemos la grafica de barras por ocupación por semana
      this.findAllByStatusOrTypeOccupationAndDateBetween();

      // Obtenemos la grafica de pastel por tipo de cita
      this.findAllByDoctorAndTypeOccupationAndDateBetween();
    }
  }

  showModalOtherIncome(startDate, endDate) {
    this.dialog.open(OwnerMedicalConsultationConsultingIncomeTableComponent, {
      width: '80%',
      height: '90%',
      data: { startDate: startDate, endDate: endDate }
    }).afterClosed().subscribe(result => {
      if (result) {
        if (result.transaction == 'ok') {

        }
      }
    });
  }
  showModalConsultingIncome(startDate, endDate) {
    this.dialog.open(OwnerMedicalConsultationConsultingTableComponent, {
      width: '80%',
      height: '90%',
      data: { startDate: startDate, endDate: endDate }
    }).afterClosed().subscribe(result => {
      if (result) {
        if (result.transaction == 'ok') {

        }
      }
    });
  }

  showModalspending(startDate, endDate) {
    this.dialog.open(OwnerMedicalConsultationSpendigTableComponent, {
      width: '80%',
      height: '90%',
      data: { startDate: startDate, endDate: endDate }
    }).afterClosed().subscribe(result => {
      if (result) {
        if (result.transaction == 'ok') {

        }
      }
    });
  }

  /**funcionalidad para crear la grafica de linea de tiempo de consultas
  */
  async creatingChartConsultation(){
    try{
      this.consultinChart.chartLabels = [];
      for(let i = 0; i < this.consultinChart.chartData.length; i++){
        this.consultinChart.chartData[i].data = [];
      }

      let index = 0;
      let end = moment().locale('es-mx').format('M');
      // let endNumber = 11;
      //creamos los labels del mes
      while (index < 12) {
        let month = moment().locale('es-mx').set('month',index).format('MMMM');
        console.log(month);
        this.consultinChart.chartLabels.push(month);
        index++;
      }

      // Generamos las consultas para lo contadores
      index = 0;
      while(index < 12){
        let year:any = this.consultinChart.year;
        // let startMonth = moment().set('day',1).set('months',index).set('year',year).toISOString();
        // let endMonth = moment().set('day',1).set('months',index+1).subtract(1,'day').set('year',year).toISOString();
        let startMonth  = new Date(year, index, 1).toISOString();
        let endMonth =  new Date((new Date(year, index+1, 1)).setDate((new Date(year, index+1, 1)).getDate() - 1)).toISOString();
        // console.log(index);
        // console.log(startMonth,endMonth)
        let dataValue = await this.countByDoctorAndStatusAndDateTimeAppointmentBetween('Atendida',startMonth,endMonth);
        this.consultinChart.chartData[3].data.push(dataValue);
        // Total de citas en consultorio atendidas por Primera vez y Seguimiento
        let aPrimera:any = await this.countByDoctorAndStatusAndTypeAppointmentAndDateTimeAppointmentBetweenChart('Atendida', 'Primera vez',startMonth,endMonth);
        let aSeguimiento:any = await this.countByDoctorAndStatusAndTypeAppointmentAndDateTimeAppointmentBetweenChart('Atendida', 'Seguimiento',startMonth,endMonth);
        let aPS = aPrimera + aSeguimiento;
        this.consultinChart.chartData[1].data.push(aPS);
        // Total de cirugias atendidas
        let cirugias = await this.countByDoctorAndStatusAndTypeAppointmentAndDateTimeAppointmentBetweenChart('Atendida', 'Cirugía',startMonth,endMonth);
        this.consultinChart.chartData[0].data.push(cirugias);
        // Total de Endoscopia
        let endoscopia = await this.countByDoctorAndStatusAndTypeAppointmentAndDateTimeAppointmentBetweenChart('Atendida', 'Endoscopia',startMonth,endMonth);
        this.consultinChart.chartData[2].data.push(endoscopia);
        index++;
      }
      // console.log(this.consultinChart)
    }catch(e){

    }
  }

  /**funcionalidad para crear la grafica de linea de tiempo de balance
  */
  async creatingChartBalancing(){
    try{
      this.balanceChart.chartLabels = [];
      for(let i = 0; i < this.balanceChart.chartData.length; i++){
        this.balanceChart.chartData[i].data = [];
      }

      let index = 0;
      let end = moment().locale('es-mx').format('M');
      // let endNumber = 11;
      //creamos los labels del mes
      while (index < 12) {
        let month = moment().locale('es-mx').set('month',index).format('MMMM');
        console.log(month);
        this.balanceChart.chartLabels.push(month);
        index++;
      }

      // Generamos las consultas para lo contadores
      index = 0;
      while(index < 12){
        let year:any = this.balanceChart.year;
        // let startMonth = moment().set('day',1).set('months',index).set('year',year).toISOString();
        // let endMonth = moment().set('day',1).set('months',index+1).subtract(1,'day').set('year',year).toISOString();
        let startMonth  = new Date(year, index, 1).toISOString();
        let endMonth =  new Date((new Date(year, index+1, 1)).setDate((new Date(year, index+1, 1)).getDate() - 1)).toISOString();

        // Obtenemos el total de ganancias por consulta, por periodo y doctor
        let incomen:any = await this.summaryConsultingRoomByDoctorAndStatusAndDateTimeAppointmentBetweenDate('Atendida',startMonth,endMonth);
        // Obtenemos el total de otros ingresos por periodo y doctor
        let otherIncomen:any = await this.summaryOtherIncomeByDoctorAndDateExpenditureBetweenDate(startMonth,endMonth);
        // Obtenemos el total de gastos por periodo y doctor
        let expenses:any = await this.summaryExpenseByDoctorAndDateExpenditureBetweenDate(startMonth,endMonth);
        let totalIncome = incomen+otherIncomen;
        let balanceTotal = totalIncome - expenses;
        this.balanceChart.chartData[0].data.push(expenses); //Egresos
        this.balanceChart.chartData[1].data.push(balanceTotal);//utilidad
        this.balanceChart.chartData[2].data.push(totalIncome);//Ingresos
        index++;
      }
      // console.log(this.consultinChart)
    }catch(e){

    }
  }

  countByDoctorAndStatusAndTypeAppointmentAndDateTimeAppointmentBetweenChart(status, typeAppointment, startDate,endDate):Promise<Number> {
    return new Promise((resolve,reject)=>{
      this.session.getRequest("consultingRoom:countByDoctorAndStatusAndTypeAppointmentAndDateTimeAppointmentBetween", { main: { typeAppointment: typeAppointment, status: status, dateStart: moment(startDate).format('DD/MM/YYYY hh:mm:ss'), dateEnd: moment(endDate).format('DD/MM/YYYY hh:mm:ss') }, system: { user: this.session.getUser() } }).subscribe((data: any) => {
        resolve(data.object)
      }, error => {
        console.log("Error: consultingRoom:countByDoctorAndStatusAndTypeAppointmentAndDateTimeAppointmentBetween", error);
        reject(error);
      })
    });

  }

  /***
  UTILIDADES
  **/

  /**funciñonalidad para crear el arreglo de años
  */
  createYears(){
    let year = moment().format('YYYY');
    let initial = 2019;
    this.years = [];

    while(initial <= parseInt(year)){
      this.years.push(initial);
      initial++;
    }
  }

  /**
  Obtenemos el total de ganancias por consulta, por periodo y doctor
  */
  summaryConsultingRoomByDoctorAndStatusAndDateTimeAppointmentBetweenDate(status,startDate,endDate) {
    return new Promise((resolve,reject)=>{
      this.session.getRequest("consultingRoom:summaryByDoctorAndStatusAndDateTimeAppointmentBetween", { main: { status: status, dateStart: moment(startDate).format('DD/MM/YYYY hh:mm:ss'), dateEnd: moment(endDate).format('DD/MM/YYYY hh:mm:ss') }, system: { user: this.session.getUser() } }).subscribe((data: any) => {
        resolve(data.object)
      }, error => {
        console.log("Error: consultingRoom:summaryByDoctorAndStatusAndDateTimeAppointmentBetween", error);
        reject(error);
      })
    })
  }

  /** Obtenemos el total de otros ingresos por periodo y doctor*/
  summaryOtherIncomeByDoctorAndDateExpenditureBetweenDate(startDate,endDate) {
    return new Promise((resolve,reject)=>{
      this.session.getRequest("officeOtherIncome:summaryByDoctorAndDateExpenditure", { main: { dateStart: moment(startDate).format('DD/MM/YYYY hh:mm:ss'), dateEnd: moment(endDate).format('DD/MM/YYYY hh:mm:ss') }, system: { user: this.session.getUser() } }).subscribe((data: any) => {
        resolve(data.object)
      }, error => {
        console.log("Error: officeOtherIncome:summaryByDoctorAndDateExpenditure", error);
        reject(error);
      })
    })

  }

/** Obtenemos el total de gastos por periodo y doctor*/
  summaryExpenseByDoctorAndDateExpenditureBetweenDate(startDate,endDate) {
    return new Promise((resolve,reject)=>{
      this.session.getRequest("officeExpense:summaryByDoctorAndDateExpense", { main: { dateStart: moment(startDate).format('DD/MM/YYYY hh:mm:ss'), dateEnd: moment(endDate).format('DD/MM/YYYY hh:mm:ss') }, system: { user: this.session.getUser() } }).subscribe((data: any) => {
        resolve(data.object)
      }, error => {
        console.log("Error: officeExpense:summaryByDoctorAndDateExpense", error);
        reject(error);
      });
    })
  }

  toogleMenu(ev) {
    this.drawerCtrl.opened = ev;
  }

}
